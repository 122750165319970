import { BlobData } from './loader/bim-api-client';

export function toBlob(fileData: BlobData): Blob {
    if (fileData instanceof Blob) {
        // File inherits Blob so File is handled here
        return fileData;
    } else if (fileData instanceof ArrayBuffer) {
        return new Blob([fileData]);
    } else {
        return new Blob([JSON.stringify(fileData)]);
    }
}

export interface BlobSlice {
    blob: Blob;
    start: number;
    end: number;
}
