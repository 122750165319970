/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { TransformNode, StandardMaterial, Scene, Color3, Vector3, MeshBuilder } from '../loader/babylonjs-import';

/**
 * Shows 3 axes in a specific point in space
 */
export class AxesViewer {
    private _xAxis: TransformNode;
    private _yAxis: TransformNode;
    private _zAxis: TransformNode;
    private _scaleLinesFactor = 4;

    /**
     * Gets or sets a number used to scale line length
     */
    public scaleLines = 1;

    /**
     * Creates a new AxesViewer
     * @param scene defines the hosting scene
     * @param scaleLines defines a number used to scale line length (1 by default)
     * @param renderingGroupId defines a number used to set the renderingGroupId of the meshes (2 by default)
     */
    constructor(parent: TransformNode, public readonly name: string, scaleLines = 1, renderingGroupId = 2) {
        const scene = parent.getScene();
        this.scaleLines = scaleLines;

        const redColoredMaterial = new StandardMaterial(name + ':xAxis', scene);
        redColoredMaterial.disableLighting = true;
        redColoredMaterial.emissiveColor = Color3.Red().scale(0.5);
        this._xAxis = this._CreateAxis(scene, parent, redColoredMaterial);

        const greenColoredMaterial = new StandardMaterial(name + ':yAxis', scene);
        greenColoredMaterial.disableLighting = true;
        greenColoredMaterial.emissiveColor = Color3.Green().scale(0.5);
        this._yAxis = this._CreateAxis(scene, parent, greenColoredMaterial);

        const blueColoredMaterial = new StandardMaterial(name + ':zAxis', scene);
        blueColoredMaterial.disableLighting = true;
        blueColoredMaterial.emissiveColor = Color3.Blue().scale(0.5);
        this._zAxis = this._CreateAxis(scene, parent, blueColoredMaterial);

        this._xAxis.scaling.setAll(this.scaleLines * this._scaleLinesFactor);
        this._yAxis.scaling.setAll(this.scaleLines * this._scaleLinesFactor);
        this._zAxis.scaling.setAll(this.scaleLines * this._scaleLinesFactor);

        AxesViewer._SetRenderingGroupId(this._xAxis, renderingGroupId);
        AxesViewer._SetRenderingGroupId(this._yAxis, renderingGroupId);
        AxesViewer._SetRenderingGroupId(this._zAxis, renderingGroupId);

        this.update(new Vector3(), Vector3.Right(), Vector3.Up(), Vector3.Forward());
    }

    private _CreateAxis(scene: Scene, parent: TransformNode, material: StandardMaterial): TransformNode {
        const arrow = new TransformNode(this.name + ':arrow', scene);
        arrow.parent = parent;
        const line = MeshBuilder.CreateCylinder(
            this.name + ':cylinder',
            { diameterTop: 0.05, height: 0.275, diameterBottom: 0.05, tessellation: 96 },
            scene
        );
        line.material = material;
        line.parent = arrow;

        // Position arrow pointing in its drag axis
        line.material = material;
        line.position.z += 0.275 / 2;
        line.rotation.x = Math.PI / 2;

        return arrow;
    }

    /**
     * Forces the viewer to update
     * @param position defines the position of the viewer
     * @param xaxis defines the x axis of the viewer
     * @param yaxis defines the y axis of the viewer
     * @param zaxis defines the z axis of the viewer
     */
    public update(position: Vector3, xaxis: Vector3, yaxis: Vector3, zaxis: Vector3): void {
        this._xAxis.position.copyFrom(position);
        this._xAxis.setDirection(xaxis);
        this._xAxis.scaling.setAll(this.scaleLines * this._scaleLinesFactor);

        this._yAxis.position.copyFrom(position);
        this._yAxis.setDirection(yaxis);
        this._yAxis.scaling.setAll(this.scaleLines * this._scaleLinesFactor);

        this._zAxis.position.copyFrom(position);
        this._zAxis.setDirection(zaxis);
        this._zAxis.scaling.setAll(this.scaleLines * this._scaleLinesFactor);
    }

    /** Releases resources */
    public dispose(): void {
        this._xAxis.dispose();
        this._yAxis.dispose();
        this._zAxis.dispose();
    }

    private static _SetRenderingGroupId(node: TransformNode, id: number): void {
        node.getChildMeshes().forEach((mesh) => {
            mesh.renderingGroupId = id;
        });
    }
}
