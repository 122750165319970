import { BimIfcClass } from './bim-ifc-class';
import { DeepImmutable } from './babylonjs-import';
import '../MapExtensions';
/**
 * Discipline
 */
export class Discipline {
    private static _cache: Map<string, Discipline> = new Map<string, Discipline>();

    /** @hidden */
    public static readonly NotAvailableShortId: string = 'NA';

    /** @hidden */
    public static readonly NotAvailable = Discipline.getOrAdd(Discipline.NotAvailableShortId);

    /** @hidden */
    public static readonly All: Map<string, string> = new Map<string, string>([
        ['AK', 'Acoustical Engineer'], // Akustik
        ['BE', 'Builder'], // Byggentreprenör
        ['BH', 'Developer'], // Byggherre
        ['BR', 'Fire Protection Engineer'], // Brandprojektör
        ['EE', 'Electrical Engineer '], // Elentreprenör
        ['GE', 'General Contractor'], // Generalentreprenör
        ['HE', 'Mechanical Engineer'], // Hissentreprenör
        ['KE', 'HVAC and Refrigerating Engineer'], // Kylentreprenör
        ['KP', 'Prefab Designer'], // Prefabkonstruktör
        ['ME', 'Soils Engineer'], // Markentreprenör
        ['PL', 'Project Management'], // Projekteringsledning
        ['RE', 'Plumbing Engineer'], // Rörentreprenör
        ['SE', 'Automation Engineer'], // Styrentreprenör
        ['SK', 'Commercial Kitchen Engineer'], // Storköksprojektör
        ['SP', 'Fire Protection Engineer'], // Sprinklerprojektör
        ['TE', 'Turnkey Contractor'], // Totalentreprenör
        ['VE', 'HVAC and Refrigerating Engineer'], // Ventilationsentreprenör
        ['A', 'Architect'], // Arkitekt
        ['B', 'Geologist'], // Bergprojektör
        ['C', 'BIM Coordinator'], // BIM-Samordnare
        ['E', 'Electrical Engineer'], // Elprojektör
        ['F', 'Building Administrator'], // Förvaltare
        ['G', 'Geologist'], // Geotekniker
        ['H', 'Mechanical Engineer'], // Hissprojektör
        ['I', 'Interior Architect'], // Inredningsarkitekt
        ['K', 'Structural Engineer'], // Byggnadskonstruktör
        ['L', 'Landscape Architect'], // Landskapsarkitekt
        ['M', 'Land Surveyor'], // Markprojektör
        ['N', 'Environmental Engineer'], // Miljö
        ['O', 'Hydrologist'], // Hydrogeologi
        ['R', 'Plumbing Engineer'], // Vatten- och avloppsprojektör
        ['S', 'Security Specialist'], // Styr- och övervakningsprojektör
        ['T', 'Transportation Engineer'], // Trafik- och Vägprojektör
        ['V', 'Heat, HVAC and Sanitary Engineer '], // VVS-projektör
        ['W', 'Water and Sanitary Engineer'], // Vs-projektör
        [Discipline.NotAvailableShortId, 'Unspecified']
    ]);

    private readonly _referencedClassesSet: DeepImmutable<Set<BimIfcClass>> = new Set<BimIfcClass>();

    /**
     * Number of BimIfcObject instances using this class.
     */
    public readonly ifcObjectCount: number = 0;

    /**
     * Number of instances where {@link BimIfcObjects.hasGeometry} === true that are using this class
     */
    public readonly ifcObjectsWithGeometryCount: number = 0;

    /**
     * Gets an array of all classes that have references to this disciple.
     */
    public get referencedClasses(): DeepImmutable<BimIfcClass>[] {
        return [...this._referencedClassesSet.values()];
    }

    /** @hidden */
    public addReferencedClass(ifcClass: BimIfcClass): void {
        this._referencedClassesSet.add(ifcClass);
    }

    /** Discipline description */
    public get description(): string {
        return Discipline.All.get(this.short) ?? 'Other';
    }

    private constructor(public readonly short: string) {
        if (short === undefined) {
            throw new Error('No discipline defined');
        }
    }

    /**
     * Get the discipline that a serverRelativeUrl represents. We simply split the
     * url on / and attempt to find the last segment that matches a known discipline short id. If none is found then
     * {@link Discipline.NotAvailable} is returned.
     * @param serverRelativeUrl
     * @returns Discipline. If instance is reference equal to {@link Discipline.NotAvailable} then no discipline was found.
     */
    public static getOrAddFromServerRelativeUrl(serverRelativeUrl: string): Discipline {
        const segments = serverRelativeUrl.split('/').reverse();
        for (const segment of segments) {
            if (segment !== Discipline.NotAvailableShortId && Discipline.All.has(segment.toUpperCase())) {
                return Discipline.getOrAdd(segment);
            }
        }
        return Discipline.NotAvailable;
    }

    /**
     * Gets a {@link Discipline} instance from a discipline short code.
     * @param disciplineShort short discipline code. See {@link Discipline.All} for a non exhaustive list.
     * @returns {@link Discipline} representing the short code.
     */
    public static getOrAdd(disciplineShort: string): Discipline {
        disciplineShort = disciplineShort.toUpperCase();
        return Discipline._cache.getOrAdd(disciplineShort, (short) => new Discipline(short));
    }

    /** Resets all disciplines to their original state.
     * {@link _referencedClassesSet} is cleared. {@link ifcObjectCount} and {@link ifcObjectsWithGeometryCount}
     * are set to 0
     */
    public static reset(): void {
        for (const d of Discipline._cache.values()) {
            d._referencedClassesSet.clear();
            (d.ifcObjectCount as number) = 0;
            (d.ifcObjectsWithGeometryCount as number) = 0;
        }
    }
}
