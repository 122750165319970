import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { Material } from '@babylonjs/core/Materials/material';
import { BimIfcSpace, Vertex3, TrackCoordinate2D, Vertex2, BimIfcObject } from '@twinfinity/core';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { ObjectPool } from './ObjectPool';
import { Engine } from '@babylonjs/core';

/**
 * HTML representation of a space label.
 */
class SpaceLabelElement {
    private readonly _root: HTMLDivElement;
    private readonly _cb: HTMLInputElement;
    private _onChange?: () => void;
    public constructor() {
        this._root = document.createElement('div');
        this._root.classList.add('spaceLabel', 'hidden');
        this._root.style.position = 'absolute';
        this._root.innerHTML = `
        <div>
            <span class="title"></span>
        </div>
        <div>
            <span>
                <input class="visible" type="checkbox" ></input>
            </span>
            <span><span class="area"></span> m<sup>2</sup></span>
        </div>`;

        this._cb = this._root.querySelector('input')!;
        window.document.body.appendChild(this._root);
    }

    public show(
        title: string,
        area: number,
        isTransparentSpaceEnabled: boolean,
        onChange: (checked: boolean) => void
    ): void {
        const titleElement = this._root.querySelector('.title')!;
        titleElement.textContent = title;
        const areaElement = this._root.querySelector('.area')!;
        areaElement.textContent = area.toFixed(1);
        this._root.classList.remove('hidden');

        if (this._onChange !== undefined) {
            this._cb.removeEventListener('change', this._onChange);
        }
        this._onChange = () => onChange(this._cb.checked);
        this._cb.addEventListener('change', this._onChange);
        this._cb.checked = isTransparentSpaceEnabled;
    }

    public move(position: Vertex2, distanceToCamera: number): void {
        const clientRect = this._root.getBoundingClientRect();
        const w = clientRect.width / 2;
        const h = clientRect.height / 2;
        // update css left and top including canvas offset on screen
        this._root.style.left = position.x - w + 'px';
        this._root.style.top = position.y - h + 'px';
        this._root.style.zIndex = 1000 - Math.floor(distanceToCamera) + ''; //depth sorting labels
    }

    public hide(): void {
        this._root.classList.add('hidden');
        if (this._onChange !== undefined) {
            this._cb.removeEventListener('change', this._onChange);
        }
    }
}

/**
 * Object pool of spacelabel elements. This allows reuse of existing DOM elements so we do not have
 * to add too many extra elements to the DOM needlessly. Saves some RAM as well.
 */
const spaceLabelElementPool = new ObjectPool<SpaceLabelElement>(() => new SpaceLabelElement());

/**
 * Represents a space label. A space label consists of a HTML element
 * and (if user clicks the checkbox in the label) a transparent BabylonJS mesh
 * with the same geometry as the original space.
 */
export class SpaceLabel implements Vertex3 {
    private _spaceLabelElement?: SpaceLabelElement;
    public x = 0;
    public y = 0;
    public z = 0;
    public visibleBelowDistance = 50;
    public readonly id: string;

    public readonly space: BimIfcSpace;

    private _transparentBabylonMesh?: Mesh;

    public constructor(
        private readonly _engine: Engine,
        private readonly _parent: TransformNode,
        private readonly _spaceMeshMaterial: Material,
        private readonly _isSpaceLabelVisible: (space: BimIfcSpace) => boolean,
        private readonly _isSpaceVisible: (space: BimIfcSpace) => boolean,
        space: BimIfcSpace
    ) {
        const bbox = space.boundingInfo().boundingBox;
        const p = bbox.centerWorld;

        this.id = `${space.ifc.id}.${space.ifc.version}.${space.entityLabelInIfc}`;
        this.x = p.x;
        this.y = bbox.minimum.y;
        this.z = p.z;
        this.space = space;
    }

    public dispose(): void {
        if (this._spaceLabelElement) {
            this._spaceLabelElement.hide();
            spaceLabelElementPool.release(this._spaceLabelElement);
        }
        this.ensureTransparentSpaceRemoved();
    }

    /**
     * Called whenever the coordinate tracker for the space labels gives us an update.
     * @param trackedCoordinate
     */
    public updateScreenPosition(trackedCoordinate: TrackCoordinate2D): void {
        // This method is called when camera changes or when ifc object visibility/colors
        // change. Ie it will be called when this.space becomes visible/invisible

        // NOTE We could also look at trackedCoordinate.state to determine when to add, update
        // and delete space label. In this particular case it is not needed though.

        const isSpaceLabelElementVisible =
            this._isSpaceLabelVisible(this.space) &&
            trackedCoordinate.distance < this.visibleBelowDistance &&
            trackedCoordinate.visible;

        if (isSpaceLabelElementVisible) {
            if (this._spaceLabelElement === undefined) {
                this._spaceLabelElement = spaceLabelElementPool.get();
                // getLabel is just a simple way to get some text for the label
                // not really correct. In reality we should probably check what kind
                // of space it is and then get text by looking at appropriate properties
                // on either space object or in its property bag. Perhaps different types
                // of spaces should have different colors as well?
                const spaceLabelTitle = this.getTitle(this.space);
                const isTransparentMeshCurrentlyVisible = this._transparentBabylonMesh !== undefined;
                this._spaceLabelElement.show(
                    spaceLabelTitle,
                    this.space.calculatedArea ?? 0,
                    isTransparentMeshCurrentlyVisible,
                    (isTransparentSpaceEnabled) => {
                        // This callback occurs when user toggles the checkbox in the label
                        // checkbox determines whether the transparent space should be visible or not
                        if (!isTransparentSpaceEnabled) {
                            this.ensureTransparentSpaceRemoved();
                        } else if (isTransparentSpaceEnabled) {
                            this.ensureTransparentSpaceExists();
                        }
                    }
                );
            }

            this._spaceLabelElement.move(trackedCoordinate.position, trackedCoordinate.distance);
        } else {
            if (this._spaceLabelElement) {
                this._spaceLabelElement.hide();
                spaceLabelElementPool.release(this._spaceLabelElement);
                this._spaceLabelElement = undefined;
            }
        }
    }

    private ensureTransparentSpaceExists(): void {
        if (this._transparentBabylonMesh !== undefined) {
            return;
        }
        const objects: BimIfcObject[] = [this.space];
        // Note this only works if object is already on the GPU. Otherwise you have to use
        // BimIfcOBject.createGeometryFromAsync(). bimIfcObject.isOnGpu() will tell you if object
        // is on GPU or not. (Basically it means that we know that the geometry has been loaded for the object).
        const g = BimIfcObject.createGeometryFrom(objects);
        this._transparentBabylonMesh = new Mesh(this.id, this._parent.getScene(), this._parent);
        this._transparentBabylonMesh.material = this._spaceMeshMaterial;
        g.applyToMesh(this._transparentBabylonMesh, this._engine);
        this.space.visible(false);
    }

    private ensureTransparentSpaceRemoved(): void {
        if (this._transparentBabylonMesh !== undefined) {
            this._transparentBabylonMesh.dispose();
            this._transparentBabylonMesh = undefined;
            this.space.visible(this._isSpaceVisible(this.space));
        }
    }

    /**
     * Just a helper method to get a title for a space. Not really production ready. In reality
     * it is probably much more complicated to get a good title for space. We should probably know
     * what kind of space it is and based on that look for specific values in the property set's of the IFC object.
     * @param space Space to get `title ` for.
     */
    private getTitle(space: BimIfcSpace): string {
        const labels = [this.space.name, this.space.description, this.space.longname, this.space.gid];
        let label = '';
        if (space.spaceType) label += `[${space.spaceType}] `;
        for (const l of labels) {
            if (!!l && l !== 'undefined') {
                return l;
            }
        }
        return label || '-';
    }
}
