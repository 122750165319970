/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */
import { TargetCamera } from '@babylonjs/core/Cameras/targetCamera';
import { Ray } from '@babylonjs/core/Culling/ray';
import { DynamicTexture } from '@babylonjs/core/Materials/Textures/dynamicTexture';
import { ISize } from '@babylonjs/core/Maths/math.size';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { BimCoreApi, intersectsPlaneAtToRef, PlaneUtil } from '@twinfinity/core';
import { BimDrawingParentBase } from './BimDrawingParentBase';
import { BimDrawingPlane } from './BimDrawingPlane';
import { BimPdf } from './BimPdf';
import { DrawingApi } from './DrawingApi';

interface drawImageCoords {
    sx: number;
    sy: number;
    sWidth: number;
    sHeight: number;
    dx: number;
    dy: number;
    dWidth: number;
    dHeight: number;
}

export class BimPdfParent extends BimDrawingParentBase {
    constructor(_api: BimCoreApi, _textureSize: number, readonly pdf: BimPdf, parent: TransformNode) {
        super(_api, _textureSize, parent);
        pdf.parent = this;
        this.addOnRenderObs();
    }

    //These are used to temporarily store values to avoid allocating new memory every time we zoom.
    tmpVec = Vector3.Zero();
    tmpVec2 = Vector3.Zero();
    canvasPosVectors = [Vector3.Zero(), Vector3.Zero()];

    /**
     * Zoom in or out on the SVG based off of the cameras view.
     * @param camera The camera to base the zoom off of.
     * @param canvasRect The canvas embedded is rendering to.
     */
    public async zoom(camera: TargetCamera, canvasRect: ISize): Promise<boolean> {
        this._finishedDrawing = false;
        // Notice that we use a square instead of full canvas.
        const canvasSize = Math.max(canvasRect.width, canvasRect.height);
        const minX = (canvasRect.width - canvasSize) / 2;
        const minY = (canvasRect.height - canvasSize) / 2;
        const canvasPositions = [
            { x: minX, y: minY },
            { x: canvasRect.width - minX, y: canvasRect.height - minY }
        ];

        const ray = new Ray(BimDrawingParentBase.vec3Zero, BimDrawingParentBase.vec3Up);
        // TODO Predefined points instead. We know how many there are.
        const frustumCornerPointsOnZoomPlane: Vector3[] = [];

        //Get points in world coordinates where the top left and bottom right of the camera frustrum intersects the PDF plane.
        for (let i = 0; i < canvasPositions.length; ++i) {
            const canvasPos = canvasPositions[i];
            camera.twinfinity.createPickingRayToRef(ray, canvasPos);
            this.canvasPosVectors[i].set(0, 0, 0);
            const p = this.canvasPosVectors[i];

            intersectsPlaneAtToRef(ray, this.plane.mathematicalPlane, p);
            frustumCornerPointsOnZoomPlane.push(p);
        }

        //Rescale PDF plane to fit camera frustrum.
        this.plane.rescaleToPoints(frustumCornerPointsOnZoomPlane);
        await this._svgTex.renderPartOfPDFToRef(
            this.plane.texture,
            this.pdf,
            this.plane,
            frustumCornerPointsOnZoomPlane
        );

        this._finishedDrawing = true;
        return true;
    }

    public calculatePDFDrawImageCoords(
        texture: DynamicTexture,
        bimPdf: BimPdf,
        plane: BimDrawingPlane,
        frustumCornerPointsOnZoomPlane: Vector3[]
    ): drawImageCoords | undefined {
        const defaultCenter = DrawingApi.defaultPosition;
        const defaultScale = DrawingApi.defaultScale;
        const currentCenter = frustumCornerPointsOnZoomPlane[0].subtract(
            frustumCornerPointsOnZoomPlane[0].subtract(frustumCornerPointsOnZoomPlane[1]).scale(0.5)
        );
        const currentScale = new Vector3(
            plane.parent.scaling.x * plane.mesh.scaling.x,
            1,
            plane.parent.scaling.z * plane.mesh.scaling.z
        );

        const ppuWidth = Math.max(bimPdf.canvas.width, bimPdf.canvas.height) / defaultScale.x;
        const textureppu = texture.getSize().width / currentScale.x;
        const intersectionPoints = PlaneUtil.planesIntersect(defaultCenter, defaultScale, currentCenter, currentScale);

        if (intersectionPoints === undefined) {
            texture.update();
            this._api.viewer.wakeRenderLoop();
            return undefined;
        }

        const topLeftDefault = defaultCenter.subtract(new Vector3(-defaultScale.x / 2, 0, defaultScale.z / 2));
        const topLeftCurrent = currentCenter.subtract(new Vector3(-currentScale.x / 2, 0, currentScale.z / 2));

        const topLeftIntersection = new Vector3(intersectionPoints[1].x, 0, intersectionPoints[0].z);
        const bottomRightIntersection = new Vector3(intersectionPoints[0].x, 0, intersectionPoints[1].z);

        const defaultDiffTopLeft = topLeftIntersection.subtract(topLeftDefault);
        const defaultDiffBottomRight = bottomRightIntersection.subtract(topLeftDefault);
        const defaultDelta = defaultDiffBottomRight.subtract(defaultDiffTopLeft);

        const currentDiffTopLeft = topLeftIntersection.subtract(topLeftCurrent);
        const currentDiffBottomRight = bottomRightIntersection.subtract(topLeftCurrent);
        const currentDelta = currentDiffBottomRight.subtract(currentDiffTopLeft);

        const sourceCanvasX = Math.abs(defaultDiffTopLeft.x * ppuWidth);
        const sourceCanvasY = Math.abs(defaultDiffTopLeft.z * ppuWidth);
        const sourceCanvasWidth = Math.abs(defaultDelta.x * ppuWidth);
        const sourceCanvasHeight = Math.abs(defaultDelta.z * ppuWidth);

        const destinationCanvasX = Math.abs(currentDiffTopLeft.x * textureppu);
        const destinationCanvasY = Math.abs(currentDiffTopLeft.z * textureppu);
        const destinationCanvasWidth = Math.abs(currentDelta.x * textureppu);
        const destinationCanvasHeight = Math.abs(currentDelta.z * textureppu);

        return {
            sx: sourceCanvasX,
            sy: sourceCanvasY,
            sWidth: sourceCanvasWidth,
            sHeight: sourceCanvasHeight,
            dx: destinationCanvasX,
            dy: destinationCanvasY,
            dWidth: destinationCanvasWidth,
            dHeight: destinationCanvasHeight
        };
    }
}
