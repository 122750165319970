/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { Plane, Vector3 } from '../loader/babylonjs-import';

/**
 * Represents a frustum.
 */
export type FrustumPlanes = [Plane, Plane, Plane, Plane, Plane, Plane];

/**
 * Create a default (non valid) frustum.
 */
export function createNullFrustum(): FrustumPlanes {
    return [
        new Plane(0, 0, 0, 0),
        new Plane(0, 0, 0, 0),
        new Plane(0, 0, 0, 0),
        new Plane(0, 0, 0, 0),
        new Plane(0, 0, 0, 0),
        new Plane(0, 0, 0, 0)
    ];
}

/**
 * Check if `position` is within a specified frustum (`frustmPlanes`).
 * @param position Position to check
 * @param frustumPlanes Frustum to check against.
 * @returns `true` if points is within frustum, otherwise `false`.
 */
export function isPointInFrustum(position: Vector3, frustumPlanes: FrustumPlanes): boolean {
    for (let i = 0; i < 6; i++) {
        if (frustumPlanes[i].dotCoordinate(position) < 0) {
            return false;
        }
    }
    return true;
}
