import { PropertyChangeObservable, makePropertyChangeObservable } from '../PropertyChangeObservable';
import { Vertex3 } from '../math';
import { Color3, Plane, Scene, Vector3 } from './babylonjs-import';

export type ClipPlanes<T = PropertyChangeObservable<ClipPlane>> = {
    [plane in ClipPlaneName]?: T;
};

export type ClipPlaneName = keyof Pick<
    Scene,
    'clipPlane' | 'clipPlane2' | 'clipPlane3' | 'clipPlane4' | 'clipPlane5' | 'clipPlane6'
>;

type ClipPlanePoint = {
    readonly enabled: boolean;
    readonly clipPlaneIndex: ClipPlaneName;
};

type EnableClipPlanePoint = {
    readonly enabled: true;
    readonly point: Vertex3;
    readonly normalVector: Vertex3;
    readonly color?: Color3;
    readonly fadeSize?: number;
    readonly ignoreDepthWrite?: boolean;
} & ClipPlanePoint;

type DisableClipPlanePoint = {
    readonly enabled: false;
} & ClipPlanePoint;

export type ClipPlaneOptions = EnableClipPlanePoint | DisableClipPlanePoint;

export class ClipPlane {
    enabled: boolean;
    readonly index: ClipPlaneName;
    readonly position: Vertex3;
    readonly normal: Vertex3;
    readonly color: Color3;
    readonly plane: Plane;
    fadeSize: number;
    ignoreDepthWrite: boolean;

    public static readonly allClipPlanes = new Set<ClipPlaneName>();
    private static _clipPlanes: ClipPlanes = {};

    public static create(o: ClipPlanes<{ readonly color: Color3 }>): ClipPlanes {
        const clipPlanes: ClipPlanes = {};
        for (const [planeName, plane] of ClipPlane.entries(o)) {
            clipPlanes[planeName] = makePropertyChangeObservable(new ClipPlane(planeName, plane.color));
        }
        return clipPlanes;
    }

    static {
        ClipPlane.allClipPlanes.add('clipPlane');
        ClipPlane.allClipPlanes.add('clipPlane2');
        ClipPlane.allClipPlanes.add('clipPlane3');
        ClipPlane.allClipPlanes.add('clipPlane4');
        ClipPlane.allClipPlanes.add('clipPlane5');
        ClipPlane.allClipPlanes.add('clipPlane6');
    }

    private constructor(index: ClipPlaneName, color: Color3) {
        this.index = index;
        this.enabled = false;
        this.plane = new Plane(0.0, 0.0, 0.0, 0.0);
        this.color = color;
        this.position = new Vector3(0.0, 0.0, 0.0);
        this.normal = new Vector3(0.0, 0.0, 0.0);
        this.fadeSize = 0.25;
        this.ignoreDepthWrite = false;
    }

    public static *entries<T>(o: ClipPlanes<T>): IterableIterator<[ClipPlaneName, T]> {
        for (const [planeName, plane] of Object.entries(o) as [ClipPlaneName, T | undefined | null][]) {
            if (plane && ClipPlane.allClipPlanes.has(planeName)) {
                yield [planeName, plane];
            }
        }
    }

    public static entry(name: ClipPlaneName): ClipPlane {
        const clipPlane = ClipPlane._clipPlanes[name];

        if (clipPlane) {
            return clipPlane;
        } else {
            throw new Error('Clipplane for ' + name + ' missing');
        }
    }
}
