import { MarkupEntity, MarkupEntityConstructorArgs } from './MarkupEntity';
import { MarkupSheet2D } from './MarkupSheet2D';
import { Vertex3 } from '../../math';
import { Color3, Color4 } from '../../loader/babylonjs-import';
import { MarkupProperty, MarkupStyle } from '../MarkupEntityTypes';
import { equalsWithEpsilonVertex3 } from '../../math/index';
import { MergableObjectEventArgs, MergableObjectEventSource } from '../../MergableSet';

/**
 * Style for the markup arrow.
 */
export interface MarkupArrowStyle {
    /** Background color for the markup arrow */
    backgroundColor: Color4;
}

/**
 * Class representing a markup arrow in the markup API.
 */
export class MarkupArrow extends MarkupEntity implements MarkupStyle<MarkupArrowStyle> {
    arrowPoint: Vertex3;
    bluntPoint: Vertex3;
    lineThickness: number;
    arrowWidth: number;
    arrowHeight: number;

    style = { backgroundColor: new Color4(...Color3.Teal().asArray(), 1.0) };

    /**
     * Do not use. Use {@link MarkupSheet2D.add} instead.
     * @hidden
     */
    public constructor({
        id,
        sheet,
        properties,
        arrowPoint,
        bluntPoint,
        lineThickness,
        arrowWidth,
        arrowHeight,
        style
    }: MarkupEntityConstructorArgs & {
        arrowPoint: Vertex3;
        bluntPoint: Vertex3;
        lineThickness: number;
        arrowWidth: number;
        arrowHeight: number;
        style: MarkupArrowStyle;
    }) {
        super(id, sheet, properties);
        this.style = style;
        this.arrowPoint = arrowPoint;
        this.bluntPoint = bluntPoint;
        this.lineThickness = lineThickness;
        this.arrowWidth = arrowWidth;
        this.arrowHeight = arrowHeight;
    }

    /**
     * Copy the arrow to another markup sheet.
     * @param sheet Markup sheet to copy the arrow to.
     * @returns The new markup arrow entity.
     */
    copyTo(sheet: MarkupSheet2D): MarkupArrow {
        const deepCopyArrowPoint: Vertex3 = { x: this.arrowPoint.x, y: this.arrowPoint.y, z: this.arrowPoint.z };
        const deepCopyBluntPoint: Vertex3 = { x: this.bluntPoint.x, y: this.bluntPoint.y, z: this.bluntPoint.z };

        return sheet.add(
            MarkupArrow,
            {
                arrowPoint: deepCopyArrowPoint,
                bluntPoint: deepCopyBluntPoint,
                style: { backgroundColor: this.style.backgroundColor.clone() },
                lineThickness: this.lineThickness,
                arrowWidth: this.arrowWidth,
                arrowHeight: this.arrowHeight
            },
            new Map<string, MarkupProperty>(this.properties)
        );
    }

    onUpdate(o: MergableObjectEventArgs<MarkupArrow>): void {
        if (o.eventSource === MergableObjectEventSource.Remote && !o.conflictReason && o.remote) {
            this.copyFrom(o.remote);
        }

        super.onUpdate(o);
    }

    copyFrom(arrow: MarkupArrow): void {
        this.bluntPoint = { x: arrow.bluntPoint.x, y: arrow.bluntPoint.y, z: arrow.bluntPoint.z };
        this.arrowPoint = { x: arrow.arrowPoint.x, y: arrow.arrowPoint.y, z: arrow.arrowPoint.z };
        this.arrowHeight = arrow.arrowHeight;
        this.arrowWidth = arrow.arrowWidth;
        this.lineThickness = arrow.lineThickness;

        this.properties.clear();
        for (const [key, value] of arrow.properties) {
            this.properties.set(key, value);
        }
    }

    isEqual(o: MarkupArrow): boolean {
        return (
            o.id === this.id &&
            o.sheet.isEqual(this.sheet) &&
            o.properties.size === this.properties.size &&
            [...this.properties].every(([key, value]) => value === o.properties.get(key)) &&
            equalsWithEpsilonVertex3(this.arrowPoint, o.arrowPoint) &&
            equalsWithEpsilonVertex3(this.bluntPoint, o.bluntPoint)
        );
    }

    clone(): MarkupArrow {
        const arrow = new MarkupArrow({
            id: this.id,
            sheet: this.sheet,
            properties: new Map<string, MarkupProperty>(this.properties),
            style: { backgroundColor: this.style.backgroundColor.clone() },
            arrowPoint: { x: this.arrowPoint.x, y: this.arrowPoint.y, z: this.arrowPoint.z },
            bluntPoint: { x: this.bluntPoint.x, y: this.bluntPoint.y, z: this.bluntPoint.z },
            lineThickness: this.lineThickness,
            arrowHeight: this.arrowHeight,
            arrowWidth: this.arrowWidth
        });
        return arrow;
    }
}
