/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { TypedArray, BimVertexData, BimProductMeshDescriptor } from './bim-format-types';

type ArrayBufferOffset = {
    array: ArrayBuffer;
    offsetInBytes: number;
};

enum TypedArrayType {
    Uint8 = 0,
    Uint16 = 1,
    Uint32 = 2,
    Float32 = 3,
    Float64 = 4
}

export class BimVertexDataReader {
    private static readonly _typedArrayByteAlignment: number = 4;

    public constructor(private readonly _encodedVertexData: ArrayBuffer) {}

    private getPadByteCount(size: number, alignmentInBytes: number): number {
        return (alignmentInBytes - (size % alignmentInBytes)) % alignmentInBytes;
    }

    private readPadding(
        arrayBufferOffset: ArrayBufferOffset,
        alignmentInBytes: number,
        throwOnInvalidPadding: boolean
    ): number {
        const padCount = this.getPadByteCount(arrayBufferOffset.offsetInBytes, alignmentInBytes);
        if (padCount === 0) {
            return 0;
        }
        if (throwOnInvalidPadding) {
            const bytes = new Uint8Array(arrayBufferOffset.array, arrayBufferOffset.offsetInBytes, padCount);
            for (let i = 0; i < padCount; ++i) {
                const isValid = bytes[i] === 1 << i % 8;
                if (!isValid) {
                    throw new Error('Invalid padding');
                }
            }
        }
        arrayBufferOffset.offsetInBytes += padCount;
        return padCount;
    }

    private readTypedArray(
        arrayBufferOffset: ArrayBufferOffset,
        length: number
    ): { array: TypedArray; sizeInBytes: number } {
        const arrayType: TypedArrayType = new Uint32Array(
            arrayBufferOffset.array,
            arrayBufferOffset.offsetInBytes,
            1
        )[0];
        arrayBufferOffset.offsetInBytes += Uint32Array.BYTES_PER_ELEMENT;
        let sizeInBytes = 0;
        let unsignedTypedArray: TypedArray;
        if (arrayType === TypedArrayType.Uint8) {
            unsignedTypedArray = new Uint8Array(arrayBufferOffset.array, arrayBufferOffset.offsetInBytes, length);
            sizeInBytes = unsignedTypedArray.length * Uint8Array.BYTES_PER_ELEMENT;
        } else if (arrayType === TypedArrayType.Uint16) {
            unsignedTypedArray = new Uint16Array(arrayBufferOffset.array, arrayBufferOffset.offsetInBytes, length);
            sizeInBytes = unsignedTypedArray.length * Uint16Array.BYTES_PER_ELEMENT;
        } else if (arrayType === TypedArrayType.Uint32) {
            unsignedTypedArray = new Uint32Array(arrayBufferOffset.array, arrayBufferOffset.offsetInBytes, length);
            sizeInBytes = unsignedTypedArray.length * Uint32Array.BYTES_PER_ELEMENT;
        } else if (arrayType === TypedArrayType.Float32) {
            unsignedTypedArray = new Float32Array(arrayBufferOffset.array, arrayBufferOffset.offsetInBytes, length);
            sizeInBytes = unsignedTypedArray.length * Float32Array.BYTES_PER_ELEMENT;
        } else if (arrayType === TypedArrayType.Float64) {
            unsignedTypedArray = new Float64Array(arrayBufferOffset.array, arrayBufferOffset.offsetInBytes, length);
            sizeInBytes = unsignedTypedArray.length * Float64Array.BYTES_PER_ELEMENT;
        } else {
            throw new Error('Unsupported vertex type');
        }
        arrayBufferOffset.offsetInBytes += sizeInBytes;
        this.readPadding(arrayBufferOffset, BimVertexDataReader._typedArrayByteAlignment, true);
        return {
            array: unsignedTypedArray,
            sizeInBytes: sizeInBytes
        };
    }

    public deflateToRef(descriptor: BimProductMeshDescriptor, dst: BimVertexData): BimVertexData {
        const geomData = this._encodedVertexData;
        const { byteOffset, byteSize, vertexCount, indexCount } = {
            byteOffset: descriptor.obd,
            byteSize: descriptor.sbd,
            vertexCount: descriptor.p,
            indexCount: descriptor.i
        };

        // TODO Create class ArrayBufferReader. Will have methods to create for example arrays and a offset we can use to avoid
        // going outside data bounds.
        const data: ArrayBufferOffset = { array: geomData, offsetInBytes: byteOffset }; // offset in array buffer where vertex data starts

        // Read header. Since header has a version number and has a dynamic size
        // future versions of this code may read more data but old versions should always be able to
        // read only the old data.
        const [version, headerSizeIncludingVersion] = new Uint16Array(data.array, data.offsetInBytes, 2);
        if (version < 1) {
            throw new Error(`Unsupported version ${version}. Only support version 1 and upwards`);
        }
        data.offsetInBytes += headerSizeIncludingVersion;

        // Read the vertex offset
        const [offsetX, offsetY, offsetZ] = new Float64Array(data.array, data.offsetInBytes, 3);
        data.offsetInBytes += 3 * Float64Array.BYTES_PER_ELEMENT;

        // Read vertex quantizer values
        let [kX, kY, kZ] = new Float64Array(data.array, data.offsetInBytes, 3);
        data.offsetInBytes += 3 * Float64Array.BYTES_PER_ELEMENT;

        // Read the vertex array and (if quantized) dequantize the vertices
        const positions = this.readTypedArray(data, vertexCount * 3);

        const len = positions.array.length;

        if (kX > 0) {
            kX = 1.0 / kX;
            kY = 1.0 / kY;
            kZ = 1.0 / kZ;
            for (let i = 0; i < len; i += 3) {
                dst.positions[i] = positions.array[i] * kX + offsetX;
                dst.positions[i + 1] = positions.array[i + 1] * kY + offsetY;
                dst.positions[i + 2] = positions.array[i + 2] * kZ + offsetZ;
            }
        } else {
            for (let i = 0; i < len; i += 3) {
                dst.positions[i] = positions.array[i] + offsetX;
                dst.positions[i + 1] = positions.array[i + 1] + offsetY;
                dst.positions[i + 2] = positions.array[i + 2] + offsetZ;
            }
        }

        // Read the indice array
        const indices = this.readTypedArray(data, indexCount);
        dst.indices.set(indices.array, 0);

        // VERY IMPORTANT!!!!!. Packed normals are unsigned int16 not signed int16!!!!! Using Int16Array leads to almost correct normals but half are flipped
        // also note that backend actually sends them as big endian encoded uint16 (so bytes are [u,v] where u is most significant).
        const packedNormals = new Uint16Array(data.array, data.offsetInBytes, vertexCount);
        data.offsetInBytes += packedNormals.length * packedNormals.BYTES_PER_ELEMENT;

        // Skip padding bytes
        this.readPadding(data, 8, true);

        if (data.offsetInBytes > data.array.byteLength) {
            throw new Error('Invalid geometry array buffer');
        }

        if (data.offsetInBytes !== byteOffset + byteSize) {
            throw new Error('Did not read all data');
        }

        dst.packedNormals.set(packedNormals, 0);

        return dst;
    }
}
