/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */

import { Vertex3 } from '.';

export function getTriangleArea(p1: Vertex3, p2: Vertex3, p3: Vertex3): number {
    const v1x = p1.x - p2.x;
    const v1y = p1.y - p2.y;
    const v1z = p1.z - p2.z;
    const v2x = p3.x - p2.x;
    const v2y = p3.y - p2.y;
    const v2z = p3.z - p2.z;

    const crossx = v1y * v2z - v1z * v2y;
    const crossy = v1z * v2x - v1x * v2z;
    const crossz = v1x * v2y - v1y * v2x;

    return Math.sqrt(crossx * crossx + crossy * crossy + crossz * crossz) * 0.5;
}
