import { TwinfinityPostProcess } from './TwinfinityPostProcess';
import { Effect, PostProcess, Camera } from '../loader/babylonjs-import';

export const TEXTURE_SAMPLER = 'textureSampler';

export abstract class BaseLineShading extends TwinfinityPostProcess {
    private _lineThickness = 1;
    protected _screenWrite: boolean;

    /**
     * Returns the current line thickness value.
     */
    public get lineThickness(): number {
        return this._lineThickness;
    }

    /**
     * Sets the line thickness and updates the shader with the new value.
     * @param thickness - Thickness of the lines, value between 0 and 1.
     */
    public set lineThickness(thickness: number) {
        this._lineThickness = thickness;
        this._lineThickness = Math.min(Math.max(thickness, 0.0), 1.0);
        const effect = this._postProcess?.getEffect();
        if (effect !== undefined) {
            this.updateLineThicknessUniform(effect, this._lineThickness);
        }
    }

    /*
     * Updates the thickness uniform in the fragment shader.
     */
    protected updateLineThicknessUniform(effect: Effect, thickness: number): void {
        effect.setFloat('thickness', thickness);
    }

    protected abstract initialize(camera: Camera): PostProcess;

    /**
     * @constructor
     * @param lineThickness Thickness of the lines, value between 0 and 1.
     */
    constructor(lineThickness = 1) {
        super();
        this.lineThickness = lineThickness;
    }
}
