import { BimVertexData, BimProductMeshDescriptor } from './bim-format-types';
import { BimIfcIndex } from './BimIfcIndex';
import { BimChangeIfc } from './bim-api-client';
import { BimVertexDataRepository } from './bim-vertex-data-repository';
import { BimPropertySetRepository } from './bim-property-set-repository';
import { BimTransformsRepository } from './bim-transforms-repository';
import { BimIfcObject, BimIfcSpace, BimIfcBuildingStorey } from './bim-ifc-object';
import { Discipline } from './discipline';
import { Materials } from './materials';
import { BimIfcStyle } from './bim-ifc-style';
import { BimIfcClass } from './bim-ifc-class';
import { Writeable } from '../Types';
import { BimIfcLoaderElementParent } from './bim-ifc-loader';
import { BimApiIfcObjectsLoadOptions } from '../BimApiLoadOptions';
import { HttpResponseType } from '../http';
import { BimCoreApiClient } from './client/BimCoreApiClient';
import { IfcProductMesh } from './IfcProductMesh';
import { FastTransform } from '../math/FastTransform';
import { GeometryArrayOffset, GeometryArrays } from './GeometryBuffer';
import { Matrix, Quaternion, Vector3 } from './babylonjs-maths-import';
import { BimTypeObjectRepository } from './BimTypeObjectRepository';
import { coordinateSystems } from './babylon-coordinate-system';
import { BimObjectAttribute } from './BimObjectAttribute';
import { setMax, setMin, Vertex3 } from '../math';
import { BimObjectAttributeReader } from './BimObjectAttributeReader';
import { Fail, Failure } from '../fail';
import { BimProductMesh } from './BimProductMesh';

/**
 * Represents an IFC file and provides access to its data.
 */
export interface IBimIfcLoaderElement {
    /**
     * The total number of vertices contained in all instances of {@link BimProductMesh}
     * in this IFC file.
     */
    readonly numberOfVertices: number;

    /**
     * The total number of indices contained in all instances of {@link BimProductMesh}
     * in this IFC file.
     */
    readonly numberOfIndices: number;

    /**
     * The total number of triangles contained in all instances of {@link BimProductMesh}
     * in this IFC file.
     */
    readonly numberOfTriangles: number;

    /**
     * All {@link BimIfcClass} instances referenced by {@link BimProduct}'s in the IFC file this instance represents.
     */
    readonly classes: BimIfcClass[];

    /**
     * The IfcProject instance of the IFC file this instance represents.
     */
    readonly project: BimIfcObject;

    /**
     * The total number of {@link BimProductMesh} instances in the IFC file this instance represents.
     * Same as {@link ifcProductsWithGeometryCount}.
     */
    readonly meshCount: number;

    /**
     * The total number of {@link BimProductMesh} instances in the IFC file this instance represents.
     * Same as {@link meshCount}.
     */
    readonly ifcProductsWithGeometryCount: number;
    /**
     * All IfcSpace ({@link BimIfcSpace}) instances in the IFC file this instance represents.
     */
    readonly spaces: BimIfcSpace[];

    /**
     * All IfcBuildingStorey ({@link BimIfcBuildingStorey}) instances in the IFC file this instance represents.
     */
    readonly floors: BimIfcBuildingStorey[];

    /**
     * All IFC products in the IFC file this instance represents. Here represented as a flat list.
     */
    readonly products: BimIfcObject[];

    /**
     * The discipline of the IFC file this instance represents.
     */
    readonly discipline: Discipline;

    /**
     * The IFC index (.idx file)  of the IFC file this instance represents. This describes (in a very raw format)
     * the content of the IFC file. It does not include any geometry or property sets.
     */
    readonly index: BimIfcIndex;

    /**
     * Url where the IFC index (.idx file) will be retrieved from.
     */
    readonly indexUrl: URL | undefined;

    /**
     * Url where the IFC geometry (.geom file) will be retrieved from.
     */
    readonly geometryUrl: URL | undefined;

    /**
     * Url where the IFC property set (.prop file) will be retrieved from.
     */
    readonly propertiesUrl: URL | undefined;

    /**
     * Reference to the transform repository this instance uses when
     * transforming IFC product geometries.
     */
    readonly transformsRepository: BimTransformsRepository;

    /**
     * Reference to the type object repository this instance uses for type properties.
     */
    readonly typeObjectRepository: BimTypeObjectRepository;

    /**
     * Reference to a {@link Materials} instance.
     */
    readonly materials: Materials;

    /**
     * All IFC styles that the {@link BimProductMesh} instances in the IFC file refers to.
     * The styles are used to color the meshes.
     */
    readonly styles: BimIfcStyle[];

    /**
     * The url where the contents of the IFC file can be retrieved from.
     */
    readonly ifcUrl: URL;

    /**
     * The name of the IFC file this instance represents.
     */
    readonly ifcName: string;

    /**
     * The {@link BimApiIfcObjectsLoadOptions} options (if any) that was used when this instance was created.
     */
    readonly loadOptions?: BimApiIfcObjectsLoadOptions;

    /**
     * The loader this instance belongs to.
     */
    readonly loader: BimIfcLoaderElementParent;

    /**
     * The IFC change (file) this instance represents.
     */
    readonly ifc: BimChangeIfc;

    /**
     * An optional transform for the IFC file. This transform will be applied to all IFC product geometries
     */
    readonly transform?: Matrix;

    /**
     * Ensures that the vertex data is loaded.
     * @returns A promise that resolves to a boolean. See it as a signal. I will be true if the call actualy loaded the data. Otherwhise it will be false (data loading is either in progress or has completed).
     */
    ensureVertexDataLoaded(): Promise<boolean>;

    /**
     * Gets the vertex data for a given product mesh descriptor.
     * @param productMeshDescriptor - The product mesh descriptor.
     * @returns The vertex data.
     */
    getVertexData(productMeshDescriptor: BimProductMeshDescriptor): BimVertexData;

    /**
     * Writes the vertex data to the specified destination.
     * @param ifcProductMesh - The IFC product mesh.
     * @param transform - The transformation matrix.
     * @param destination - The destination geometry arrays.
     * @param offset - The offset in the geometry arrays.
     * @param aabb - The axis-aligned bounding box.
     */
    writeVertexData(
        ifcProductMesh: BimProductMesh,
        transform: FastTransform,
        destination: GeometryArrays,
        offset: GeometryArrayOffset,
        aabb: { min: Vector3; max: Vector3 },
        options: { includeNormals?: boolean }
    ): void;

    /**
     * Loads the property sets and assigns them to the IFC products contained within this instance.
     * @returns A promise that resolves to a boolean indicating whether the property set was loade
     */
    loadPropertySets(): Promise<boolean>;
}

/**
 * Represents a predicate function used to query BIM products.
 * The function takes a `loaderElement` of type {@link BimIfcLoaderElement} and a `bimIfcObject` of type {@link BimIfcObject} as parameters,
 * and returns a boolean value indicating whether the object satisfies the query condition.
 */
export interface BimProductQueryPredicate {
    (loaderElement: BimIfcLoaderElement, bimIfcObject: BimIfcObject): boolean;
}

interface TmpVars {
    scale: Vector3;
    rotation: Quaternion;
    translate: Vector3;
    matrix: Matrix;
    points: [Vector3, Vector3, Vector3, Vector3, Vector3, Vector3, Vector3, Vector3];
    min: Vector3;
    max: Vector3;
}

/**
 * Represents the failure result when craeting a {@link BimIfcLoaderElement} fails.
 */
export type BimIfcLoaderElementCreateFailure = Failure<
    {
        /**
         * Status of the HTTP request that failed.
         */
        httpStatus: number;
        /**
         * Gives the reason for the failure.
         */
        reason: string;
    } & { ifc: Pick<BimChangeIfc, 'id' | 'version' | 'url'> }
>;

/**
 * Represents an IFC file and provides access to its data.
 */
export class BimIfcLoaderElement implements IBimIfcLoaderElement {
    private static readonly _tmp: TmpVars = {
        scale: Vector3.Zero(),
        rotation: Quaternion.Identity(),
        translate: Vector3.Zero(),
        matrix: Matrix.Identity(),
        points: [
            Vector3.Zero(),
            Vector3.Zero(),
            Vector3.Zero(),
            Vector3.Zero(),
            Vector3.Zero(),
            Vector3.Zero(),
            Vector3.Zero(),
            Vector3.Zero()
        ],
        min: Vector3.Zero(),
        max: Vector3.Zero()
    };

    private _numberOfVertices = 0;
    private _numberOfIndices = 0;
    private _ifcUrl: URL;
    private readonly _objectAttributes: BimObjectAttribute[];

    /** @inheritDoc */
    public readonly transformsRepository: BimTransformsRepository;

    /** @inheritDoc */
    public readonly typeObjectRepository: BimTypeObjectRepository;

    /** @inheritDoc */
    public readonly project: BimIfcObject;

    /** @inheritDoc */
    public readonly spaces: BimIfcSpace[] = [];

    /** @inheritDoc */
    public readonly floors: BimIfcBuildingStorey[] = [];

    /** @inheritDoc */
    public readonly products: BimIfcObject[] = [];

    /** @inheritDoc */
    public readonly meshCount: number;

    /** @inheritDoc */
    public readonly discipline: Discipline;

    /** @inheritDoc */
    public readonly styles: BimIfcStyle[] = [];

    /** @inheritDoc */
    public readonly classes: BimIfcClass[] = [];

    /** @inheritDoc */
    public readonly ifcName: string;

    private constructor(
        public readonly loader: BimIfcLoaderElementParent,
        public readonly ifcHttpResource: BimChangeIfc,
        public readonly materials: Materials,
        public readonly index: BimIfcIndex,
        private readonly _propertySetRepository: BimPropertySetRepository,
        private readonly _vertexDataRepository: BimVertexDataRepository,
        public readonly loadOptions?: BimApiIfcObjectsLoadOptions,
        public readonly transform?: Matrix
    ) {
        this.discipline = ifcHttpResource.discipline;
        this.ifcName = ifcHttpResource.name;
        this.styles = index.styles.map((s) => new BimIfcStyle(s));
        this.classes = index.classes.map((classId) => BimIfcClass.getOrAdd(classId));
        this.transformsRepository = new BimTransformsRepository(this);
        const mutableIfcIndex: Writeable<BimIfcIndex> = index;
        mutableIfcIndex.texts.push('undefined'); // Always add undefined for missing names, descriptions etc
        mutableIfcIndex.discipline = ifcHttpResource.discipline;
        this._ifcUrl = new URL(ifcHttpResource.url);

        this.assignDefaultValuesToTransforms(index, transform);

        this._objectAttributes = new BimObjectAttributeReader().read(this.index);
        this.typeObjectRepository = new BimTypeObjectRepository(this.index, this._objectAttributes);
        this.project = BimIfcObject.create(this);
        const referencedMeshDescriptors = new Set<number>();
        const referencedTransforms = new Set<number>();
        const leafNodesWithNoGeometry = new Set<BimIfcObject>();
        let meshCount = 0;

        for (const o of this.project.entries()) {
            let mD: BimProductMeshDescriptor;
            const len = o.rawProduct.m?.length ?? 0;
            for (let idx = 0; idx < len; ++idx) {
                const m = IfcProductMesh.from(o, o.rawProduct.m[idx]);
                o.rawProduct.m[idx] = m;

                mD = o.meshDescriptor(m);
                // Keep track of all meshdescriptors and transforms that actually are referenced by
                // meshes. We use this to trim away all meshdescriptors and transforms that have no references
                // later on. This is especialy useful in cases where we forcefully set some ifc objects
                // as not having a geometry. That means that we can clean out all geometries that referenced those
                // objects and save RAM.
                referencedMeshDescriptors.add(m.mi);
                referencedTransforms.add(m.wt);
                this._numberOfIndices += mD.i;
                this._numberOfVertices += mD.p;
            }

            if (loadOptions?.removeLeafIfcObjectsWithNoGeometry && o.childCount === 0 && !o.hasGeometry) {
                leafNodesWithNoGeometry.add(o);
            }

            this.discipline.addReferencedClass(o.class);
            (this.discipline.ifcObjectCount as number) = this.discipline.ifcObjectCount + 1;
            o.class.addReferencedClass(o.discipline);
            (o.class.ifcObjectCount as number) = o.class.ifcObjectCount + 1;

            if (o.hasGeometry) {
                meshCount += o.rawProduct.m.length;
                (o.class.ifcObjectsWithGeometryCount as number) = o.class.ifcObjectsWithGeometryCount + 1;
                (this.discipline.ifcObjectsWithGeometryCount as number) =
                    this.discipline.ifcObjectsWithGeometryCount + 1;
                if (o.enclosingFloor) {
                    (o.enclosingFloor.ifcObjectsWithGeometryCount as number) =
                        o.enclosingFloor.ifcObjectsWithGeometryCount + 1;
                }
            }

            this.products.push(o);
            if (o instanceof BimIfcSpace) {
                this.spaces.push(o);
            }
            if (o instanceof BimIfcBuildingStorey) {
                this.floors.push(o);
            }
        }

        this.meshCount = meshCount;

        // Trim away leafnodes that has no geometry and no children
        this.deleteLeafNodesWithNoGeometry(leafNodesWithNoGeometry);

        // Check if we need to remove unreferenced mesh descriptors.
        this.deleteUnreferencedMeshDescriptors(referencedMeshDescriptors);

        this.deleteUnreferencedTransforms(referencedTransforms);
    }

    /**
     * Creates a new instance of the BimIfcLoaderElement class.
     *
     * @param bimApi - The {@link BimCoreApiClient} instance.
     * @param materials - The {@link Materials} instance.
     * @param vertexDataRepository - The {@link BimVertexDataRepository} instance.
     * @param propertySetRepository - The {@link BimPropertySetRepository} instance.
     * @param parent - The {@link BimIfcLoaderElementParent} instance.
     * @param ifcHttpResource - The {@link BimChangeIfc} instance.
     * @param options - Optional. The {@link BimApiIfcObjectsLoadOptions} instance.
     * @param transform - Optional. The `Matrix` instance that will be applied to all transformations in the IFC.
     * @returns A Promise that resolves to a {@link BimIfcLoaderElement} instance or a {@link BimIfcLoaderElementCreateFailure} instance.
     */
    public static async create(
        bimApi: BimCoreApiClient,
        materials: Materials,
        vertexDataRepository: BimVertexDataRepository,
        propertySetRepository: BimPropertySetRepository,
        parent: BimIfcLoaderElementParent,
        ifcHttpResource: BimChangeIfc,
        options?: BimApiIfcObjectsLoadOptions,
        transform?: Matrix
    ): Promise<BimIfcLoaderElement | BimIfcLoaderElementCreateFailure> {
        const { id, version, url } = ifcHttpResource;
        if (!ifcHttpResource.resourceUrl.idx) {
            return Fail({
                httpStatus: 404,
                reason: 'No resource url.',
                ifc: { id, version, url }
            });
        }
        const ifcIndexResponse = await bimApi.get<BimIfcIndex>(ifcHttpResource.resourceUrl.idx, HttpResponseType.json);
        if (ifcIndexResponse.status !== 200) {
            return Fail({
                httpStatus: ifcIndexResponse.status,
                reason: 'Not found.',
                ifc: { id, version, url }
            });
        }
        const ifcIndex = await ifcIndexResponse.value;
        ifcIndex.styles.push({
            r: 50,
            g: 180,
            b: 50,
            a: 255, // ALPHA
            diffuseFactor: 0,
            diffuseTransmissionFactor: 0,
            hash: 'hash',
            name: 'ifcSpaceColor',
            reflectionFactor: 0,
            specularFactor: 0,
            transmissionFactor: 0
        });

        return new BimIfcLoaderElement(
            parent,
            ifcHttpResource,
            materials,
            ifcIndex,
            propertySetRepository,
            vertexDataRepository,
            options,
            transform
        );
    }

    /** @inheritDoc */
    public get ifc(): BimChangeIfc {
        return this.ifcHttpResource;
    }

    /** @inheritDoc */
    public get ifcUrl(): URL {
        return this._ifcUrl;
    }

    /** @inheritDoc */
    public get numberOfVertices(): number {
        return this._numberOfVertices;
    }
    /** @inheritDoc */
    public get numberOfIndices(): number {
        return this._numberOfIndices;
    }
    /** @inheritDoc */
    public get numberOfTriangles(): number {
        return this._numberOfIndices / 3;
    }
    /** @inheritDoc */
    public get ifcProductsWithGeometryCount(): number {
        return this.meshCount;
    }
    /** @inheritDoc */
    public get indexUrl(): URL | undefined {
        return this.ifcHttpResource.resourceUrl.idx;
    }
    /** @inheritDoc */
    public get geometryUrl(): URL | undefined {
        return this.ifcHttpResource.resourceUrl.geom;
    }
    /** @inheritDoc */
    public get propertiesUrl(): URL | undefined {
        return this.ifcHttpResource.resourceUrl.prop;
    }
    /** @inheritDoc */
    public ensureVertexDataLoaded(): Promise<boolean> {
        return this._vertexDataRepository.load(this);
    }
    /** @inheritDoc */
    public getVertexData(productMeshDescriptor: BimProductMeshDescriptor): BimVertexData {
        return this._vertexDataRepository.get(productMeshDescriptor);
    }
    /** @inheritDoc */
    public writeVertexData(
        ifcProductMesh: BimProductMesh,
        transform: FastTransform,
        destination: GeometryArrays,
        offset: GeometryArrayOffset,
        aabb: { min: Vector3; max: Vector3 },
        options: { includeNormals?: boolean }
    ): void {
        return this._vertexDataRepository.write(ifcProductMesh, transform, destination, offset, aabb, options);
    }
    /** @inheritDoc */
    public loadPropertySets(clearCache = false): Promise<boolean> {
        try {
            if (this.propertiesUrl) {
                return this._propertySetRepository.assignPropertySetsToProducts(
                    this.propertiesUrl,
                    this.products,
                    this.typeObjectRepository.types
                );
            }
        } finally {
            if (clearCache) {
                this._propertySetRepository.clear();
            }
        }
        return Promise.resolve(false);
    }

    private deleteUnreferencedTransforms(referencedTransforms: Set<number>): void {
        const transformsLen = this.index.transforms.length;
        if (transformsLen !== referencedTransforms.size) {
            for (let transformIdx = 0; transformIdx < transformsLen; transformIdx++) {
                if (!referencedTransforms.has(transformIdx)) {
                    // Set element in meshDescriptors to undefined. This allows GC to collect it
                    // it will also ensure that bim-vertex-data-repository.ts does not keep geometry
                    // relatited to it
                    this.index.transforms[transformIdx] = undefined;
                }
            }
        }
    }

    private deleteUnreferencedMeshDescriptors(referencedMeshDescriptors: Set<number>): void {
        const meshDescriptorLen = this.index.meshDescriptors.length;
        if (meshDescriptorLen !== referencedMeshDescriptors.size) {
            for (let meshDescriptorIdx = 0; meshDescriptorIdx < meshDescriptorLen; meshDescriptorIdx++) {
                if (!referencedMeshDescriptors.has(meshDescriptorIdx)) {
                    // Set element in meshDescriptors to undefined. This allows GC to collect it
                    // it will also ensure that bim-vertex-data-repository.ts does not keep geometry
                    // relatited to it
                    this.index.meshDescriptors[meshDescriptorIdx] = undefined;
                }
            }
        }
    }

    private deleteLeafNodesWithNoGeometry(leafNodesWithNoGeometry: Set<BimIfcObject>): void {
        while (leafNodesWithNoGeometry.size > 0) {
            for (const o of leafNodesWithNoGeometry) {
                o.remove();
                leafNodesWithNoGeometry.delete(o);
                if (o.parent && o.parent.childCount === 0 && !o.parent.hasGeometry) {
                    leafNodesWithNoGeometry.add(o.parent);
                }
            }
        }
    }
    private getBabylonBboxPointsFromIfcMinMaxToRef(
        min: Vertex3,
        max: Vertex3,
        out: [Vector3, Vector3, Vector3, Vector3, Vector3, Vector3, Vector3, Vector3],
        scale = 1,
        transform?: Matrix
    ): void {
        out[0].set(min.x, min.z, min.y).scaleInPlace(scale);
        out[1].set(max.x, min.z, min.y).scaleInPlace(scale);
        out[2].set(min.x, min.z, max.y).scaleInPlace(scale);
        out[3].set(min.x, max.z, min.y).scaleInPlace(scale);
        out[4].set(max.x, min.z, max.y).scaleInPlace(scale);
        out[5].set(max.x, max.z, min.y).scaleInPlace(scale);
        out[6].set(min.x, max.z, max.y).scaleInPlace(scale);
        out[7].set(max.x, max.z, max.y).scaleInPlace(scale);

        if (transform) {
            out.forEach((p) => {
                Vector3.TransformCoordinatesToRef(p, transform, p);
            });
        }
    }

    private assignDefaultValuesToTransforms(index: BimIfcIndex, transform?: Matrix): void {
        const k = 10000.0;

        const toMeterScale = 1.0 / index.modelFactors.oneMeter;
        const tmp = BimIfcLoaderElement._tmp;

        for (const wt of index.transforms) {
            (wt!.x as number) = (wt!.x ?? 0) * toMeterScale;
            (wt!.y as number) = (wt!.y ?? 0) * toMeterScale;
            (wt!.z as number) = (wt!.z ?? 0) * toMeterScale;

            (wt!.qx as number) = (wt!.qx ?? 0) / k;
            (wt!.qy as number) = (wt!.qy ?? 0) / k;
            (wt!.qz as number) = (wt!.qz ?? 0) / k;
            (wt!.qw as number) = (wt!.qw ?? k) / k;

            (wt!.sx as number) = (wt!.sx ?? 1) * toMeterScale;
            (wt!.sy as number) = (wt!.sy ?? 1) * toMeterScale;
            (wt!.sz as number) = (wt!.sz ?? 1) * toMeterScale;

            const ifcTransform = this.transformsRepository.convertBimTransformToMatrixToRef(wt!, tmp.matrix);

            // all transforms from backend are in IFC space. We work in BJS space so to simplify things
            // we convert transforms to BJS space. That way all vertices etc will be automatically converted
            // to BJS space when transformed.
            const bjsTransform = coordinateSystems.convertIfcTransformToBabylonTransformInPlace(ifcTransform);

            if (transform) {
                // bjsTransform = transform * bjsTransform
                bjsTransform.multiplyToRef(transform, bjsTransform);
            }
            bjsTransform.decompose(tmp.scale, tmp.rotation, tmp.translate);

            (wt!.x as number) = tmp.translate.x;
            (wt!.y as number) = tmp.translate.y;
            (wt!.z as number) = tmp.translate.z;

            (wt!.qx as number) = tmp.rotation.x;
            (wt!.qy as number) = tmp.rotation.y;
            (wt!.qz as number) = tmp.rotation.z;
            (wt!.qw as number) = tmp.rotation.w;

            (wt!.sx as number) = tmp.scale.x;
            (wt!.sy as number) = tmp.scale.y;
            (wt!.sz as number) = tmp.scale.z;
        }

        for (const region of index.regions) {
            this.getBabylonBboxPointsFromIfcMinMaxToRef(
                { x: region.bbox[0], y: region.bbox[1], z: region.bbox[2] },
                { x: region.bbox[3], y: region.bbox[4], z: region.bbox[5] },
                tmp.points,
                toMeterScale,
                transform
            );

            setMax(tmp.min);
            setMin(tmp.max);

            BimIfcLoaderElement._tmp.points.forEach((point) => {
                tmp.min.minimizeInPlace(point);
                tmp.max.maximizeInPlace(point);
            });

            region.bbox[0] = tmp.min.x;
            region.bbox[1] = tmp.min.y;
            region.bbox[2] = tmp.min.z;

            region.bbox[3] = tmp.max.x;
            region.bbox[4] = tmp.max.y;
            region.bbox[5] = tmp.max.z;
        }
    }
}
