import { Vertex3 } from '../math';
import { Vector3 } from './babylonjs-maths-import';

/**
 * WGS84 coordinates for a point in the local coordinate system
 */
export interface Wgs84Position {
    /** WGS84 latitude coordinate */
    latitude: number;
    /** WGS84 longitude coordinate */
    longitude: number;
    /** Elevation above sea level */
    elevation?: number;
    /** Position of the given WGS84 coordinate in the local coordinate system */
    local: Vector3;
}

/**
 * Information about a IfcSite object
 */
export interface IfcSiteInformation {
    /** Global ID of the site */
    id: string;
    /** Name of the site */
    name?: string;
    /** Site position in WGS84 */
    position?: Wgs84Position;
}

/**
 * @internal
 * @hidden
 */
export interface IfcSiteInformationDto extends Omit<IfcSiteInformation, 'position'> {
    position?: Omit<Wgs84Position, 'local'> & {
        /** Position of the given WGS84 coordinate in the local coordinate system */
        local: Vertex3;
    };
}

/**
 * @internal
 * @hidden
 */
export class IfcSiteInformationImplementation implements IfcSiteInformation {
    id: string;
    name?: string;
    position?: Wgs84Position;

    constructor(IfcSiteInformationDto: IfcSiteInformationDto) {
        this.id = IfcSiteInformationDto.id;
        this.name = IfcSiteInformationDto.name;
        if (IfcSiteInformationDto.position != null) {
            this.position = {
                latitude: IfcSiteInformationDto.position.latitude,
                longitude: IfcSiteInformationDto.position.longitude,
                elevation: IfcSiteInformationDto.position.elevation,
                local: new Vector3(
                    IfcSiteInformationDto.position.local.x,
                    IfcSiteInformationDto.position.local.y,
                    IfcSiteInformationDto.position.local.z
                )
            };
        }
    }
}
