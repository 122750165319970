/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { BimPropertySetValueType, BimPropertyUnitType, Mutable } from './bim-format-types';
import { clone } from '../util';

export const units = Symbol('units');

export interface BimPropertySet {
    readonly hash: string;
    readonly [name: string]: BimPropertySetValueType;
    readonly isReadonly: boolean;
    [units]: { [name: string]: BimPropertyUnitType };
}

export type BimPropertySetWritable = Mutable<BimPropertySet> & { readonly isReadonly: boolean };
// could have used interface for property sets but then it is more difficult to add
// utility methods and there is also added benefit of seing the class name in the profiler
// heap snapshots when checking memory usage
export class BimPropertySets {
    public readonly data: {
        [name: string]: BimPropertySet;
    } = {};

    public getWriteable(name: string): BimPropertySetWritable {
        let deepClone = this.data[name] as Mutable<BimPropertySet>;
        if (deepClone?.isReadonly === false) {
            return deepClone;
        }
        if (deepClone !== undefined) {
            deepClone = clone(deepClone);
            deepClone.isReadonly = false;
            this.data[name] = deepClone;
            return deepClone;
        }

        deepClone = {
            hash: '',
            isReadonly: false,
            [units]: {}
        };
        this.data[name] = deepClone;
        return deepClone;
    }
}
