import {
    BIT_SHIFTING,
    DITHERING,
    DITHER_UNIFORMS,
    WEBGL2,
    createPropertiesTextureReadShaderCode,
    DITHER_DISCARD,
    HIGHLIGHT_INDEX_PROPERTY,
    VISIBLITY_PROPERTY,
    PROPERTIES_CASTED_A,
    COLOR_PIXEL_INDEX,
    USE_HSV_MAPPING,
    HSV_2_RGB_SMOOTH,
    HSV_VALUES,
    RGB_2_HSV,
    HSV_COLOR_CHANGER
} from '../ShaderSnippets';
import { packedNormalPackSize } from '../PackedNormal';
import { AbstractMesh, Scene, Engine } from '../babylonjs-import';
import { TransparencyMode } from '../twinfinity-viewer';
import { UniformsAndDefinesAndSamplers, clipPlanesStrings } from './CullingMaterial';
import { LazyResizableRawTexture } from '../LazyResizableRawTexture';
import { DitheringMaterial } from './DitheringMaterial';
import { DitherTexture } from './DitherTexture';

const SIZE = 'size';
const PACK_SIZE = 'packSize';
const WORLD_MATRIX = 'worldMatrix';
const DIFFUSE_SAMPLER = 'diffuseSampler';
const PROPERTIES_SAMPLER = 'propertiesSampler';
const HIGHLIGHT_COLORS = 'highlightColors';

const TransparentIFCMaterialVertexShader = /* glsl*/ `#define PI 3.14
#ifdef GL_ES
    precision highp float;
#endif
attribute vec3 position;
attribute vec2 uv;

${BIT_SHIFTING}

uniform mat4 worldViewProjection;
uniform mat4 ${WORLD_MATRIX};
uniform float packSize;
uniform vec3 ${SIZE};


#ifdef ${WEBGL2}
    out float vColorPixelIndex;
    out vec3 vPositionW;
#else
    varying float vColorPixelIndex;
    varying vec3 vPositionW;
#endif


void main(void) {    
    vPositionW = (worldMatrix * vec4(position, 1.0)).xyz;        
    vColorPixelIndex = uv.x;
    gl_Position = worldViewProjection * vec4(position, 1.0);
}`;

const TransparentIFCMaterialFragmentShader = /* glsl*/ `
#ifdef GL_ES
    precision highp float;
#endif

#define PI 3.14
${BIT_SHIFTING}

${DITHER_UNIFORMS}
uniform sampler2D ${DIFFUSE_SAMPLER};
uniform sampler2D ${PROPERTIES_SAMPLER};
uniform vec3 ${SIZE};
uniform float ${PACK_SIZE};
uniform vec3 ${HIGHLIGHT_COLORS}[3];

#ifdef ${USE_HSV_MAPPING}
    ${HSV_2_RGB_SMOOTH}
    ${RGB_2_HSV}
    uniform vec3 ${HSV_VALUES};
#endif

#ifdef ${WEBGL2}
    in float vColorPixelIndex;
    in vec3 vPositionW;
#else
    varying float vColorPixelIndex;
    varying vec3 vPositionW;
#endif

${clipPlanesStrings.CLIP_PLANE_UNIFORMS}

void main(void) {

    ivec2 textureSize = textureSize(${DIFFUSE_SAMPLER}, 0);
    ${COLOR_PIXEL_INDEX}

    vec4 diffuseColor = texelFetch(${DIFFUSE_SAMPLER}, ivec2(pixelIndex), 0);

    if (diffuseColor.a == 0.0) {
        discard;
    }

    vec3 worldSpaceVertex = vPositionW;
    ${clipPlanesStrings.CLIP_PLANE_DISCARDING}

    float opaqueness = diffuseColor.a;

    vec3 finalDiffuse = diffuseColor.rgb;
	
    ${clipPlanesStrings.CLIP_PLANE_COLOR_BLENDING}

    ${createPropertiesTextureReadShaderCode('pixelIndex')};
    ${PROPERTIES_CASTED_A}
    ${HIGHLIGHT_INDEX_PROPERTY}
    ${VISIBLITY_PROPERTY}

    if (!visible) {
        discard;
    }

    if (highlighted) {
        finalDiffuse = ${HIGHLIGHT_COLORS}[highlightIndex - 1];
    }

    ${HSV_COLOR_CHANGER}
    
    #ifdef ${DITHERING}
        float distanceImpact = 0.0;
        ${DITHER_DISCARD}
        const float DITHERING_COLOR_COEFFICIENT = 2.0;
        // Also make the color a bit brigther since there are fewer lit fragments
        gl_FragColor = vec4(finalDiffuse * DITHERING_COLOR_COEFFICIENT, 1.0); // Alpha does not matter here as blending is disabled when dithering is enabled
    #else
        gl_FragColor = vec4(finalDiffuse, opaqueness);
    #endif
}`;

export class TransparentIfcMaterial extends DitheringMaterial {
    constructor(
        name: string,
        scene: Scene,
        public readonly diffuseTexture: LazyResizableRawTexture,
        public readonly propertiesTexture: LazyResizableRawTexture,
        ditherTexture: DitherTexture
    ) {
        super(
            name,
            scene,
            {
                attributes: ['position', 'uv'],
                samplers: [DIFFUSE_SAMPLER, PROPERTIES_SAMPLER]
            },
            ditherTexture,
            true,
            false
        );
        this.pictureSettings.ifc.color.onPropertyChanged.add(() => {
            this.isDirty = true;
        });

        this.pictureSettings.ifc.transparency.onPropertyChanged.add((change) => {
            if (change.property === 'mode') {
                this.isDirty = true;
                this.updateAlphaMode();
            }
        });
    }

    public updateAlphaMode(): void {
        if (this.pictureSettings.ifc.transparency.mode === TransparencyMode.Dithering) {
            this._isDitherModeEnabled = true;
            this.alpha = 1.0;
            this.alphaMode = Engine.ALPHA_DISABLE;
        } else {
            this._isDitherModeEnabled = false;
            this.alpha = 0.99;
            this.alphaMode = Engine.ALPHA_COMBINE;
        }
    }

    getUniformsAndDefinesAndSamplers(): UniformsAndDefinesAndSamplers {
        const uniformsAndSamplers = super.getUniformsAndDefinesAndSamplers();

        const defines: string[] = [];
        const uniforms = ['worldViewProjection', SIZE, PACK_SIZE, WORLD_MATRIX];
        const samplers = [DIFFUSE_SAMPLER, PROPERTIES_SAMPLER];

        if (this._isDitherModeEnabled) {
            defines.push(DITHERING);
        }

        // Babylonjs need to have every index of uniform arrays addded to the list of uniforms used by the shader
        for (let i = 0; i < 3; i++) {
            uniforms.push(HIGHLIGHT_COLORS + '[' + i + ']');
        }

        const aggregatedUniformsAndSamplers = {
            uniforms: [...uniformsAndSamplers.uniforms, ...uniforms],
            defines: [...uniformsAndSamplers.defines, ...defines],
            samplers: [...uniformsAndSamplers.samplers, ...samplers]
        };

        this.pictureSettings.ifc.color._insertUniformsAndDefines(aggregatedUniformsAndSamplers);

        return aggregatedUniformsAndSamplers;
    }

    override bindFunction(mesh: AbstractMesh): void {
        super.bindFunction(mesh);
        const effect = this.getEffect();
        effect.setTexture(PROPERTIES_SAMPLER, this.propertiesTexture.getTexture(mesh.getScene()));

        this.setFloat(PACK_SIZE, packedNormalPackSize);

        const wm = mesh.getWorldMatrix();
        this.getEffect().setMatrix(WORLD_MATRIX, wm);

        this.pictureSettings.ifc.color._setEffectValues(effect);
        this.pictureSettings.ifc._setEffectValues(effect);
    }

    public getFragmentSource(): string {
        return TransparentIFCMaterialFragmentShader;
    }

    public getVertexSource(): string {
        return TransparentIFCMaterialVertexShader;
    }
}
