import { BimChangeIfc, isFailure, Failure, Layer, LayerApi, BimApi } from '@twinfinity/core';
import { TextObject } from './TextObject';

interface TextObjectDto {
    readonly id: string;
    readonly text: string;
}

export type TextObjectLayer = Layer<BimChangeIfc, TextObject, TextObjectDto>;
const btnAddOrUpdateLayer = document.getElementById('btnAddOrUpdateLayer') as HTMLButtonElement;
const cbSaveLayerOverwrite = document.getElementById('cbSaveLayerOverwrite') as HTMLInputElement;
const txtId = document.getElementById('txtId') as HTMLInputElement;

const lblLayerId = document.getElementById('lblLayerId') as HTMLLabelElement;
const txtValue = document.getElementById('txtValue') as HTMLInputElement;
const btnSaveLayer = document.getElementById('btnSaveLayer') as HTMLButtonElement;
const btnClearLayer = document.getElementById('btnClearLayer') as HTMLButtonElement;
const btnLoadLayer = document.getElementById('btnLoadLayer') as HTMLButtonElement;
const lstSaveAndRefreshLog = document.getElementById('lstSaveAndRefreshLog') as HTMLDivElement;

/**
 * Adds, Modifies and deletes {@link TextObject} items in the  `textobject` layer. A
 * layer is extra data that is linked to a {@link BimChangeIfc} (or other type of change) and
 * is versioned. A layer can also detect concurrency issues when multiple users are modifying the same data.
 * A `textobject` layer consist of a collection of {@link TextObject} items.
 */
export class TextObjectLayerEditor {
    private _layer?: TextObjectLayer;
    private _attachedTo?: BimChangeIfc;

    public static readonly format = 'textobject';
    private _layerApi: LayerApi<TextObject, TextObjectDto[]>;

    public constructor(api: BimApi) {
        // create the layer api which will be used to store TextObject instance
        // in the layer. Notice the from/to functions which are used to convert TextObject
        // instances into json representations.
        this._layerApi = new LayerApi(api, TextObjectLayerEditor.format, {
            from: (dtos) => {
                const ret = dtos.map((dto) => new TextObject(this, dto.id, dto.text));
                ret.sort((c0, c1) => c0.id.localeCompare(c1.id));
                return ret;
            },
            to: (tOs) => {
                tOs.sort((t0, t1) => t0.id.localeCompare(t1.id));
                return tOs.map((t) => {
                    return { id: t.id, text: t.text };
                });
            }
        });

        btnAddOrUpdateLayer.addEventListener('click', (ev) => {
            ev.preventDefault();
            const id = txtId.value;
            if (id && this.layer) {
                const val = txtValue.value ?? '';
                if (!this.layer.add(new TextObject(this, id, val))) {
                    alert('Cannot add existing object.');
                }
            }
        });

        btnClearLayer.addEventListener('click', (ev) => {
            ev.preventDefault();
            this.clear();
        });

        btnSaveLayer.addEventListener('click', async (ev) => {
            ev.preventDefault();

            if (this.layer) {
                const changeCount = this.layer.changeCount;
                const overwriteLatestVersion = cbSaveLayerOverwrite.checked;
                const conflictCountOrFailure = await this.layer.saveAndMerge(overwriteLatestVersion);
                for (const o of this.layer.objects) {
                    o.local.updateUIState(o.state, o.conflictReason, o.remote?.text);
                }
                this.appendToLog(conflictCountOrFailure, { type: 'save', changeCount });
            }
        });

        btnLoadLayer.addEventListener('click', async (ev) => {
            ev.preventDefault();
            if (this.layer) {
                const conflictCountOrFailure = await this.layer.loadAndMerge();
                this.appendToLog(conflictCountOrFailure, { type: 'load' });
            }
        });
    }

    private appendToLog(
        conflictCountOrFailure: number | Failure<{ httpStatus?: number | undefined; reason: string }>,
        operation: { type: 'save'; changeCount: number } | { type: 'load' }
    ): void {
        const logLine = document.createElement('div');
        const now = new Date();

        let innerHTML = `<span class='timestamp'>${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}:${now.getMilliseconds()}</span><span class='type'>${
            operation.type
        }</span>`;
        if (isFailure(conflictCountOrFailure)) {
            innerHTML += `<span>${conflictCountOrFailure.httpStatus ?? -1}: ${conflictCountOrFailure.reason}</span>`;
        } else if (operation.type === 'load') {
            innerHTML += `<span>${conflictCountOrFailure} conflicts.</span>`;
        } else if (operation.type === 'save') {
            if (conflictCountOrFailure > 0) {
                innerHTML += `<span>${conflictCountOrFailure} conflicts.</span>`;
            } else {
                innerHTML += `<span>${operation.changeCount} objects.</span>`;
            }
        }
        logLine.innerHTML = innerHTML;
        lstSaveAndRefreshLog.append(logLine);
    }

    public get layer(): TextObjectLayer | undefined {
        return this._layer;
    }

    public async loadLayerFor(attachedTo: BimChangeIfc | undefined): Promise<void> {
        if (this._attachedTo || !attachedTo) {
            this.clear();
            if (!attachedTo) {
                return;
            }
        }
        this._attachedTo = attachedTo;

        // Will automatically populate the ui
        const ret = await this._layerApi.getOrLoadLayer(this._attachedTo);
        if (!isFailure(ret)) {
            lblLayerId.textContent = `'${TextObjectLayerEditor.format}' layer for ${this._attachedTo.name}`;
            this._layer = ret;
        } else {
            lblLayerId.textContent = `Failed to load layer. ${ret.reason}`;
        }
    }

    public clear(): void {
        if (this.layer) {
            this._layerApi.unload(this.layer.attachedTo);
        }

        this._layer = undefined;
        this._attachedTo = undefined;
        lblLayerId.textContent = 'select a IFC change';
        txtId.value = '';
        txtValue.value = '';
    }
}
