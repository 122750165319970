/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { Vector3, DeepImmutable } from './babylonjs-import';

export const packedNormalPackSize = 252.0;

const HALF_PI = Math.PI / 2;
const ONE_AND_HALF_PI = Math.PI + Math.PI / 2;
const TWO_PI = 2 * Math.PI;

const dv = new DataView(new ArrayBuffer(2));
function toBigEndianUint16(u: number, v: number): number {
    // store u, v in little endian order (will be read back in big endian order below)
    dv.setUint8(0, Math.trunc(v));
    dv.setUint8(1, Math.trunc(u));

    // backend sends us packed normals (uint16) in big endian
    // format (ie like [v, u] where v is MSB and u is LSB
    // above we stored in [u, v] order (little endian)
    // so to get correct uint16 back we read as big endian.
    return dv.getUint16(0, false);
}

const yPlus1EqualsZeroNormal = toBigEndianUint16(0b11111100, 0b11111100);
const _1MinusYEqualsZeroNormal = 0;

const lonK = packedNormalPackSize / TWO_PI;
const latK = packedNormalPackSize / Math.PI;

const zMinus1EqualsZeroNormal = toBigEndianUint16(0, HALF_PI * latK);
const zPlus1EqualsZeroNormal = toBigEndianUint16(Math.PI * lonK, HALF_PI * latK);
const xMinus1EqualsZeroNormal = toBigEndianUint16(HALF_PI * lonK, HALF_PI * latK);
const xPlus1EqualsZeroNormal = toBigEndianUint16(ONE_AND_HALF_PI * lonK, HALF_PI * latK);

// 4 * 1e-4 has been selected as tolerance since it results in similar error as packing the normal
const encodeNormalTolerance = 4 * 1e-4;
function isZero(value: number): boolean {
    return Math.abs(value) < encodeNormalTolerance;
}

export function encodePackedNormal(x: number, y: number, z: number): number {
    //the most basic case when normal points in Y direction (singular point)
    if (isZero(1 - y)) {
        return _1MinusYEqualsZeroNormal;
    }
    //the most basic case when normal points in -Y direction (second singular point)
    if (isZero(y + 1)) {
        return yPlus1EqualsZeroNormal;
    }

    //special cases when vector is aligned to one of the axis
    if (isZero(z - 1)) {
        // u = 0; //* lonK;
        // v = HALF_PI * latK;
        return zMinus1EqualsZeroNormal;
    } else if (isZero(z + 1)) {
        // u = Math.PI * lonK;
        // v = HALF_PI * latK;
        return zPlus1EqualsZeroNormal;
    } else if (isZero(x - 1)) {
        // u = HALF_PI * lonK;
        // v = HALF_PI * latK;
        return xMinus1EqualsZeroNormal;
    } else if (isZero(x + 1)) {
        // u = ONE_AND_HALF_PI * lonK;
        // v = HALF_PI * latK;
        return xPlus1EqualsZeroNormal;
    } else {
        // Put the calculation code in its own function because it shows up
        // better when profiling.
        return calculatePackedNormal(x, y, z);
    }
}

function calculatePackedNormal(x: number, y: number, z: number): number {
    let theta = Math.atan2(x, z);
    if (theta < 0) {
        theta += TWO_PI;
    }
    const u = theta * lonK;
    //latitude from 0 to PI starting at positive Y ending at negative Y
    const v = Math.acos(y) * latK;
    return toBigEndianUint16(u, v);
}

const normalizeTolerance = 1e-9;
function normalizeVector3WithBackendAlgorithmInPlace(v: Vector3): Vector3 {
    const vectorLen = v.length();
    if (Math.abs(vectorLen) < normalizeTolerance) {
        v.setAll(0);
        return v;
    }

    v.scaleInPlace(1.0 / vectorLen);

    if (Math.abs(v.x - 1.0) < normalizeTolerance) {
        v.x = Math.sign(v.x);
        v.y = 0;
        v.z = 0;
        return v;
    }
    if (Math.abs(v.y - 1.0) < normalizeTolerance) {
        v.x = 0;
        v.y = Math.sign(v.y);
        v.z = 0;
        return v;
    }
    if (Math.abs(v.z - 1.0) < normalizeTolerance) {
        v.x = 0;
        v.y = 0;
        v.z = Math.sign(v.z);
        return v;
    }
    return v;
}

// packed normals consists of two values u and v. Each can only be a byte
// hence we can precalculate all normals.
function createNormalLookupTable(): Vector3[] {
    const uint16ToVector3Lookup: Vector3[] = [];
    for (let i = 0; i < 256 * 256; ++i) uint16ToVector3Lookup.push(Vector3.Zero());

    for (let u = 0; u < 256; ++u) {
        for (let v = 0; v < 256; ++v) {
            const lon = (u / packedNormalPackSize) * TWO_PI;
            const lat = (v / packedNormalPackSize) * Math.PI;
            const packedNormal = toBigEndianUint16(u, v);
            const normal = uint16ToVector3Lookup[packedNormal];
            normal.y = Math.cos(lat);
            normal.x = Math.sin(lon) * Math.sin(lat);
            normal.z = Math.cos(lon) * Math.sin(lat);
            normalizeVector3WithBackendAlgorithmInPlace(normal); // Otherwise our normals wont be quite the same as on backend.
        }
    }
    return uint16ToVector3Lookup;
}

export const decodePackedNormal: DeepImmutable<Vector3[]> = createNormalLookupTable();
