/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */

import { Vector3, Ray, Plane, DeepImmutable, Epsilon, Scalar } from '../loader/babylonjs-import';

/**
 * Represents a 3D point/vector in space.
 */
export interface Vertex3 {
    x: number;
    y: number;
    z: number;
}

/**
 * Represents a 2D point/vector in space.
 */
export interface Vertex2 {
    x: number;
    y: number;
}

/**
 * Represents either a 2D or 3D point/vector in space.
 */
export type Vertex = Vertex2 | Vertex3;

/**
 * Represents a object that looks like an array and where it is possible
 * to change the individual elements.
 */
export type MutableArrayLike<T> = ArrayLike<number> & {
    [index: number]: T;
};

/**
 * Perform `ceil(base^v)` calcuation.
 * @param v Number to perform `base^v` on
 * @param base Power of base. Ie `base^v`. Defaults to `2`
 * @returns `ceil(base^v)`
 */
export function pow2ceil(v: number, base = 2): number {
    return Math.pow(base, Math.ceil(Math.log(v) / Math.log(base)));
}

/**
 * Assign `Number.MAX_VALUE` to `x, y ,z` properties of `dst`.
 * @param dst {@link Vertex3}.
 * @returns Referece to `m`.
 */
export function setMax<T extends Vertex3>(dst: T): T {
    return setValue(dst, Number.MAX_VALUE);
}

/**
 * Assign `-Number.MAX_VALUE` to `x, y ,z` properties of `dst`.
 * @param dst {@link Vertex3}.
 * @returns Referece to `m`.
 */
export function setMin<T extends Vertex3>(dst: T): T {
    return setValue(dst, -Number.MAX_VALUE);
}

/**
 * Assign `n` to `dst.x, y, z`.
 * @param dst Instance to assign `n` to.
 * @param n Value to assign to `dst.x, y, z`.
 * @returns Reference to `dst`-
 */
export function setValue<T extends Vertex3>(dst: T, n: number): T {
    dst.x = n;
    dst.y = dst.x;
    dst.z = dst.x;
    return dst;
}

/**
 * Copy x, y, z properties from one instance to another
 * @param src Object to copy from-
 * @param dst Object to copy to.
 * @returns Reference to `dst`
 */
export function copyVertex3ToRef<T extends Vertex3>(src: Vertex3, dst: T): T {
    dst.x = src.x;
    dst.y = src.y;
    dst.z = src.z;
    return dst;
}

/**
 * Get sum of all numbers in `arr`.
 * @param arr Array of numbers.
 * @returns sum.
 */
export function sum(arr: number[]): number {
    return arr.reduce((acc, curr) => acc + curr, 0);
}

/**
 * Get man value of all numbers in `arr`.
 * @param arr Array of numbers.
 * @returns Mean value.
 */
export function mean(arr: number[]): number {
    return sum(arr) / arr.length;
}

/**
 * Get standard deviation of numbers in `data`
 * @param data Array of numbers.
 * @returns Standard deviation.
 */
export function standardDeviation(data: number[]): number {
    const dataMean = mean(data);
    const meanDists = data.map((x) => Math.pow(x - dataMean, 2));

    return Math.sqrt(mean(meanDists));
}

export function equalsWithEpsilonVertex3(
    vertex1: DeepImmutable<Vertex3>,
    vertex2: DeepImmutable<Vertex3>,
    epsilon: number = Epsilon
): boolean {
    return (
        Scalar.WithinEpsilon(vertex1.x, vertex2.x, epsilon) &&
        Scalar.WithinEpsilon(vertex1.y, vertex2.y, epsilon) &&
        Scalar.WithinEpsilon(vertex1.z, vertex2.z, epsilon)
    );
}

/**
 * @internal
 * @hidden
 */
export function findAnomalyIndices(data: number[]): number[] {
    const dataStd = standardDeviation(data);
    const dataMean = mean(data);
    const cutOff = dataStd * 3;

    const lowerLimit = dataMean - cutOff;
    const upperLimit = dataMean + cutOff;

    const anomalies: number[] = [];

    data.forEach((x, i) => {
        if (x > upperLimit || x < lowerLimit) {
            anomalies.push(i);
        }
    });

    return anomalies;
}

/**
 * Get point of intersection (if any) between a ray and a plane.
 * @param ray Ray to use for intersection calculation.
 * @param plane Plane to performn intersection calculation against
 * @param dst Point of intersection is written to this object (if a intersection could be found)
 * @returns Distance to point of intersection on plane, < 0 if no interrsection.
 */
export function intersectsPlaneAtToRef(ray: Ray, plane: Plane, dst: Vector3): number {
    const distance = ray.intersectsPlane(plane);
    if (distance !== null && distance !== undefined && distance >= 0) {
        dst.copyFrom(ray.origin);
        ray.direction.scaleAndAddToRef(distance, dst);
        return distance;
    }
    return -1;
}

const props: ('x' | 'y' | 'z')[] = ['x', 'y', 'z'];

/**
 * Checks if a ray intersets a specified axis aligned bounding box.
 * @param ray Ray to use for intersection calculation.
 * @param aabbMin Min value of axis aligned bounding box
 * @param aabbMax Max value of axis aligned bounding box
 * @returns Undefined of no intersection was found. Otherwise intersection distance
 */
export function rayDistanceToBoundingBox(ray: Ray, aabbMin: Vertex3, aabbMax: Vertex3): number | undefined {
    let lo = -Infinity;
    let hi = +Infinity;
    const ro = ray.origin;
    const rd = ray.direction;
    for (const p of props) {
        let dimLo = (aabbMin[p] - ro[p]) / rd[p];
        let dimHi = (aabbMax[p] - ro[p]) / rd[p];

        if (dimLo > dimHi) {
            const tmp = dimLo;
            dimLo = dimHi;
            dimHi = tmp;
        }

        if (dimHi < lo || dimLo > hi) {
            return undefined;
        }

        if (dimLo > lo) lo = dimLo;
        if (dimHi < hi) hi = dimHi;
    }

    return lo > hi ? undefined : lo;
}

/**
 * Checks if a ray intersets a specified axis aligned bounding box. If it intersects we get
 * the point of intersection.
 * @param ray Ray to use for intersection calculation.
 * @param aabbMin Min value of axis aligned bounding box
 * @param aabbMax Max value of axis aligned bounding box
 * @param dst Intersection point is written to this object if intersection exists.
 * @returns Undefined of no intersection was found. Otherwise intersection distance
 */
export function intersectRayWithAAABBToRef<T extends Vertex3>(
    ray: Ray,
    aabbMin: T,
    aabbMax: T,
    dst: T
): number | undefined {
    const d = rayDistanceToBoundingBox(ray, aabbMin, aabbMax);
    if (d !== undefined) {
        const ro = ray.origin;
        const rd = ray.direction;
        dst.x = ro.x + rd.x * d;
        dst.y = ro.y + rd.y * d;
        dst.z = ro.z + rd.z * d;
    }
    return d;
}

/**
 * Given two points/vectors, calculate a point/vector which lies on the middle of the line that spans
 * the points.
 * @param start Start of line
 * @param end End of line
 * @param dst Results is written to this object.
 * @returns Reference to `dst`.
 */
export function vertexBetweenToRef<T extends Vertex, K extends Vertex>(start: T, end: T, dst: K): K {
    dst.x = (start.x + end.x) * 0.5;
    dst.y = (start.y + end.y) * 0.5;
    if (isVertex3(start) && isVertex3(dst)) {
        dst.z = (start.z + (end as Vertex3).z) * 0.5;
    }
    return dst;
}

/**
 * Add a {@link Vertex} to another {@link Vertex}.
 * @param src {@link Vertex} to add to `dst`.
 * @param dst `src` is added to `dst`.
 * @returns Reference to `dst`.
 */
export function addVertexToRef<T extends Vertex, K extends Vertex>(src: T, dst: K): K {
    dst.x += src.x;
    dst.y += src.y;
    if (isVertex3(src) && isVertex3(dst)) {
        dst.z += src.z;
    }
    return dst;
}

/**
 * Subtract a {@link Vertex} from another {@link Vertex}.
 * @param vert1 `vert2` is subracted from `vert1`.
 * @param vert2 {@link Vertex} to subtract from `vert1`.
 * @param dst the result of the operation `vert1` - `vert2`.
 * @returns Reference to `dst`.
 */
export function subtractVertexToRef<T extends Vertex, K extends Vertex, V extends Vertex>(
    vert1: T,
    vert2: K,
    dst: V
): V {
    dst.x = vert1.x - vert2.x;
    dst.y = vert1.y - vert2.y;
    if (isVertex3(vert1) && isVertex3(vert2) && isVertex3(dst)) {
        dst.z = vert1.z - vert2.z;
    }
    return dst;
}

/**
 * Type guard to check if `v` is a {@link Vertex3} or not
 * @param v Possibly a {@link Vertex3}
 */
export function isVertex3(v: Vertex): v is Vertex3 {
    return 'z' in v;
}

/**
 * Type guard to check if `v` is a {@link Vertex2} or not
 * @param v Possibly a {@link Vertex2}
 */
export function isVertex2(v: Vertex): v is Vertex2 {
    return !isVertex3(v);
}

/**
 * Given a collection of points(vertices) , representing corners of outer edges of a polygon. Calculate
 * the centroid.
 * @param points Points
 * @param dst Where centroid value is written
 * @returns Reference to `dst`
 */
export function calculateCentroidVertex3ToRef<T extends Vertex3>(points: IterableIterator<Vertex3>, dst: T): T {
    setValue(dst, 0);
    let len = 0;
    for (const p of points) {
        len++;
        dst.x += p.x;
        dst.y += p.y;
        dst.z += p.z;
    }
    dst.x /= len;
    dst.y /= len;
    dst.z /= len;
    return dst;
}
