/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { TargetCamera } from '@babylonjs/core/Cameras/targetCamera';
import { Engine } from '@babylonjs/core/Engines/engine';
import { ISize } from '@babylonjs/core/Maths/math.size';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { Observer } from '@babylonjs/core/Misc/observable';
import { Nullable } from '@babylonjs/core/types';
import { BimCoreApi, boundingClientRectCache, StopWatch } from '@twinfinity/core';
import { BimDrawingPlane } from './BimDrawingPlane';
import { SvgTexture } from './SvgTexture';

export abstract class BimDrawingParentBase {
    public readonly plane: BimDrawingPlane;
    public readonly camera: TargetCamera;
    protected readonly _svgTex: SvgTexture;
    protected renderObservable: Nullable<Observer<Engine>> = null;
    protected static readonly timeUntilUpdateAfterCameraStop = 500;
    protected _finishedDrawing = false;
    protected static readonly vec3Zero = Vector3.Zero();
    protected static readonly vec3Up = Vector3.Up();

    constructor(protected readonly _api: BimCoreApi, protected readonly _textureSize: number, parent: TransformNode) {
        this.plane = new BimDrawingPlane(_api, parent, this._textureSize);
        this.camera = _api.viewer.camera.activeCamera;
        this._svgTex = new SvgTexture(this._api, this._textureSize);
    }

    /**
     * @hidden
     * @internal
     */
    public get svgTex(): SvgTexture {
        return this._svgTex;
    }

    protected addOnRenderObs(): void {
        const sw = new StopWatch(true);
        const ss = this._api.viewer.camera.activeCamera.twinfinity.stateSnapshot();
        this.removeOnRenderObs();
        this.renderObservable = this._api.viewer.engine.onBeginFrameObservable.add(async () => {
            if (ss.refresh()) {
                sw.resetAndStart();
                // We are currently moving camera.
            } else if (sw.elapsed > BimDrawingParentBase.timeUntilUpdateAfterCameraStop) {
                sw.resetAndStop();
                // We are no longer moving so zoom the camera once.
                const rect = boundingClientRectCache.getOrAdd(this._api.viewer.canvas);
                await this.zoom(this.camera, rect);
            }
        });
    }

    protected removeOnRenderObs(): void {
        if (this.renderObservable) {
            this._api.viewer.engine.onBeginFrameObservable.remove(this.renderObservable);
            this.renderObservable = null;
        }
    }

    public dispose(): void {
        this.plane.dispose();

        this.removeOnRenderObs();
    }

    public abstract zoom(camera: TargetCamera, canvasRect: ISize): Promise<boolean>;
}
