/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { BimIfcPropertiesResponse, Mutable } from './bim-format-types';
import { BimIfcObject } from './bim-ifc-object';
import { BimPropertySets, BimPropertySet } from './bim-property-sets';
import { HttpResponseType } from '../http';
import '../MapExtensions';
import { BimCoreApiClient } from './client/BimCoreApiClient';
import { BimPropertySetReader } from './bim-property-set-reader';
import { BimTypeObject } from './BimTypeObject';
import { telemetry } from '../Telemetry';

type DecodedPropertySet = {
    name: string;
    pset: BimPropertySet;
    isQuantifier: boolean;
};

export class BimPropertySetRepository {
    private static readonly _propertyReader = new BimPropertySetReader();
    readonly _propertySetByHash = new Map<string, BimPropertySet>();
    readonly _isPropertySetUrlAdded = new Map<string, Promise<boolean>>();

    public constructor(private readonly _bimApi: BimCoreApiClient) {}

    public assignPropertySetsToProducts(
        propertysetUrl: URL,
        ifcObjects: BimIfcObject[],
        ifcTypeObjects: BimTypeObject[]
    ): Promise<boolean> {
        const key = propertysetUrl.href.toLowerCase();
        const loadingPromise = this._isPropertySetUrlAdded.get(key);
        if (loadingPromise) {
            return loadingPromise;
        }
        const p = this.fetchDecodeAndAssignPropertySetsToIfcObjects(propertysetUrl, ifcObjects, ifcTypeObjects);
        this._isPropertySetUrlAdded.set(key, p);
        return p;
    }

    public clear(): void {
        this._propertySetByHash.clear();
        this._isPropertySetUrlAdded.clear();
    }

    private async fetchDecodeAndAssignPropertySetsToIfcObjects(
        propertysetUrl: URL,
        ifcObjects: BimIfcObject[],
        ifcTypeObjects: BimTypeObject[]
    ): Promise<boolean> {
        const bimIfcPropertiesDataResponse = await this._bimApi.get<BimIfcPropertiesResponse>(
            propertysetUrl,
            HttpResponseType.json
        );
        if (bimIfcPropertiesDataResponse.status !== 200) {
            telemetry.trackTrace(
                { message: 'Failed to load property set data' },
                {
                    httpStatus: bimIfcPropertiesDataResponse.status,
                    reason: bimIfcPropertiesDataResponse.statusText,
                    url: propertysetUrl.href
                }
            );
            return false;
        }

        const bimIfcPropertiesData = await bimIfcPropertiesDataResponse.value;

        // If we have property set data then unpack it.
        const bimPropertySets = BimPropertySetRepository._propertyReader.decodeProperties(bimIfcPropertiesData);
        let decodedPropertySet: DecodedPropertySet;
        for (const ifcObject of ifcObjects) {
            const propertySetIndicesForProduct = bimIfcPropertiesData.products[ifcObject.entityLabelInIfc] ?? [];
            for (const propertySetIndice of propertySetIndicesForProduct) {
                decodedPropertySet = bimPropertySets[propertySetIndice];
                if (decodedPropertySet.isQuantifier) {
                    (ifcObject.quantifiers as Mutable<BimPropertySets>).data[decodedPropertySet.name] =
                        decodedPropertySet.pset;
                } else {
                    (ifcObject.properties as Mutable<BimPropertySets>).data[decodedPropertySet.name] =
                        decodedPropertySet.pset;
                }
            }
        }

        if (bimIfcPropertiesData.types != null) {
            for (const ifcTypeObject of ifcTypeObjects) {
                const propertySetIndicesForProduct = bimIfcPropertiesData.types[ifcTypeObject.id] ?? [];
                for (const propertySetIndice of propertySetIndicesForProduct) {
                    decodedPropertySet = bimPropertySets[propertySetIndice];
                    if (decodedPropertySet.isQuantifier) {
                        (ifcTypeObject.quantifiers as Mutable<BimPropertySets>).data[decodedPropertySet.name] =
                            decodedPropertySet.pset;
                    } else {
                        (ifcTypeObject.properties as Mutable<BimPropertySets>).data[decodedPropertySet.name] =
                            decodedPropertySet.pset;
                    }
                }
            }
        }

        return true;
    }
}
