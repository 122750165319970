import { DeepImmutable } from './babylonjs-import';
import { BimObjectAttributeValue } from './bim-format-types';
import { BimPropertySets } from './bim-property-sets';

export interface BimIndexableIfcObject {
    [attribute: string]: BimObjectAttributeValue | undefined;
}

export class BimTypeObject {
    constructor(
        public readonly id: number,
        public readonly globalId: string,
        public readonly ifcClass: string,
        public readonly tag?: string,
        public readonly name?: string,
        public readonly description?: string,
        /**
         * Contains type specific attributes (e.g operationMode)
         * Readonly
         */
        public readonly attributes: DeepImmutable<BimIndexableIfcObject> = {}
    ) {}

    /**
     * Contains all property sets for this product
     * Readonly
     */
    public readonly properties: DeepImmutable<BimPropertySets> = new BimPropertySets();
    /**
     * Contains all quantifiers for this product
     * Readonly
     */
    public readonly quantifiers: DeepImmutable<BimPropertySets> = new BimPropertySets();
}
