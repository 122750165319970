/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { Scene } from '../../loader/babylonjs-import';
import { Vertex2 } from '../../math';
import { boundingClientRectCache } from '../../BoundingClientRectCache';

/**
 * Converts screen coordinates to canvas coordinates.
 * @param scene Scene which utilizes the canvas we which to get coordinate for
 * @param x The X coordinate of the in local (DOM content) coordinates
 * @param y The Y coordinate of the in local (DOM content) coordinates
 * @param out canvas coordinate
 * @returns Reference to `out`.
 */
export function convertToCanvasCoordinatesToRef<T extends Vertex2>(scene: Scene, c: Vertex2, out: T): T {
    const engine = scene.getEngine();
    const canvas = engine.getInputElement()!;
    const canvasRect = boundingClientRectCache.getOrAdd(canvas);

    out.x = Math.floor(c.x - canvasRect.left);
    out.y = Math.floor(c.y - canvasRect.top);

    return out;
}

/**
 * Get center coordinate of canvas used by `scene`
 * @param scene Scene which utilizes the canvas we which to get center coordinate for
 * @param out Canvas coordinates
 * @eturns Reference to `out`.
 */
export function getCanvasCenterCoordinatesToRef<T extends Vertex2>(scene: Scene, out: T): T {
    const engine = scene.getEngine();
    const canvas = engine.getInputElement()!;
    const canvasRect = boundingClientRectCache.getOrAdd(canvas);

    out.x = Math.floor(canvasRect.width * 0.5);
    out.y = Math.floor(canvasRect.height * 0.5);
    return out;
}
