import {
    BimApi,
    MarkupSheet2D,
    MarkupSheets2DApi,
    MergableObjectEventSource,
    BimChangeDwg,
    LayerFailure,
    PlaneUtil,
    Vertex3,
    MarkupSheet2DCollection,
    isFailure,
    MarkupLayerEvent2D,
    MarkupEventTypes,
    MarkupEntityBase,
    MergableObjectWithState,
    BimChangeBlob,
    MarkupEntity,
    MarkupArea,
    MarkupText,
    Html2CanvasPlane,
    MarkupLine,
    MarkupArrow,
    Shapes,
    LineUtil,
    DynamicPolygonWithArbitraryPoints,
    MarkupCircle,
    BimCoreApi,
    DragPointPositioningFunctionOrIndex,
    DynamicPolygonPointValidationResult
} from '@twinfinity/core';
import { Selector } from './Selector';
import { DynamicPolygon } from '@twinfinity/core';
import { AreaPolygonPointWithLabel } from './AreaTool';
import { DrawingApi } from '@twinfinity/experimental';
import { HtmlPoint } from './HtmlPoint';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import {
    Vector3,
    Mesh,
    StandardMaterial,
    Observable,
    Color3,
    Color4,
    Plane,
    TransformNode,
    Camera
} from '@babylonjs/core';

type DynamicPolygonWithDOMPoints = DynamicPolygon<AreaPolygonPointWithLabel>;

const UP = Vector3.Up();

const textPointsParentElement = document.createElement('div');
textPointsParentElement.id = 'textPoints';
document.body.appendChild(textPointsParentElement);

export class MarkupTool {
    private _isEnabled = false;
    private _markupApi: MarkupSheets2DApi;
    private _scaleDenom = 6;
    hasLoadedSheets = false;
    spheres = new Map<string, Mesh>();
    markupAreaMaterial: StandardMaterial;
    areaIdToDynamicPolygon = new Map<string, DynamicPolygonWithDOMPoints>();
    textIdToHtml2CanvasText = new Map<string, Html2CanvasPlane<HtmlPoint>>();
    markupIdToDynamicPolygonWithArbitraryPoints = new Map<
        string,
        DynamicPolygonWithArbitraryPoints<AreaPolygonPointWithLabel>
    >();
    private _sheets?: MarkupSheet2DCollection;
    private readonly _observable = new Observable<MarkupLayerEvent2D>();

    constructor(private _api: BimApi, private _drawingApi: DrawingApi) {
        this._markupApi = new MarkupSheets2DApi(this._api);

        this.markupAreaMaterial = new StandardMaterial('markupAreaMaterial', _api.viewer.scene);
        // Need to see both sides of the area polygon because
        // it is not double sided.
        this.markupAreaMaterial.backFaceCulling = false;
        this.markupAreaMaterial.specularColor = Color3.Black(); // No specular hightlights
        this.markupAreaMaterial.diffuseColor = Color3.Teal();
        this.markupAreaMaterial.alpha = 0.7;

        this._observable.add(async (o) => {
            if (
                o.eventType === MarkupEventTypes.Added &&
                (o.local instanceof MarkupLine ||
                    o.local instanceof MarkupArea ||
                    o.local instanceof MarkupArrow ||
                    o.local instanceof MarkupText ||
                    o.local instanceof MarkupCircle)
            ) {
                if (o.local instanceof MarkupArea) {
                    if (!DynamicPolygon.validatePoints(o.local.points)) {
                        throw new Error('Could not build polygon, points form a complex polygon.');
                    }

                    const dynamicPolygon = this.createMarkupShapeDynamicPolygon(
                        o.local,
                        this.markupAreaMaterial,
                        false
                    );
                    this.areaIdToDynamicPolygon.set(o.local.id, dynamicPolygon);
                } else if (
                    o.local instanceof MarkupLine ||
                    o.local instanceof MarkupArrow ||
                    o.local instanceof MarkupCircle
                ) {
                    const dynamicPolygonWithArbitraryPoints = this.createMarkupShapeDynamicPolygonWithArbitraryPoints(
                        o.local,
                        this.markupAreaMaterial,
                        false
                    );
                    this.markupIdToDynamicPolygonWithArbitraryPoints.set(o.local.id, dynamicPolygonWithArbitraryPoints);
                } else if (o.local instanceof MarkupText) {
                    const onPointMove = new Observable<HtmlPoint>();

                    const h2cPlane = await Html2CanvasPlane.create<HtmlPoint>(
                        this._api,
                        o.local.id,
                        o.local.text,
                        (text) => {
                            return DOMPurify.sanitize(marked(text), {
                                USE_PROFILES: { html: true }
                            });
                        },
                        o.local.position,
                        o.local.normal,
                        o.local.scale,
                        o.local.rotation,
                        new Color4(
                            o.local.style.backgroundColor.r,
                            o.local.style.backgroundColor.g,
                            o.local.style.backgroundColor.b,
                            o.local.style.backgroundColor.a
                        ),
                        (api, plane) => {
                            const ret = new HtmlPoint(api, plane, onPointMove);
                            return ret;
                        },
                        this._drawingApi.drawingParentNode
                    );

                    h2cPlane.onPointTrackableScreen.add((eventData, eventState) => {
                        const measurePointWithLabel = eventData.trackedCoordinate;
                        measurePointWithLabel.updateHTMLElement(eventData);
                        // const cssText = updateLabel(eventData, domElement.offsetHeight);
                        // domElement.style.cssText = cssText;
                    });

                    const markupText = o.local as MarkupText;

                    onPointMove.add(() => {
                        markupText.position.copyFrom(h2cPlane.position);
                        markupText.scale = h2cPlane.scaling.clone();
                        markupText.rotation = h2cPlane.rotationAroundNormal;
                        o.local.markAsUpdated();
                    });

                    h2cPlane.onHtmlChangeObservable.add((text) => {
                        markupText.text = text;
                        markupText.scale = h2cPlane.scaling.clone();
                        o.local.markAsUpdated();
                    });

                    h2cPlane.onBackgroundColorChangeObservable.add((color) => {
                        markupText.style.backgroundColor.r = color.r;
                        markupText.style.backgroundColor.g = color.g;
                        markupText.style.backgroundColor.b = color.b;
                        markupText.style.backgroundColor.a = color.a;
                        o.local.markAsUpdated();
                    });

                    this.textIdToHtml2CanvasText.set(o.local.id, h2cPlane);
                }
                //disable mesh if sheet has conflict
                //This is not real conflict resolution, we simply disabled everything in sheets with conflicts for debugging purposes.
                //Since all areas dissapear when there is a conflict on the sheet its easy to spot which sheet has a conflict.
                //In a real application there would be conflict handling here.
                const sheet = o.local.sheet;
                const sheetMergable = sheet.parent._layer.get(sheet.id);
                if (sheetMergable?.hasConflict) {
                    sheet.isEnabled = false;
                }
            } else if (
                o.eventType === MarkupEventTypes.Delete &&
                (o.local instanceof MarkupArea ||
                    o.local instanceof MarkupLine ||
                    o.local instanceof MarkupArrow ||
                    o.local instanceof MarkupCircle)
            ) {
                if (o.local instanceof MarkupArea) {
                    const dynamicPolygon = this.areaIdToDynamicPolygon.get(o.local.id);
                    dynamicPolygon?.dispose();
                    this.areaIdToDynamicPolygon.delete(o.local.id);
                } else if (
                    o.local instanceof MarkupLine ||
                    o.local instanceof MarkupArrow ||
                    o.local instanceof MarkupCircle
                ) {
                    const dynamicPolygonWithArbitraryPoints = this.markupIdToDynamicPolygonWithArbitraryPoints.get(
                        o.local.id
                    );
                    dynamicPolygonWithArbitraryPoints?.dispose();
                    this.markupIdToDynamicPolygonWithArbitraryPoints.delete(o.local.id);
                }
            } else if (o.eventType === MarkupEventTypes.Delete && o.local instanceof MarkupText) {
                const h2c = this.textIdToHtml2CanvasText.get(o.local.id);
                h2c?.dispose();
                this.textIdToHtml2CanvasText.delete(o.local.id);
            } else if (
                o.eventType === MarkupEventTypes.Update &&
                o.eventSource === MergableObjectEventSource.Remote &&
                o.remote instanceof MarkupArea
            ) {
                if (!o.conflictReason) {
                    //The markup area has been changed remotely so we need to validate the new points and then update the dynamic polygon.
                    const dynamicPolygon = this.areaIdToDynamicPolygon.get(o.remote.id);
                    if (!DynamicPolygon.validatePoints(o.remote.points)) {
                        throw new Error('Could not build polygon, points form a complex polygon.');
                    }
                    dynamicPolygon?.build(o.remote.points, false);
                } else {
                    //resolve conflicts here then build polygon
                    console.log('Resolve conflicts here');
                }
            }
        });
    }

    public get sheets(): MarkupSheet2DCollection | undefined {
        return this._sheets;
    }

    private createMarkupShapeDynamicPolygon(
        markupEntity: MarkupArea,
        material: StandardMaterial,
        transformPointsToLocal = true
    ): DynamicPolygon<AreaPolygonPointWithLabel> {
        const onPointMoved = new Observable<AreaPolygonPointWithLabel>();

        let dynamicPolygon: DynamicPolygon<AreaPolygonPointWithLabel>;
        if (markupEntity instanceof MarkupArea) {
            dynamicPolygon = new DynamicPolygon<AreaPolygonPointWithLabel>(
                'markupfigure_' + markupEntity.id,
                this._api,
                (api, plane, isVirtual) => {
                    const ret = new AreaPolygonPointWithLabel(api, plane, isVirtual, onPointMoved);
                    return ret;
                },
                true,
                this._drawingApi.drawingParentNode,
                (points) => {
                    const dragPointPos = { x: 0, y: 0, z: 0 };
                    points.forEach((p) => {
                        dragPointPos.x += p.x;
                        dragPointPos.y += p.y;
                        dragPointPos.z += p.z;
                    });
                    dragPointPos.x /= points.length;
                    dragPointPos.y = points[0].y;
                    dragPointPos.z /= points.length;
                    return dragPointPos;
                }
            );
        } else {
            throw new Error('Unknown type, this case should not occur');
        }

        const scene = material.getScene();
        scene.onBeforeRenderObservable.add(() => {
            if (dynamicPolygon.isEnabled !== markupEntity.isEnabled) {
                dynamicPolygon.isEnabled = markupEntity.isEnabled;
                for (const p of dynamicPolygon.points()) {
                    if (p instanceof AreaPolygonPointWithLabel) {
                        p.updateVisibility();
                    }
                }
            }
        });
        //if you wish to use area.style.backgroundColor simply make multiple materials and use the appropriate one here.

        dynamicPolygon.mesh.material = material;
        dynamicPolygon.onPointTrackableScreen.add((eventData, eventState) => {
            const measurePointWithLabel = eventData.trackedCoordinate;
            measurePointWithLabel.updateHTMLElement(eventData);
        });

        if (markupEntity instanceof MarkupArea) {
            dynamicPolygon!.build(markupEntity.points, transformPointsToLocal);

            onPointMoved.add(() => {
                markupEntity.points = [...dynamicPolygon.points((p) => !p.virtual())].map((p) => {
                    return { x: p.localX, y: p.localY, z: p.localZ };
                });
                markupEntity.markAsUpdated();
            });
        }

        return dynamicPolygon!;
    }

    private createMarkupShapeDynamicPolygonWithArbitraryPoints(
        markupEntity: MarkupLine | MarkupArrow | MarkupCircle,
        material: StandardMaterial,
        transformPointsToLocal = true
    ): DynamicPolygonWithArbitraryPoints<AreaPolygonPointWithLabel> {
        const onPointMoved = new Observable<AreaPolygonPointWithLabel>();

        let dynamicPolygonWithArbitraryPoints: DynamicPolygonWithArbitraryPoints<AreaPolygonPointWithLabel>;
        if (
            markupEntity instanceof MarkupLine ||
            markupEntity instanceof MarkupArrow ||
            markupEntity instanceof MarkupCircle
        ) {
            let pointsFunction: (points: Vertex3[]) => Vertex3[];
            let dragPointPositioningFunctionOrIndex: DragPointPositioningFunctionOrIndex | undefined = undefined;
            let polygonPointsValidator:
                | ((controlPoints: Vertex3[]) => DynamicPolygonPointValidationResult)
                | undefined = undefined;

            if (markupEntity instanceof MarkupLine) {
                dragPointPositioningFunctionOrIndex = (points) => {
                    let p1 = points[0];
                    let p2 = points[1];
                    if (points.length > 2) {
                        p1 = points[Math.floor(points.length / 2)];
                        p2 = points[Math.floor(points.length / 2) + 1];
                    }

                    const halfLength = { x: (p2.x - p1.x) / 2, y: p1.y, z: (p2.z - p1.z) / 2 };
                    const ret = { x: p1.x + halfLength.x, y: p1.y, z: p1.z + halfLength.z };
                    return ret;
                };
                pointsFunction = (points) => {
                    return Shapes.createLinePolygonPoints(points, markupEntity.thickness);
                };
                polygonPointsValidator = (controlPoints) => {
                    const shapePoints = Shapes.createLinePolygonPoints(
                        controlPoints,
                        markupEntity.thickness * DrawingApi.defaultScale.x
                    );
                    return DynamicPolygon.validatePoints(shapePoints);
                };
            } else if (markupEntity instanceof MarkupArrow) {
                dragPointPositioningFunctionOrIndex = (points) => {
                    const middle = {
                        x: points[1].x + (points[0].x - points[1].x) / 2,
                        y: points[1].y + (points[0].y - points[1].y) / 2,
                        z: points[1].z + (points[0].z - points[1].z) / 2
                    };

                    return middle;
                };
                pointsFunction = (points) => {
                    const bluntPoint = points[0];
                    const arrowPoint = points[1];
                    return Shapes.createArrowPolygonPoints(
                        bluntPoint,
                        arrowPoint,
                        markupEntity.lineThickness,
                        markupEntity.arrowWidth,
                        markupEntity.arrowHeight
                    );
                };
            } else {
                dragPointPositioningFunctionOrIndex = 0;
                pointsFunction = (points) => {
                    const circlePoint = points[0];
                    const radiusPoint = points[1];
                    return Shapes.createCirclePolygonPoints(circlePoint, radiusPoint, markupEntity.lineThickness, 32);
                };
            }

            const onPointMovedDynamicPolygon = new Observable<AreaPolygonPointWithLabel>();
            const pointFactory = (api: BimCoreApi, plane: Plane, isVirtual: boolean): AreaPolygonPointWithLabel => {
                const ret = new AreaPolygonPointWithLabel(api, plane, isVirtual, onPointMovedDynamicPolygon);
                return ret;
            };

            const pointFactoryControlPoint = (
                api: BimCoreApi,
                plane: Plane,
                isVirtual: boolean
            ): AreaPolygonPointWithLabel => {
                const ret = new AreaPolygonPointWithLabel(api, plane, isVirtual, onPointMoved);
                return ret;
            };

            dynamicPolygonWithArbitraryPoints = new DynamicPolygonWithArbitraryPoints<AreaPolygonPointWithLabel>(
                pointsFunction,
                polygonPointsValidator,
                'markupfigure_' + markupEntity.id,
                this._api,
                pointFactory,
                pointFactoryControlPoint,
                true,
                this._drawingApi.drawingParentNode,
                dragPointPositioningFunctionOrIndex
            );
        } else {
            throw new Error('Unknown type, this case should not occur');
        }

        const scene = material.getScene();
        scene.onBeforeRenderObservable.add(() => {
            if (dynamicPolygonWithArbitraryPoints.isEnabled !== markupEntity.isEnabled) {
                dynamicPolygonWithArbitraryPoints.isEnabled = markupEntity.isEnabled;
                for (const p of dynamicPolygonWithArbitraryPoints.controlPoints()) {
                    if (p instanceof AreaPolygonPointWithLabel) {
                        p.updateVisibility();
                    }
                }
            }
        });
        //if you wish to use area.style.backgroundColor simply make multiple materials and use the appropriate one here.

        dynamicPolygonWithArbitraryPoints.setMaterial(material);
        dynamicPolygonWithArbitraryPoints.onPointTrackableScreen.add((eventData, eventState) => {
            const measurePointWithLabel = eventData.trackedCoordinate;
            measurePointWithLabel.updateHTMLElement(eventData);
        });

        type customPointsPolygonType = DynamicPolygonWithArbitraryPoints<AreaPolygonPointWithLabel>;

        if (
            markupEntity instanceof MarkupLine ||
            markupEntity instanceof MarkupArrow ||
            markupEntity instanceof MarkupCircle
        ) {
            // mystic compile errors if instanceof is used for customPointsPolygonType
            const customPointsShape = dynamicPolygonWithArbitraryPoints as customPointsPolygonType;

            if (markupEntity instanceof MarkupLine) {
                // mystic compile errors if instanceof is used for customPointsPolygonType
                customPointsShape.build(markupEntity.linePoints, transformPointsToLocal, true);

                onPointMoved.add(() => {
                    markupEntity.linePoints = [...customPointsShape.controlPoints((p) => !p.virtual())].map((p) => {
                        return { x: p.localX, y: p.localY, z: p.localZ };
                    });
                    markupEntity.markAsUpdated();
                });
            } else if (markupEntity instanceof MarkupArrow) {
                // mystic compile errors if instanceof is used for customPointsPolygonType
                customPointsShape.build(
                    [markupEntity.bluntPoint, markupEntity.arrowPoint],
                    transformPointsToLocal,
                    false
                );

                onPointMoved.add(() => {
                    const arrowPoints = [...customPointsShape.controlPoints((p) => !p.virtual())].map((p) => {
                        return { x: p.localX, y: p.localY, z: p.localZ };
                    });
                    markupEntity.bluntPoint = arrowPoints[0];
                    markupEntity.arrowPoint = arrowPoints[1];
                    markupEntity.markAsUpdated();
                });
            } else if (markupEntity instanceof MarkupCircle) {
                // mystic compile errors if instanceof is used for customPointsPolygonType
                customPointsShape.build(
                    [markupEntity.circlePoint, markupEntity.radiusPoint],
                    transformPointsToLocal,
                    false
                );

                onPointMoved.add(() => {
                    const circlePoints = [...customPointsShape.controlPoints((p) => !p.virtual())].map((p) => {
                        return { x: p.localX, y: p.localY, z: p.localZ };
                    });
                    markupEntity.circlePoint = circlePoints[0];
                    markupEntity.radiusPoint = circlePoints[1];
                    markupEntity.markAsUpdated();
                });
            }
        }

        return dynamicPolygonWithArbitraryPoints!;
    }

    public get isEnabled(): boolean {
        return this._isEnabled;
    }

    public set isEnabled(v: boolean) {
        this._isEnabled = v;
    }

    get conflicts(): Readonly<MergableObjectWithState<MarkupEntityBase>>[] {
        return this.sheets?.conflicts() ?? [];
    }

    createMarkupText(
        t: string,
        position: Vector3,
        normal: Vector3,
        sheet: MarkupSheet2D,
        parent?: TransformNode
    ): MarkupEntity {
        if (parent) {
            Vector3.TransformCoordinatesToRef(position, parent.getWorldMatrix().clone().invert(), position);
            Vector3.TransformCoordinatesToRef(normal, parent.getWorldMatrix().clone().invert(), normal);
            normal.normalize();
        }

        const size = PlaneUtil.cameraScaledSize(position, this._api.viewer.camera.activeCamera, this._scaleDenom);

        const text = sheet.add(MarkupText, {
            text: t,
            position: position,
            normal: normal,
            scale: size,
            style: { backgroundColor: new Color4(1.0, 1.0, 1.0, 1.0) },
            rotation: 0
        });
        return text;
    }

    createMarkupArea(position: Vector3, sheet: MarkupSheet2D): MarkupEntity {
        const babylonVectorPoints = this.calculateSquareLocalPointsForPointerPositionWithParent(
            position,
            this._api.viewer.camera.activeCamera,
            this._drawingApi.drawingParentNode
        );

        const vertexPoints = new Array<Vertex3>();

        for (const vec of babylonVectorPoints) {
            vertexPoints.push({ x: vec.x, y: vec.y, z: vec.z });
        }

        const area = sheet.add(MarkupArea, {
            points: vertexPoints,
            style: { backgroundColor: new Color4(...Color3.Teal().asArray(), 0.7) }
        });

        return area;
    }

    createMarkupLine(position: Vector3, sheet: MarkupSheet2D): MarkupEntity {
        const babylonVectorPoints = this.calculateLineLocalPointsForPointerPositionWithParent(
            position,
            this._api.viewer.camera.activeCamera,
            this._drawingApi.drawingParentNode
        );

        const vertexPoints = new Array<Vertex3>();

        const thickness = babylonVectorPoints[0].subtract(babylonVectorPoints[1]).length() / 4.0;

        for (const vec of babylonVectorPoints) {
            vertexPoints.push({ x: vec.x, y: vec.y, z: vec.z });
        }

        const line = sheet.add(MarkupLine, {
            thickness: thickness,
            linePoints: vertexPoints,
            style: { backgroundColor: new Color4(...Color3.Purple().asArray(), 0.7) }
        });

        return line;
    }

    createMarkupArrow(position: Vector3, sheet: MarkupSheet2D): MarkupEntity {
        const babylonVectorPoints = this.calculateLineLocalPointsForPointerPositionWithParent(
            position,
            this._api.viewer.camera.activeCamera,
            this._drawingApi.drawingParentNode
        );

        const thickness = babylonVectorPoints[0].subtract(babylonVectorPoints[1]).length() / 4.0;

        const vertexPoints = new Array<Vertex3>();
        for (const vec of babylonVectorPoints) {
            vertexPoints.push({ x: vec.x, y: vec.y, z: vec.z });
        }

        const bluntPointVec3 = new Vector3(vertexPoints[0].x, vertexPoints[0].y, vertexPoints[0].z);
        const arrowPointVec3 = new Vector3(vertexPoints[1].x, vertexPoints[1].y, vertexPoints[1].z);
        const lineDirection = arrowPointVec3.subtract(bluntPointVec3);
        lineDirection.normalizeToRef(lineDirection);
        const arrowExtraLength = lineDirection.scale(thickness * 2.0);
        vertexPoints[0].x -= arrowExtraLength.x / 2;
        vertexPoints[0].y -= arrowExtraLength.y / 2;
        vertexPoints[0].z -= arrowExtraLength.z / 2;

        vertexPoints[1].x += arrowExtraLength.x / 2;
        vertexPoints[1].y += arrowExtraLength.y / 2;
        vertexPoints[1].z += arrowExtraLength.z / 2;

        const arrow = sheet.add(MarkupArrow, {
            bluntPoint: vertexPoints[0],
            arrowPoint: vertexPoints[1],
            lineThickness: thickness,
            arrowHeight: thickness * 2.0,
            arrowWidth: thickness,
            style: { backgroundColor: new Color4(...Color3.Purple().asArray(), 0.7) }
        });

        return arrow;
    }

    createMarkupCircle(position: Vector3, sheet: MarkupSheet2D): MarkupEntity {
        const babylonVectorPoints = this.calculateLineLocalPointsForPointerPositionWithParent(
            position,
            this._api.viewer.camera.activeCamera,
            this._drawingApi.drawingParentNode
        );

        const middle = Vector3.Lerp(babylonVectorPoints[0], babylonVectorPoints[1], 0.5);

        babylonVectorPoints[0].subtract(babylonVectorPoints[1]).length() / 4.0;

        const circle = sheet.add(MarkupCircle, {
            circlePoint: { x: middle.x, y: middle.y, z: middle.z },
            radiusPoint: { x: babylonVectorPoints[1].x, y: babylonVectorPoints[1].y, z: babylonVectorPoints[1].z },
            lineThickness: 0.1,
            style: { backgroundColor: new Color4(...Color3.Purple().asArray(), 0.7) }
        });

        return circle;
    }

    private calculateSquareLocalPointsForPointerPositionWithParent(
        position: Vector3,
        camera: Camera,
        parent: TransformNode
    ): Vector3[] {
        const points: [Vector3, Vector3, Vector3, Vector3] = [
            Vector3.Zero(),
            Vector3.Zero(),
            Vector3.Zero(),
            Vector3.Zero()
        ];

        return PlaneUtil.cameraScaledSquareWithParentOnPlaneToRef(
            position,
            UP,
            camera,
            this._scaleDenom,
            parent,
            points
        );
    }

    private calculateLineLocalPointsForPointerPositionWithParent(
        position: Vector3,
        camera: Camera,
        parent: TransformNode
    ): Vector3[] {
        const points: [Vector3, Vector3] = [Vector3.Zero(), Vector3.Zero()];

        return LineUtil.cameraScaledLineWithParentOnPlaneToRef(position, UP, camera, this._scaleDenom, parent, points);
    }

    createNewMarkupSheet(name: string, selector: Selector<MarkupSheet2D>): MarkupSheet2D {
        if (!this.sheets) throw new Error(`Call loadMarkupSheets first.`);
        const sheet = this.sheets.addSheet(name);
        selector.build([...this.sheets].sort((c0, c1) => c0.id.localeCompare(c1.id)));
        selector.setSelection([sheet]);
        return sheet;
    }

    async saveMarkupSheets(): Promise<number | LayerFailure> {
        if (!this._sheets) {
            console.warn('Attempted to save markups to drawing but no sheet collection exists on that drawing.');
            return 0;
        }
        const conflictCountOrFailure = await this._sheets.saveAndMergeSheets(false);
        //TODO: handle conflicts
        return conflictCountOrFailure;
    }

    async loadMarkupSheets(drawing: BimChangeDwg | BimChangeBlob): Promise<undefined | MarkupSheet2DCollection> {
        const collectionOrLayerFailure = await this._markupApi.getOrLoadSheets(drawing, () => this._observable);
        if (isFailure(collectionOrLayerFailure)) {
            //TODO: handle layer failure here
            return undefined;
        }
        this._sheets = collectionOrLayerFailure;
        return collectionOrLayerFailure;
    }

    deleteEntity(entity: MarkupEntity): boolean {
        return entity.markAsDeleted();
    }

    async deleteMarkupSheet(sheet: MarkupSheet2D, selector: Selector<MarkupSheet2D>): Promise<void> {
        if (!this.sheets) throw new Error('Call loadMarkupSheets first.');
        sheet.markAsDeleted();
        selector.build([...this.sheets].sort((c0, c1) => c0.id.localeCompare(c1.id)));
        const s = [...this.sheets][0];
        if (s !== undefined) {
            selector.setSelection([s]);
            s.isEnabled = true;
        }
    }

    duplicateMarkupSheet(
        sheet: MarkupSheet2D,
        id: string,
        selector: Selector<MarkupSheet2D>
    ): MarkupSheet2D | undefined {
        if (!this.sheets) throw new Error('Call loadMarkupSheets first');
        const newSheet = sheet.duplicate(id);
        // const newSheet = this._markupApi.sheets.duplicate(sheet, id);

        selector.build([...this.sheets].sort((c0, c1) => c0.id.localeCompare(c1.id)));
        selector.setSelection([newSheet]);

        // return newSheet;
        return newSheet;
    }

    unloadMarkupSheets(): boolean {
        return this._sheets ? this._markupApi.unloadSheets(this._sheets?.attachedTo) : false;
    }

    refresh(): Promise<number | LayerFailure> {
        if (!this._sheets) {
            console.warn('Tried to refresh sheet when no sheet was loaded.');
            return Promise.resolve(0);
        }
        return this._sheets.loadAndMerge();
    }
}
