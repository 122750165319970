/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */
import { BimSvg } from './BimSvg';
import { ISize } from '@babylonjs/core/Maths/math.size';
export class SvgEditor {
    private static readonly _div = document.createElement('div');

    /**
     * Sets the viewbox of an SVG
     * @param svg The SVG to set the viewbox of
     * @param x where the viewbox starts in the x axis
     * @param y where the viewbox starts in the y axis
     * @param width width of the viewbox
     * @param height height of the viewbox
     */
    static setViewBox(svg: BimSvg, x: number, y: number, width: number, height: number, textureSize: number): void {
        SvgEditor._div.style.position = 'absolute';
        SvgEditor._div.innerHTML = svg.svgContent.header;

        // These 4 lines can be used to optimize the SVG rendering. However, it comes at a cost of precision which we do not want to deal with.
        // These are left here in case we need more performance and not as much precision at some point in the future.
        // width = Math.floor(width);
        // height = Math.floor(height);
        // x = Math.floor(x);
        // y = Math.floor(y);
        const svgElement = SvgEditor._div.querySelector('svg')!;
        svgElement.setAttribute('viewBox', `${x} ${y} ${width} ${height}`);
        // svgElement.setAttribute('shape-rendering', 'optimizeSpeed');
        // svgElement.setAttribute('color-rendering', 'optimizeSpeed');
        // svgElement.setAttribute('text-rendering', 'optimizeSpeed');
        // svgElement.setAttribute('image-rendering', 'optimizeSpeed');
        // svgElement.setAttribute('buffered-rendering', 'dynamic');

        svgElement.setAttribute('width', textureSize.toString());
        svgElement.setAttribute('height', textureSize.toString());
        svg.svgContent.header = SvgEditor._div.innerHTML.replace('</svg>', '');
    }

    /**
     * Gets the clip path of the SVG. Due to how the SVGs are created the clip path is the actual width and height of the SVG.
     * @param svg The SVG to get the clip path of.
     * @returns The real width and height of the SVG in the format {width, height}.
     */
    static getClipPath(svg: BimSvg): ISize {
        const clipPathRegexp = /M0\,\s*(?<height>[^\s,]+)\s*(,|\s)\s*(?<width>[^\s,]+)/;
        const clipPath = clipPathRegexp.exec(svg.svgSource);
        return {
            width: parseInt(clipPath?.groups?.width ?? '-1'),
            height: parseInt(clipPath?.groups?.height ?? '-1')
        };
    }

    static getViewBox(svg: BimSvg): { x: number; y: number; width: number; height: number } {
        const viewBoxRegexp =
            /viewBox\=\"\s*(?<x>[^\s,]+)\s*(,|\s)\s*(?<y>[^\s,]+)\s*(,|\s)\s*(?<width>[^\s,]+)\s*(,|\s)\s*(?<height>[^\s,]+)\"/;

        const vBox = viewBoxRegexp.exec(svg.svgContent.header);

        return {
            x: parseInt(vBox?.groups?.x ?? '-1'),
            y: parseInt(vBox?.groups?.y ?? '-1'),
            width: parseInt(vBox?.groups?.width ?? '-1'),
            height: parseInt(vBox?.groups?.height ?? '-1')
        };
    }

    static combineSvgs(baseSvg: BimSvg, svg: BimSvg): void {
        baseSvg.svgContent.addedSvgs.push(
            `<g transform="translate(${baseSvg.offset.x - svg.offset.x} ${baseSvg.offset.y - svg.offset.y})"> \n ${
                svg.svgContent.content
            } \n </g>`
        );
    }

    static setGrayscale(svg: BimSvg, grayscale: boolean): void {
        SvgEditor._div.innerHTML = svg.svgContent.header;
        const svgElement = SvgEditor._div.querySelector('svg')!;
        const gs = grayscale ? 100 : 0;
        svgElement.setAttribute('filter', `grayscale(${gs}%)`);
        svg.svgContent.header = SvgEditor._div.innerHTML;
    }

    static invertBackgroundColor(svg: BimSvg, invert: boolean): void {
        SvgEditor._div.innerHTML = svg.svgContent.header;
        const svgElement = SvgEditor._div.querySelector('svg')!;
        svgElement.style.backgroundColor = invert ? 'white' : 'transparent';
        svg.svgContent.header = SvgEditor._div.innerHTML;
    }
}
