import { Scene, Texture } from './loader/babylonjs-import';

/**
 * This helper functions makes it easy to load texture
 * @param scene Scene
 * @param url The image atlas url.
 * @returns Texture.
 */
export async function getTexture(scene: Scene, url: URL): Promise<Texture> {
    return new Promise((resolve, reject) => {
        const texture = new Texture(
            url.href,
            scene,
            true,
            false,
            undefined,
            function () {
                resolve(texture);
            },
            function (message) {
                reject(message);
            }
        );
    });
}
