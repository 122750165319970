/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { Scalar } from '../loader/babylonjs-import';
import { BimTransform } from '../loader/bim-format-types';
import { decodePackedNormal, encodePackedNormal } from '../loader/PackedNormal';
import { Vertex3, MutableArrayLike } from './index';

function optimizedTransformInPlace(
    A: number,
    B: number,
    C: number,
    D: number,
    E: number,
    F: number,
    G: number,
    H: number,
    J: number,
    tX: number,
    tY: number,
    tZ: number,
    x: number,
    y: number,
    z: number,
    dst: MutableArrayLike<number>,
    dstOffset: number
): void {
    dst[dstOffset] = x * A + y * B + z * C + tX;
    dst[dstOffset + 1] = x * D + y * E + z * F + tY;
    dst[dstOffset + 2] = x * G + y * H + z * J + tZ;
}

function optimizedIdentityTransformInPlace(
    A: number,
    B: number,
    C: number,
    D: number,
    E: number,
    F: number,
    G: number,
    H: number,
    J: number,
    tX: number,
    tY: number,
    tZ: number,
    x: number,
    y: number,
    z: number,
    dst: MutableArrayLike<number>,
    dstOffset: number
): void {
    dst[dstOffset] = x * A + tX;
    dst[dstOffset + 1] = y * E + tY;
    dst[dstOffset + 2] = z * J + tZ;
}

function optimizedRotatePackedNormalInPlaceAndRepack(
    A: number,
    B: number,
    C: number,
    D: number,
    E: number,
    F: number,
    G: number,
    H: number,
    J: number,
    packedNormal: number
): number {
    const n = decodePackedNormal[packedNormal];
    // BUG? What happens if we have a non uniform scale? Will the normal really be rotated
    // correctly then? Also we probably have to normalize in that case.

    return encodePackedNormal(n.x * A + n.y * B + n.z * C, n.x * D + n.y * E + n.z * F, n.x * G + n.y * H + n.z * J);
}

function optimizedTransformInPlaceVector3(
    A: number,
    B: number,
    C: number,
    D: number,
    E: number,
    F: number,
    G: number,
    H: number,
    J: number,
    tX: number,
    tY: number,
    tZ: number,
    x: number,
    y: number,
    z: number,
    dst: Vertex3
): void {
    dst.x = x * A + y * B + z * C + tX;
    dst.y = x * D + y * E + z * F + tY;
    dst.z = x * G + y * H + z * J + tZ;
}

export enum TransformType {
    Identity = 0,
    Position = 1,
    Rotation = 1 << 1,
    Scale = 1 << 2,
    All = TransformType.Position | TransformType.Rotation | TransformType.Scale
}

export class FastTransform {
    // These represents quaternion rotation in form of
    // premultipled constants. These are used
    // when transforming (S*R*T) a position/vertex (x,y,z)
    private _vA: number;
    private _vB: number;
    private _vC: number;
    private _vD: number;
    private _vE: number;
    private _vF: number;
    private _vG: number;
    private _vH: number;
    private _vJ: number;

    // These represents quaternion rotation in form of
    // premultipled constants and shall only be used to rotate
    // (packed) normals
    private _nA: number;
    private _nB: number;
    private _nC: number;
    private _nD: number;
    private _nE: number;
    private _nF: number;
    private _nG: number;
    private _nH: number;
    private _nJ: number;

    private readonly _translation: Vertex3 = { x: 0, y: 0, z: 0 };

    public readonly type: TransformType;

    public constructor(transform: BimTransform) {
        this.type = FastTransform.GetTransformType(transform);
        const absSx = Math.abs(transform.sx);
        const absSy = Math.abs(transform.sy);
        const absSz = Math.abs(transform.sz);

        const signSx = Math.sign(transform.sx);
        const signSy = Math.sign(transform.sy);
        const signSz = Math.sign(transform.sz);

        // TODO Will this work even if scaling is not uniform?
        const _2x = transform.qx + transform.qx;
        const _2y = transform.qy + transform.qy;
        const _2z = transform.qz + transform.qz;

        const w2x = transform.qw * _2x;
        const w2y = transform.qw * _2y;
        const w2z = transform.qw * _2z;

        const x2x = transform.qx * _2x;
        const x2y = transform.qx * _2y;
        const x2z = transform.qx * _2z;

        const y2y = transform.qy * _2y;
        const y2z = transform.qy * _2z;

        const z2z = transform.qz * _2z;

        this._nA = (1.0 - y2y - z2z) * signSx;
        this._nB = (x2y - w2z) * signSy;
        this._nC = (x2z + w2y) * signSz;

        this._nD = (x2y + w2z) * signSx;
        this._nE = (1.0 - x2x - z2z) * signSy;
        this._nF = (y2z - w2x) * signSz;

        this._nG = (x2z - w2y) * signSx;
        this._nH = (y2z + w2x) * signSy;
        this._nJ = (1.0 - x2x - y2y) * signSz;

        this._vA = this._nA * absSx;
        this._vB = this._nB * absSy;
        this._vC = this._nC * absSz;

        this._vD = this._nD * absSx;
        this._vE = this._nE * absSy;
        this._vF = this._nF * absSz;

        this._vG = this._nG * absSx;
        this._vH = this._nH * absSy;
        this._vJ = this._nJ * absSz;

        this._translation.x = transform.x;
        this._translation.y = transform.y;
        this._translation.z = transform.z;
    }

    public static identity(): FastTransform {
        return new FastTransform({
            x: 0,
            y: 0,
            z: 0,
            sx: 1,
            sy: 1,
            sz: 1,
            qw: 1,
            qx: 0,
            qy: 0,
            qz: 0
        });
    }

    public translateToRef(center: Vertex3, dst: FastTransform): FastTransform {
        (dst.type as TransformType) = this.type;

        dst._nA = this._nA;
        dst._nB = this._nB;
        dst._nC = this._nC;

        dst._nD = this._nD;
        dst._nE = this._nE;
        dst._nF = this._nF;

        dst._nG = this._nG;
        dst._nH = this._nH;
        dst._nJ = this._nJ;

        dst._vA = this._vA;
        dst._vB = this._vB;
        dst._vC = this._vC;

        dst._vD = this._vD;
        dst._vE = this._vE;
        dst._vF = this._vF;

        dst._vG = this._vG;
        dst._vH = this._vH;
        dst._vJ = this._vJ;

        // Attempt to minimize rounding errors here
        // this.translation has already been multiplied with scale in constructor so it is already
        // in same space as center

        dst._translation.x = this._translation.x - center.x;
        dst._translation.y = this._translation.y - center.y;
        dst._translation.z = this._translation.z - center.z;

        return dst;
    }

    public rotatePackedNormalInPlace(packedNormal: number, dst: Float32Array, dstOffset: number): void {
        // This is a little weird but apparently this call where
        // we basically just read all properties we need and pass into a "pure"
        // function becomes faster in at least chrome.
        if (this.type & TransformType.Rotation) {
            dst[dstOffset] = optimizedRotatePackedNormalInPlaceAndRepack(
                this._nA,
                this._nB,
                this._nC,
                this._nD,
                this._nE,
                this._nF,
                this._nG,
                this._nH,
                this._nJ,
                packedNormal
            );
        } else {
            // Identity rotation is just writing the existing packed normal
            // to the destination.
            dst[dstOffset] = packedNormal;
        }
    }

    public transformInPlace(x: number, y: number, z: number, dst: MutableArrayLike<number>, dstOffset: number): void {
        // This is a little weird but apparently this call where
        // we basically just read all properties we need and pass into a "pure"
        // function becomes faster in at least chrome.
        if (this.type & TransformType.Rotation) {
            optimizedTransformInPlace(
                this._vA,
                this._vB,
                this._vC,
                this._vD,
                this._vE,
                this._vF,
                this._vG,
                this._vH,
                this._vJ,
                this._translation.x,
                this._translation.y,
                this._translation.z,
                x,
                y,
                z,
                dst,
                dstOffset
            );
        } else {
            optimizedIdentityTransformInPlace(
                this._vA,
                this._vB,
                this._vC,
                this._vD,
                this._vE,
                this._vF,
                this._vG,
                this._vH,
                this._vJ,
                this._translation.x,
                this._translation.y,
                this._translation.z,
                x,
                y,
                z,
                dst,
                dstOffset
            );
        }
    }

    public transformInPlaceVector3(x: number, y: number, z: number, dst: Vertex3): void {
        return optimizedTransformInPlaceVector3(
            this._vA,
            this._vB,
            this._vC,
            this._vD,
            this._vE,
            this._vF,
            this._vG,
            this._vH,
            this._vJ,
            this._translation.x,
            this._translation.y,
            this._translation.z,
            x,
            y,
            z,
            dst
        );
    }

    private static GetTransformType(transform: BimTransform): TransformType {
        const epsilon = 0.001;
        let type = TransformType.Identity;
        if (
            !Scalar.WithinEpsilon(transform.sx, 1, epsilon) ||
            !Scalar.WithinEpsilon(transform.sy, 1, epsilon) ||
            !Scalar.WithinEpsilon(transform.sz, 1, epsilon)
        ) {
            type |= TransformType.Scale;
        }

        if (
            !Scalar.WithinEpsilon(transform.x, 0, epsilon) ||
            !Scalar.WithinEpsilon(transform.y, 0, epsilon) ||
            !Scalar.WithinEpsilon(transform.z, 0, epsilon)
        ) {
            type |= TransformType.Position;
        }

        if (
            !Scalar.WithinEpsilon(transform.qw, 1, epsilon) ||
            !Scalar.WithinEpsilon(transform.qx, 0, epsilon) ||
            !Scalar.WithinEpsilon(transform.qy, 0, epsilon) ||
            !Scalar.WithinEpsilon(transform.qz, 0, epsilon)
        ) {
            type |= TransformType.Rotation;
        }
        return type;
    }
}
