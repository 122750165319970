/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { Writeable } from '../Types';

/**
 * Return random integer number between [0, max)
 * @param min Smallest possible random integer  (inclusive)
 * @param max Largest possible random integer (exclusive)
 * @returns Random number
 */
function randomIntegerMaxExclusive(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * Perform a knuth shuffle on a array.
 * @param ar Array to shuffle
 * @param startOffset Where to start shuffling in array
 * @returns Reference to ar (which is now shuffled)
 */
export function knuthShuffle<K, T extends Writeable<ArrayLike<K>>>(ar: T, startOffset = 0): T {
    const len = ar.length;
    let temp: K;
    let rPos = 0;
    for (let idx = startOffset; idx < len; ++idx) {
        rPos = randomIntegerMaxExclusive(idx, len - 1);
        temp = ar[idx];
        ar[idx] = ar[rPos];
        ar[rPos] = temp;
    }
    return ar;
}

/**
 * Creates a knuth shuffled numeric array
 * @param arrayFactory
 * @param step
 * @returns
 */
export function createKnuthShuffledNumberArray<T extends Writeable<ArrayLike<number>>>(
    arrayFactory: () => T,
    step = 1
): T {
    const shuffledArray = arrayFactory();
    const aLen = shuffledArray.length;
    for (let idx = 0, val = 0; idx < aLen; idx++, val += step) {
        shuffledArray[idx] = val;
    }

    return knuthShuffle<number, T>(shuffledArray);
}
