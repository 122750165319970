import { Plane } from '@babylonjs/core/Maths/math.plane';
import { Scalar } from '@babylonjs/core/Maths/math.scalar';
import { Observable } from '@babylonjs/core/Misc/observable';
import { Scene } from '@babylonjs/core/scene';
import {
    BimCoreApi,
    convertToCanvasCoordinatesToRef,
    Html2CanvasPoint,
    PointMoveResult,
    TrackCoordinate2D,
    TrackCoordinate2DState,
    Vertex2
} from '@twinfinity/core';

export class HtmlPoint extends Html2CanvasPoint {
    private _label?: HTMLDivElement;

    private _scene: Scene;

    private readonly _dragCanvasCoordinate = { x: -1, y: -1 };
    private readonly _prevDragCanvasCoordinate = { x: -1, y: -1 };
    private _labelCssText = '';

    public constructor(api: BimCoreApi, plane: Plane, readonly onPointMove: Observable<HtmlPoint>) {
        super(api, plane);
        this._scene = api.viewer.scene;
    }

    asCssText(position: Vertex2): string {
        return `transform: translate(${position.x}px, ${position.y}px);`;
    }

    public updateVisibility(): void {
        if (this._label) {
            if (this.isEnabled) {
                this._label.style.display = 'block';
            } else {
                this._label.style.display = 'none';
            }
        }
    }

    public updateHTMLElement(o: TrackCoordinate2D<HtmlPoint>): void {
        const textPointsParentElement = document.getElementById('textPoints');
        if (textPointsParentElement === null) {
            return;
        }
        const state = o.state;

        if (state === TrackCoordinate2DState.Added) {
            this._label = this._label ?? document.createElement('div');
            this._label.setAttribute('draggable', 'true');
            this._label.classList.add('areaPolygonPoint');
            this._label = textPointsParentElement.appendChild(this._label);
            this.updateLabel(o);

            this._label.addEventListener('dragstart', (ev) => this.onDragStart(ev));
            this._label.addEventListener('drag', (ev) => this.onDragging(ev));
            this._label.addEventListener('dragend', (ev) => this.onDragEnd(ev));
        } else if (state === TrackCoordinate2DState.Deleted) {
            this._label?.remove();
            this._labelCssText = '';
            this._label = undefined;
            this._prevDragCanvasCoordinate.x = -1;
            this._prevDragCanvasCoordinate.y = -1;
        } else if (state === TrackCoordinate2DState.Updated) {
            if (this._label === undefined) {
                this._label = this._label ?? document.createElement('div');
                this._label.setAttribute('draggable', 'true');
                this._label.classList.add('areaPolygonPoint');
                this._label = textPointsParentElement.appendChild(this._label);
                this.updateLabel(o);
                this._label.addEventListener('dragstart', (ev) => this.onDragStart(ev));
                this._label.addEventListener('dragend', (ev) => this.onDragEnd(ev));
                this._label.addEventListener('drag', (ev) => this.onDragging(ev));
            }
            this.updateLabel(o);
        }
    }

    private updateLabel(o: TrackCoordinate2D<HtmlPoint>): string {
        // Always reposition label so it is at center of point. We can cache same
        // DOMRect forever since point labels will never change size.

        const pos = {
            x: Math.floor(o.position.x - 12),
            y: Math.floor(o.position.y - 12)
        };

        const cssText = this.asCssText(pos);
        if (cssText !== this._labelCssText) {
            this._labelCssText = cssText;
            this._label!.style.cssText = cssText;
            this.updateVisibility();
        }
        return cssText;
    }

    //dragend event seems to have a bug with altkey/ctrlkey etc, so this is a workaround.
    //this is also used to keep the status of the keys through the entire event even if they are released
    altKeyStatus = false;
    ctrlKeyStatus = false;
    private onDragStart(ev: DragEvent): void {
        this.altKeyStatus = ev.altKey;
        this.ctrlKeyStatus = ev.ctrlKey;
    }

    private onDragEnd(ev: DragEvent): void {
        convertToCanvasCoordinatesToRef(this._scene, ev, this._dragCanvasCoordinate);
        const moveResult = this.move(this._dragCanvasCoordinate);
        if (moveResult === PointMoveResult.Success) {
            this.update();
        } else if (moveResult === PointMoveResult.RayIntersectionFailed) {
            alert('Can not move, current mouse coordinate does not intersect plane');
        }

        this._prevDragCanvasCoordinate.x = -1;
        this._prevDragCanvasCoordinate.y = -1;
    }

    private onDragging(ev: DragEvent): void {
        if (ev.screenX === 0 && ev.screenY === 0) {
            return;
        }

        convertToCanvasCoordinatesToRef(this._scene, ev, this._dragCanvasCoordinate);
        const domElementHasMoved =
            !Scalar.WithinEpsilon(this._dragCanvasCoordinate.x, this._prevDragCanvasCoordinate.x, 1) ||
            !Scalar.WithinEpsilon(this._dragCanvasCoordinate.y, this._prevDragCanvasCoordinate.y, 1);
        if (domElementHasMoved) {
            if (this.move(this._dragCanvasCoordinate) === PointMoveResult.Success) {
                this.update();
            }

            Object.assign(this._prevDragCanvasCoordinate, this._dragCanvasCoordinate);
        }
    }

    private update(): void {
        if (this === this._parent.centerPoint()) {
            this.tranlate();
        } else {
            if (this.altKeyStatus) {
                this.rotate();
            } else {
                this.scale(!this.ctrlKeyStatus);
            }
        }
        this.onPointMove.notifyObservers(this);
    }
}
