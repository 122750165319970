/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { BimProductMeshDescriptor, BimVertexData } from './bim-format-types';
import { IBimIfcLoaderElement } from './bim-ifc-loader-element';
import { HttpResponseType } from '../http';
import { BimVertexDataReader } from './bim-vertex-data-reader';

import { GeometryMemoryBlock as GeometryMemoryBlock } from './GeometryMemoryBlock';

import { telemetry } from '../Telemetry';
import { BimCoreApiClient } from './client/BimCoreApiClient';
import { FastTransform } from '../math/FastTransform';
import { GeometryArrayOffset, GeometryArrays } from './GeometryBuffer';
import { Vector3 } from './babylonjs-maths-import';
import { BimProductMesh } from './BimProductMesh';

export class BimVertexDataRepository {
    private _vertexDataBlock = new GeometryMemoryBlock();

    private readonly _loadedUrls = new Map<string, Promise<boolean>>();

    public constructor(private readonly _bimApiClient: BimCoreApiClient) {}

    public load(loaderElement: IBimIfcLoaderElement): Promise<boolean> {
        if (!loaderElement.geometryUrl?.href) {
            return Promise.resolve(false);
        }

        const key = loaderElement.geometryUrl.href.toLowerCase();
        const loadingPromise = this._loadedUrls.get(key);
        if (loadingPromise) {
            // If we are in the process of loading then return the loading promise.
            return loadingPromise;
        }

        const fetchPromise = this.fetchGeometry(loaderElement).then(async (geometryArrayBuffer) => {
            if (geometryArrayBuffer.byteLength === 0) return true;
            const vertexReader = new BimVertexDataReader(geometryArrayBuffer);
            await this._vertexDataBlock.importPackedVertexData(vertexReader, loaderElement.index.meshDescriptors);

            return true;
        });

        this._loadedUrls.set(key, fetchPromise);
        return fetchPromise;
    }

    public get sizeInBytes(): number {
        return this._vertexDataBlock.byteSize;
    }

    public clear(): void {
        this._vertexDataBlock.reset();
        this._loadedUrls.clear();
    }

    public get(md: BimProductMeshDescriptor): BimVertexData {
        const ret = this._vertexDataBlock.getVertexData(md);
        if (ret === undefined) throw new Error(`BimProductMeshDescriptor with hash ${md.h} is not registered.`);
        return ret;
    }

    public write(
        ifcProductMesh: BimProductMesh,
        transform: FastTransform,
        destination: GeometryArrays,
        offset: GeometryArrayOffset,
        aabb: { min: Vector3; max: Vector3 },
        options: { includeNormals?: boolean }
    ): void {
        const includeNormals = options.includeNormals ?? false;
        const data = this.get(ifcProductMesh.descriptor);
        const { colorTexturePixelIndex } = ifcProductMesh;
        // Since we merge geometries we need to offset the indices of each indice buffer we merge (otherwise
        // they refer to the wrong vertices)
        let indiceOffset = offset.indices;
        let positionsOffset = offset.positions * 3;
        let uvOffset = offset.positions * 2; // Normal + color
        const posLen = data.positions.length;
        for (let vI = 0, pNi = 0; vI < posLen; vI += 3, pNi++, positionsOffset += 3) {
            // transform vertices
            transform.transformInPlace(
                data.positions[vI],
                data.positions[vI + 1],
                data.positions[vI + 2],
                destination.positions,
                positionsOffset
            );

            // copy uvs. u=colorTexturePixelIndex and v = packed normal (if normals are included).
            // colorTexturePixelIndex is converted to real UV coordinate on shader.
            destination.uvs[uvOffset++] = colorTexturePixelIndex;
            if (includeNormals) {
                transform.rotatePackedNormalInPlace(data.packedNormals[pNi], destination.uvs, uvOffset);
            }
            uvOffset++;

            this.extendAabb(
                aabb,
                destination.positions[positionsOffset],
                destination.positions[positionsOffset + 1],
                destination.positions[positionsOffset + 2]
            );
        }
        for (let i = 0; i < data.indices.length; ++i) {
            destination.indices[indiceOffset] = offset.positions + data.indices[i];
            indiceOffset++;
        }
    }

    private extendAabb(aabb: { min: Vector3; max: Vector3 }, x: number, y: number, z: number): void {
        if (aabb.min.x > x) aabb.min.x = x;
        if (aabb.min.y > y) aabb.min.y = y;
        if (aabb.min.z > z) aabb.min.z = z;

        if (aabb.max.x < x) aabb.max.x = x;
        if (aabb.max.y < y) aabb.max.y = y;
        if (aabb.max.z < z) aabb.max.z = z;
    }

    private async fetchGeometry(loaderElement: IBimIfcLoaderElement): Promise<ArrayBuffer> {
        const properties = {
            url: loaderElement.ifc.metadata.serverRelativeUrl,
            version: loaderElement.ifc.version,
            id: loaderElement.ifc.id,
            discipline: loaderElement.ifc.discipline.short,
            httpStatus: -1,
            byteSize: -1
        };

        const event = telemetry.startTrackEvent('Fetched IFC geometry');

        try {
            const { status, value } = await this._bimApiClient.get(
                loaderElement.geometryUrl!,
                HttpResponseType.arrayBuffer
            );
            properties.httpStatus = status;
            const geometryArrayBuffer = status === 200 ? await value : new ArrayBuffer(0);
            properties.byteSize = geometryArrayBuffer.byteLength;
            return geometryArrayBuffer;
        } finally {
            event.stop(properties);
        }
    }
}
