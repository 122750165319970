﻿import { BimCoreApi } from './BimCoreApi';
import { BimContainerInfo, TwinfinityInfo, BimContainer } from './loader/bim-api-client';
import { BimApiLoadOptions } from './BimApiLoadOptions';
import { BimTwinfinityApiClient } from './loader/client/BimTwinfinityApiClient';
import { AuthorizationHeaderProvider, Http, HttpStatusCode } from './http';
import { TwinfinityApiClient } from './loader/client/TwinfinityApiClient';
import { telemetry } from './Telemetry';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

/**
 * Creation options for {@link BimApi}
 */
export type BimApiOptions =
    | (
          | {
                /**
                 * If `true` then {@link BimApi} will automatically redirect the user
                 * to the PS login page if the user is not already authenticated.
                 */
                readonly isPSAuthenticationRedirectEnabled?: false;
                session?: { getAuthorizationHeader: () => Promise<string> };
            }
          | {
                /**
                 * If `true` then {@link BimApi} will automatically redirect the user
                 * to the PS login page if the user is not already authenticated.
                 */
                readonly isPSAuthenticationRedirectEnabled: true;
            }
      ) & {
          /** set this to a unique name for the appliation. Will allow log filtering on application name. */
          readonly applicationName?: string;
      };

/**
 * BimApi is the main API class. This a subclass of {@link BimCoreApi} that is extended
 * with information only available from the Twinfinity API backend.
 */
export class BimApi extends BimCoreApi {
    private _info?: TwinfinityInfo;
    private _containerInfo?: BimContainerInfo;
    private readonly _bimApiOptions: Omit<BimApiOptions, 'session'>;
    private _twinfinitySession?: AuthorizationHeaderProvider;

    /**
     * @async
     * Creates a new instance of {@link BimApi}.
     * @param containerId ID of the HTML element where the viewer should be created.
     * @param absoluteContainerUrlOrApiClient Absolute URL to the container.
     * @param options Creation options.
     * @example
     * ```typescript
     * // Create an API instance.
     * const api = await BimApi.create('viewer', 'project-url'); // Open project directly.
     * const api = await BimApi.create('viewer', new BimTwinfinityApiClient(url)); // Create API. If a valid container url is given, the API automatically switches to it, otherwise calls api.setContainer() afterwards.
     * ```
     */
    public static async create(
        _htmlElementOrId: string | HTMLElement,
        absoluteContainerUrlOrApiClient: URL | string | TwinfinityApiClient,
        options?: BimApiOptions
    ): Promise<BimApi> {
        if (options?.applicationName) {
            telemetry.role = options.applicationName;
        }
        if (BimApi.isTwinfinityApiClient(absoluteContainerUrlOrApiClient)) {
            return BimCoreApi.createApi(
                _htmlElementOrId,
                (canvas) => new BimApi(canvas, absoluteContainerUrlOrApiClient, options)
            );
        }

        // absoluteContainerUrlOrApiClient was either a URL or a string representing a URL
        const absoluteUrl = new URL(decodeURI(absoluteContainerUrlOrApiClient.toString()).toLowerCase());

        const apiClient = new BimTwinfinityApiClient(absoluteUrl);
        const api = await BimCoreApi.createApi(_htmlElementOrId, (canvas) => new BimApi(canvas, apiClient, options));

        try {
            await api.setContainer(absoluteUrl);
        } catch (err) {
            // Means that the passed url did not point to a valid container. In that case we just return the API
            // the user will have to call api.setContainer() later with a valid project. This can happen if API is created like this
            // created('canvasId', "https://bim.demo.projektstruktur.se") which is a valid use case.
            telemetry.trackTrace({
                message: `Failed to setContainer(${absoluteUrl.href}).`,
                severityLevel: SeverityLevel.Error
            });
        }
        return api;
    }

    /** Constructor.
     * @param canvas Canvas element where 3D content will be rendered.
     * @param apiClient Methods for communicating with the backend.
     */
    public constructor(
        canvas: HTMLCanvasElement,
        private readonly _apiClient: TwinfinityApiClient,
        bimApiOptions?: BimApiOptions
    ) {
        super(canvas, _apiClient);
        const givenOptions = bimApiOptions ?? { isPSAuthenticationRedirectEnabled: false };
        this._bimApiOptions = {
            isPSAuthenticationRedirectEnabled: givenOptions.isPSAuthenticationRedirectEnabled
        };
        if (bimApiOptions?.isPSAuthenticationRedirectEnabled !== true) {
            this._twinfinitySession = bimApiOptions?.session;
        }
    }

    /** General Twinfinity information such as customer portal name, Twinfinity API version etc. */
    public info(): TwinfinityInfo {
        return this._info!;
    }

    /** Direct access to the methods for communicating the backend. */
    public get backend(): TwinfinityApiClient {
        return this._apiClient;
    }

    /**
     * Gives information on currently selected container such as title, language
     * and also details of the current user.
     */
    public get currentContainerInfo(): BimContainerInfo {
        return this._containerInfo!;
    }

    /**
     * @async
     * Sets the container of the current BimApi instance.
     * @param containerOrUrl Container object or absolute URL Example: https://bim.foo.com/sites/portal/projects/\{project name\}
     * @param options Loading options
     */
    public async setContainer(containerOrUrl: BimContainer | URL, options?: BimApiLoadOptions): Promise<void> {
        const url = 'url' in containerOrUrl ? new URL(containerOrUrl.url) : containerOrUrl;
        const containerInfo = await this.backend.getContainerInfo(url);
        if (!containerInfo.ok) {
            throw new Error(`${url.href} does not exist.`);
        }
        this._containerInfo = await containerInfo.value;
        return await super.setContainer(containerOrUrl, options);
    }

    protected async initialize(): Promise<void> {
        await super.initialize();
        const rootContainerInfoResponse = await this.backend.getContainerInfo(); // No initial url get top most container info

        if (this._twinfinitySession != null) {
            const session = this._twinfinitySession;
            Http.setAuthorizationHeaderProvider(this.wrapAuthorizationHeaderProvider(session));
            delete this._twinfinitySession;
        } else if (
            this._bimApiOptions?.isPSAuthenticationRedirectEnabled &&
            rootContainerInfoResponse.status === HttpStatusCode.Unauthorized
        ) {
            telemetry.trackTrace({
                severityLevel: SeverityLevel.Warning,
                message:
                    'Authentication by third party cookies has been deprecated. See https://help.twinfinity.com/space/HCFD/125370380/KB%3A+Changes+in+Authentication+Method for further information.'
            });

            // Redirect to projektstruktur login page and force user to login. Projekstruktur will
            // then redirect us back to the page where this code is hosted. Hence we will start executing
            // the this.initialize() method again. However this time container info will report
            // that we are logged in.
            const returnUrl = encodeURIComponent(window.parent.location.href);
            const psUrl = BimApi.getPsUrl(rootContainerInfoResponse.url);
            window.parent.location.href = `${psUrl.protocol}//${psUrl.hostname}/sites/portal/_layouts/PS_Core/Twinfinity/Login.aspx?TFUrl=${returnUrl}`;
        } else if (rootContainerInfoResponse.status === HttpStatusCode.NotFound) {
            throw new Error(`Backend API does not exist at ${rootContainerInfoResponse.url}`);
        } else if (!rootContainerInfoResponse.ok) {
            throw new Error(
                `Failed to connect to ${rootContainerInfoResponse.url}. Http status ${rootContainerInfoResponse.status}, ${rootContainerInfoResponse.statusText}`
            );
        }

        this._containerInfo = await rootContainerInfoResponse.value;
        telemetry.setAuthenticatedUserContext(this._containerInfo.user.name, telemetry.accountId);
        // Call system endpoints to get system info
        this._info = await this.backend.getInfo();
    }

    private wrapAuthorizationHeaderProvider(tokenProvider: AuthorizationHeaderProvider): AuthorizationHeaderProvider {
        return {
            getAuthorizationHeader: async () => {
                try {
                    return await tokenProvider.getAuthorizationHeader();
                } catch (error) {
                    if (error instanceof Error) {
                        telemetry.trackTrace(
                            {
                                severityLevel: SeverityLevel.Error,
                                message: `Failed to fetch authorization header: ${error.message}`,
                                properties: {
                                    error
                                }
                            },
                            { ErrorMessage: error.message }
                        );
                    } else {
                        telemetry.trackTrace({
                            severityLevel: SeverityLevel.Error,
                            message: 'Failed to fetch authorization header',
                            properties: {
                                error
                            }
                        });
                    }
                    return undefined;
                }
            }
        };
    }

    private static getPsUrl(url: string): URL {
        const psUrl = new URL(url);
        psUrl.hostname = psUrl.hostname.replace(/^bim\./i, '');
        return psUrl;
    }

    private static isTwinfinityApiClient(arg: any): arg is TwinfinityApiClient {
        return arg.getContainers !== undefined;
    }
}
