const pathSeparator = '/';
/**
 * Represents '/' separated path.
 * Contains utility methods to work with such paths.
 */
export class Path {
    private constructor(private readonly _path: string[]) {}

    /**
     * Creates a {@link Path} instance from a string or an array of path segments. A path segment
     * is the texts between '/' characters.
     *
     * @param path - The path string or array of strings.
     * @returns A {@link Path} instance if the path is valid, otherwise undefined. A path is valid if it has at least
     * one path segment.
     */
    static from(path: string | string[]): Path | undefined {
        if (typeof path === 'string') {
            const offset = path.startsWith(pathSeparator) ? 1 : 0;
            const end = path.endsWith(pathSeparator) ? path.length - 1 : path.length;
            return this.from(path.substring(offset, end).split(pathSeparator));
        } else {
            if (path.length < 2 && !path[0]) return undefined;
            if (path.some((p) => p.includes(pathSeparator)))
                throw new Error(`Path segments can't contain '${pathSeparator}'`);
            return new Path(path);
        }
    }

    /**
     * Gets the level of the path, which is the number of path segments.
     */
    get level(): number {
        return this._path.length;
    }

    /**
     * Gets the parent path, if it exists.
     *
     * @returns The parent {@link Path} instance, or undefined if the current path has no parent.
     */
    get parent(): Path | undefined {
        if (this._path.length < 2) return undefined;
        return new Path(this._path.slice(0, -1));
    }

    /**
     * Checks if the current path is equal to the specified path.
     *
     * @param other - The path to compare.
     * @param options - The options for string comparison. Default is { sensitivity: 'accent' }.
     * @returns True if the paths are equal, false otherwise.
     */
    equals(other: string | Path, options: Intl.CollatorOptions = { sensitivity: 'accent' }): boolean {
        if (!other) return false;
        if (typeof other === 'string') {
            const tmp = Path.from(other);
            if (!tmp) return false;
            other = tmp;
        }
        if (other.level !== this.level) return false;
        return other._path.every(
            (segment, index) => this._path[index].localeCompare(segment, undefined, options) === 0
        );
    }

    /**
     * Converts the path to a string representation.
     *
     * @returns The path as a string.
     */
    toString(): string {
        return this._path.join(pathSeparator);
    }
}

/**
 * Utility function to quicly create a {@link Path} instance from a string or an array of path segments.
 * @param path
 * @returns A {@link Path} instance if the path is valid, otherwise undefined. A path is valid if it has at least
 * one path segment.
 */
export function path(path: string | string[]): Path | undefined {
    return Path.from(path);
}
