/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { Vector2, Vector3 } from '@babylonjs/core/Maths/math.vector';
import { BimSvg } from './BimSvg';
import { Ray } from '@babylonjs/core/Culling/ray';
import { Vertex2, BimCoreApi, intersectsPlaneAtToRef } from '@twinfinity/core';
import { SvgEditor } from './SvgEditor';
import { TargetCamera } from '@babylonjs/core/Cameras/targetCamera';
import { ISize } from '@babylonjs/core/Maths/math.size';
import { Texture } from '@babylonjs/core/Materials/Textures/texture';
import { BimDrawingParentBase } from './BimDrawingParentBase';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';

export class BimSvgGroup extends BimDrawingParentBase {
    private readonly _svgWidth: number;
    private readonly _svgHeight: number;
    private _baseSvg: BimSvg;

    constructor(_api: BimCoreApi, private readonly _bimSvgs: BimSvg[], _textureSize = 2048, parent: TransformNode) {
        super(_api, _textureSize, parent);
        this._svgWidth = 0;
        this._svgHeight = 0;
        for (const svg of this._bimSvgs) {
            const clipPath = SvgEditor.getClipPath(svg);
            this._svgWidth = Math.max(this._svgWidth, clipPath.width);
            this._svgHeight = Math.max(this._svgHeight, clipPath.height);
        }
    }

    public get finishedDrawing(): boolean {
        return this._finishedDrawing;
    }

    public getSvgCoordinateFromRayToRef(ray: Ray, dstSvgCoordinate: Vertex2): number {
        const tmp = Vector3.Zero();
        const distance = intersectsPlaneAtToRef(ray, this.plane.mathematicalPlane, tmp);
        if (distance >= 0) {
            const fullPlaneSize = this._baseSvg.parent.plane.parent.scaling.x * 2;
            const kWidth = this._svgWidth / fullPlaneSize;
            const kHeight = this._svgHeight / fullPlaneSize;
            const k = Math.max(kWidth, kHeight);
            dstSvgCoordinate.x =
                this._svgWidth - (tmp.x - this._baseSvg.parent.plane.parent.position.x + fullPlaneSize / 2) * k;
            dstSvgCoordinate.y = (tmp.z - this._baseSvg.parent.plane.parent.position.z + fullPlaneSize / 2) * k;
        }
        return distance;
    }

    public static async create(
        bimSvgs: BimSvg[],
        api: BimCoreApi,
        textureSize = 2048,
        grayscale = false,
        invertBackground = false,
        parent: TransformNode
    ): Promise<BimSvgGroup> {
        const bimSvgGroup = new BimSvgGroup(api, bimSvgs, textureSize, parent);
        bimSvgGroup.groupSvgs(bimSvgs);
        SvgEditor.setGrayscale(bimSvgGroup._baseSvg, grayscale);
        SvgEditor.invertBackgroundColor(bimSvgGroup._baseSvg, invertBackground);

        for (const svg of bimSvgs) {
            svg.parent = bimSvgGroup;
        }

        bimSvgGroup.addOnRenderObs();
        return bimSvgGroup;
    }

    private async render(): Promise<Texture> {
        return await this._svgTex.renderSvgToRef(this._baseSvg.svgContent, this.plane.texture);
    }

    private groupSvgs(svgs: BimSvg[]): void {
        this._baseSvg = svgs[0];
        for (const svg of svgs) {
            if (svg.size.width > this._baseSvg.size.width && svg.size.height > this._baseSvg.size.height) {
                this._baseSvg = svg;
            }
        }
        SvgEditor.setViewBox(
            this._baseSvg,
            0,
            0,
            this._baseSvg.size.width,
            this._baseSvg.size.height,
            this._textureSize
        );

        for (const svg of svgs) {
            if (svg !== this._baseSvg) {
                SvgEditor.combineSvgs(this._baseSvg, svg);
            }
        }
    }

    /**
     * Zoom in or out on the SVG based off of the cameras view.
     * @param camera The camera to base the zoom off of.
     * @param canvasRect The canvas embedded is rendering to.
     */
    public async zoom(camera: TargetCamera, canvasRect: ISize): Promise<boolean> {
        this._finishedDrawing = false;
        // Notice that we use a square instead of full canvas.
        const canvasSize = Math.max(canvasRect.width, canvasRect.height);
        const minX = (canvasRect.width - canvasSize) / 2;
        const minY = (canvasRect.height - canvasSize) / 2;
        const canvasPositions = [
            { x: minX, y: minY },
            { x: canvasRect.width - minX, y: canvasRect.height - minY }
        ];

        const ray = new Ray(BimSvgGroup.vec3Zero, BimSvgGroup.vec3Up);
        // TODO Predefined points instead. We know how many there are.
        const frustumCornerPointsOnZoomPlane: Vector3[] = [];
        const svgCoordinates = [Vector2.Zero(), Vector2.Zero()];
        for (let i = 0; i < canvasPositions.length; ++i) {
            const canvasPos = canvasPositions[i];
            camera.twinfinity.createPickingRayToRef(ray, canvasPos);
            const p = Vector3.Zero();
            intersectsPlaneAtToRef(ray, this.plane.mathematicalPlane, p);
            frustumCornerPointsOnZoomPlane.push(p);

            this.getSvgCoordinateFromRayToRef(ray, svgCoordinates[i]);
        }

        for (const svg of this._bimSvgs) {
            SvgEditor.setViewBox(
                svg,
                svgCoordinates[0].x,
                svgCoordinates[0].y,
                svgCoordinates[1].x - svgCoordinates[0].x,
                svgCoordinates[1].y - svgCoordinates[0].y,
                this._textureSize
            );
        }
        await this.render();
        this.plane.rescaleToPoints(frustumCornerPointsOnZoomPlane);

        this._finishedDrawing = true;

        return true;
    }
}
