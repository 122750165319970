import { TwinfinityCameraBehavior } from '../camera/TwinfinityCameraBehavior';

import { Camera, PostProcess } from '../loader/babylonjs-import';

export const EXPENSIVE_NORMALS = 'EXPENSIVE_NORMALS';
export const DEPTH_DIMENSIONS = 'depthDimensions';
export const NEAR_FAR = 'nearFar';
export const INVERTED_PROJECTION_MATRIX = 'invertedProjectionMatrix';

export const NORMALS_FROM_DEPTH = `
    uniform vec2 ${DEPTH_DIMENSIONS};
    #ifdef ${EXPENSIVE_NORMALS}
    
    // https://wickedengine.net/2019/09/22/improved-normal-reconstruction-from-depth/
    // Constructs the normal from a 4 sample neighbor cross
    // Takes the smallest differences in depth in the vertical and horizontal directions from the fragment center and recreates the viewspace positions with the corresponding texel
    // Then these two positions are used to create two vectors from the fragment center, and these two vectors are crossed to get ther surface normal in viewspace
    vec3 expensiveViewSpaceNormalFromNeighbours(vec2 uv, vec3 viewSpaceFragmentCenter, float depthCenter) {
        vec2 right = (uv + (vec2(1,0) * ${DEPTH_DIMENSIONS}));
        vec2 up = (uv + (vec2(0,1) * ${DEPTH_DIMENSIONS}));
  
        vec2 down = (uv + (vec2(0,-1) * ${DEPTH_DIMENSIONS}));
        vec2 left = (uv + (vec2(-1,0) * ${DEPTH_DIMENSIONS}));
  
        float depthRight = texture2D(depthSampler, right).r;
        float depthUp = texture2D(depthSampler, up).r;
        float depthDown = texture2D(depthSampler, down).r;
        float depthLeft = texture2D(depthSampler, left).r;

        bool downIsLess = abs(depthDown - depthCenter) < abs(depthUp - depthCenter);
        bool leftIsLess = abs(depthLeft - depthCenter) < abs(depthRight - depthCenter);

        vec3 point1;
        vec3 point2;

        if (downIsLess && leftIsLess) {
            point1 = viewSpacePositionFromDepth(depthLeft, left.x, left.y);
            point2 = viewSpacePositionFromDepth(depthDown, down.x, down.y);
        } else if (downIsLess) { // right is less
            point1 = viewSpacePositionFromDepth(depthDown, down.x, down.y);
            point2 = viewSpacePositionFromDepth(depthRight, right.x, right.y);
        } else if (!downIsLess && leftIsLess) {
            point1 = viewSpacePositionFromDepth(depthUp, up.x, up.y);
            point2 = viewSpacePositionFromDepth(depthLeft, left.x, left.y);
        } else { // right is less, up is less
            point1 = viewSpacePositionFromDepth(depthRight, right.x, right.y);
            point2 = viewSpacePositionFromDepth(depthUp, up.x, up.y);
        }

        return -normalize(cross(normalize(point1 - viewSpaceFragmentCenter), normalize(point2 - viewSpaceFragmentCenter)));
    }
    #else
    
    // Take the fragment above and to the right of the current fragment and use their depths to reconstruct two positions, subtract these positions with 
    // the fragment centers position to get two vectors, take the cross product of these two vectors to get the viewspace surface normal
    vec3 viewSpaceNormalFromNeighbours(vec2 uv, vec3 viewSpaceFragmentCenter) {
        vec2 right = (uv + (vec2(1,0) * ${DEPTH_DIMENSIONS}));
        vec2 up = (uv + (vec2(0,1) * ${DEPTH_DIMENSIONS}));

        float depthRight = texture2D(depthSampler, right).r;
        float depthUp = texture2D(depthSampler, up).r;

        vec3 rightViewSpaceFragment = viewSpacePositionFromDepth(depthRight, right.x, right.y);
        vec3 upViewSpaceFragment = viewSpacePositionFromDepth(depthUp, up.x, up.y);
        
        return -normalize(cross(normalize(rightViewSpaceFragment - viewSpaceFragmentCenter), normalize(upViewSpaceFragment - viewSpaceFragmentCenter)));
    }
    #endif`;

export const VIEWSPACE_POSITION_FROM_LINEAR_DEPTH = `
    uniform vec2 ${NEAR_FAR};
    uniform mat4 ${INVERTED_PROJECTION_MATRIX};

    vec3 viewSpacePositionFromDepth(float depth, float u, float v)
    {
        float z = depth * ${NEAR_FAR}.y - ${NEAR_FAR}.x;
        // Get x/w and y/w from the viewport position
        float x = u * 2.0 - 1.0;
        float y = v * 2.0 - 1.0;
        vec4 vProjectedPos = vec4(x, y, z, 1.0);
        // Transform by the inverse projection matrix
        vec4 viewspacePosition = ${INVERTED_PROJECTION_MATRIX} * vProjectedPos;
        // Divide by w to get the view-space position
        return viewspacePosition.xyz / viewspacePosition.w;
    }`;

export const VIEWSPACE_POSITION_FROM_LOGARITHMIC_DEPTH = `
    uniform mat4 ${INVERTED_PROJECTION_MATRIX};

    vec3 viewSpacePositionFromDepth(float depth, float u, float v)
    {
        float z = depth;
        // Get x/w and y/w from the viewport position
        float x = u * 2.0 - 1.0;
        float y = v * 2.0 - 1.0;
        vec4 vProjectedPos = vec4(x, y, z, 1.0);
        // Transform by the inverse projection matrix
        vec4 viewspacePosition = ${INVERTED_PROJECTION_MATRIX} * vProjectedPos;
        // Divide by w to get the view-space position
        return viewspacePosition.xyz / viewspacePosition.w;
    }`;

/**
 * TwinfinityPostProcess is the base class that can be used to extend classes for post-process effects.
 */
export abstract class TwinfinityPostProcess implements TwinfinityCameraBehavior {
    protected _postProcess: PostProcess | undefined = undefined;
    private _affectedCameras: Set<Camera> = new Set<Camera>();

    /**
     * Returns a set of all the cameras this post-process effect is attached to.
     */
    get affectedCameras(): Set<Camera> {
        return this._affectedCameras;
    }

    /**
     * Method for initializing this post-process effect before it gets attached to a camera.
     * This gets called automatically from attach.
     * @param engine BabylonJS engine variable.
     */
    protected abstract initialize(camera: Camera): PostProcess;

    /**
     * Runs the initialize method if the post-process has not yet been initialized.
     * Then attaches the post-process effect to the specified camera.
     * @param camera The camera to attach the post-process effect to.
     * @returns true if the post-process effect was successfully attached to the camera, otherwise false.
     */
    attach(camera: Camera): boolean {
        this._postProcess = this.initialize(camera);
        if (!this.affectedCameras.has(camera)) {
            camera.attachPostProcess(this._postProcess);
            this.affectedCameras.add(camera);
            return true;
        }
        return false;
    }

    /**
     * Disables the post-process effect for the specified camera.
     * @param camera The camera to detach the post-process effect from.
     * @returns true if the post-process effect was successfully detached from the camera, otherwise false.
     */
    detach(camera: Camera): boolean {
        if (this._affectedCameras.has(camera)) {
            camera.detachPostProcess(this._postProcess!);
            this._affectedCameras.delete(camera);
            return true;
        }
        return false;
    }
}
