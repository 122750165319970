import { Logger, ThinEngine, Observable } from './loader/babylonjs-import';

import {
    IEventTelemetry,
    IExceptionTelemetry,
    ITraceTelemetry,
    IMetricTelemetry
} from '@microsoft/applicationinsights-common';
import { ICustomProperties } from '@microsoft/applicationinsights-core-js';
import { IApplicationInsights } from '@microsoft/applicationinsights-web';

import { buildInfo } from './BuildInfo';
import { StopWatch } from './loader/stopwatch';

/**
 * References to the Application Insights module only uses interfaces from it. There are therefore
 * no traces of application insights code in a compiled app using the Twinfinity client API (unless the app itself uses application insights)
 * Application Insights is set as a peer dependency to indicate that it is a good idea to use it
 * but this is not a requirement
 *
 * If users of Twinfinity client api use Application Insights then logs from embedded will
 * be forwarded to application insights (can be one of our instances or a customer instance). By default,
 * all logs end up in the console. If Application Insights is already registered in the page
 * then logs will be forwarded to Application Insights.
 */

/**
 * Interface that describes the methods used by Twinfinity client API to log telemetry. Basically a subset of Microsoft Application Insights.
 * Application Insights can be used in an application that includes the Twinfinity client API and then uses the Twinfinity client API to forward its internal logs to
 * Application Insights. See Application Insights documentation for description of each of the methods.
 */
export type TwinfinityTelemetryClient = Pick<
    IApplicationInsights,
    'trackEvent' | 'trackException' | 'trackTrace' | 'trackMetric' | 'setAuthenticatedUserContext'
>;

/**
 * Represents a telemetry event that can be use to track events.
 */
export class TelemetryEvent {
    private readonly _sw = new StopWatch();

    /**
     * Creates a new instance of TelemetryEvent.
     * @param name The name of the telemetry event.
     * @param _client The telemetry client used to track the event.
     */
    public constructor(public readonly name: string, private readonly _client: TwinfinityTelemetryClient) {
        this._sw.resetAndStart();
    }

    /**
     * Stops the telemetry event and tracks it with the specified custom properties.
     * Also adds a duration in ms to the logged event properties.
     * @param customProperties Optional custom properties to be associated with the event.
     */
    public stop(customProperties?: ICustomProperties): void {
        this._sw.stop();
        const durationMs = this._sw.totalElapsed;
        this._client.trackEvent({ name: this.name }, { durationMs, ...customProperties });
    }
}
/**
 * Logs telemetry to the console.
 */
class ConsoleTelemetryClient implements TwinfinityTelemetryClient {
    public setAuthenticatedUserContext(
        authenticatedUserId: string,
        accountId?: string | undefined,
        storeInCookie?: boolean | undefined
    ): void {
        this.consoleWrite(1, 'setAuthenticatedUserContext:', { userId: authenticatedUserId, customer: accountId });
    }
    public trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties): void {
        const { name, ...rest } = event;
        this.consoleWrite(1, 'event:', name, ...this.filterLoggable(rest, customProperties));
    }

    public trackException(exception: IExceptionTelemetry, customProperties?: ICustomProperties): void {
        this.consoleWrite(
            exception.severityLevel ?? 0,
            'exception:',
            exception,
            ...this.filterLoggable(customProperties)
        );
    }

    public trackTrace(trace: ITraceTelemetry, customProperties?: ICustomProperties): void {
        const { message, ...rest } = trace;
        this.consoleWrite(trace.severityLevel ?? 0, message, ...this.filterLoggable(rest, customProperties));
    }

    public trackMetric(metric: IMetricTelemetry, customProperties?: ICustomProperties): void {
        const { name, ...rest } = metric;
        this.consoleWrite(1, 'metric:', name, ...this.filterLoggable(rest, customProperties));
    }

    private consoleWrite(severityLevel: number, ...data: any[]): void {
        switch (severityLevel) {
            case 0: {
                // Verbose = 0
                console.log(...data);
                break;
            }
            case 1: {
                // Information = 1
                console.info(...data);
                break;
            }
            case 2: {
                // Warn = 2
                console.warn(...data);
                break;
            }
            case 4: // Critical = 4, Error = 3
            case 3: {
                console.error(...data);
                break;
            }
        }
    }

    private filterLoggable(...data: any[]): any[] {
        return data.filter((c) => this.isLoggable(c));
    }

    private isLoggable(value: any): boolean {
        const notLoggable =
            value === undefined || value === null || (Object.keys(value).length === 0 && value.constructor === Object);
        return !notLoggable;
    }
}

type BabylonJSRawLogsObservable = Observable<Pick<ITraceTelemetry, 'message' | 'severityLevel'>>;
const consoleTelemetryClientName = 'console';
const applicationInsightsTelemetryClientName = 'appInsights';

/**
 * Configuration used when registering {@link TwinfinityTelemetryClient} instances
 * in {@link CompoundTelemetryClient}. Normally done by calling {@link telemetry}.set().
 */
export interface TwinfinityTelemetryClientConfig {
    /**
     * Name of {@link client}. Name should be unique.
     */
    name: string;

    /** {@link TwinfinityTelemetryClient} instance.*/
    client: TwinfinityTelemetryClient;

    /**
     * BabylonJS logs are normally sent to {@link client}. Setting this to `true`
     * ensures that they aren't.
     */
    disableBabylonJSLoggerForwarding?: boolean;
}

/**
 * Used to combine a set of {@link TwinfinityTelemetryClient} instances and
 * have calls to the trackXYZ methods, on this instance, forwarded to them.
 */
export class CompoundTelemetryClient implements TwinfinityTelemetryClient {
    private readonly _clients = new Map<string, TwinfinityTelemetryClientConfig>();
    private static readonly _babylonJSRawLogs = CompoundTelemetryClient.createRawBabylonJSLogsObservable();
    private static readonly _anonymousUserId = 'anonymous-63137';
    private static readonly _noAccountId = 'not-available';
    private _userId = CompoundTelemetryClient._anonymousUserId;
    private _accountId = CompoundTelemetryClient._noAccountId;

    /** Optional Role name that application insights will report. A good idea is to use application name for this. */
    public role?: string;

    /** AccountId that application insights will report. In twinfinity its a good idea to set it to the
     * customer hostname.
     */
    public get accountId(): string {
        return this._accountId;
    }

    /** User id that application insights will report. In twinfinity its a good idea to set this to the
     * logged in user.
     */
    public get userId(): string {
        return this._userId;
    }

    /**
     * Constructor. While possible to use one should probably use {@link createDefault} instead.
     */
    public constructor() {
        // Forward all (raw) babylonjs logs to all TelemetryClients that are not explicitly
        // configured to not get them (typically ConsoleTelemetryClient does not want them
        // since babylonjs logger already writes to console. No reason to output it to console
        // twice)
        CompoundTelemetryClient._babylonJSRawLogs.add((ed) => {
            for (const { client, disableBabylonJSLoggerForwarding } of this._clients.values()) {
                if (!disableBabylonJSLoggerForwarding) {
                    // TODO Add babylonjs property so we can see that log came from babylonjs
                    client.trackTrace(ed);
                }
            }
        });
    }

    /**
     * Set the authenticated user id and the account id. Used for identifying a specific signed-in user. Parameters must not contain whitespace or ,;=|
     *
     * The method will only set the `authenticatedUserId` and `accountId` in the current page view. To set them for the whole session, you should set `storeInCookie = true`
     * @param {string} authenticatedUserId
     * @param {string} [accountId]
     * @param {boolean} [storeInCookie=false]
     */
    public setAuthenticatedUserContext(
        authenticatedUserId: string,
        accountId?: string | undefined,
        storeInCookie?: boolean | undefined
    ): void {
        const clean = (str: string): string => {
            return str.replace(/[\s,;=|\\]+/gm, '_').toLowerCase();
        };
        this._userId = authenticatedUserId ?? CompoundTelemetryClient._anonymousUserId;
        this._accountId = accountId ?? CompoundTelemetryClient._noAccountId;
        for (const { client } of this._clients.values()) {
            client.setAuthenticatedUserContext(clean(this._userId), clean(this.accountId), storeInCookie);
        }
    }

    /**
     * Creates the default {@link CompoundTelemetryClient} instance.
     * It will log to console by default and if it detects a application insights
     * instance in the page it will use that as well.
     */
    public static createDefault(): CompoundTelemetryClient {
        const telemetry = new CompoundTelemetryClient();

        // Always log to console by default.
        telemetry.isConsoleLoggingEnabled = true;

        if (applicationInsightsTelemetryClientName in window) {
            // If application insights is detected in the page then use it for logging by default too,.
            const appInsightsClient = (window as any).appInsights as TwinfinityTelemetryClient;
            telemetry.set({ name: applicationInsightsTelemetryClientName, client: appInsightsClient });
        }
        return telemetry;
    }

    /**
     * `true` if console logging is enabled, otherwise `false`.
     */
    public get isConsoleLoggingEnabled(): boolean {
        return this._clients.has(consoleTelemetryClientName);
    }

    /**
     * Set to `true` to enable console logging. `false` to disable.
     */
    public set isConsoleLoggingEnabled(v: boolean) {
        if (v) {
            this.set({
                name: consoleTelemetryClientName,
                client: new ConsoleTelemetryClient(),
                disableBabylonJSLoggerForwarding: true
            });
        } else {
            this._clients.delete(consoleTelemetryClientName);
        }
    }

    /**
     * Iterator for all registered {@link TwinfinityTelemetryClientConfig}.
     * See {@link set} and {@link delete} as well.
     * @returns Iterator for all registered {@link TwinfinityTelemetryClientConfig}.
     */
    public entries(): IterableIterator<TwinfinityTelemetryClientConfig> {
        return this._clients.values();
    }

    /**
     * Register a {@link TwinfinityTelemetryClientConfig} instance. Future calls to trackXYZ methods
     * will be forwarded to the client property of this instance.
     * @param o a {@link TwinfinityTelemetryClientConfig}.
     */
    public set(o: TwinfinityTelemetryClientConfig): void {
        this._clients.set(o.name, o);

        if (this.isApplicationInsightsClient(o.client)) {
            // If we add a application insights client then add extra
            // data to each telemetry item that is sent.
            o.client.addTelemetryInitializer((i) => {
                if (!i.data) i.data = {};
                if (!i.data.twinfinity) i.data.twinfinity = {};
                i.data.twinfinity.buildInfo = buildInfo;
                if (!i.data.babylonjs) i.data.babylonjs = {};
                i.data.babylonjs.version = ThinEngine.Version;
                if (this.role) i.tags!['ai.cloud.role'] = this.role;
            });
        }
    }

    /**
     * Unregister a {@link TwinfinityTelemetryClientConfig}. Calls to the trackXYZ methods will no longer be forwarded to that
     * instance.
     * @param name Name of {@link TwinfinityTelemetryClientConfig} to delete.
     * @returns `true` if {@link TwinfinityTelemetryClientConfig} existed (and hence was deleted), otherwise `false`
     */
    public delete(name: string): boolean {
        return this._clients.delete(name);
    }

    /** Track an event. See Application insights documentation for more information */
    public trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties): void {
        for (const { client } of this._clients.values()) client.trackEvent(event, customProperties);
    }

    /**
     * Starts tracking an event with the given name.
     * @param name - The name of the event to track.
     */
    public startTrackEvent(name: string): TelemetryEvent {
        return new TelemetryEvent(name, this);
    }

    /** Track an exception. See Application insights documentation for more information */
    public trackException(exception: IExceptionTelemetry, customProperties?: ICustomProperties): void {
        for (const { client } of this._clients.values()) client.trackException(exception, customProperties);
    }

    /** Track a trace. See Application insights documentation for more information */
    public trackTrace(trace: ITraceTelemetry, customProperties?: ICustomProperties): void {
        for (const { client } of this._clients.values()) client.trackTrace(trace, customProperties);
    }

    /** Track a metric. See Application insights documentation for more information */
    public trackMetric(metric: IMetricTelemetry, customProperties?: ICustomProperties): void {
        for (const { client } of this._clients.values()) client.trackMetric(metric, customProperties);
    }

    private static createRawBabylonJSLogsObservable(): BabylonJSRawLogsObservable {
        const bjsLogger = Logger as any;

        const observable = new Observable<Pick<ITraceTelemetry, 'message' | 'severityLevel'>>();

        const LOG_LEVELS = 'LogLevels';

        const propertyDescriptor = Object.getOwnPropertyDescriptor(bjsLogger, LOG_LEVELS)!;
        const bjsLogLevelsSetter = propertyDescriptor.set!.bind(bjsLogger);

        const LOG = 'Log';
        const WARN = 'Warn';
        const ERROR = 'Error';

        type loggerType = {
            severityLevel: number;
            logFunction: (message: string, limit?: number) => void;
        };

        const ogLoggers = new Map<string, loggerType>();
        ogLoggers.set(LOG, {
            severityLevel: 0,
            logFunction: Logger.Log
        });

        ogLoggers.set(WARN, {
            severityLevel: 2,
            logFunction: Logger.Warn
        });

        ogLoggers.set(ERROR, {
            severityLevel: 3,
            logFunction: Logger.Error
        });

        const reAssignLogFunctions = (): void => {
            const logFunctionNames = [LOG, WARN, ERROR];

            logFunctionNames.forEach((logFunctionName) => {
                bjsLogger[logFunctionName] = (message: string, limit?: number) => {
                    const ogLogger = ogLoggers.get(logFunctionName);

                    if (ogLogger) {
                        ogLogger.logFunction(message, limit);
                        observable.notifyObservers({ message, severityLevel: ogLogger.severityLevel });
                    }
                };
            });
        };

        reAssignLogFunctions();

        if (bjsLogLevelsSetter) {
            Object.defineProperty(Logger, LOG_LEVELS, {
                set: function (level: number) {
                    bjsLogLevelsSetter(level);
                    reAssignLogFunctions();
                }
            });
        } else {
            throw new Error('No setter for the log levels?');
        }
        // Ensures that the above hooks are applied. (Otherwise Logger.Log, etc will refer to old
        // Logger._LogEnabled etc functions, Ie not the ones we just created above.).
        Logger.LogLevels = Logger.AllLogLevel;
        return observable;
    }

    private isApplicationInsightsClient(client: TwinfinityTelemetryClient): client is IApplicationInsights {
        if ('addTelemetryInitializer' in client) {
            return true;
        }
        return false;
    }
}

/**
 * Use for telemetry logging. Use this instead of console.log and similiar. It is used internally by
 * the Twinfinity Client API.
 * By default logs are output to console. If application insights is detected in the page,
 * when `telemetry` is first accessed, then it will also automatically log to application insights.
 *
 * Simplest way to forward logs to application insights is to use the "Snippet based setup"
 * from https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript.
 * Ensure that the snippet comes before all other scripts in the page.
 *
 * @example
 * ```typescript
 * // Log a warning.
 * telemetry.traceTrace({message: "log message", severityLevel: 2});
 * ```
 * @example
 * ```typescript
 * // Explicitly register existing application insights instance. See "npm based setup" at
 * // https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript for how to get the instance.
 * telemetry.set('myApplicationInsights', applicationInsightsInstance);
 * telemetry.traceTrace({message: "log message" }); // Log a debug log. Same output as console.log
 * ```
 * @example
 * ```typescript
 * // Explicitly register custom telemetryclient
 * class CustomClient implements TwinfinityTelemetryClient { ... }
 * telemetry.set('customclient', new CustomClient());
 * telemetry.isConsoleLoggingEnabled = false; // Disable console logging
 * // Log a debug log. Will be forwarded to the custom client. If
 * // application insights was registered in the page then the log will be sent
 * // there too.
 * telemetry.traceTrace({message: "log message" });
 * ```
 */
export const telemetry = CompoundTelemetryClient.createDefault();
