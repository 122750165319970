import { Camera, Effect, PostProcess } from '../loader/babylonjs-import';
import { TwinfinityPostProcess } from './TwinfinityPostProcess';

const SHADER_NAME_PREFIX = 'ditherBlurApplier';
const SCREEN_SAMPLER = 'sceneSampler';
const BLURRED_DITHER_SAMPLER = 'blurredDitherSampler';

/**
 * DitherBlurApplier is the class that blends the blurred dither with the screen contexnt
 * The variable called blittedscreen is a postprocess that just writes the current scene to a texture buffer
 */
export class DitherBlurApplier extends TwinfinityPostProcess {
    private static readonly _fragmentShaderSource = `#ifdef GL_ES
        precision highp float;
    #endif
    varying vec2 vUV;

    // Samplers
    uniform sampler2D ${SCREEN_SAMPLER};
    uniform sampler2D ${BLURRED_DITHER_SAMPLER};
    
    void main(void)
    {
        vec2 sampleUV = vUV;

        vec4 screenFragment = texture2D(${SCREEN_SAMPLER}, sampleUV);
        vec4 blurredDithering = texture2D(${BLURRED_DITHER_SAMPLER}, sampleUV);
        float blurMix = 1.0 - step((1.0 - blurredDithering.w), 0.001);
 
        gl_FragColor = vec4(mix(screenFragment.xyz, blurredDithering.xyz, blurMix), 1.0);
        
    }`;

    constructor(private readonly _blitedScreen: PostProcess) {
        super();
    }

    protected initialize(camera: Camera): PostProcess {
        const engine = camera.getEngine();
        const defines = new Array<string>();

        if (this._postProcess === undefined) {
            Effect.ShadersStore[SHADER_NAME_PREFIX + 'FragmentShader'] = DitherBlurApplier._fragmentShaderSource;

            const uniforms: string[] = [];

            const newPostProcess = new PostProcess(
                'Dither_blur_applier',
                SHADER_NAME_PREFIX,
                uniforms,
                [SCREEN_SAMPLER, BLURRED_DITHER_SAMPLER],
                1.0,
                null,
                undefined,
                engine,
                false,
                defines.join('\n')
            );

            const viewer = camera.getScene().twinfinity.viewer;
            if (viewer) {
                newPostProcess.onApplyObservable.add((effect) => {
                    effect.setTextureFromPostProcess(SCREEN_SAMPLER, this._blitedScreen);
                    effect.setTextureFromPostProcess(BLURRED_DITHER_SAMPLER, newPostProcess); // Babylon is trippy in the way that this call will set the texture to the current screen as it was BEFORE newPostProcess is applied
                });
            } else {
                throw new Error('No viewer created?');
            }

            return newPostProcess;
        } else return this._postProcess;
    }
}
