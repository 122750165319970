/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { BimIfcPropertiesResponse, Mutable, BimPropertySetScalarValueType, BimPropertyUnit } from './bim-format-types';
import { lookupGetOrAdd } from './lookup';
import { BimPropertySet, units } from './bim-property-sets';
import '../MapExtensions';

type DecodedPropertySet = {
    name: string;
    pset: BimPropertySet;
    isQuantifier: boolean;
};

export class BimPropertySetReader {
    readonly _propertySetByHash = new Map<string, BimPropertySet>();
    readonly _isPropertySetUrlAdded = new Map<string, Promise<boolean>>();

    public decodeProperties(bimIfcPropertiesResponse: BimIfcPropertiesResponse): DecodedPropertySet[] {
        const bimPropertySets: DecodedPropertySet[] = [];
        let newOrExistingPropertySet: Mutable<BimPropertySet> | undefined;
        let psetName: string;
        let isQuantifier = false;
        for (const pset of bimIfcPropertiesResponse.propertySets) {
            // Add a property set to the global lookup table if it does not already exist.
            // This way we reuse existing data even between ifc files (saves memory)
            newOrExistingPropertySet = this._propertySetByHash.get(pset.h);
            psetName = bimIfcPropertiesResponse.propertySetNames[pset.s];
            isQuantifier = psetName.startsWith('q.');
            psetName = psetName.slice(2); // first two chars are either p. or q. (property or quantifier)
            if (newOrExistingPropertySet !== undefined) {
                bimPropertySets.push({ name: psetName, pset: newOrExistingPropertySet, isQuantifier });
                continue;
            }

            newOrExistingPropertySet = {
                hash: pset.h,
                isReadonly: true,
                [units]: {}
            };

            const pLen = pset.n.length;
            for (let i = 0; i < pLen; ++i) {
                const pNameIdx = pset.n[i];
                const pValIdx = pset.v[i];
                const pUnitIdx = pset.u == null ? -1 : pset.u[i];
                const pName = bimIfcPropertiesResponse.propertyNames[pNameIdx];
                const pVal = bimIfcPropertiesResponse.propertyValues[pValIdx];
                const pUnit =
                    bimIfcPropertiesResponse.propertyUnits == null
                        ? undefined
                        : bimIfcPropertiesResponse.propertyUnits[pUnitIdx];
                // some property names actually have multiple values.
                // therefore we always use an array
                const pVals = lookupGetOrAdd(
                    newOrExistingPropertySet,
                    pName,
                    (_pName) => []
                ) as BimPropertySetScalarValueType[];
                // Will always be true. Just needed for type guard
                pVals.push(pVal);
                const pUnits = lookupGetOrAdd(newOrExistingPropertySet[units], pName, (_pName) => []) as Array<
                    BimPropertyUnit | undefined
                >;
                pUnits.push(pUnit);
            }

            const isSingletonArray = (x: unknown): x is Array<unknown> => x instanceof Array && x.length === 1;

            // Properties and units that are not multivalued should be represented as scalars. Ie
            // remove array wrapper.
            for (const [prop, val] of Object.entries(newOrExistingPropertySet)) {
                if (prop === 'hash' || prop === 'propertySetType') {
                    continue;
                }
                if (isSingletonArray(val)) {
                    newOrExistingPropertySet[prop] = val[0];
                }
                const unit = newOrExistingPropertySet[units][prop];
                if (isSingletonArray(unit)) {
                    newOrExistingPropertySet[units][prop] = unit[0];
                }
            }

            // unpacked property sets get same order in bimPropertySets array as in
            // bimIfcPropertiesData.propertySets
            bimPropertySets.push({ name: psetName, pset: newOrExistingPropertySet, isQuantifier });
        }
        return bimPropertySets;
    }
}
