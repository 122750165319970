import { setMax, setMin } from '../math';
import { FastTransform } from '../math/FastTransform';
import { Writeable } from '../Types';
import { BoundingInfo, DeepImmutable, Matrix, Vector3 } from './babylonjs-import';
import { BimChangeIfc } from './bim-api-client';
import { BimProductMeshDescriptor, BimVertexData } from './bim-format-types';
import { BimProductMesh } from './BimProductMesh';
import { BimIfcClass } from './bim-ifc-class';
import { IBimIfcLoaderElement } from './bim-ifc-loader-element';
import { BimIfcMesh } from './bim-ifc-mesh';
import { BimIfcObject } from './bim-ifc-object';
import { BimIfcStyle } from './bim-ifc-style';
import { IfcMaterialHighlightIndex, IfcMaterialRenderProperty } from './CustomBabylonMaterials/IfcMaterial';
import { Materials } from './materials';
import { RgbaComponent } from './rgba';

const aabbSideLen = 15;
const aabbTypes = {
    large: { k: 1 / aabbSideLen, suffix: 'l' },
    small: { k: 1 / aabbSideLen, suffix: 's', maxLength: 0.3 }
};

const opaqueOrTransparentId = ['t', 'o'];
const aabbCenter = Vector3.Zero();
const aabbMin = setMax(Vector3.Zero());
const aabbMax = setMin(Vector3.Zero());

/**
 * Represents the mesh of a IFC product. A IFC product may
 * consist of `0..N` meshes. A door often represented as two meshes. A
 * rectangle and a door handle.
 */
export class IfcProductMesh implements BimProductMesh {
    private static readonly _tmp = {
        vectorA: Vector3.Zero(),
        vectorB: Vector3.Zero(),
        vectorC: Vector3.Zero()
    };
    readonly colorTexturePixelIndex: number = -1;

    /** Determine which babylonjs mesh this ifc product mesh shall belong to. */
    public mergeId = '';

    /** @inheritDoc */
    public cullingDistance = -1;

    /** Center of the cube, this mesh will be a part of, in IFC space (not worldspace) */
    public centerOfParentIfcCube?: DeepImmutable<Vector3>;

    constructor(
        public readonly ifcProduct: BimIfcObject,
        public readonly mi: number,
        public readonly r: number,
        public readonly si: number,
        public readonly wt: number
    ) {}

    public static from(o: BimIfcObject, dto: BimProductMesh): BimProductMesh {
        // TODO: Fix better override of space colors. Currently we know that last element of
        // o.ifcLoaderElement.styles is a specially created style that should be used for all
        // IFC spaces. Only reason is that requirments stated that they should be green which they
        // are not always in the IFC files.
        const si = o.class === BimIfcClass.ifcSpace ? o.ifcLoaderElement.styles.length - 1 : dto.si;
        return new IfcProductMesh(o, dto.mi ?? 0, dto.r ?? 0, si ?? 0, dto.wt ?? 0);
    }

    public get isOnGpu(): boolean {
        // Empty string mergeid means that mesh is not on gpu
        return !!this.mergeId;
    }

    public get visible(): boolean {
        return this.materials.renderPropertiesFor(this).has(IfcMaterialRenderProperty.visibility);
    }

    public set visible(visible: boolean) {
        this.materials.renderPropertiesFor(this).set(IfcMaterialRenderProperty.visibility, visible);
    }

    public get highlight(): IfcMaterialHighlightIndex {
        return this.materials.renderPropertiesFor(this).getNumber(26, 2);
    }

    public set highlight(value: IfcMaterialHighlightIndex) {
        this.materials.renderPropertiesFor(this).setNumber(26, 2, value);
    }

    public get depthWriteIgnoresVisibility(): boolean {
        return this.materials.renderPropertiesFor(this).has(IfcMaterialRenderProperty.depthWriteIgnoresVisibility);
    }

    public set depthWriteIgnoresVisibility(depthWriteIgnoresVisibility: boolean) {
        this.materials
            .renderPropertiesFor(this)
            .set(IfcMaterialRenderProperty.depthWriteIgnoresVisibility, depthWriteIgnoresVisibility);
    }

    public get ghostOutline(): boolean {
        return this.depthWriteIgnoresVisibility && !this.visible;
    }

    public set ghostOutline(enabled: boolean) {
        this.visible = !enabled;
        this.depthWriteIgnoresVisibility = enabled;
    }

    public get descriptor(): BimProductMeshDescriptor {
        return this.loaderElement.index.meshDescriptors[this.mi]!;
    }

    public get style(): BimIfcStyle {
        return this.loaderElement.styles[this.si];
    }

    public get ifc(): BimChangeIfc {
        return this.loaderElement.ifc;
    }

    public get loaderElement(): IBimIfcLoaderElement {
        return this.ifcProduct.ifcLoaderElement;
    }

    public get vertexData(): BimVertexData {
        return this.loaderElement.getVertexData(this.descriptor);
    }

    public aabb(min: Vector3, max: Vector3, clearTransformCache = true): void {
        const tmpTransform = this.loaderElement.transformsRepository.getTransform(this);
        const tmpBbox = this.descriptor.bbox;
        const tmpVector = IfcProductMesh._tmp.vectorA;
        tmpTransform.transformInPlaceVector3(tmpBbox[0], tmpBbox[1], tmpBbox[2], tmpVector);
        min.minimizeInPlace(tmpVector);
        max.maximizeInPlace(tmpVector);
        tmpTransform.transformInPlaceVector3(tmpBbox[3], tmpBbox[4], tmpBbox[5], tmpVector);
        min.minimizeInPlace(tmpVector);
        max.maximizeInPlace(tmpVector);

        if (clearTransformCache) {
            this.loaderElement.transformsRepository.clear;
        }
    }

    public _clearMergeId(): boolean {
        if (!this.mergeId) {
            return false;
        }
        this.mergeId = '';
        this.cullingDistance = -1;
        return true;
    }

    public _buildMergeId(buildId: number, uniqueAabbCenterCache: Map<string, Vector3>): boolean {
        // Divides world in into aabb's with a set side length. Basically we subdivide ifc space into
        // aabbs (cubes) with equal length. We then look at each ifc mesh and assign it to one of the aabbs.
        // all meshes which end up in the aabb cube will be merged for performance reasons. We actually
        // have two types of aabbs. One for large objects and one for small objects. Aabbs containing
        // small objects are distance culled. Ie they are not visible if the camera is further away than
        // N units. This can give a large performance boost (fps) since small objects can be very complex.
        // NOTE cube sizes Should probably not be hardcoded if possible.
        // NOTE: Empirical studies show that we get good performance with around 2000 meshes.
        // We should attempt to mesh so we get a maxmimum of that. If we look at the total number of
        // triangles we would get a inidication of how many triangles we should attempt to put in each mesh.
        if (this.mergeId) {
            return false;
        }

        const p = this.ifcProduct;
        const mD = this.descriptor;
        const transform = p.ifcLoaderElement.transformsRepository.getTransform(this);
        setMax(aabbMin);
        setMin(aabbMax);
        transform.transformInPlaceVector3(mD.bbox[0], mD.bbox[1], mD.bbox[2], aabbCenter);
        aabbMin.minimizeInPlace(aabbCenter);
        aabbMax.maximizeInPlace(aabbCenter);
        transform.transformInPlaceVector3(mD.bbox[3], mD.bbox[4], mD.bbox[5], aabbCenter);
        aabbMin.minimizeInPlace(aabbCenter);
        aabbMax.maximizeInPlace(aabbCenter);

        // calculate length of mesh aabb sides. Result is stored in aabbCenter
        aabbMax.subtractToRef(aabbMin, aabbCenter);
        const aabbType =
            aabbCenter.x > aabbTypes.small.maxLength ||
            aabbCenter.y > aabbTypes.small.maxLength ||
            aabbCenter.z > aabbTypes.small.maxLength
                ? aabbTypes.large
                : aabbTypes.small;

        // Calculate real center of ifc space aabb.
        aabbCenter.x = Math.floor((aabbMin.x + aabbCenter.x / 2.0) * aabbType.k);
        aabbCenter.y = Math.floor((aabbMin.y + aabbCenter.y / 2.0) * aabbType.k);
        aabbCenter.z = Math.floor((aabbMin.z + aabbCenter.z / 2.0) * aabbType.k);

        // Now we can assign the meshdescriptor the center (in ifc space)
        // of the aabb it will be placed in in the world (not same as its own aabb).
        // many meshdescriptors will share the same center (since they are placed in the same ifc space aabb)
        // const aabbKey = `${aabbCenter.x},${aabbCenter.y},${aabbCenter.z},${this.ifc.id},${this.ifc.version}`;
        const aabbKey = `${buildId}-${aabbCenter.x},${aabbCenter.y},${aabbCenter.z}`;
        this.centerOfParentIfcCube = uniqueAabbCenterCache.getOrAdd(
            aabbKey,
            (_key) => new Vector3(aabbCenter.x * aabbSideLen, aabbCenter.y * aabbSideLen, aabbCenter.z * aabbSideLen)
        );
        this.mergeId = `${opaqueOrTransparentId[Math.floor(p.style(this).a / 255)]}-${aabbKey}-${aabbType.suffix}`;
        if (aabbType.suffix === 's') {
            this.cullingDistance = 15;
        }
        return true;
    }

    public boundingInfo(bI?: BoundingInfo, clearTransformCache = true): BoundingInfo {
        const tmpMin = IfcProductMesh._tmp.vectorB;
        const tmpMax = IfcProductMesh._tmp.vectorC;
        if (bI === undefined) {
            this.aabb(setMax(tmpMin), setMin(tmpMax), clearTransformCache);
            return new BoundingInfo(tmpMin, tmpMax);
        }

        this.aabb(tmpMin.copyFrom(bI.minimum), tmpMax.copyFrom(bI.maximum), clearTransformCache);
        bI.reConstruct(tmpMin, tmpMax);
        return bI;
    }

    public defaultColor(): boolean {
        return this.setColor(this.style.color);
    }

    public transform(clearTransformCache = true): Matrix {
        const ret = this.loaderElement.transformsRepository.getMatrix(this);
        if (clearTransformCache) {
            this.loaderElement.transformsRepository.clear();
        }
        return ret;
    }

    public fastTransform(clearTransformCache = true): FastTransform {
        const ret = this.loaderElement.transformsRepository.getTransform(this);
        if (clearTransformCache) {
            this.loaderElement.transformsRepository.clear();
        }
        return ret;
    }

    public mesh(clearTransformCache = true): BimIfcMesh {
        return new BimIfcMesh(
            this.vertexData,
            this.transform(clearTransformCache),
            1.0 / this.loaderElement.index.modelFactors.oneMeter
        );
    }

    public copyColorTo(dst: Writeable<ArrayLike<number>>, dstOffset = 0): void {
        return this.materials.copyColorTo(this, dst, dstOffset);
    }

    public setColor(color: ArrayLike<number>): boolean {
        return this.materials.setColor(this, color);
    }

    public getColorComponent(colorComponent: RgbaComponent): number {
        return this.materials.getColorComponent(this, colorComponent);
    }

    private get materials(): Materials {
        return this.loaderElement.materials;
    }
}
