import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import { Plane } from '@babylonjs/core/Maths/math.plane';
import {
    BimApi,
    BimCoreApi,
    PickOptionType,
    PredefinedCanvasPosition,
    DynamicPolygon,
    TrackCoordinate2D,
    TrackCoordinate2DState,
    Vertex3,
    PlaneUtil,
    convertToCanvasCoordinatesToRef,
    DynamicPolygonPointMoveResult,
    Vertex2,
    DynamicPolygonPointDeleteResult,
    ArbitraryShapePoint
} from '@twinfinity/core';
import { Scene } from '@babylonjs/core/scene';
import { Scalar } from '@babylonjs/core/Maths/math.scalar';
import { Observable } from '@babylonjs/core/Misc/observable';

function asCssText(position: Vertex2): string {
    return `transform: translate(${position.x}px, ${position.y}px);`;
}

const areaParentElement = document.createElement('div');
areaParentElement.id = 'areaPolygon';
document.body.appendChild(areaParentElement);

/**
 * Represents the area text of a area polygon.
 */
export class AreaPolygonAreaLabel {
    private _label?: HTMLElement;
    private _labelCssText = '';

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public constructor() {}

    public updateHTMLElement(o: TrackCoordinate2D<Vector3>, area: number, volume: number): void {
        const state = o.state;
        if (state === TrackCoordinate2DState.Added) {
            this._label = this._label ?? document.createElement('div');
            this._label.classList.add('areaPolygonArea');
            this._label = areaParentElement.appendChild(this._label);
            this.updateLabel(o, area, volume);
        } else if (state === TrackCoordinate2DState.Deleted) {
            this._label?.remove();
            this._labelCssText = '';
            this._label = undefined;
        } else if (state === TrackCoordinate2DState.Updated) {
            this.updateLabel(o, area, volume);
        }
    }

    private updateLabel(o: TrackCoordinate2D<Vector3>, area: number, volume: number): void {
        // Always reposition label so it is at center of point.
        if (!this._label) {
            return;
        }

        const pos = {
            x: Math.floor(o.position.x),
            y: Math.floor(o.position.y)
        };

        const cssText = asCssText(pos);
        if (cssText !== this._labelCssText) {
            this._label.style.cssText = cssText;
            this._labelCssText = cssText;
        }
        let vol = ``;
        if (volume !== 0) {
            vol = `| Volume: ${volume.toFixed(2)} m^3 `;
        }

        this._label!.textContent = `Area: ${area.toFixed(2)} m^2 ${vol}`;
    }

    public setTextContent(area: number, vol: number): void {
        if (!this._label) {
            return;
        }
        this._label.textContent = `Area: ${area.toFixed(2)} m^2 ${vol}`;
    }
}
/**
 * Custom class for point in the PolyLine tool. This is not at all
 * required. You can use any object that implements TrackCoordinate3D (which in turn)
 * extends Vertex3. Ie you can use a simple Vector3 if that is what you need.
 */
export class AreaPolygonPointWithLabel extends ArbitraryShapePoint {
    private _label?: HTMLDivElement;

    private _scene: Scene;

    private readonly _dragCanvasCoordinate = { x: -1, y: -1 };
    private readonly _prevDragCanvasCoordinate = { x: -1, y: -1 };
    private _labelCssText = '';
    // private _previousParentVisibility: boolean | undefined = undefined;

    public constructor(
        api: BimCoreApi,
        plane: Plane,
        isVirtual: boolean,
        readonly onPointMove: Observable<AreaPolygonPointWithLabel>
    ) {
        super(api, plane, isVirtual);
        this._scene = api.viewer.scene;
    }

    public updateVisibility(): void {
        if (this._label) {
            if (this._parent.isEnabled) {
                this._label.style.display = 'block';
            } else {
                this._label.style.display = 'none';
            }
        }
    }

    public updateHTMLElement(o: TrackCoordinate2D<AreaPolygonPointWithLabel>): void {
        const state = o.state;

        if (state === TrackCoordinate2DState.Added) {
            this._label = this._label ?? document.createElement('div');
            this._label.setAttribute('draggable', 'true');
            this._label.classList.add('areaPolygonPoint');
            this._label = areaParentElement.appendChild(this._label);
            this.updateLabel(o);

            this._label.addEventListener('dragend', (ev) => this.onDragEnd(ev));
            this._label.addEventListener('drag', (ev) => this.onDragging(ev));
            this._label.addEventListener('click', (o) => {
                if (o.ctrlKey) {
                    const ret = this.delete();
                    if (ret === DynamicPolygonPointDeleteResult.Success) {
                        this.apply();
                        this.onPointMove.notifyObservers(this);
                    } else if (ret === DynamicPolygonPointDeleteResult.ComplexPolygon) {
                        alert('Can not delete, would result in a complex polygon');
                    } else if (ret === DynamicPolygonPointDeleteResult.Virtual) {
                        alert('Can not delete, point is virutal');
                    } else if (ret === DynamicPolygonPointDeleteResult.NotEnoughPoints) {
                        alert('Can not delete, would result in not a polygon');
                    } else if (ret === DynamicPolygonPointDeleteResult.Protected) {
                        alert('Can not delete, point is protected.');
                    }
                    o.preventDefault();
                }
            });
        } else if (state === TrackCoordinate2DState.Deleted) {
            this._label?.remove();
            this._labelCssText = '';
            this._label = undefined;
            this._prevDragCanvasCoordinate.x = -1;
            this._prevDragCanvasCoordinate.y = -1;
        } else if (state === TrackCoordinate2DState.Updated) {
            this.updateLabel(o);
        }
    }

    private updateLabel(o: TrackCoordinate2D<AreaPolygonPointWithLabel>): void {
        // Always reposition label so it is at center of point. We can cache same
        // DOMRect forever since point labels will never change size.

        const pos = {
            x: Math.floor(o.position.x - 12),
            y: Math.floor(o.position.y - 12)
        };

        const cssText = asCssText(pos);
        if (cssText !== this._labelCssText) {
            this._labelCssText = cssText;
            this._label!.style.cssText = cssText;
            this.updateVisibility();
        }

        if (this.virtual()) {
            this._label!.classList.add('virtual');
        } else {
            this._label!.classList.remove('virtual');
        }
    }

    private onDragEnd(ev: DragEvent): void {
        convertToCanvasCoordinatesToRef(this._scene, ev, this._dragCanvasCoordinate);
        const moveResult = this.move(this._dragCanvasCoordinate);
        if (moveResult === DynamicPolygonPointMoveResult.Success) {
            this.apply();
            this.onPointMove.notifyObservers(this);
        } else if (moveResult === DynamicPolygonPointMoveResult.ComplexPolygon) {
            alert('Can not move, would result in a complex polygon');
        } else if (moveResult === DynamicPolygonPointMoveResult.RayIntersectionFailed) {
            alert('Can not move, current mouse coordinate does not intersect plane');
        }

        this._prevDragCanvasCoordinate.x = -1;
        this._prevDragCanvasCoordinate.y = -1;
    }

    private onDragging(ev: DragEvent): void {
        if (ev.screenX === 0 && ev.screenY === 0) {
            return;
        }

        convertToCanvasCoordinatesToRef(this._scene, ev, this._dragCanvasCoordinate);
        const domElementHasMoved =
            !Scalar.WithinEpsilon(this._dragCanvasCoordinate.x, this._prevDragCanvasCoordinate.x, 1) ||
            !Scalar.WithinEpsilon(this._dragCanvasCoordinate.y, this._prevDragCanvasCoordinate.y, 1);
        if (domElementHasMoved) {
            if (this.move(this._dragCanvasCoordinate) === DynamicPolygonPointMoveResult.Success) {
                this.apply();
                this.onPointMove.notifyObservers(this);
            }

            Object.assign(this._prevDragCanvasCoordinate, this._dragCanvasCoordinate);
        }
    }
}

export class ParentedAreaPolygonPointWithLabel extends AreaPolygonPointWithLabel {}

/**
 * A simple sample that shows how to use {@link DynamicPolygon} to make a
 * tool that measures the area of a polygon with dynamic points.
 */
export class AreaTool {
    private _polyArea: DynamicPolygon<AreaPolygonPointWithLabel>;
    private _polyAreaLabel: AreaPolygonAreaLabel;
    private _isEnabled = false;
    private _scaleDenom = 6;
    /**
     * If `false` then it is possible to add new points and move the last point.
     */
    public isAreaAdded = false;
    private _material: StandardMaterial;

    public constructor(public readonly name: string, private readonly _api: BimApi) {
        this._material = new StandardMaterial('AreaTool', _api.viewer.scene);
        // Need to see both sides of the area polygon because
        // it is not double sided.
        this._material.backFaceCulling = false;
        this._material.specularColor = Color3.Black(); // No specular hightlights
        this._material.diffuseColor = Color3.Teal();
        this._material.alpha = 0.7;

        const observable = new Observable<AreaPolygonPointWithLabel>();
        this._polyArea = new DynamicPolygon(
            'areatool',
            this._api,
            (api, plane, isVirtual) => {
                const ret = new AreaPolygonPointWithLabel(api, plane, isVirtual, observable);
                return ret;
            },
            true,
            undefined,
            (points) => {
                const a = { x: 0, y: 0, z: 0 };
                points.forEach((p) => {
                    a.x += p.x;
                    a.y += p.y;
                    a.z += p.z;
                });
                a.x /= points.length;
                a.y /= points.length;
                a.z /= points.length;
                return a;
            }
        );

        this._polyAreaLabel = new AreaPolygonAreaLabel();
        this._polyArea.mesh.material = this._material;

        this._polyArea.onPointTrackableScreen.add((eventData, eventState) => {
            const measurePointWithLabel = eventData.trackedCoordinate;
            measurePointWithLabel.updateHTMLElement(eventData);
        });

        this._polyArea.onAreaLabelTrackableScreen.add((eD) => {
            this._polyAreaLabel?.updateHTMLElement(eD, this._polyArea.bottomArea, this._polyArea!.volume);
        });
    }

    /**
     * `true` if area tool is activated
     */
    public get isEnabled(): boolean {
        return this._isEnabled;
    }

    /**
     * Set to `true` to activate area tool. Set to `false` to deactivate
     * and remove current area
     */
    public set isEnabled(v: boolean) {
        this._isEnabled = v;
        if (!v) {
            this._polyArea.clear();
            this.isAreaAdded = false;
        }
    }

    /**
     * Attempts to add a point to the polyline at the current pointer position.
     */
    public attemptAddPolyAreaAtCurrentPointerToPosition(): boolean {
        if (!this.isEnabled || this.isAreaAdded) {
            return false;
        }
        this.isAreaAdded = true;
        const hitInfo = this._api.selectables.pick({
            type: PickOptionType.Canvas,
            position: PredefinedCanvasPosition.Mouse,
            isGeometryIntersectionEnabled: true // Here we need exact click position
        }).hitInfo[0];

        if (hitInfo) {
            const points: [Vector3, Vector3, Vector3, Vector3] = [
                Vector3.Zero(),
                Vector3.Zero(),
                Vector3.Zero(),
                Vector3.Zero()
            ];

            PlaneUtil.cameraScaledSquareOnPlaneToRef(
                hitInfo.position,
                hitInfo.normal,
                this._api.viewer.camera.activeCamera,
                this._scaleDenom,
                points
            );

            this._polyArea.build(points);
        }

        return !!hitInfo;
    }

    public get surfaceArea(): number {
        return this._polyArea!.surfaceArea;
    }

    public get bottomArea(): number {
        return this._polyArea!.bottomArea;
    }

    public get volume(): number {
        return this._polyArea.volume;
    }

    public get interiorPoint(): Vertex3 {
        return this._polyArea!.interiorPoint;
    }

    public get height(): number {
        return this._polyArea.height;
    }

    public set height(height: number) {
        this._polyArea.height = height;
    }
}
