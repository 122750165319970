/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { BimChangeBlob, BimCoreApi, boundingClientRectCache } from '@twinfinity/core';
import { BimDrawing } from './BimDrawing';
import { getDocument, GlobalWorkerOptions, PDFDocumentProxy } from 'pdfjs-dist';
import { Color3 } from '@babylonjs/core/Maths/math.color';

export class BimPdf extends BimDrawing {
    private _page = 1;
    document: PDFDocumentProxy;
    canvas = document.createElement('canvas');
    img: HTMLImageElement;
    blobUrl: string;
    renderPageNextZoom = true;
    private constructor(
        private readonly pdfBlob: BimChangeBlob,
        private readonly _api: BimCoreApi,
        public readonly backgroundColor: Color3
    ) {
        super();
    }

    get url(): string {
        return this.pdfBlob.url;
    }

    static async create(blob: BimChangeBlob, api: BimCoreApi, backgroundColor: Color3): Promise<BimPdf> {
        const bimPdf = new BimPdf(blob, api, backgroundColor);
        GlobalWorkerOptions.workerSrc = '//cdn.jsdelivr.net/npm/pdfjs-dist@2.12.313/build/pdf.worker.js';
        const loadingTask = getDocument({ withCredentials: true, url: blob.url });
        bimPdf.document = await loadingTask.promise;
        return bimPdf;
    }

    async page(p?: number): Promise<number> {
        if (!p) {
            return this._page;
        }
        if (p < 1 || p > this.document.numPages) {
            throw new Error(`Page number ${p} is out of range 1 - ${this.document.numPages}`);
        }
        this._page = p;
        const rect = boundingClientRectCache.getOrAdd(this._api.viewer.canvas);
        this.renderPageNextZoom = true;
        await this.parent.zoom(this.parent.camera, rect);
        return this._page;
    }

    get pageCount(): number {
        return this.document.numPages;
    }
}
