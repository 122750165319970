/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */
import { MemoryBlock } from './MemoryBlock';

/**
 * Represents a BabylonJS indice array which can be either Uint16 (if used with less  than Uint16 vertices)  or Uint32
 */
export type GeometryIndiceArray = Uint16Array | Uint32Array;

/**
 * Represents a geometry that can be sent to BabylonJS
 */
export interface GeometryArrays {
    /**
     * Positions (x,y,z).
     */
    readonly positions: Float32Array;

    /**
     * Uvs (u,v)
     */
    readonly uvs: Float32Array;

    /**
     * Indices. Describes triangles by referencing items in {@link positions}.
     */
    readonly indices: GeometryIndiceArray;
}

/**
 * Represent an offset into a Geometry array that indicates the position of a mesh or sub-mesh
 */
export interface GeometryArrayOffset {
    readonly indices: number;
    readonly positions: number;
}

const maxIndicesInUint16IndiceBuffer = 65535;
/**
 * Represents a block of reusable geometry memory.
 * It is expensive to allocate memory for geometry arrays (vertices, normals, indices) for each mesh.
 * By using this class we can allocate a large block of memory and then allocate smaller slices from this block
 * to write geometry data to. For example during mesh merging.
 */
export class GeometryBuffer {
    private readonly _verticesBlock = new MemoryBlock('mesh-merge-vertices', Float32Array);
    private readonly _normalsBlock = new MemoryBlock('mesh-merge-normals', Float32Array);
    private readonly _uint32IndiceBlock = new MemoryBlock('mesh-merge-uint32indices', Uint32Array);

    /**
     * Total size in bytes of geometry memory.
     */
    public get byteLength(): number {
        return this._verticesBlock.byteLength + this._normalsBlock.byteLength + this._uint32IndiceBlock.byteLength;
    }

    /**
     * Updates the maximum length of the geometry buffer to accommodate the given primitive count and indice count.
     * If the given counts are greater than the current maximum lengths, then the maximum size will be updated.
     *
     * @param primitiveCount - The desired primitive count.
     * @param indiceCount - The desired indice count.
     * @returns This  {@link GeometryBuffer} instance.
     */
    public maximizeLength(primitiveCount: number, indiceCount: number): GeometryBuffer {
        // Update max values so we can allocate one large memory block during mesh merging
        primitiveCount = Math.max(primitiveCount, this._verticesBlock.length / 3);
        indiceCount = Math.max(indiceCount, this._uint32IndiceBlock.length);
        this._verticesBlock.length = primitiveCount * 3;
        this._normalsBlock.length = primitiveCount * 2;

        this._uint32IndiceBlock.length = indiceCount;
        return this;
    }

    /**
     * Allocate a block of geometry arrays ({@link GeometryArray}). Only one allocation at a time may exist
     * Therefore next call to this method will invalidate contents of previous {@link GeometryArray} instances.
     * @param primitiveCount Number of primitives to allocate. Cannot be more than {@link maxNumberOfPrimitives}.
     * @param indiceCount Number of indices to allocate. Cannot be more than {@link maxNumberOfIndices.}
     */
    public malloc(primitiveCount: number, indiceCount: number): GeometryArrays {
        // We can only allocate and use one block at any one time. So subsequent calls to malloc will
        // simply reset the offset to 0 and return slices from the beginning of the block.
        // this invalidates any previous GeometryArrays instances.
        this._verticesBlock.offset = this._normalsBlock.offset = this._uint32IndiceBlock.offset = 0;

        return {
            positions: this._verticesBlock.subarray(primitiveCount * 3),
            uvs: this._normalsBlock.subarray(primitiveCount * 2), // Normals are stored in the first 16 bits of of the second float in the uvs
            indices: this.indiceBlock(primitiveCount, indiceCount)
        };
    }

    /**
     * Frees the memory used by the geometry buffer.
     */
    public free(): void {
        this._verticesBlock.free();
        this._normalsBlock.free();
        this._uint32IndiceBlock.free();
    }

    private indiceBlock(primitiveCount: number, indiceCount: number): GeometryIndiceArray {
        if (primitiveCount > maxIndicesInUint16IndiceBuffer) return this._uint32IndiceBlock.subarray(indiceCount);
        return this._uint32IndiceBlock.subView(indiceCount, Uint16Array);
    }
}
