/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { DumpTools } from './babylonjs-import';

export async function saveAsImage(
    pixelArray: ArrayBufferView,
    width: number,
    height: number,
    fileName?: string,
    mimeType = 'image/png'
): Promise<void> {
    DumpTools.DumpData(width, height, pixelArray, undefined, mimeType, fileName, true);
}
