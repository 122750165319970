/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */
type PropertyList = (() => void)[];

/**
 * Records changes to a object which can later be reversed.
 */
export class ChangeRecorder {
    private _items: PropertyList = [];

    /**
     * Save a property `prop` on `obj`. This value will be restored when {@link restoreAll} is called later.
     * @param obj
     * @param prop
     */
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
    public save<O extends unknown, P extends keyof O>(obj: O, prop: P): void {
        const oldVal = obj[prop];
        this._items.push(() => {
            obj[prop] = oldVal;
        });
    }

    /**
     * Set a property `prop` on `obj` to `newValue`. This can later be reversed by calling {@link restoreAll}.
     * @param obj Object to set property on.
     * @param prop Property to set
     * @param newValue New value which `obj[prop]` will get.
     */
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
    public set<O extends unknown, P extends keyof O, V extends O[P]>(obj: O, prop: P, newValue: V): void {
        this.save(obj, prop);
        obj[prop] = newValue;
    }

    /**
     * Register callback, for `o`, which will be invoked when {@link restoreAll} is called. Useful
     * when a more complex state needs to be restored when {@link restoreAll} is called. The callback is then
     * responsible for modifying `o` when {@link restoreAll} is called.
     * @param o Object to register callback for.
     * @param onRestore callback.
     */
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
    public onRestore<O extends unknown>(o: O, onRestore: (obj: O) => void): void {
        this._items.push(() => onRestore(o));
    }

    /**
     * Reverts all changes made to the objects.
     */
    public restoreAll(): void {
        for (const restore of this._items) {
            restore();
        }
        this._items.length = 0;
    }
}
