import { TwinfinityCameraBehavior } from '../camera/TwinfinityCameraBehavior';
import { BlurPostProcess, Camera, PassPostProcess, Vector2 } from '../loader/babylonjs-import';
import { DitherBlurApplier } from './DitherBlurApplier';

export class DitherBlurPipeline implements TwinfinityCameraBehavior {
    private _ditherBlurApplier: DitherBlurApplier;
    private _screenBlit: PassPostProcess;
    private _horizontalBlur: BlurPostProcess;
    private _verticalBlur: BlurPostProcess;

    constructor(private readonly _kernel: number) {}

    attach(camera: Camera): boolean {
        this._screenBlit = new PassPostProcess('screen blit', 1.0, camera);

        const ratio = 1.0; // Pretty much has to be 1.0

        // Seperable blur, first blur along the horizontal axis (or vertical does not matter as long as you switch axis afterwards)
        this._horizontalBlur = new BlurPostProcess('Horizontal blur', new Vector2(1.0, 0), this._kernel, ratio, camera);

        this._verticalBlur = new BlurPostProcess('Vertical blur', new Vector2(0, 1.0), this._kernel, ratio, camera);

        this._ditherBlurApplier = new DitherBlurApplier(this._screenBlit);

        const alphaBlurAttached = this._ditherBlurApplier.attach(camera);

        return alphaBlurAttached;
    }

    detach(camera: Camera): boolean {
        this._ditherBlurApplier.detach(camera);
        camera.detachPostProcess(this._horizontalBlur);
        camera.detachPostProcess(this._verticalBlur);
        camera.detachPostProcess(this._screenBlit);
        return true;
    }
}
