import {
    BimCoreApi,
    BimChangeDwg,
    setMax,
    setMin,
    BimChangeBlob,
    HttpResponseType,
    boundingClientRectCache,
    PredefinedCanvasPosition,
    intersectsPlaneAtToRef,
    ViewerCamera
} from '@twinfinity/core';
import {
    BimDrawingObject,
    BimDrawingObjectForEachAction,
    BimDrawingObjectRecursionOptions,
    DrawingType
} from './BimDrawingObject';
import { BimSvg } from './BimSvg';
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { BimSvgGroup } from './BimSvgGroup';
import { BimPdf } from './BimPdf';
import { BimPdfParent } from './BimPdfParent';

import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { Camera } from '@babylonjs/core/Cameras/camera';
import { Ray } from '@babylonjs/core/Culling/ray';
import { BimDrawingPdf } from './BimDrawingPdf';
import { BimDrawingDwg } from './BimDrawingDwg';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import { DeepImmutable } from '@babylonjs/core/types';

export type DrawingApiLoadParameter<Change> = Change extends BimChangeDwg ? BimChangeDwg[] : BimChangeBlob;

/**
 * Use this API to load and visualize drawings.
 */
export class DrawingApi<Change extends BimChangeBlob | BimChangeDwg = BimChangeDwg> {
    private _drawings = new Map<string, BimDrawingObject>();
    private readonly _boundingInfo = new BoundingInfo(Vector3.Zero(), Vector3.Zero());
    private _drawingParentNode: TransformNode;
    public static readonly defaultPosition = Vector3.ZeroReadOnly;
    public static readonly defaultScale: DeepImmutable<Vector3> = new Vector3(50, 1, 50);
    public static readonly pdfDefaultBackgroundColor = Color3.White();

    /**
     * Constructor
     * @param _api Api
     * @param _textureSize Defines size of texture used to render drawings. Recommended value is power of two. Higher resolution gives
     * better visuals but costs more memory (a pixel takes 4 bytes).
     * @param grayscaleDefault Decides if the DWG drawings are grayscale by default. Onnly applies to dwg drawings.
     * @param invertedBackgroundDefault Decides if the DWG drawings are created with inverted background as default. Only applies to dwg drawings.
     */
    public constructor(
        private readonly _api: BimCoreApi,
        private readonly _textureSize = 1024,
        private _grayscaleDefault = false,
        private _invertedBackgroundDefault = false
    ) {}

    //TODO: These should not all be available at the same time. grayscaledefault should for example only be available
    //when using the DrawingApi for DWGs while backgroundColorDefault should only be available for PDF.

    public get grayscaleDefault(): boolean {
        return this._grayscaleDefault;
    }

    public set grayscaleDefault(value: boolean) {
        this._grayscaleDefault = value;
    }

    public get invertedBackgroundDefault(): boolean {
        return this._invertedBackgroundDefault;
    }

    public set invertedBackgroundDefault(value: boolean) {
        this._invertedBackgroundDefault = value;
    }

    /**
     * Bounding information for the currently loaded DWG's
     */
    public get boundingInfo(): BoundingInfo {
        if (this._drawings.size === 0) {
            this._boundingInfo.reConstruct(Vector3.Zero(), Vector3.Zero());
            return this._boundingInfo;
        }

        // Rebuild the bounding info so it is consistent
        // with the mesh
        const aabbMin = setMax(Vector3.Zero());
        const aabbMax = setMin(Vector3.Zero());

        this.foreach((drawing) => {
            const bI = drawing.boundingInfo;
            aabbMax.maximizeInPlace(bI.maximum);
            aabbMax.maximizeInPlace(bI.minimum);

            aabbMin.minimizeInPlace(bI.maximum);
            aabbMin.minimizeInPlace(bI.minimum);
        });

        this._boundingInfo.reConstruct(aabbMin, aabbMax);
        return this._boundingInfo;
    }

    /**
     * Load one or more drawings.
     * Only PDF and 2D DWG files are supported.
     * Note that 3D DWG's are not supported.
     * @param drawings Drawings to load
     * @returns Promise
     */
    public async load(drawings: DrawingApiLoadParameter<Change>, camera?: ViewerCamera): Promise<void> {
        if (Array.isArray(drawings)) {
            if (drawings.length > 0) {
                await this.loadDwgs(drawings);
                if (camera) {
                    this.fitDWGToCamera(camera);
                }
            }
        } else {
            const bimPdfParent = await this.loadPdf(drawings);
            if (camera) {
                await this.fitPDFToCamera(camera, bimPdfParent);
            }
        }
    }

    public async fitDrawingsToCamera(camera: ViewerCamera): Promise<void> {
        const drawings = [...this._drawings.values()];
        if (drawings.length === 0) {
            return;
        }
        const i = drawings[0];
        if (i instanceof BimDrawingDwg) {
            this.fitDWGToCamera(camera);
        } else if (i instanceof BimDrawingPdf) {
            this.fitPDFToCamera(camera, i.drawing.parent as BimPdfParent);
        } else {
            throw new Error('Not supported');
        }
    }

    private async fitPDFToCamera(camera: ViewerCamera, bimPdfParent: BimPdfParent): Promise<void> {
        //zoom to extent on the default bounding info guaranteeing that the entire PDF will be in view.
        camera.zoomToExtent(this.boundingInfo, 'top');
        //force render the PDF so it rescales the plane properly
        for (const drawing of this._drawings.values()) {
            await drawing.forceUpdate();
        }
        //get the bounding info of the PDF instead of the plane
        const binfo = this.getPDFBoundingInfo(camera, bimPdfParent);

        if (camera.activeCamera.mode === Camera.PERSPECTIVE_CAMERA) {
            this._api.viewer.camera.activeCamera.mode = Camera.PERSPECTIVE_CAMERA;
            const renderCanvasWidth = this._api.viewer.engine.getRenderWidth();
            const renderCanvasHeight = this._api.viewer.engine.getRenderHeight();

            let size =
                renderCanvasWidth >= renderCanvasHeight
                    ? Math.abs(binfo.minimum.z - binfo.maximum.z)
                    : Math.abs(binfo.minimum.x - binfo.maximum.x);

            if (renderCanvasWidth >= renderCanvasHeight) {
                size /= 2;
                if (
                    bimPdfParent.pdf.canvas.width / bimPdfParent.pdf.canvas.height >
                    renderCanvasWidth / renderCanvasHeight
                ) {
                    size *= bimPdfParent.pdf.canvas.width / bimPdfParent.pdf.canvas.height;
                }
            } else {
                size *= renderCanvasHeight / renderCanvasWidth / 2;
                if (
                    bimPdfParent.pdf.canvas.height / bimPdfParent.pdf.canvas.width >
                    renderCanvasHeight / renderCanvasWidth
                ) {
                    size *= bimPdfParent.pdf.canvas.height / bimPdfParent.pdf.canvas.width;
                }
            }

            const cameraForwardVector = new Vector3(0, 1, 0);
            const center = binfo.minimum.add(binfo.maximum.subtract(binfo.minimum).scale(0.5)); // PDF center

            // calculate half angle of field ov view
            const angle = camera.activeCamera.fov / 2;
            const targetDistance = size / Math.tan(angle);
            const newCameraPosition = cameraForwardVector.scale(targetDistance);
            camera.activeCamera.position.copyFrom(center.add(newCameraPosition));
            camera.activeCamera.setTarget(center);
            camera.activeCamera.getViewMatrix(true); // Force recompute of word matrix etc
            camera.activeCamera.twinfinity.pivot.update({ target: center });
        } else {
            camera.zoomToExtent(new BoundingInfo(binfo.minimum, binfo.maximum), 'top');
            camera.activeCamera.mode = Camera.ORTHOGRAPHIC_CAMERA;
            camera.activeCamera.getViewMatrix(true);
            camera.activeCamera.twinfinity.setOrtho({
                height: Math.abs(binfo.minimum.z - binfo.maximum.z)
            });
            camera.activeCamera.getViewMatrix(true);
        }
    }

    private getPDFBoundingInfo(camera: ViewerCamera, bimPdfParent: BimPdfParent): BoundingInfo {
        const canvasRect = boundingClientRectCache.getOrAdd(this._api.viewer.canvas);
        const canvasSize = Math.max(canvasRect.width, canvasRect.height);

        const minX = (canvasRect.width - canvasSize) / 2;
        const minY = (canvasRect.height - canvasSize) / 2;
        const canvasPositions = [
            { x: minX, y: minY },
            { x: canvasRect.width - minX, y: canvasRect.height - minY }
        ];

        const ray = new Ray(Vector3.Zero(), Vector3.Up());
        // TODO Predefined points instead. We know how many there are.
        const frustumCornerPointsOnZoomPlane: Vector3[] = [];

        camera.activeCamera.twinfinity.createPickingRayToRef(ray, PredefinedCanvasPosition.Center);
        const tmpVec = Vector3.Zero();
        const cameraCenterOnZoomPlane = tmpVec;
        //Get world coordinates where the center of the camera frustrum intersects with the pdf plane.
        intersectsPlaneAtToRef(ray, bimPdfParent.plane.mathematicalPlane, cameraCenterOnZoomPlane);

        //Get points in world coordinates where the top left and bottom right of the camera frustrum intersects the PDF plane.
        const canvasPosVectors = [Vector3.Zero(), Vector3.Zero()];
        for (let i = 0; i < canvasPositions.length; ++i) {
            const canvasPos = canvasPositions[i];
            camera.activeCamera.twinfinity.createPickingRayToRef(ray, canvasPos);
            canvasPosVectors[i].set(0, 0, 0);
            const p = canvasPosVectors[i];

            intersectsPlaneAtToRef(ray, bimPdfParent.plane.mathematicalPlane, p);
            frustumCornerPointsOnZoomPlane.push(p);
        }

        //We can use the coordinates used in draw image to create a bounding box for the PDF.
        const drawImgCoords = bimPdfParent.calculatePDFDrawImageCoords(
            bimPdfParent.plane.texture,
            bimPdfParent.pdf,
            bimPdfParent.plane,
            frustumCornerPointsOnZoomPlane
        );

        if (!drawImgCoords) {
            return this.boundingInfo;
        }

        const defaultScale = bimPdfParent.plane.parent.scaling.clone();
        const currentCenter = frustumCornerPointsOnZoomPlane[0].subtract(
            frustumCornerPointsOnZoomPlane[0].subtract(frustumCornerPointsOnZoomPlane[1]).scale(0.5)
        );
        const currentScale = new Vector3(
            bimPdfParent.plane.parent.scaling.x * bimPdfParent.plane.mesh.scaling.x,
            1,
            bimPdfParent.plane.parent.scaling.z * bimPdfParent.plane.mesh.scaling.z
        );

        const ppuWidth = Math.max(bimPdfParent.pdf.canvas.width, bimPdfParent.pdf.canvas.height) / defaultScale.x;
        const textureppu = bimPdfParent.plane.texture.getSize().width / currentScale.x; //Shouldnt matter if we use width or height for texturesize since its square.

        const topLeftCurrent = currentCenter.subtract(new Vector3(-currentScale.x / 2, 0, currentScale.z / 2));

        const canvasDelta =
            (Math.max(bimPdfParent.pdf.canvas.height, bimPdfParent.pdf.canvas.width) -
                Math.min(bimPdfParent.pdf.canvas.width, bimPdfParent.pdf.canvas.height)) /
            2;
        const aspectRatioDiffAsWorldCoords = canvasDelta / ppuWidth;
        const aspectRatioDiffInTextureCoords = aspectRatioDiffAsWorldCoords * textureppu;

        const dxWorldSpace = drawImgCoords.dx / textureppu;
        const dyWorldSpace = drawImgCoords.dy / textureppu;
        let dWidthWorldSpace = -1;
        let dHeightWorldSpace = -1;

        if (bimPdfParent.pdf.canvas.width >= bimPdfParent.pdf.canvas.height) {
            dWidthWorldSpace = drawImgCoords.dWidth / textureppu;
            dHeightWorldSpace = (drawImgCoords.dHeight - aspectRatioDiffInTextureCoords * 2) / textureppu;
        } else {
            dWidthWorldSpace = (drawImgCoords.dWidth - aspectRatioDiffInTextureCoords * 2) / textureppu;
            dHeightWorldSpace = drawImgCoords.dHeight / textureppu;
        }

        const topLeftPDF = new Vector3(topLeftCurrent.x - dxWorldSpace, 0, topLeftCurrent.z + dyWorldSpace);
        const bottomRightPDF = new Vector3(
            topLeftCurrent.x - dxWorldSpace - dWidthWorldSpace,
            0,
            topLeftCurrent.z + dyWorldSpace + dHeightWorldSpace
        );

        const min = new Vector3(bottomRightPDF.x, 0, topLeftPDF.z);
        const max = new Vector3(topLeftPDF.x, 0, bottomRightPDF.z);
        return new BoundingInfo(min, max);
    }

    private fitDWGToCamera(camera: ViewerCamera): void {
        camera.zoomToExtent(this.boundingInfo, 'top');
    }

    private async loadDwgs(dwgs: BimChangeDwg[]): Promise<void> {
        if (dwgs.filter((dwg) => dwg.metadata.dwg.type === '3d').length > 0) {
            throw new Error('3D DWG is not supported.');
        }

        const bimSvgs: BimSvg[] = [];
        for (const dwg of dwgs.values()) {
            if (dwg.metadata.dwg.type === '2d') {
                if (this._drawings.has(dwg.metadata.dwg.url.svg)) {
                    continue;
                }
                // TODO This is not efficient if we want to load many SVG's. In that case it will be better
                // fire multiple requests at once and them afterwards.
                const response = await this._api.backend.get(dwg.metadata.dwg.url.svg, HttpResponseType.text);
                if (response.ok) {
                    const src = await response.value;
                    const bimSvg = new BimSvg(
                        src,
                        dwg.metadata.dwg.offset.left,
                        dwg.metadata.dwg.offset.top,
                        dwg.metadata.dwg.width,
                        dwg.metadata.dwg.height
                    );

                    const i = new BimDrawingDwg(this._api, bimSvg, dwg, (o) => {
                        if (o.drawingChange.metadata.dwg.type === '2d') {
                            this._drawings.delete(o.drawingChange.metadata.dwg.url.svg);
                        }
                    });

                    this._drawings.set(dwg.metadata.dwg.url.svg, i);

                    bimSvgs.push(bimSvg);
                }
            }
        }

        await BimSvgGroup.create(
            bimSvgs,
            this._api,
            this._textureSize,
            this.grayscaleDefault,
            this.invertedBackgroundDefault,
            this.drawingParentNode
        );
    }

    private async loadPdf(pdf: BimChangeBlob): Promise<BimPdfParent> {
        const bimPdf = await BimPdf.create(pdf, this._api, DrawingApi.pdfDefaultBackgroundColor);
        const bimDrawingPdf = new BimDrawingPdf(this._api, bimPdf, pdf, (o) => {
            this._drawings.delete(o.drawingChange.url);
        });

        this._drawings.set(pdf.url, bimDrawingPdf);

        return new BimPdfParent(this._api, this._textureSize, bimPdf, this.drawingParentNode);
    }

    /**
     * Iterate all loaded drawings.
     * @param action Action which is called on each drawing.
     */
    public foreach(action: BimDrawingObjectForEachAction<Change>): void {
        const recursionOptions: BimDrawingObjectRecursionOptions = {
            stopRecursion: false,
            abort: false
        };

        for (const drawing of this._drawings.values()) {
            action(drawing as DrawingType<Change>, recursionOptions);
            if (recursionOptions.abort || recursionOptions.stopRecursion) {
                return;
            }
        }
    }

    *[Symbol.iterator](): IterableIterator<DrawingType<Change>> {
        for (const drawing of this._drawings.values()) {
            yield drawing as DrawingType<Change>;
        }
    }

    /**
     * Remove all loaded drawings
     */
    public clear(): void {
        for (const drawing of this._drawings.values()) {
            drawing.dispose();
        }
        this._drawings.clear();
    }

    public get drawingParentNode(): TransformNode {
        if (this._drawingParentNode !== undefined) {
            return this._drawingParentNode;
        }
        this._drawingParentNode = new TransformNode('drawing-parent', this._api.viewer.scene);
        this._drawingParentNode.id = 'drawing-parent';
        this._drawingParentNode.scaling.copyFrom(DrawingApi.defaultScale);
        return this._drawingParentNode;
    }
}
