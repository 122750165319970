import { MergableObject, MergableObjectEventArgs } from '../../MergableSet';
import { MarkupProperty } from '../MarkupEntityTypes';

/**
 * Base class for markup entities and Markup sheets in the Markup API.
 */
export abstract class MarkupEntityBase implements MergableObject<MarkupEntityBase> {
    /**
     * Do not use, use {@link MarkupSheet2D.add} instead.
     * @hidden
     */
    constructor(public readonly id: string, public readonly properties = new Map<string, MarkupProperty>()) {}

    /** Called when this entity is added */
    abstract onAdded(o: MergableObjectEventArgs<MarkupEntityBase>): void;
    /** Called when this entity is updated */
    abstract onUpdate(o: MergableObjectEventArgs<MarkupEntityBase>): void;
    /** Called when this entity is deleted */
    abstract onDelete(o: MergableObjectEventArgs<MarkupEntityBase>): void;
    /** Compares if this entity is equal to another entity */
    abstract isEqual(o: MarkupEntityBase): boolean;
    /** Makes a deep copy of this entity */
    abstract clone(): MarkupEntityBase;
    /** Returns `true` if the entity and the sheet it belongs to are both enabled, otherwise `false`. */
    abstract get isEnabled(): boolean;
    /** Sets if entity is enabled */
    abstract set isEnabled(b: boolean);
    /**
     * Marks the entity as deleted in the {@link MarkupSheet2D}.
     * @returns `true` if object was deleted. Otherwise `false`.
     */
    abstract markAsDeleted(): boolean;
    /**
     * Sets the entity as updated in the layer API.
     * @returns `true` if object did exist, otherwise `false`
     */
    abstract markAsUpdated(): boolean;
}
