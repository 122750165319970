export const BIT_SHIFTING = `
const float leftShift8 = 256.0;
const float leftShift16 = leftShift8 * leftShift8;
const float leftShift24 = leftShift8 * leftShift8 * leftShift8;

const float rightShift24 = 1.0 / leftShift24;
const float rightShift16 = 1.0 / leftShift16;
const float rightShift8 = 1.0 / leftShift8;

// NOTE: the bitwise shift functions are incomplete 
// and don't account for sign. You've been warned :O

// bitwise shift x to the left n spaces (bitwise <<)
// x is int to shift
// n is number of "shifts" or spaces to shift left
int shiftleft(int x, int n) {
	return int(float(x) * pow(2.0,float(n)));
}

// bitwise shift x to the right n spaces (bitwise >>)
// x is int to shift
// n is number of "shifts" or spaces to shift right
int shiftright(int x, int n) {
	return int(floor(float(x) / pow(2.0,float(n))));
}

#define ZERO 0
#define ONE 1


// Stuff from: https://www.shadertoy.com/view/4tjyW3
// NOTE: this is pure confirmation bias, hasn't been tested, may cause late nights :)

// Collection of functions designed to help packing/unpacking rgba values from/to textures
// create a bitmask (bitwise &)
// a is int to mask
// b is the bitmask e.g. (0b00001111 = 15)
int bitmask(int a, int b) {
    int result = 0;
    int byteVal = 1;
    int zero = int(0);
    int two = int(2);
    
    // NOTE: safari hated while loops for some reason
    for(int i = ONE; i > ZERO; i++) {
        if(a > ZERO || b > ZERO) {
            // do nothing
        } else {
        	break;
        }
        
        // using mod() for safari
        //if(a % two != zero && b % two != zero) {
        if(int(mod(float(a), float(two))) != zero && int(mod(float(b),float(two))) != zero) {
            result += byteVal;
        }
        a = int(floor(float(a) * 0.5));
        b = int(floor(float(b) * 0.5));
        byteVal *= two;
    }
    return result;
}`;

export const DITHERING = 'dithering';
export const DITHER_SIZE = 'ditherSize';
export const DITHER_SAMPLER = 'ditherSampler';
export const RESOLUTION = 'resolution';
export const USE_HSV_MAPPING = 'useHSVMapping';
export const HSV_VALUES = 'hsvValues';
export const HIGHLIGHT_COLORS = 'highlightColors';

export const RGB_2_HSV = `vec3 rgb2hsv_2(vec3 c){
	vec4 K=vec4(0.,-1./3.,2./3.,-1.),
	     p=mix(vec4(c.bg ,K.wz),vec4(c.gb,K.xy ),step(c.b,c.g)),
	     q=mix(vec4(p.xyw,c.r ),vec4(c.r ,p.yzx),step(p.x,c.r));
	float d=q.x-min(q.w,q.y),
	      e=1e-10;
	return vec3(abs(q.z+(q.w-q.y)/(6.*d+e)),d/(q.x+e),q.x);
}`;

export const HSV_2_RGB_OFFICIAL = `// Official HSV to RGB conversion 
vec3 hsv2rgb( in vec3 c )
{
    vec3 rgb = clamp( abs(mod(c.x*6.0+vec3(0.0,4.0,2.0),6.0)-3.0)-1.0, 0.0, 1.0 );

	return c.z * mix( vec3(1.0), rgb, c.y);
}`;

export const HSV_2_RGB_SMOOTH = `
// Smooth HSV to RGB conversion 
vec3 hsv2rgb_smooth( in vec3 c )
{
    vec3 rgb = clamp( abs(mod(c.x*6.0+vec3(0.0,4.0,2.0),6.0)-3.0)-1.0, 0.0, 1.0 );

	rgb = rgb*rgb*(3.0-2.0*rgb); // cubic smoothing	

	return c.z * mix( vec3(1.0), rgb, c.y);
}`;

export const HSV_COLOR_CHANGER = `#ifdef ${USE_HSV_MAPPING}
    finalDiffuse = rgb2hsv_2(finalDiffuse);

    finalDiffuse.x *= ${HSV_VALUES}.x;
    finalDiffuse.y *= ${HSV_VALUES}.y;
    finalDiffuse.z *= ${HSV_VALUES}.z;

    finalDiffuse = hsv2rgb_smooth(finalDiffuse);
#endif`;

export const DITHER_UNIFORMS = `
#ifdef ${DITHERING}
    uniform sampler2D ${DITHER_SAMPLER};
    uniform vec2 ${DITHER_SIZE};
    uniform vec2 ${RESOLUTION};
#endif`;

export const DITHER_DISCARD = `
    const float DITHERING_OPACITY_COEFFICIENT = 1.0 / 3.0;
    // Cranked up a bit since the new blending mode has a less noticeable impact
    float transparency = (1.0 - step(0.99, opaqueness)) * max(1.0 - opaqueness * DITHERING_OPACITY_COEFFICIENT, 0.0);
    
    float fakeDistance = pow(min((gl_FragCoord.z / gl_FragCoord.w) / 7.5, 1.0), 1.15);

    vec2 samplePosition = (gl_FragCoord.xy / ${RESOLUTION}) * ${DITHER_SIZE};
    vec3 dither = texture2D(${DITHER_SAMPLER}, samplePosition).xyz;

    float exponent = dither.r;
    float mantissa = dither.g;
    float unpackedValue = mantissa * exp2(exponent);

    if (unpackedValue < transparency - (fakeDistance * distanceImpact * transparency)) {
        discard;
    }
`;

export const SIZE = 'size';
export const DIFFUSE_SAMPLER = 'diffuseSampler';
export const PROPERTIES_SAMPLER = 'propertiesSampler';
export const IOS_BIT_OPERATIONS = 'IOS_BIT_OPERATIONS';
export const WEBGL2 = 'WEBGL2';
export const GHOST_VALUE = 'ghostValue';
export const VISIBILITY_VALUE = 'visibilityValue';
export const REQUIRES_WORLD_FRAGMENT = 'REQUIRES_WORLD_FRAGMENT';

export const COLOR_PIXEL_INDEX = `float colorPixelIndex = vColorPixelIndex + 0.5;
float uvY = floor(colorPixelIndex / float(textureSize.x)); 
float uvX = floor(floor(colorPixelIndex) - uvY * float(textureSize.x));    
vec2 pixelIndex = vec2(uvX, uvY);
`;

export const createPropertiesTextureReadShaderCode = (uvName: string): string => {
    const PROPERTIES_READ = `vec4 properties;
    #ifdef ${WEBGL2} 
        properties = texelFetch(${PROPERTIES_SAMPLER}, ivec2(floor(${uvName})), 0);
    #else
        properties = texture2D(${PROPERTIES_SAMPLER}, ${uvName});
    #endif
    `;

    return PROPERTIES_READ;
};

export const PROPERTIES_CASTED_A = `int castedA = int(ceil(clamp(properties.a, 0., 1.) * 255.0));`;

export const DEPTH_WRITE_IGNORES_VISIBLITY_PROPERTY = `
int lastBitMask = 1; // Depth write bit
    
#ifdef ${IOS_BIT_OPERATIONS}
    int depthWriteIgnoresVisibilityValue = bitmask(castedA, lastBitMask);
#else
    int depthWriteIgnoresVisibilityValue = castedA & lastBitMask;
#endif

bool depthWriteIgnoresVisibility = depthWriteIgnoresVisibilityValue == lastBitMask;
`;

export const VISIBLITY_PROPERTY = `
int secondLastBitMask = 2; // Visibility bit
    
#ifdef ${IOS_BIT_OPERATIONS}
    int visibilityValue = bitmask(castedA, secondLastBitMask);
#else
    int visibilityValue = castedA & secondLastBitMask;
#endif

bool visible = visibilityValue == secondLastBitMask;
`;

export const HIGHLIGHT_INDEX_PROPERTY = `
int fourthAndThirdLastBitsMask = int(12); // Highlight bits
    
#ifdef ${IOS_BIT_OPERATIONS}
    int highlightIndex = bitmask(castedA, fourthAndThirdLastBitsMask);
    highlightIndex = shiftright(highlightIndex, 2); //Move 2 bits to the right to get the values in the 0-3 range
#else
    int highlightIndex = castedA & fourthAndThirdLastBitsMask;
    highlightIndex = highlightIndex >> 2; //Move 2 bits to the right to get the values in the 0-3 range
#endif

bool highlighted = highlightIndex != 0;
`;
