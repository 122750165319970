import { PropertyChangeObservable, makePropertyChangeObservable } from '../PropertyChangeObservable';
import { ClipPlane, ClipPlaneOptions, ClipPlanes } from './ClipPlane';
import { DitheringTextureMode } from './CustomBabylonMaterials/DitherTexture';
import { IfcFragmentHsvColoringSettings } from './IfcFragmentHsvColoringSettings';
import { IFCLightingEnvironment, LightingEnvironment } from './IfcLightingEnvironment';
import { Color3, DirectionalLight, Effect, HemisphericLight, Vector3 } from './babylonjs-import';
import { TransparencyMode, TwinfinityViewer } from './twinfinity-viewer';
import { telemetry } from '../Telemetry';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { IfcMaterialHighlightIndex } from './CustomBabylonMaterials/IfcMaterial';
import { HIGHLIGHT_COLORS } from './ShaderSnippets';

export type DitheringOptions = {
    readonly mode: DitheringTextureMode;
    readonly animatedFrames: number;
    readonly ditherDiscardDepth: boolean;
};

/**
 * Transparency settings for IFC objects.
 */
export class IfcTransparencySettings {
    /**
     * Changes how transparency is handled for transparent IFC objects
     * @param transparencyMode The type of rendering that will be used for all transparent IFC objects, dithering means that pixels are
     * rendered opaque but skipping every few pixels in a screen pattern depending on the opacity
     * Seethrough is normal alpha blending
     * AnimatedFrames changes the noise from every frame in a loop, when the camera moves, to fight a visual swimming effect when the camera moves, set to 1 to dis
     */
    public mode: TransparencyMode = TransparencyMode.Seethrough;

    /**
     * @param ditheringMode What dithering mode to use, bayer uses a bayer matrix to determine which pixels to render opaque, blue noise uses a blue noise texture
     * @param animatedFrames How many frames to use for the dithering animation when moving the camera, if 1 then no animation is used. Works best with Blue Noise
     * @param ditherDiscardDepth Wheter or not to discard fragments in the depth shader that are dithered, this is needed for transparent objects to not write to the depth buffer
     */
    public ditheringOptions: DitheringOptions;
}

type InitializePoint = {
    color: Color3;
};

const initialPlanes: ClipPlanes<InitializePoint> = {
    clipPlane: { color: new Color3(1.0, 0.0, 0.0) },
    clipPlane2: { color: new Color3(0.0, 1.0, 0.0) },
    clipPlane3: { color: new Color3(0.0, 0.0, 1.0) },
    clipPlane4: { color: new Color3(1.0, 0.0, 1.0) },
    clipPlane5: { color: new Color3(0.0, 1.0, 1.0) },
    clipPlane6: { color: new Color3(1.0, 0.0, 1.0) }
};

/**
 * Represents the visual settings for an IFC model.
 */
export class IfcVisualSettings {
    /**  */
    readonly color = makePropertyChangeObservable(new IfcFragmentHsvColoringSettings(false));
    readonly transparency: PropertyChangeObservable<IfcTransparencySettings>;
    readonly clipPlanes = ClipPlane.create(initialPlanes);
    public readonly _highlightColors = new Map<IfcMaterialHighlightIndex, Color3>();

    /**
     * Creates an instance of {@link IfcVisualSettings}.
     * @param lighting The lighting environment for the model.
     * @param viewer The {@link TwinfinityViewer} instance.
     */
    constructor(readonly lighting: IFCLightingEnvironment, viewer: TwinfinityViewer) {
        this.transparency = makePropertyChangeObservable(new IfcTransparencySettings());
        this._highlightColors.set(IfcMaterialHighlightIndex.One, Color3.Blue());
        this._highlightColors.set(IfcMaterialHighlightIndex.Two, Color3.Green());
        this._highlightColors.set(IfcMaterialHighlightIndex.Three, Color3.Red());
    }

    setHighlightColor(color: Color3, highlightIndex: IfcMaterialHighlightIndex): void {
        if (highlightIndex !== IfcMaterialHighlightIndex.None) {
            this._highlightColors.set(highlightIndex, color);
        }
    }

    /**
     * Assigns effect values for the highlight colors. For internal use only.
     * @hidden
     * @internal
     * @param effect - The effect to assign values to.
     */
    public _setEffectValues(effect: Effect): void {
        const enums = [IfcMaterialHighlightIndex.One, IfcMaterialHighlightIndex.One, IfcMaterialHighlightIndex.Three];
        for (let i = 0; i < 3; i++) {
            const color = this._highlightColors.get(enums[i]);
            if (color) {
                effect.setColor3(HIGHLIGHT_COLORS + '[' + i + ']', color);
            } else {
                // telemetry.lo
            }
        }
    }

    public setClipPlane(clipPlaneOptions: ClipPlaneOptions): void {
        const clipPlane = this.clipPlanes[clipPlaneOptions.clipPlaneIndex];
        if (clipPlane) {
            const enabled = clipPlaneOptions.enabled;

            clipPlane.enabled = enabled;

            if (enabled) {
                if (
                    clipPlaneOptions.ignoreDepthWrite !== undefined &&
                    clipPlaneOptions.ignoreDepthWrite !== clipPlane.ignoreDepthWrite
                ) {
                    clipPlane.ignoreDepthWrite = clipPlaneOptions.ignoreDepthWrite;
                }
                const fadeSize = clipPlaneOptions.fadeSize;
                if (fadeSize !== undefined) {
                    if (fadeSize < 0.0) {
                        throw new Error('FadeSize must be equal to or larger than 0');
                    }
                }

                const point = clipPlaneOptions.point;
                const normalVector = clipPlaneOptions.normalVector;
                const color = clipPlaneOptions.color;

                const normalLen = Math.sqrt(
                    Math.pow(normalVector.x, 2.0) + Math.pow(normalVector.y, 2.0) + Math.pow(normalVector.z, 2.0)
                );

                if (normalLen > 0.0) {
                    if (normalLen !== 1.0) {
                        telemetry.trackTrace({
                            message: 'Normal was not normalized, values used from normal are normalized',
                            severityLevel: SeverityLevel.Warning
                        });
                    }

                    clipPlane.position.x = point.x;
                    clipPlane.position.y = point.y;
                    clipPlane.position.z = point.z;

                    clipPlane.normal.x = normalVector.x / normalLen;
                    clipPlane.normal.y = normalVector.y / normalLen;
                    clipPlane.normal.z = normalVector.z / normalLen;
                } else {
                    throw new Error('Normal not set');
                }

                if (color) {
                    clipPlane.color.r = color.r;
                    clipPlane.color.g = color.g;
                    clipPlane.color.b = color.b;
                }

                if (fadeSize !== undefined) {
                    clipPlane.fadeSize = fadeSize;
                }

                const nX = clipPlane.normal.x;
                const nY = clipPlane.normal.y;
                const nZ = clipPlane.normal.z;

                const pX = clipPlane.position.x;
                const pY = clipPlane.position.y;
                const pZ = clipPlane.position.z;

                const normalVec3 = new Vector3(nX, nY, nZ);
                const pointVec3 = new Vector3(pX, pY, pZ);

                const d = Vector3.Dot(normalVec3, pointVec3);

                clipPlane.plane.normal.set(nX, nY, nZ);
                clipPlane.plane.d = -d;
            }
        } else {
            throw new Error('Unknown clipplane index');
        }
    }
}

/**
 * Visual settings for {@link TwinfinityViewer}. Controls things like lighting, IFC object transparency etc
 */
export class VisualSettings {
    private readonly _lighting: IFCLightingEnvironment & LightingEnvironment;

    /**
     * Visual settings for IFC objects. These settings are applied to all IFC objects in the scene.
     */
    readonly ifc: IfcVisualSettings;

    // TODO: If we have dwg specific settings, we should add them here.

    /**
     * Constructor for the visual settings.
     * @param viewer {@link TwinfinityViewer} instance this {@link VisualSettings} instance is associated with.
     */
    constructor(viewer: TwinfinityViewer) {
        const directionalLightDirection = new Vector3(0.0, -1.0, 0.25).normalize();
        const directionalLight = new DirectionalLight(
            'default directional light',
            directionalLightDirection,
            viewer.scene
        );

        this._lighting = {
            directionalLight: directionalLight,
            hemisphericLight: new HemisphericLight('default hemispheric light', Vector3.Up(), viewer.scene),
            ambient: new Color3(0.5, 0.5, 0.5),
            hemisphericLightStrength: 0.5,
            directionalLightStrength: 0.35,
            directionalLightLambertStrength: 1.0,
            directionalLightSpecularStrength: 0.5
        };

        this._lighting.hemisphericLight.diffuse.set(0.85, 0.85, 0.85);
        this._lighting.hemisphericLight.groundColor = new Color3(0.45, 0.45, 0.45);

        this.ifc = new IfcVisualSettings(this._lighting, viewer);
    }

    /**
     * Current global lighting settings. These settings control
     * how the scene is lit and affects all objects (both IFC and standard BabylonJS objects).
     */
    public get lighting(): LightingEnvironment {
        return this._lighting;
    }
}
