import {
    Camera,
    Color4,
    LinesMesh,
    Mesh,
    Nullable,
    Observer,
    PointerEventTypes,
    PointerInfo,
    Ray,
    TransformNode,
    Vector3
} from '@babylonjs/core';
import {
    BimApi,
    ClipPlaneOptions,
    GeometryTools,
    PickOption,
    PickOptionRay,
    PickOptionType,
    TwinfinityCameraStateSnapshot
} from '@twinfinity/core';

enum ClippingPlaneToolType {
    Preview = 0,
    Instance = 1
}

class ClippingPlaneInterface {
    private _originMesh: Mesh;
    private _normalOrientationMesh: LinesMesh;
    private _planeMesh: Mesh;

    private readonly _length = 3.0;

    private setPlaneMeshOrientation(normal: Vector3): void {
        // const direction = normal.cross(new Vector3(0.0, 1.0, 0.0));
        this._planeMesh.setDirection(normal, 0.0, Math.PI * 0.5);
    }

    public constructor(
        private readonly _geometryTool: GeometryTools,
        private readonly _parent: TransformNode,
        public readonly type: ClippingPlaneToolType,
        public readonly center: Vector3,
        public readonly normal: Vector3,
        private readonly _api: BimApi,
        private _clippingPlaneIndex: number,
        private readonly _color: Color4 // public readonly onLabelUpdate?: LabelUpdateHandler
    ) {
        const id = _clippingPlaneIndex.toString();

        const size = length;

        if (type === ClippingPlaneToolType.Instance) {
            this._originMesh = _geometryTool.createStartDisc(`${id}_origin`, center, normal, size, 8);
            this._originMesh.setParent(this._parent);
        } else if (type === ClippingPlaneToolType.Preview) {
            this._originMesh = _geometryTool.createSphere(`${id}_origin`, center, 0.1);
            this._originMesh.useVertexColors = true;
            this._originMesh.setParent(this._parent);
        } else {
            throw new Error('Not implemented');
        }
        const planeMeshPosition = new Vector3();
        planeMeshPosition.copyFrom(center);
        this._planeMesh = _geometryTool.createStartDisc(`${id}_origin`, planeMeshPosition, normal, 30.0, 4);
        this.setPlaneMeshOrientation(normal);

        this._planeMesh.isPickable = false;
        this._planeMesh.setEnabled(false);
        this._originMesh.isPickable = false;
        this._originMesh.setEnabled(false);
        this._normalOrientationMesh = _geometryTool.createLine(`${id}_normalOrientation`, this.start, this.end, _color);
        this._normalOrientationMesh.alwaysSelectAsActiveMesh = true;
        this._normalOrientationMesh.setEnabled(false);
        this._normalOrientationMesh.isPickable = false;
        this._normalOrientationMesh.setParent(this._parent);
    }

    public scroll(scrollage: number): void {
        const clipPlanePosition = this.start.subtract(this.normal.scale(scrollage));

        this.updatePlaneMesh(clipPlanePosition);

        const enableOption: ClipPlaneOptions = {
            clipPlaneIndex: 'clipPlane',
            enabled: true,
            point: clipPlanePosition,
            normalVector: this.normal
        };
        this._api.viewer.setClipPlane(enableOption);
    }

    public get start(): Vector3 {
        return this.center;
    }

    public get end(): Vector3 {
        return this.center.add(this.normal.scale(this._length));
    }

    public get length(): number {
        return this._length;
    }

    /**
     * If `true` then the clipping tool is enabled.
     */
    public get isVisible(): boolean {
        return this._originMesh.isEnabled();
    }

    /**
     * If `false` then the clipping tool is not visible.
     */
    public set isVisible(val: boolean) {
        this._originMesh.setEnabled(val);
        this._normalOrientationMesh.setEnabled(val);
        this._planeMesh.setEnabled(val);
    }

    public deactivate(): void {
        this.isVisible = false;
        const disableOption: ClipPlaneOptions = {
            clipPlaneIndex: 'clipPlane',
            enabled: false
        };

        this._api.viewer.setClipPlane(disableOption);
    }

    /**
     * `false` when clipping tool is not visible, `true` otherwise.
     */
    public get isClippingToolVisible(): boolean {
        return this._normalOrientationMesh.isEnabled();
    }

    public update(camera: Camera, pO: PickOption): void {
        this.isVisible = false; // Nothing starts out as visible.
        const pointHits = camera.twinfinity.pick(pO).hitInfo;
        const isClippingPlaneOriginVisible = pointHits.length > 0;

        this.center.setAll(0);
        this.normal.setAll(0);

        if (isClippingPlaneOriginVisible) {
            const firstHit = pointHits[0];
            this.center.copyFrom(firstHit.position);
            this.normal.copyFrom(firstHit.normal);

            this._originMesh.position.copyFrom(this.start);
            this._originMesh.overlayColor.g = 1;
            this._originMesh.overlayColor.b = 0;

            this._originMesh.setEnabled(true); // Show at least part of the laser

            this._normalOrientationMesh.setEnabled(true);
            this._geometryTool.updateLine(
                this._normalOrientationMesh,
                this.start,
                this.end,
                new Color4(1.0, 1.0, 0.0, 1.0)
            );
            this._normalOrientationMesh.setEnabled(true);
            this._planeMesh.setEnabled(true);
            this.updatePlaneMesh(this.start);
            this.setPlaneMeshOrientation(firstHit.normal);
        } else {
            this._originMesh.overlayColor.g = 0;
            this._originMesh.overlayColor.b = 1;
            this.isVisible = false; // Do not show visualization for a clipping plane with no origin
        }
    }

    public dispose(): void {
        this._originMesh.dispose();
        this._normalOrientationMesh.dispose();
    }

    public updatePlaneMesh(position: Vector3): void {
        this._planeMesh.position.copyFrom(position);
    }
}

/**
 * Implementation showing how clipping planes can be used
 */
export class ClippingPlaneTool {
    private static readonly _tmpPickOptionRay: PickOptionRay = {
        type: PickOptionType.Ray,
        ray: new Ray(Vector3.Zero(), Vector3.Zero(), 0)
    };

    private _lockedDown = false;
    private _active = false;
    private _currentScroll = 0.0;
    private _geomTool: GeometryTools;
    private _pointerMoveObserver: Nullable<Observer<PointerInfo>> = null;
    private _clippingPlaneInterfacePreview: ClippingPlaneInterface;
    private _clippingPlaneToolRootNode: TransformNode;

    public constructor(private readonly _api: BimApi) {
        this._geomTool = new GeometryTools(_api.viewer.scene);

        this._clippingPlaneToolRootNode = new TransformNode(`clippingPlaneToolRoot`, _api.viewer.scene);

        this._clippingPlaneInterfacePreview = new ClippingPlaneInterface(
            this._geomTool,
            this._clippingPlaneToolRootNode,
            ClippingPlaneToolType.Preview,
            Vector3.Zero(),
            Vector3.Zero(),
            _api,
            1,
            new Color4(1.0, 0.0, 0.0, 1.0)
        );
    }

    /**
     * `true` If clipping plane tool is activated
     */
    public get isEnabled(): boolean {
        return this._active;
    }

    /**
     * Set to `true` to activate clipping plane tool. Set to `false` to deactivate
     * and and remove all current clipping planes.
     */
    public set isEnabled(v: boolean) {
        if (v) {
            this._active = true;
            this.activateClippingPlanePreview();
        } else {
            this._active = false;
            // this._clippingPlaneInterfacePreview.clear();
        }
    }

    /**
     * Creates and updates clipping plane preview on canvas upon mouse move,
     * by means of {@link createOrUpdateClippingPlanePreview}.
     */
    public activateClippingPlanePreview(): void {
        this._clippingPlaneInterfacePreview.isVisible = false;
        let cameraStateSnapShot: TwinfinityCameraStateSnapshot | undefined = undefined;
        this._pointerMoveObserver = this._api.viewer.scene.onPointerObservable.add((eD, eS) => {
            if (!this._lockedDown) {
                const camera = this._api.viewer.scene.activeCamera;
                const mouseRay = eD.pickInfo?.ray;
                if (!camera || !mouseRay) {
                    return;
                }

                if (!cameraStateSnapShot) {
                    cameraStateSnapShot = camera.twinfinity.stateSnapshot();
                }

                // Only perform _clippingPlaneToolPreview.update() when the camera is stationary
                // internal pickoperations creates laggy fps if executed while camera is actually
                // moving.
                if (cameraStateSnapShot.refresh()) {
                    return;
                }

                const pickOption = ClippingPlaneTool._tmpPickOptionRay;
                pickOption.ray = mouseRay;
                this._clippingPlaneInterfacePreview.update(camera, pickOption);
            }
        }, PointerEventTypes.POINTERMOVE);
        this._api.viewer.wakeRenderLoop();
    }

    /**
     * Deactivates and removes clipping plane preview on canvas upon mouse move.
     */
    public deactivateClippingPlanePreview(): void {
        this._clippingPlaneInterfacePreview.deactivate();

        if (this._pointerMoveObserver) {
            this._api.viewer.scene.onPointerObservable.remove(this._pointerMoveObserver);
            this._pointerMoveObserver = null;
            this._api.viewer.wakeRenderLoop();
        }
    }

    /**
     * Enables and moves the current preview scrolling plane along the normal of the preview position
     *
     * @param scrollage How much to move the preview clipping plane along the normal with
     */
    public scrollPreviewClippingPlane(scrollage: number): void {
        if (this._lockedDown) {
            this._currentScroll -= scrollage / 100.0;

            this._clippingPlaneInterfacePreview.scroll(this._currentScroll);
        }
    }

    public setLockDown(lockDown: boolean): void {
        this._lockedDown = lockDown;

        // Disable camera zooming when locked down so as to not drive the user crazy
        // Also stop the preview from moving while CTRL is held down
        if (this._lockedDown) {
            this._api.viewer.camera.options.isZoomEnabled = false;
        } else {
            this._api.viewer.camera.options.isZoomEnabled = true;
            this._currentScroll = 0.0;
        }
    }
}
