import { Http, HttpResponseType, TypedResponse } from '../../http';
import { DeleteUserSetting, UpsertUserSetting, UserSettingListResults } from './UserSetting';
import { UserSettingApCLient } from './UserSettingApCLient';

export class BimUserSettingsApiClient implements UserSettingApCLient {
    public constructor(readonly _baseApiUrl: URL | string) {}

    public async get<T = unknown>(
        filePath: string,
        maxLevels?: number
    ): Promise<TypedResponse<UserSettingListResults<T>>> {
        const requestHeaders: HeadersInit = new Headers();

        const res = await Http.get<UserSettingListResults<T>>(
            `${this._baseApiUrl}/_ps/api/settings/user/${filePath}?level=${maxLevels ?? 0}`,
            HttpResponseType.json,
            { headers: requestHeaders }
        );

        return res;
    }

    public upsert<T>(upsertUserSettings: UpsertUserSetting<T>): Promise<Response> {
        const res = Http.put(
            `${this._baseApiUrl}/_ps/api/settings/user/${upsertUserSettings.path}`,
            HttpResponseType.json,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(upsertUserSettings)
            }
        );

        return res;
    }

    public delete(deleteUserSetting: DeleteUserSetting): Promise<Response> {
        const res = Http.delete(
            `${this._baseApiUrl}/_ps/api/settings/user/${deleteUserSetting.path}?etag=${deleteUserSetting.etag}`,
            HttpResponseType.json
        );

        return res;
    }
}
