import { TextObjectLayerEditor } from './TextObjectLayerEditor';
import {
    MergableObject,
    MergableObjectEventSource,
    MergableObjectConflict,
    MergableObjectEventArgs,
    MergableObjectState
} from '@twinfinity/core';

const tblTextObjects = document.getElementById('lstLayerObjects') as HTMLTableElement;

/**
 * Represents a object that can be stored (togheter with other such objects) in the `textobject` layer.
 * See also {@link TextObjectLayerEditor} which is responsible for storing/loading objects in the `textobject` layer.
 */
export class TextObject implements MergableObject<TextObject> {
    private _row?: HTMLTableRowElement;
    private _textInput?: HTMLInputElement;
    private _conflict?: HTMLTableDataCellElement;

    public constructor(
        private readonly _textObjectLayerEditor: TextObjectLayerEditor,
        public readonly id: string,
        public text: string
    ) {}
    onAdded(o: MergableObjectEventArgs<TextObject>): void {
        console.log('added', o);
        this.updateUI(o);
    }
    onUpdate(o: MergableObjectEventArgs<TextObject>): void {
        if (o.eventSource === MergableObjectEventSource.Local) {
            console.log('updated', o);
            this.text = o.local.text;
        } else {
            if (!o.conflictReason) {
                this.text = o.remote?.text ?? 'DELETED'; // DELETED should be impossible
            }
        }
        this.updateUI(o);
    }
    onDelete(o: MergableObjectEventArgs<TextObject>): void {
        console.log('deleted', o);

        if (this._row) {
            if (o.eventSource === MergableObjectEventSource.Local || !o.conflictReason) {
                this._row.remove();
                this._row = undefined;
                this._textInput = undefined;
                this._conflict = undefined;
                this.text = '';
            } else {
                this.updateUI(o);
            }
        }
    }
    isEqual(o: TextObject): boolean {
        return o.id === this.id && o.text === this.text;
    }

    public clone(): TextObject {
        return new TextObject(this._textObjectLayerEditor, this.id, this.text);
    }

    public updateUIState(state: MergableObjectState, conflict: MergableObjectConflict, remoteText?: string): void {
        if (!this._textInput) {
            return;
        }
        this._conflict!.textContent = null;
        this._textInput.classList.remove('modified', 'conflict', 'added');
        this._textInput!.value = this.text;
        if (conflict === MergableObjectConflict.RemoteDeleted) {
            this._textInput!.classList.add('conflict');
            this._conflict!.textContent = 'Remote deleted';
        } else if (conflict === MergableObjectConflict.RemoteDiffers) {
            this._textInput!.classList.add('conflict');
            this._conflict!.textContent = remoteText ?? 'NULL';
        } else if (state === MergableObjectState.Added) {
            this._textInput!.classList.add('added');
        } else if (state === MergableObjectState.Modified) {
            this._textInput!.classList.add('modified');
        }
    }

    private updateUI(o: MergableObjectEventArgs<TextObject>): void {
        //tblTextObjects.
        if (!this._row) {
            this._row = tblTextObjects.insertRow(-1);
            const del = this._row.insertCell();
            const delHref = document.createElement('a');
            delHref.textContent = 'x';
            delHref.classList.add('fake-link');
            delHref.addEventListener('click', (e) => {
                this._textObjectLayerEditor.layer?.delete(this);
            });
            del.appendChild(delHref);
            const id = this._row.insertCell();
            id.textContent = this.id;

            const text = this._row.insertCell();
            this._textInput = document.createElement('input');
            this._textInput.type = 'text';

            this._textInput.addEventListener('blur', (ev) => {
                // Commit text change when input loses focus.
                const e = this._textObjectLayerEditor.layer?.get(this.id);
                if (e !== undefined && (e.hasConflict || this.text !== this._textInput?.value)) {
                    this._textObjectLayerEditor.layer?.update(this.id, (o) => (o.text = this._textInput?.value ?? ''));
                    const tmp = this._textObjectLayerEditor.layer?.get(this.id);
                    if (tmp !== undefined) {
                        this.updateUIState(tmp.state, tmp.conflictReason);
                    }
                }
            });
            text.appendChild(this._textInput);

            this._conflict = this._row.insertCell();
        }

        if (o.eventSource === MergableObjectEventSource.Remote && o.conflictReason) {
            this.updateUIState(o.state, o.conflictReason, o.remote?.text);
        } else {
            this.updateUIState(o.state, MergableObjectConflict.None);
        }
    }
}
