import { BimChangeLayer } from '../loader/bim-api-client';
import { TypedResponse } from '../http';
import { Access, Permission } from '../loader/Permission';
import {
    BimChangeUploadable,
    UploadApiClientFailure,
    UploadApiTask,
    UploadClientOperationAppendFileVersion,
    UploadClientOperationCreate
} from '../loader/client/UploadApiClient';
import { PredefinedBimChangeMetadataQuery } from '../loader/PredefinedBimChangeMetadataQuery';
import { TwinfinityApiClient } from '../loader/client/TwinfinityApiClient';
import { path } from '../Path';

/**
 * Represents the Layer Attachments API.
 * Contains methods to work with layer attachments for a specific layer.
 * @alpha This class is not fully tested yet. Use with caution.
 */
export class LayerAttachmentsApi {
    /**
     * Creates a new instance of the LayerAttachmentsApiImpl class.
     * @param _clientApi The TwinfinityApiClient instance.
     * @param _parent The parent object containing the layer and permission-related methods.
     */
    public constructor(
        private readonly _clientApi: TwinfinityApiClient,
        private readonly _parent: {
            layer: BimChangeLayer;
            throwIfNoPermissionsAreSatisfied(...requiredPermissions: Permission[]): void;
        }
    ) {}

    /**
     * Adds a new attachment to the layer.
     * @param options The options for creating the attachment.
     * @returns A promise that resolves to the created attachment or an error.
     */
    public async add<Change extends BimChangeUploadable = BimChangeUploadable>(
        options: Pick<UploadClientOperationCreate, 'metadata' | 'content' | 'parent' | 'filename'>
    ): Promise<UploadApiClientFailure | UploadApiTask<Change>> {
        this._parent.throwIfNoPermissionsAreSatisfied(Access.add);
        return this._clientApi.upload.createFileSession({
            operation: 'create',
            ...options
        });
    }

    /**
     * Checks if the given change is an attachment of the layer.
     * @param change The change to check.
     * @returns True if the change is an attachment of the layer, false otherwise.
     */
    public isAttachment(change: BimChangeUploadable): boolean {
        // Attachmenst are changes with a layer as a parent (instead of a folder).
        return path(change.path)?.parent?.equals(this._parent.layer.path) === true;
    }

    /**
     * Determines whether the caller has sufficient permissions to add attachments to the layer.
     * @returns True if attachments can be added to the layer, false otherwise.
     */
    public canAdd(): boolean {
        const layer = this._parent.layer;
        return this._clientApi.upload.canCreateFileIn(layer);
    }

    /**
     * Determines whether the caller has sufficient permissions to update a attachment (create a new version of it) on the layer.
     * @param attachment The attachment to check.
     * @returns True if the attachment can be updated (new version created), false otherwise.
     */
    public canUpdate(attachment: BimChangeUploadable): boolean {
        return this._clientApi.upload.canAppendFileVersion(attachment);
    }

    /**
     * Retrieves all attachments of the layer (may be none).
     * @param options The options for getting the attachments.
     * @returns List of attachments.
     */
    public async get<Change extends BimChangeUploadable = BimChangeUploadable>(): Promise<TypedResponse<Change[]>> {
        const layer = this._parent.layer;
        return this._clientApi.getChanges<Change>(layer, PredefinedBimChangeMetadataQuery.all());
    }

    /**
     * Updates an existing attachment with a new version.
     * @param attachment The attachment to update.
     * @param options The options for updating the attachment.
     * @returns A promise that resolves to the updated attachment or an error.
     */
    public async update<Change extends BimChangeUploadable = BimChangeUploadable>(
        attachment: Change,
        options: Pick<UploadClientOperationAppendFileVersion<Change>, 'metadata' | 'content'>
    ): Promise<UploadApiClientFailure | UploadApiTask<Change>> {
        this._parent.throwIfNoPermissionsAreSatisfied(Access.edit);
        this.throwIfAttachmentDoesNotBelongToLayer(attachment);
        return this._clientApi.upload.createFileSession<Change>({
            operation: 'append',
            previousVersion: attachment,
            ...options
        });
    }

    /**
     * Deletes the specified attachments from the layer.
     * @param changes The attachments to delete.
     * @returns A promise that resolves to the number of deleted attachments or an error.
     */
    public delete(changes: BimChangeUploadable[]): Promise<TypedResponse<number>[]> {
        changes.forEach((attachment) => this.throwIfAttachmentDoesNotBelongToLayer(attachment));
        return this._clientApi.deleteChanges(changes);
    }

    /**
     * Throws an error if the attachment does not belong to the layer.
     * @param attachment The attachment to check.
     */
    private throwIfAttachmentDoesNotBelongToLayer(attachment: BimChangeUploadable): void {
        if (!this.isAttachment(attachment))
            throw new Error(`Change ${attachment.path} does not belong to layer ${this._parent.layer.path}.`);
    }
}
