/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */
import { Texture } from '@babylonjs/core/Materials/Textures/texture';
import { DynamicTexture } from '@babylonjs/core/Materials/Textures/dynamicTexture';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { BimCoreApi } from '@twinfinity/core';
import { Nullable } from '@babylonjs/core/types';
import { SvgContent } from './SvgContent';
import { BimPdf } from './BimPdf';
import { BimDrawingPlane } from './BimDrawingPlane';
import { BimPdfParent } from './BimPdfParent';
// TODO Perhaps put this in embedded since it is a useful
// class. Would be nice to be able to put a limit on how many objects
// there can be in the pool and make get() blocking (perhaps possible with Promises)
// until objects are available
class ObjectPool<T> {
    private readonly _pool: T[] = [];
    private readonly _inUse = new Set<T>();
    public constructor(private readonly _objectFactory: () => T) {}

    public get(): T {
        const ret = this._pool.pop() ?? this._objectFactory();
        this._inUse.add(ret);
        return ret;
    }

    public release(o: T): void {
        if (this._inUse.has(o)) {
            this._inUse.delete(o);
            this._pool.push(o);
        }
    }
}

//TODO: Comment magic numbers (4096*4096 etc).
export class SvgTexture {
    private readonly _canvasPool: ObjectPool<{
        canvas: HTMLCanvasElement;
        context: CanvasRenderingContext2D;
    }>;

    private readonly _imgPool: ObjectPool<HTMLImageElement>;

    constructor(private readonly _api: BimCoreApi, private readonly _size: number) {
        this._canvasPool = new ObjectPool<{
            canvas: HTMLCanvasElement;
            context: CanvasRenderingContext2D;
        }>(() => {
            const canvas = document.createElement('canvas');
            canvas.width = this._size;
            canvas.height = this._size;
            const context = canvas.getContext('2d') as CanvasRenderingContext2D;
            context.imageSmoothingEnabled = false;
            context.imageSmoothingQuality = 'high';
            context.msImageSmoothingEnabled = false;
            return { canvas, context };
        });

        this._imgPool = new ObjectPool<HTMLImageElement>(() => {
            const ret = new Image(this._size, this._size);
            ret.style.position = 'absolute';
            ret.style.transform = 'translatez(o)';
            return ret;
        });
    }

    public async renderSvgToRef<T extends Texture>(svg: SvgContent, texture: T): Promise<T> {
        const img = this._imgPool.get();
        const blob = new Blob([svg.start, svg.header, svg.content, ...svg.addedSvgs, svg.closingHeader], {
            type: 'image/svg+xml'
        });
        const blobUrl = URL.createObjectURL(blob);
        await this.loadImage(img, blobUrl);
        if (texture instanceof DynamicTexture) {
            const ctx = texture.getContext();
            // ctx.canvas.style.transform = 'translatez(o)';
            // ctx.canvas.style.position = 'absolute';
            ctx.clearRect(0, 0, this._size, this._size);
            ctx.drawImage(img, 0, 0, this._size, this._size);
            texture.update();
        } else if (texture instanceof Texture) {
            await this.updateTexture(texture, blobUrl, img);
        }
        URL.revokeObjectURL(blobUrl);
        this._api.viewer.wakeRenderLoop();
        this._imgPool.release(img);
        img.src = '';
        return texture;
    }

    //this should be moved to bimpdf
    async renderPageOfPdf(bimPdf: BimPdf): Promise<void> {
        bimPdf.renderPageNextZoom = false;
        if (bimPdf.blobUrl) {
            URL.revokeObjectURL(bimPdf.blobUrl);
        }
        if (bimPdf.img) {
            this._imgPool.release(bimPdf.img);
        }
        const pdfjsPage = await bimPdf.document.getPage(await bimPdf.page());

        const userAgent = navigator.userAgent || '';
        const platform = navigator.platform || '';
        const maxTouchPoints = navigator.maxTouchPoints || 1;

        const isAndroid = /Android/.test(userAgent);
        const isIOS = /\b(iPad|iPhone|iPod)(?=;)/.test(userAgent) || (platform === 'MacIntel' && maxTouchPoints > 1);

        let maxSize = (16384 * 16384) / 4; //16384*16384 is the max canvas size for chrome, we divide it by four because it takes to much memory to render it in max scale.
        if (isIOS || isAndroid) {
            maxSize = 4096 * 4096;
        }

        const defualtViewPort = pdfjsPage.getViewport({ scale: 1, dontFlip: false });
        const maxScale = Math.sqrt(maxSize / (defualtViewPort.width * defualtViewPort.height));
        const viewport = pdfjsPage.getViewport({ scale: maxScale, dontFlip: false });

        const context = bimPdf.canvas.getContext('2d') as CanvasRenderingContext2D;
        bimPdf.canvas.width = viewport.width;
        bimPdf.canvas.height = viewport.height;

        const renderTask = pdfjsPage.render({
            canvasContext: context,
            viewport: viewport,
            background: bimPdf.backgroundColor.toHexString()
        });
        await renderTask.promise;
        bimPdf.blobUrl = bimPdf.canvas.toDataURL();
        bimPdf.img = this._imgPool.get();
        bimPdf.img.width = viewport.width;
        bimPdf.img.height = viewport.height;
        await this.loadImage(bimPdf.img, bimPdf.blobUrl);
    }

    /**
     *
     * @param texture Texture that the PDF will be rendered to.
     * @param bimPdf The PDF to be rendered.
     * @param plane The drawing will be rendered on.
     * @param frustumCornerPointsOnZoomPlane The corner points of the camera view frustrum on the mathematical plane of the BimDrawingPlane.
     */
    public async renderPartOfPDFToRef(
        texture: DynamicTexture,
        bimPdf: BimPdf,
        plane: BimDrawingPlane,
        frustumCornerPointsOnZoomPlane: Vector3[]
    ): Promise<void> {
        if (bimPdf.renderPageNextZoom) {
            await this.renderPageOfPdf(bimPdf);
        }
        const ctx = texture.getContext();
        if (!ctx) {
            return;
        }

        ctx.fillStyle = this._api.viewer.scene.clearColor.toHexString();
        ctx.fillRect(0, 0, this._size, this._size);

        const drawImgCoords = (bimPdf.parent as BimPdfParent).calculatePDFDrawImageCoords(
            texture,
            bimPdf,
            plane,
            frustumCornerPointsOnZoomPlane
        );

        if (!drawImgCoords || !bimPdf.img) {
            return;
        }

        ctx.drawImage(
            bimPdf.img,
            drawImgCoords.sx, // Left side cut off point.
            drawImgCoords.sy, // Top cut off point.
            drawImgCoords.sWidth, // Right cut off point.
            drawImgCoords.sHeight, // Bottom cut off point.
            drawImgCoords.dx, //Left edge of pdf, 0 if left edge is not visible.
            drawImgCoords.dy, //Top edge of pdf, 0 if top edge is not visible.
            drawImgCoords.dWidth, //Right edge of pdf
            drawImgCoords.dHeight //Bottom edge of pdf
        );

        texture.update();
        this._api.viewer.wakeRenderLoop();
    }

    private loadImage(img: HTMLImageElement, src: string): Promise<HTMLImageElement> {
        const p = new Promise<HTMLImageElement>((resolve, reject) => {
            img.onerror = (error) => {
                reject(error);
            };
            img.onload = () => {
                resolve(img);
            };
        });

        img.src = src;
        return p;
    }

    private updateTexture(
        texture: Texture,
        url: string,
        buffer: Nullable<string | ArrayBuffer | ArrayBufferView | HTMLImageElement | Blob>
    ): Promise<Texture> {
        return new Promise<Texture>((resolve) => {
            texture.updateURL(url, buffer, () => {
                resolve(texture);
            });
        });
    }
}
