/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */
export {};
declare global {
    export interface Map<K, V> {
        /**
         * If key exists returns the existing value. Otherwise factory is called and the resulting value is added
         * to the key.
         * @param key Key to check for value
         * @param factory Factory which creates value if key does not exist in map.
         * @returns value for key
         */
        getOrAdd(key: K, factory: (key: K) => V): V;
    }
}

/**
 * If key exists returns the existing value. Otherwise factory is called and the resulting value is added
 * to the key.
 * @param key Key to check for value
 * @param factory Factory which creates value if key does not exist in map.
 * @returns value for key
 */
Map.prototype.getOrAdd = function <K, V>(key: K, factory: (key: K) => V): V {
    let val = this.get(key);
    if (val === undefined) {
        val = factory(key);
        this.set(key, val);
    }
    return val;
};
