/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { BimIfcStyleDTO } from './bim-format-types';
import { Color4, Color3 } from './babylonjs-import';
export class BimIfcStyle implements BimIfcStyleDTO {
    public readonly color: ArrayLike<number>;
    public readonly color4: Color4;
    public readonly color3: Color3;

    public constructor(private readonly _style: BimIfcStyleDTO) {
        this.color = new Uint8Array([_style.r, _style.g, _style.b, _style.a]);
        this.color4 = Color4.FromArray(this.color);
        this.color3 = Color3.FromArray(this.color);
    }

    public get r(): number {
        return this._style.r;
    }
    public get g(): number {
        return this._style.g;
    }
    public get b(): number {
        return this._style.b;
    }
    public get a(): number {
        return this._style.a;
    }

    public get diffuseFactor(): number {
        return this._style.diffuseFactor;
    }
    public get diffuseTransmissionFactor(): number {
        return this._style.diffuseTransmissionFactor;
    }
    public get hash(): string {
        return this._style.hash;
    }
    public get name(): string {
        return this._style.name;
    }
    public get reflectionFactor(): number {
        return this._style.reflectionFactor;
    }
    public get specularFactor(): number {
        return this._style.specularFactor;
    }
    public get transmissionFactor(): number {
        return this._style.transmissionFactor;
    }
}
