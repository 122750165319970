import { BimChangeDwg, BimCoreApi } from '@twinfinity/core';
import { BimDrawingObject } from './BimDrawingObject';
import { BimSvg } from './BimSvg';
import { SvgEditor } from './SvgEditor';

export class BimDrawingDwg extends BimDrawingObject {
    constructor(
        api: BimCoreApi,
        private readonly _drawingSvg: BimSvg,
        drawingChange: BimChangeDwg,
        onDisposed: (o: BimDrawingObject) => void
    ) {
        super(api, _drawingSvg, drawingChange, onDisposed);
    }

    public get drawing(): BimSvg {
        return this._drawingSvg;
    }

    /**
     * Set if a DWG should render as grayscale or not.
     * @param grayscale `true` if you wish to render as grayscale, otherwise `false`
     */
    public setGrayscale(grayscale: boolean): void {
        SvgEditor.setGrayscale(this._drawingSvg, grayscale);
    }

    /**
     * Set if a DWG should have inverted background color.
     * @param invert `true` if you wish to render with inverted background color, otherwise `false`
     */
    public invertBackgroundColor(invert: boolean): void {
        SvgEditor.invertBackgroundColor(this._drawingSvg, invert);
    }
}
