/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { telemetry } from '../Telemetry';
import { TypedArray } from './bim-format-types';

export interface TypedArrayConstructorArgs<T> {
    new (buffer: ArrayBufferLike, offsetInBytes: number, length: number): T;
    BYTES_PER_ELEMENT: number;
}

/**
 * Represent a block of memory from which it is possible to allocate chunks of memory (typed arrays)
 */
export class MemoryBlock<T extends TypedArray> {
    private _typedArray?: T;

    public length = 0;

    /**
     * Current offset where next call to {@link subarray} will allocate memory.
     */
    public offset = 0;

    public constructor(public readonly id: string, private readonly _ctor: TypedArrayConstructorArgs<T>) {}

    public get byteLength(): number {
        return this.length * this._ctor.BYTES_PER_ELEMENT;
    }

    public hasSpace(length: number): boolean {
        return this.offset + length <= this.length;
    }

    public subarray(length: number): T {
        if (this.length === 0) throw new Error('0 bytes in memory block. Call expand()');

        const buffer = this.ensureBufferIsAllocated(this.length);
        if (!this.hasSpace(length)) {
            telemetry.trackTrace(
                { message: 'Out of memory', severityLevel: SeverityLevel.Critical },
                {
                    id: this.id,
                    type: this._ctor.name,
                    overshoot: this.offset + length - this.length,
                    offset: this.offset,
                    length: length,
                    totalLength: this.length
                }
            );
            throw new Error('Out of memory'); // We have run out of memory in the block
        }

        // Create typed array that is a slice of the memory block (array buffer);
        const ret = buffer.subarray(this.offset, this.offset + length) as T;
        this.offset += length;
        return ret;
    }

    public subView<T extends TypedArray>(length: number, _ctor: TypedArrayConstructorArgs<T>): T {
        const lengthInOriginalUnits = Math.ceil((_ctor.BYTES_PER_ELEMENT * length) / this._ctor.BYTES_PER_ELEMENT);
        const tmp = this.subarray(lengthInOriginalUnits);
        return new _ctor(tmp.buffer, tmp.byteOffset, length);
    }

    public free(): void {
        this._typedArray = undefined;
        this.length = 0;
        this.offset = 0;
    }

    private ensureBufferIsAllocated(length: number): T {
        if (this._typedArray) return this._typedArray;

        const byteLength = length * this._ctor.BYTES_PER_ELEMENT;
        this._typedArray = new this._ctor(new ArrayBuffer(byteLength), 0, length);

        telemetry.trackEvent(
            { name: `malloc` },
            {
                id: this.id,
                type: this._ctor.name,
                length: length,
                byteLength: this._typedArray.byteLength,
                mbLength: Math.round((this._typedArray.byteLength * 100.0) / (1024.0 * 1024.0)) / 100.0
            }
        );
        return this._typedArray;
    }
}
