import { BimChangeBase } from './bim-api-client';

export class OwnerSystem {
    public static readonly twinfinity = new OwnerSystem('twinfinity');

    public constructor(readonly id: string) {}

    public static isDeletable(o: Pick<BimChangeBase, 'ownerSystem'> | string): boolean {
        return typeof o === 'string' ? o === OwnerSystem.twinfinity.id : o.ownerSystem === OwnerSystem.twinfinity.id;
    }
}
