import { Camera, Effect, PostProcess } from '../loader/babylonjs-import';
import { TwinfinityPostProcess } from './TwinfinityPostProcess';

const SHADER_NAME_PREFIX = 'ssao_compose';
const SCREEN_SAMPLER = 'sceneSampler';
const AO_SAMPLER = 'aoSampler';

/**
 * SSAOApplier is the class that modulates the screen buffer with the calculated SSAO (which means that even directly lit areas are modulated, which is not correct but looks kind of nice anyways)
 * The variable called blittedscreen is a postprocess that just writes the current scene to a texture buffer
 */
export class SSAOApplier extends TwinfinityPostProcess {
    private static readonly _fragmentShaderSource = `#ifdef GL_ES
        precision highp float;
    #endif
    varying vec2 vUV;

    // Samplers
    uniform sampler2D ${SCREEN_SAMPLER};
    uniform sampler2D ${AO_SAMPLER};
    
    void main(void)
    {
        vec2 sampleUV = vUV;

        vec4 screenFragment = texture2D(${SCREEN_SAMPLER}, sampleUV);
        float ambientOcclusion = texture2D(${AO_SAMPLER}, sampleUV).r;

        // The ambient occlusion holds the value of the actual occlusion, not reverse
        gl_FragColor = vec4(vec3(screenFragment.xyz) * (1.0 - ambientOcclusion), screenFragment.w);
    }`;

    private _blitedScreen: PostProcess;
    private _renderScale: number;

    constructor(blitedScreen: PostProcess, renderScale: number) {
        super();
        this._blitedScreen = blitedScreen;
        this._renderScale = renderScale;
    }

    protected initialize(camera: Camera): PostProcess {
        const engine = camera.getEngine();
        const defines = new Array<string>();

        if (this._postProcess === undefined) {
            Effect.ShadersStore[SHADER_NAME_PREFIX + 'FragmentShader'] = SSAOApplier._fragmentShaderSource;

            const uniforms: string[] = [];

            const newPostProcess = new PostProcess(
                'SSAO_composer',
                SHADER_NAME_PREFIX,
                uniforms,
                [SCREEN_SAMPLER, AO_SAMPLER],
                this._renderScale,
                null,
                undefined,
                engine,
                false,
                defines.join('\n')
            );

            const viewer = camera.getScene().twinfinity.viewer;
            if (viewer) {
                newPostProcess.onApplyObservable.add((effect) => {
                    effect.setTextureFromPostProcess(SCREEN_SAMPLER, this._blitedScreen);
                    effect.setTextureFromPostProcess(AO_SAMPLER, newPostProcess); // Babylon is trippy in the way that this call will set the texture to the current screen as it was BEFORE newPostProcess is applied
                });
            } else {
                throw new Error('No viewer created?');
            }

            return newPostProcess;
        } else return this._postProcess;
    }
}
