import { AbstractMesh, IShaderMaterialOptions, Scene, ShaderMaterial, SubMesh, Vector4 } from '../babylonjs-import';
import { ClipPlane } from '../ClipPlane';
import { LazyResizableRawTexture } from '../LazyResizableRawTexture';
import { REQUIRES_WORLD_FRAGMENT } from '../ShaderSnippets';
import { VisualSettings } from '../VisualSettings';

class ClipPlanesStrings {
    public readonly MAX_CLIP_PLANES = 6;
    public readonly CLIP_PLANES: string[] = [];
    public readonly CLIP_PLANES_COLORS: string[] = [];
    public readonly CLIP_PLANES_FADE_SIZES: string[] = [];
    public readonly CLIP_PLANE_UNIFORMS: string = '';
    public readonly CLIP_PLANE_DISCARDING: string = '';
    public readonly CLIP_PLANE_COLOR_BLENDING: string = '';
    public readonly CLIP_PLANE_DEFINES: string[] = [];

    constructor() {
        for (let i = 1; i <= this.MAX_CLIP_PLANES; i++) {
            this.CLIP_PLANE_DEFINES[i - 1] = `CLIPPLANE${i}`;

            this.CLIP_PLANES.push('clipPlane' + i);
            this.CLIP_PLANES_COLORS.push('clipPlaneColor' + i);
            this.CLIP_PLANES_FADE_SIZES.push('clipPlaneFadeSize' + i);

            this.CLIP_PLANE_UNIFORMS += `#ifdef ${this.CLIP_PLANE_DEFINES[i - 1]}
                uniform vec4 clipPlane${i};
                uniform float clipPlaneFadeSize${i};
                #ifdef ${COLOR_BLENDING_ENABLED}
                    uniform vec3 clipPlaneColor${i};
                #endif
            #endif\n`;

            this.CLIP_PLANE_DISCARDING += `#ifdef ${this.CLIP_PLANE_DEFINES[i - 1]}
            float fClipDistance${i} = dot(vec4(worldSpaceVertex, 1.0), clipPlane${i});
            if (fClipDistance${i} > clipPlaneFadeSize${i} && clipPlaneFadeSize${i} != -1.0) {
                discard;
            }
            #endif\n`;

            this.CLIP_PLANE_COLOR_BLENDING += `#ifdef ${this.CLIP_PLANE_DEFINES[i - 1]}
            if (fClipDistance${i} > 0.0 && clipPlaneFadeSize${i} != -1.0) {
                float fade = 1.0 - ((clipPlaneFadeSize${i} - fClipDistance${i}) / clipPlaneFadeSize${i});
                finalDiffuse = mix(finalDiffuse, clipPlaneColor${i}, fade);
            }
            #endif\n`;
        }
    }
}

export const SIZE = 'size';
export const DIFFUSE_SAMPLER = 'diffuseSampler';
export const COLOR_BLENDING_ENABLED = 'colorBlendingEnabled';

export const clipPlanesStrings = new ClipPlanesStrings();

export type UniformsAndDefinesAndSamplers = Pick<IShaderMaterialOptions, 'uniforms'> &
    Pick<IShaderMaterialOptions, 'defines'> &
    Pick<IShaderMaterialOptions, 'samplers'>;

export abstract class CullingMaterial extends ShaderMaterial {
    protected _writeColorBlending: boolean;
    protected _excludeIgnoreDepthWriteCuttingPlanes: boolean;

    abstract getFragmentSource(): string;
    abstract getVertexSource(): string;

    protected isDirty = true;

    constructor(
        name: string,
        scene: Scene,
        options: Partial<IShaderMaterialOptions>,
        writeColorBlending: boolean,
        excludeIgnoreDepthWriteCuttingPlanes: boolean
    ) {
        super(
            name,
            scene,
            {
                // Intentionally blank
            },
            options
        );

        this._writeColorBlending = writeColorBlending;
        this._excludeIgnoreDepthWriteCuttingPlanes = excludeIgnoreDepthWriteCuttingPlanes;

        const clipPlanes = [...ClipPlane.entries(this.pictureSettings.ifc.clipPlanes)];

        for (let i = 0; i < clipPlanes.length; i++) {
            const clipPlane = clipPlanes[i];

            clipPlane[1].onPropertyChanged.add(() => {
                this.isDirty = true;
            });
        }

        this.onBindObservable.add((mesh) => {
            this.bindFunction(mesh);
        });
    }

    public override isReadyForSubMesh(mesh: AbstractMesh, subMesh: SubMesh, useInstances: boolean): boolean {
        if (this.isDirty) {
            this.isDirty = false;
            this.updateShader();
        }
        return super.isReadyForSubMesh(mesh, subMesh, useInstances);
    }

    protected get pictureSettings(): VisualSettings {
        return this.getScene().twinfinity.viewer!.visualSettings;
    }

    public getUniformsAndDefinesAndSamplers(): UniformsAndDefinesAndSamplers {
        const defines: string[] = [];
        const uniforms: string[] = [];

        const clipPlanes = [...ClipPlane.entries(this.pictureSettings.ifc.clipPlanes)];
        let isAnyClipPlaneActive = false;

        for (let i = 0; i < clipPlanes.length; i++) {
            const clipPlane = clipPlanes[i][1];
            if (clipPlane.enabled && !(this._excludeIgnoreDepthWriteCuttingPlanes && clipPlane.ignoreDepthWrite)) {
                isAnyClipPlaneActive = true;
                defines.push(clipPlanesStrings.CLIP_PLANE_DEFINES[i]);
                uniforms.push(clipPlanesStrings.CLIP_PLANES[i]);
                uniforms.push(clipPlanesStrings.CLIP_PLANES_COLORS[i]);
                uniforms.push(clipPlanesStrings.CLIP_PLANES_FADE_SIZES[i]);
            }
        }

        if (this._writeColorBlending) {
            defines.push(COLOR_BLENDING_ENABLED);
        }

        if (isAnyClipPlaneActive) {
            defines.push(REQUIRES_WORLD_FRAGMENT);
        }

        return {
            uniforms: uniforms,
            defines: defines,
            samplers: []
        };
    }

    abstract readonly diffuseTexture: LazyResizableRawTexture;

    abstract readonly propertiesTexture?: LazyResizableRawTexture;

    bindFunction(mesh: AbstractMesh): void {
        const effect = this.getEffect();
        const clipPlanes = [...ClipPlane.entries(this.pictureSettings.ifc.clipPlanes)];

        for (let i = 0; i < clipPlanes.length; i++) {
            const clipPlane = clipPlanes[i][1];
            if (clipPlane.enabled) {
                const plane = clipPlane.plane;
                const x = plane.normal.x;
                const y = plane.normal.y;
                const z = plane.normal.z;
                const d = plane.d;

                const fadeSize = clipPlane.fadeSize;
                effect.setFloat(clipPlanesStrings.CLIP_PLANES_FADE_SIZES[i], fadeSize);
                effect.setVector4(clipPlanesStrings.CLIP_PLANES[i], new Vector4(x, y, z, d));

                if (this._writeColorBlending) {
                    const color = clipPlane.color;
                    effect.setColor3(clipPlanesStrings.CLIP_PLANES_COLORS[i], color);
                }
            }
        }

        effect.setTexture(DIFFUSE_SAMPLER, this.diffuseTexture.getTexture(mesh.getScene()));
    }

    public updateShader(): void {
        const isFrozen = this.isFrozen;
        try {
            if (isFrozen) {
                this.unfreeze();
            }
            const uniformsAndDefines = this.getUniformsAndDefinesAndSamplers();
            const uniforms = uniformsAndDefines.uniforms;

            const vertexSource = this.getVertexSource();
            const fragmentSource = this.getFragmentSource();

            this.shaderPath = {
                vertexSource,
                fragmentSource
            };

            this.options.uniforms = uniforms;
            this.options.defines = uniformsAndDefines.defines;
            this.options.samplers = uniformsAndDefines.samplers;

            // Hack to make Babylon actually use the new compiled shader
            const clippingMeshes = this.getBindedMeshes(); // Nice english, should be called getBoundMeshes
            clippingMeshes.forEach((clippingMesh) => {
                clippingMesh.material = null;
                clippingMesh.material = this;
            });
        } finally {
            if (isFrozen) {
                this.freeze();
            }
        }
    }
}
