import { Observable } from '@babylonjs/core/Misc/observable';
import { DeepImmutable } from '@babylonjs/core/types';

export class Selector<T> {
    private readonly _items = new Map<string, T>();
    private readonly _selection = new Set<T>();
    private readonly _selectElement: HTMLSelectElement;
    private readonly _idAndTextSelector: (i: T) => { id: string; text: string };
    public onChange = new Observable<DeepImmutable<Set<T>>>();
    public constructor(
        parentElementOrId: HTMLElement | string,
        o: {
            caption: string;
            idAndTextSelector: (i: T) => { id: string; text: string };
            isMultiple?: boolean;
        }
    ) {
        if ('string' === typeof parentElementOrId) {
            const e = document.getElementById(parentElementOrId);
            if (!e) throw new Error(`Parent element with id ${parentElementOrId} does not exist`);
            parentElementOrId = e;
        }
        this._idAndTextSelector = o.idAndTextSelector;

        const multipleOrSingle = o.isMultiple ? 'multiple' : '';
        const multipleOrSingleClass = o.isMultiple ? 'multiple' : 'single';
        let innerHtml = `<div class='header'><b>${o.caption}</b>`;
        if (o.isMultiple) {
            innerHtml += `<a class='all' href='#'>all<a/><a class='invert' href='#'>invert</a>`;
        }
        innerHtml += `</div><select ${multipleOrSingle} class='twinfinity selector ${multipleOrSingleClass}'></select>`;
        parentElementOrId.innerHTML = innerHtml;
        this._selectElement = parentElementOrId.querySelector('select') as HTMLSelectElement;
        this._selectElement.addEventListener('change', (ev) => this._onElementChange(ev));

        if (o.isMultiple) {
            const btnAll = parentElementOrId.querySelector('.all') as HTMLAnchorElement;
            btnAll.addEventListener('click', async (ev) => {
                this._items.forEach((v) => this._selection.add(v));
                this.updateDOMFromSelection(this._selection);
                await this._onElementChange(new Event('foo'));
                return false;
            });
            const btnInvert = parentElementOrId.querySelector('.invert') as HTMLAnchorElement;
            btnInvert.addEventListener('click', async (ev) => {
                this._items.forEach((v) => {
                    if (this._selection.has(v)) {
                        this._selection.delete(v);
                    } else {
                        this._selection.add(v);
                    }
                });
                this.updateDOMFromSelection(this._selection);
                await this._onElementChange(new Event('foo'));
                return false;
            });
        }
    }

    public get isDisabled(): boolean {
        return this._selectElement.disabled;
    }

    public set isDisabled(v: boolean) {
        this._selectElement.disabled = v;
    }

    public get selection(): DeepImmutable<Set<T>> {
        return this._selection;
    }

    public setSelection(selectedItems: T[] | IterableIterator<T>): Promise<void> {
        this._selection.clear();
        for (const sI of selectedItems) {
            const { id } = this._idAndTextSelector(sI);
            if (this._items.has(id)) {
                this._selection.add(sI);
            }
        }
        this.updateDOMFromSelection(this._selection);
        return this._onElementChange(new Event('foo'));
    }

    public build(items: T[]): void {
        this._items.clear();
        this._selection.clear();
        this._selectElement.length = 0; // clear selector
        items.forEach((i) => this.createSelectionElement(i));
    }

    public clear(): void {
        this.build([]);
    }

    private createSelectionElement(item: T): void {
        const { id, text } = this._idAndTextSelector(item);
        this._items.set(id, item);
        const z = document.createElement('option');
        z.setAttribute('value', id);
        z.appendChild(document.createTextNode(text));
        this._selectElement.appendChild(z);
    }

    private async _onElementChange(ev: Event): Promise<any> {
        if (!this.onChange.hasObservers() || this.isDisabled) {
            return false;
        }
        const oldIsDisabled = this.isDisabled;
        this.isDisabled = true;
        try {
            await this.onChange.notifyObserversWithPromise(this.updateSelectionFromDOM(this._selection));
            return true;
        } catch (err) {
            console.warn('this.onChange callback failed', err);
            return false;
        } finally {
            this.isDisabled = oldIsDisabled;
        }
    }
    private updateSelectionFromDOM(selection: Set<T>): DeepImmutable<Set<T>> {
        selection.clear();
        for (let i = 0; i < this._selectElement.selectedOptions.length; ++i) {
            const id = this._selectElement.selectedOptions[i].value;
            const item = this._items.get(id);
            if (item) {
                selection.add(item);
            }
        }
        return selection;
    }

    private updateDOMFromSelection(selection: DeepImmutable<Set<T>>): void {
        for (let i = 0; i < this._selectElement.options.length; ++i) {
            const val = this._selectElement.options[i].value;
            const item = this._items.get(val);
            this._selectElement.options[i].selected = (item && selection.has(item)) ?? false;
        }
    }
}
