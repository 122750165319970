import { Matrix, TransformNode, Vector3 } from '../loader/babylonjs-import';
import { copyVertex3ToRef, Vertex3 } from '.';

export class Transforms {
    private static readonly _temp = {
        vectorA: Vector3.Zero(),
        matrixA: Matrix.Identity()
    };
    public static TransformPointsWithParent<T extends Vertex3>(parent: TransformNode, dst: Array<T>): void {
        const tmpVec = this._temp.vectorA;
        const matrix = parent.getWorldMatrix().invertToRef(this._temp.matrixA);
        for (const p of dst) {
            copyVertex3ToRef(Vector3.TransformCoordinatesFromFloatsToRef(p.x, p.y, p.z, matrix, tmpVec), p);
        }
    }
}
