import { BimIfcLoaderElement, IBimIfcLoaderElement } from './bim-ifc-loader-element';
import { BimIfcObject } from './bim-ifc-object';
import { Vector3 } from './babylonjs-import';
import { GeometryBuffer } from './GeometryBuffer';
import { Geometry3d, Geometry3dHandle, VertexDataMerge } from './vertex-data-merge';
import { telemetry } from '../Telemetry';
import { StopWatch, yieldThreadFor } from './stopwatch';

/**
 * Options for the {@link IfcGeometryBuilder}.
 */
export interface IfcGeometryBuilderOptions {
    /** Number of milliseconds to execute before sleeping {@link sleepInMs} milliseconds. */
    deadlineMs: number;
    /** Number of milliseconds to sleep after having executed for more than {@link deadlineMs} milliseconds. */
    sleepInMs: number;
}

/**
 * Represents the options for creating an {@link IfcGeometryBuilder}.
 */
export interface IfcGeometryBuilderCreateOptions {
    /**
     * The IFC loader elements containing IFC products to create geometries from.
     */
    loaderElements: BimIfcLoaderElement[];

    /**
     * The predicate function used to filter which {@link BimIfcObject} instances to include
     * in the geometry creation.
     */
    predicate: (ifcProduct: BimIfcObject) => unknown;
}

/**
 * Represents the build event data for the {@link IfcGeometryBuilder}.
 */
export class GeometryBuilderBuildEventData {
    /** @hidden */
    public _geometry?: Geometry3d;
    public processedIndiceCount = 0;

    /**
     * Creates an instance of GeometryBuilderBuildEventData.
     * @param totalGeometryCount The total number of geometries being built.
     * @param totalIndiceCount The total number of indices being processed.
     */
    public constructor(public totalGeometryCount: number, public totalIndiceCount: number) {}

    /**
     * Gets currently built geometry
     */
    public get geometry(): Geometry3d {
        return this._geometry!;
    }

    /**
     * Gets the progress of the build operation as a percentage.
     */
    public get progress(): number {
        return Math.floor((this.processedIndiceCount / this.totalIndiceCount) * 100);
    }
}

/**
 * Represents a builder for creating geometries from IFC product meshes.
 */
export class IfcGeometryBuilder {
    private _vertexDataMergeById = new Map<string, VertexDataMerge>();
    private _ifcProductMeshCount = 0;
    private _geometryCount = 0;
    private _deadline = new StopWatch(true);
    private _totalIndiceCount = 0;
    private _geometryRefs: Geometry3dHandle[] = [];
    private static _geometryBuildId = -1;

    /**
     * Creates an instance of IfcGeometryBuilder.
     * @param geometryBuildId The ID of the geometry build.
     * @param loaderElements The loader elements to build geometries from.
     * @param options The options for the geometry builder.
     */
    private constructor(
        public readonly geometryBuildId: number,
        public readonly loaderElements: BimIfcLoaderElement[]
    ) {}

    /**
     * Options to control for how long the geometry builder should execute before sleeping when performing
     * heavy operations  such as {@link create} and {@link build}. These options can be useful to
     * avoid blocking the main thread for too long. Decreasing {@link deadlineMs} and {@link sleepInMs} will make the operations
     * faster but will also increase the risk of blocking the main thread for too long. Increasing
     * makes the main thread (UI) more responsive but the operations will take longer to complete.
     */
    public static readonly options: IfcGeometryBuilderOptions = { deadlineMs: 200, sleepInMs: 4 };

    /**
     * Creates an instance of {@link IfcGeometryBuilder}.
     * @remarks
     * The create method will traverse all {@link BimProduct} instances provided by
     * `o.loaderElements.entries()`.
     * Each {@link BimProduct} that has geometry, not all products do, has N {@link BimProductMesh} instances.
     * The {@link create} method will visit each such instance and assign a `mergeId` to each of them. This `mergeId`
     * will be identical for {@link BimProductMesh} instances that shall be merged into the same {@link Geometry3d} instance when {@link build} is called.
     * {@link BimProductMesh} instances that alredy have a `mergeId` will be ignored as it is assumed that has already been processed
     * by another {@link IfcGeometryBuilder} instance. It is also possible to ignore certain {@link BimProduct} instances by providing a
     * predicate function in `o.predicate`. This will allow the caller to filter out products that should not be included in the geometry creation.
     * For example, one might want to only include IFC products that are of a certain type or have a certain property set. Specifying a predicate function allows for this.
     *
     * When a {@link IfcGeometryBuilder} instance has been created, the caller can call {@link build} to start the geometry creation process. This is a very
     * CPU intensive process.
     *
     * One should always attempt to use as few {@link IfcGeometryBuilder} instances, to create {@link Geometry3d} instances, as possible.
     * The more {@link Geometry3d} instances that we add to {@link TwinfinityViewer}, by calling {@link TwinfinityViewer.addGeometry}, the worse rendering
     * performance we will get. This is because the more geometries we have the more draw calls we will have. The more draw calls we have the worse performance we will get.
     * There is no fixed number of geometries that is the limit. It depends on the hardware and the complexity of the geometries.
     * @param o - The options for creating the geometry builder.
     * @returns A promise that resolves to an instance of `IfcGeometryBuilder`.
     */
    public static async create(o: IfcGeometryBuilderCreateOptions): Promise<IfcGeometryBuilder> {
        const { loaderElements, predicate } = o;
        IfcGeometryBuilder._geometryBuildId++;
        const geometryBuilder = new IfcGeometryBuilder(IfcGeometryBuilder._geometryBuildId, loaderElements);
        const uniqueAabbCenters = new Map<string, Vector3>();

        telemetry.trackTrace({ message: `Fetch vertexdata and build merge groups...` });
        const fetchAndBuildEvent = telemetry.startTrackEvent('Fetched vertex data and build merge groups');
        const loaderElementsWithVertexData = new Map<IBimIfcLoaderElement, Promise<boolean>>();

        // Allocate a single GeometryMemoryBlock instance which represents the largest geometry that will
        // be created. This instance will be reused when creating all geometries to avoid unnessecary memory allocation
        // which reduces performance drastically. maxPrimitive/IndiceCount is calculated before
        // the geometryMemoryBlockSingletonFactory is called by VertexDataMerge (happens when VertexDataMerge.merge() is called)
        const geomMem = new GeometryBuffer();
        // Calculate a set of mergedMeshDescriptors. Basically we just group all items that should be merged
        // into one mergedMeshDescriptor. Ie we get one descriptor per merge group.
        // Only keep data where products have not yet been loaded.

        const mergeGroupEvent = telemetry.startTrackEvent('Merge groups built');

        const { _vertexDataMergeById: vertexDataMergeById } = geometryBuilder;
        const { deadlineMs: deadline, sleepInMs } = IfcGeometryBuilder.options;
        // We don't need normals for ifc product meshes because
        // we calculate them in the fragment shader. This is a huge performance boost
        // since we can skip transforming them.
        const vertexDataMergeOptions = { includeNormals: false };
        try {
            for (const loaderElement of loaderElements) {
                for (const ifcProduct of loaderElement.project.entries()) {
                    if (!(ifcProduct.hasGeometry && predicate(ifcProduct))) {
                        continue;
                    }

                    // Kick of loading of vertex data only for those IFC files that are actually referenced
                    // by the vertex data merge operation.
                    loaderElementsWithVertexData.getOrAdd(loaderElement, (lE) => lE.ensureVertexDataLoaded());

                    for (const ifcProductMesh of ifcProduct.productMeshes) {
                        if (!ifcProductMesh._buildMergeId(IfcGeometryBuilder._geometryBuildId, uniqueAabbCenters)) {
                            // Already processed so we dont include this geometry again.
                            continue;
                        }
                        geometryBuilder._ifcProductMeshCount++;

                        const vDm = vertexDataMergeById.getOrAdd(
                            ifcProductMesh.mergeId,
                            (_mergeId) => new VertexDataMerge(_mergeId, geomMem, vertexDataMergeOptions)
                        );
                        vDm.add(ifcProductMesh);

                        // TODO It is probably better to either have a more efficient mergeid
                        // algorithm or to move calculation to a webworker (where it can run full speed) without a deadline.
                        if (geometryBuilder._deadline.elapsed > deadline) {
                            await yieldThreadFor(sleepInMs);
                            geometryBuilder._deadline.resetAndStart();
                        }
                    }
                }
            }
        } finally {
            mergeGroupEvent.stop({
                _ifcProductMeshCount: geometryBuilder._ifcProductMeshCount,
                bjsMeshCount: vertexDataMergeById.size
            });
        }

        geometryBuilder._totalIndiceCount = [...vertexDataMergeById.values()].reduce(
            (acc, vDm) => acc + vDm.indiceCount,
            0
        );

        geometryBuilder._geometryCount = vertexDataMergeById.size;

        if (loaderElementsWithVertexData.size > 0) {
            await Promise.all([...loaderElementsWithVertexData.values()]);
        }
        fetchAndBuildEvent.stop();

        return geometryBuilder;
    }

    /**
     * The number of IFC product meshes this builder will build geometries for.
     * @returns The number of meshes.
     */
    public get ifcProductMeshCount(): number {
        return this._ifcProductMeshCount;
    }

    /**
     * The number of geometries that will be built when {@link build} is called.
     * @returns The number of geometries.
     */
    public get geometryCount(): number {
        return this._geometryCount;
    }

    /**
     * Gets the number of indices in the geometry. The number of triangles in the geometry is this number divided by 3.
     * @returns The total number of indices.
     */
    public get indiceCount(): number {
        return this._totalIndiceCount;
    }

    /**
     * Builds {@link Geometry3d} instance from all {@link BimProduct} instances that was included during the call to {@link create}.
     * This is a very CPU intensive operation.
     *
     * @remarks
     * Only access the {@link Geometry3d} instance inside the action callback. The {@link Geometry3d} instance, and all data accessable trhoug it,
     * will be invalid after the action callback has been invoked.
     * Each {@link Geometry3d} instance will contain the geometry from all {@link BimProductMesh} instances that share the same `mergeId`.
     *
     * @param action - The action to be invoked for each built {@link Geometry3d} instance. Call {@link TwinfinityViewer.addOrReplaceMesh} to add the geometry to the viewer.
     * The `eventData` parameter contains both the current {@link Geometry3d} instance that was built and information such as the current progress of the build operation.
     * See {@link GeometryBuilderBuildEventData} for more information.
     * @returns A promise that resolves to an array of {@link Geometry3dHandle} objects. These can be used to unload the geometries from {@link TwinfinityViewer}
     * by calling {@link TwinfinityViewer.removeMesh}.
     */
    public async build(action: (eventData: GeometryBuilderBuildEventData) => void): Promise<Geometry3dHandle[]> {
        if (this._ifcProductMeshCount === 0 || this._vertexDataMergeById.size === 0) {
            this.loaderElements.forEach((lE) => lE.transformsRepository.clear());
            return this._geometryRefs;
        }
        let totalIfcProductMeshCount = 0;

        const meshMergeSw = new StopWatch(false);
        const actionInvokeSw = new StopWatch(false);
        telemetry.trackTrace({ message: `Mesh merging...` });
        const event = telemetry.startTrackEvent('Mesh merging');
        const { deadlineMs: deadline, sleepInMs } = IfcGeometryBuilder.options;
        this._deadline.resetAndStart();
        const eventData = new GeometryBuilderBuildEventData(this.geometryCount, this._totalIndiceCount);
        try {
            let processedGeometryCount = 0;
            for (const [propName, vDm] of this._vertexDataMergeById.entries()) {
                if (vDm.itemsToMerge.length > 0) {
                    meshMergeSw.resume();
                    const geometry = vDm.merge(); // Super CPU intensive operation
                    meshMergeSw.stop();
                    if (this._deadline.elapsed > deadline) {
                        await yieldThreadFor(sleepInMs);
                        this._deadline.resetAndStart();
                    }

                    totalIfcProductMeshCount += vDm.itemsToMerge.length;
                    actionInvokeSw.resume();
                    eventData.processedIndiceCount += geometry.indices.length;
                    eventData._geometry = geometry;
                    if (eventData.totalGeometryCount === ++processedGeometryCount) {
                        eventData.processedIndiceCount = eventData.totalIndiceCount;
                    }

                    action(eventData); // Could be a very slow operation. We dont know so we measure it.
                    this._geometryRefs.push(geometry.toHandle());
                    actionInvokeSw.stop();
                    if (this._deadline.elapsed > deadline) {
                        await yieldThreadFor(sleepInMs);
                        this._deadline.resetAndStart();
                    }
                }

                this._vertexDataMergeById.delete(propName);
            }
        } finally {
            event.stop({
                geometryCount: this.geometryCount,
                ifcProductCount: totalIfcProductMeshCount,
                geometryMergeDuration: meshMergeSw.totalElapsed,
                actionInvokeDuration: actionInvokeSw.totalElapsed
            });
        }

        this._vertexDataMergeById.clear(); // Just in case we have some left. Should not happen.

        // Get rid of all transforms. We do not need them now
        this.loaderElements.forEach((lE) => lE.transformsRepository.clear());
        return this._geometryRefs;
    }
}
