/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { CameraInputsManager, Nullable, TargetCamera } from '../loader/babylonjs-import';

import { PivotTargetCamera } from './PivotTargetCamera';
import { TwinfinityTargetCameraMouseRotationInput } from './Inputs/TwinfinityTargetCameraMouseRotationInput';
import { TwinfinityTargetCameraMousePanInput } from './Inputs/TwinfinityTargetCameraMousePanInput';
import { TwinfinityTargetCameraMouseZoomInput } from './Inputs/TwinfinityTargetCameraMouseZoomInput';

import { TwinfinityCameraTouchInput } from './Inputs/TwinfinityCameraTouchInput';

import { TwinfinityTargetCameraKeyboardInput } from './Inputs/TwinfinityCameraKeyboardInput';
import { CameraMode, CameraOptions } from './ViewerCamera';
/**
 * Default Inputs manager for the PivotTargetCamera.
 * It groups all the default supported inputs for ease of use.
 * @see http://doc.babylonjs.com/how_to/customizing_camera_inputs
 */
export class TwinfinityTargetCameraInputsManager extends CameraInputsManager<TargetCamera> implements CameraOptions {
    /**
     */
    public _mouseRotationInput: Nullable<TwinfinityTargetCameraMouseRotationInput> = null;
    public _mousePanInput: Nullable<TwinfinityTargetCameraMousePanInput<PivotTargetCamera>> = null;
    public _mouseZoomInput: Nullable<TwinfinityTargetCameraMouseZoomInput<PivotTargetCamera>> = null;
    public _touchInput: Nullable<TwinfinityCameraTouchInput> = null;
    public _keyboardInput: Nullable<TwinfinityTargetCameraKeyboardInput<PivotTargetCamera>> = null;

    public isPanEnabled = true;
    public isZoomEnabled = true;
    public isRotationEnabled = true;
    public isKeyboardMoveEnabled = true;
    public mode: CameraMode = 'all';

    /**
     * Instantiates a new TwinfinityTargetCameraInputsManager.
     * @param camera Defines the camera the inputs belong to
     */
    public constructor(camera: TargetCamera) {
        super(camera);
    }

    /**
     * Add left mouse button input support to the input manager.
     * This is used for rotation for PivotTargetCamera
     * @returns the current input manager
     */
    public addMouseRotation(): TwinfinityTargetCameraInputsManager {
        if (!this._mouseRotationInput) {
            this._mouseRotationInput = new TwinfinityTargetCameraMouseRotationInput(this);
            this.add(this._mouseRotationInput);
        }
        return this;
    }

    /**
     * Removes the left mouse button input support from the manager
     * @returns the current input manager
     */
    public removeMouseRotation(): TwinfinityTargetCameraInputsManager {
        if (this._mouseRotationInput) {
            this.remove(this._mouseRotationInput);
        }
        return this;
    }

    /**
     * Add middle mouse button input support to the input manager.
     * This is used for panning the PivotTargetCamera
     * @returns the current input manager
     */
    public addMousePan(): TwinfinityTargetCameraInputsManager {
        if (!this._mousePanInput) {
            this._mousePanInput = new TwinfinityTargetCameraMousePanInput<PivotTargetCamera>(this);
            this.add(this._mousePanInput);
        }
        return this;
    }

    /**
     * Removes the middle mouse button input support from the manager
     * @returns the current input manager
     */
    public removeMousePan(): TwinfinityTargetCameraInputsManager {
        if (this._mousePanInput) {
            this.remove(this._mousePanInput);
        }
        return this;
    }

    /**
     * Add mouse scroll wheel input support to the input manager.
     * This is used for zooming in and out the TargetCamera
     * @returns the current input manager
     */
    public addMouseZoom(): TwinfinityTargetCameraInputsManager {
        if (!this._mouseZoomInput) {
            this._mouseZoomInput = new TwinfinityTargetCameraMouseZoomInput(this);
            this.add(this._mouseZoomInput);
        }
        return this;
    }

    /**
     * Removes the mouse scroll wheel input support from the manager
     * @returns the current input manager
     */
    public removeMouseZoom(): TwinfinityTargetCameraInputsManager {
        if (this._mouseZoomInput) {
            this.remove(this._mouseZoomInput);
        }
        return this;
    }

    /**
     * Add keyboard input support to the input manager.
     * @returns the current input manager
     */
    public addKeyboard(): TwinfinityTargetCameraInputsManager {
        if (!this._keyboardInput) {
            this._keyboardInput = new TwinfinityTargetCameraKeyboardInput<PivotTargetCamera>(this);
            this.add(this._keyboardInput);
        }
        return this;
    }

    /**
     * Removes the keyboard input support from the manager
     * @returns the current input manager
     */
    public removeKeyboard(): TwinfinityTargetCameraInputsManager {
        if (this._keyboardInput) {
            this.remove(this._keyboardInput);
        }
        return this;
    }

    /**
     * Add touch rotation input support to the input manager.
     * This is used when one finger is touching the screen
     * @returns the current input manager
     */
    public addTouch(): TwinfinityTargetCameraInputsManager {
        if (!this._touchInput) {
            this._touchInput = new TwinfinityCameraTouchInput(this);
            this.add(this._touchInput);
        }
        return this;
    }

    /**
     * Removes touch rotation input support from the manager
     * @returns the current input manager
     */
    public removeTouch(): TwinfinityTargetCameraInputsManager {
        if (this._touchInput) {
            this.remove(this._touchInput);
        }
        return this;
    }

    /**
     * Remove all attached input methods from this PivotTargetCamera
     */
    public clear(): void {
        super.clear();
        this._mouseRotationInput = null;
        this._mousePanInput = null;
        this._mouseZoomInput = null;
        this._touchInput = null;
    }
}
