import { Color3 } from '@babylonjs/core/Maths/math.color';
import { BimChangeBlob, BimCoreApi } from '@twinfinity/core';
import { BimDrawingObject } from './BimDrawingObject';
import { BimPdf } from './BimPdf';

/** @inheritDoc */
export class BimDrawingPdf extends BimDrawingObject {
    constructor(
        api: BimCoreApi,
        private readonly _drawingPdf: BimPdf,
        drawingChange: BimChangeBlob,
        onDisposed: (o: BimDrawingObject) => void
    ) {
        super(api, _drawingPdf, drawingChange, onDisposed);
    }

    /** @returns The BimPdf object */
    public get drawing(): BimPdf {
        return this._drawingPdf;
    }

    /**
     * Sets the background color of the PDF.
     * @param color new background color for the PDF.
     */
    public setBackgroundColor(color: Color3): void {
        this._drawingPdf.backgroundColor.set(color.r, color.g, color.b);
    }

    /**
     * Gets the background color for the current PDF
     * @returns The background color as a Color3
     */
    public getBackgroundColor(): Color3 {
        return this._drawingPdf.backgroundColor;
    }

    /**
     * Sets which page of PDF should be rendered or gets the current page.
     * @param page Number of the page you wish to render, 1 - {@link this.pageCount}.
     */
    public page(p?: number): Promise<number> {
        return this._drawingPdf.page(p);
    }

    /**
     * @returns The number of pages in the PDF.
     */
    public pageCount(): number {
        return this._drawingPdf.pageCount;
    }
}
