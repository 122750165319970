import { Lookup } from './lookup';
import { Discipline } from './discipline';
import { BimIfcClass } from './bim-ifc-class';
import { DwgVersion } from './DwgVersion';
import { BimTransform } from './bim-format-types';
import { Permissions } from './Permission';
import { LayerDefinitions } from './LayerDefinitions';
import { IfcSiteInformation } from './IfcSiteInformation';

/** Currently supported languages (LCID). */
export enum Lcid {
    Swedish = 1053,
    English = 1033,
    Norwegian = 1044,
    Danish = 1030
}

/** General Twinfinity information. */
export interface TwinfinityInfo {
    /** Name of customer portal */
    readonly name: string;

    /** URL to logo image. May point to non-CORS enabled resource. That is, do not use in AJAX calls. */
    readonly logoImageUrl: string;

    /** URL to background image. May point to non-CORS enabled resource. That is, do not use in AJAX calls. */
    readonly backgroundImageUrl: string;

    /** URL to Projektstruktur portal that the Twinfinity API backend is connected to. */
    readonly psUrl: string;

    /** Version of Twinfinity backend. */
    readonly version: string;
}

/**
 * Defines mandatory properties for layer-compatible objects (objects that can have layers attached to them)
 */
export interface AvailableLayerDefinitions {
    /** List of layers, that can be added to this change, together with
     * the permission that the current user has for each of them.
     */
    readonly availableLayerDefinitions: LayerDefinitions;
}

type BimChangeBaseMetadata = Lookup<any>;

/** Base properties for a change. */
export interface BimChangeBase {
    /** Id of change. */
    readonly id: string;

    /**
     * Id of system that the change originated from.
     * If a change in Twinfinity was created because
     * an external system pushed it into Twinfinity then
     * the id will refer to that system. If change originates
     * from Twinfinity (for example because a file was uploaded by a user)
     * then the id will always be 'twinfinity'.
     */
    readonly ownerSystem: string;

    /** Id of {@link BimContainer} to which this change belongs. */
    readonly containerId: string;

    /** Version of change */
    readonly version: number;

    /** Name of change. */
    readonly name: string;

    /** Metadata for change. */
    readonly metadata: BimChangeBaseMetadata;

    /** etag for change. Used for optimistic concurrency checks and in operations where
     * we want to check if data has changed or not since last etag we have.
     */
    readonly etag: string;

    /** Format of change.  */
    readonly format: string;

    /**
     * Unique path for the change. Each change has one.
     * If the change or one of its parents are renamed then the path will change.
     */
    readonly path: string;

    /**
     * 64 bit permission bitmask. Used to verify that a user has required
     * permissions on a change. Since there is no native JSON support for bitmasks,
     * the value is derived from {@link permissionsString}.
     */
    readonly permissions: Permissions;

    /**
     * String used to represent the {@link permissions} bitmask.
     */
    readonly permissionsString: string;

    /** Contains base API URL to use when calling backend methods for this particular item. */
    readonly apiUrl: string;
}

/**
 * Type of BimChange. Used in interfaces extending {@link BimChangeBase} (as type property) which in turn are part
 * of discriminant union type {@link BimChange}.
 */
export enum BimChangeType {
    Undefined = 0,
    Folder = 1, // currently unused
    Container = 2,
    Blob = 4,
    BlobWithGeometry = 16,
    Ifc = 16, // Alias for BlobWithGeometry
    Dwg = 17,
    Layer = 32
}

/** Represents a floor description in metadata of an IFC file */
export interface IfcFloor {
    /** Name of floor */
    readonly name: string;
}

/** Defines URL for change content. */
export interface ContentUrl {
    /**
     * URL of change content. Points to the endpoint where the content of the change
     * can be retrieved by a simple GET request.
     * */
    readonly url: string;
}

/**
 * Represents strongly typed metadata for a layer.
 */
export interface BimChangeLayerMetadata extends BimChangeBaseMetadata {
    /** System metadata */
    readonly _system: {
        /** System metadata for layer */
        readonly layer: {
            /** Format of change. Example: `sensors` */
            readonly format: string;
            /** Id of change this layer is attached to */
            readonly attachedToId: string;
        };
    };
}

/**
 * Describes a version
 */
export interface VersionInfo {
    /** Major version number */
    readonly major: number;
    /** Minor version number */
    readonly minor: number;
    /** Patch version number */
    readonly patch: number;
    /** Unique build id */
    readonly buildId: number;
    /** The commit checksum */
    readonly commitId: string;
}

/**
 * Represents a layer
 */
export interface BimChangeLayer extends BimChangeBase, ContentUrl {
    /** Signifies that it is a layer*/
    readonly type: BimChangeType.Layer;
    /** Optional blobId for layer. Only valid if layer is connected to a blob. */
    readonly blobId?: string;

    /** Metadata of layer. */
    readonly metadata: BimChangeLayerMetadata & BimChangeBase['metadata'];
}

/**
 * Common properties set by the Twinfinity parsers (responsible for processing files such
 * as .ifc and .dwg).
 */
export interface TwinfinityParserMetadata {
    /** Set to `true` if the change has not been parsed correctly. For example if this
     * happens for a .ifc or a .dwg file then it will not be possible to load the file.
     */
    readonly hasParseError: boolean;
}

/**
 * Defines strongly typed metadata for IFC files.
 */
export interface IfcMetadata extends TwinfinityParserMetadata {
    /**
     * The transform of the topmost IfcLocalPlacement node in the IFC file.
     * This tells us how the overall model is rotated, scaled and translated.
     * Often very useful when determining why two IFC files do not align properly.
     * One can often see that the translation or rotation part differs in those cases.
     * The transform is expressed in meters.
     */
    readonly topIfcLocalPlacementTransform?: BimTransform & {
        /** Distance to origo for topmost IfcLocalPlacement node. */
        readonly distanceToOrigo: number;
    };
    /**
     * Version of the parser that parsed this IFC file. Useful when
     * we load IFC files which was parsed with old parsers to keep backwards
     * compatibility. May be undefined if file was parsed before this property was introduced.
     */
    readonly parserVersion?: VersionInfo;

    /**
     * URLs for blobs containing IFC data.
     */
    readonly url: {
        /**
         * URL to IFC index file (contains IFC product hierarchy)
         */
        readonly idx?: string;
        /**
         * URL to IFC geometry file (needed for any 3D visualizations)
         */
        readonly geom?: string;
        /**
         * URL to IFC property set file.
         */
        readonly prop?: string;
    };
    /**
     * List of floors (storey levels) available in IFC file
     */
    readonly floors?: IfcFloor[];
    /**
     * List of IFC classes available in IFC file
     */
    readonly classes?: string[];

    /**
     * List of sites in IFC file
     */
    readonly sites?: IfcSiteInformation[];

    /**
     * Statistics of IFC file
     */
    readonly statistics?: {
        /**
         * Number of products in IFC file.
         */
        readonly productCount?: number;
        /**
         * Number of issues encountered while processing the IFC file.
         */
        readonly annotations?: {
            /** Number of informational annotations made during file processing */
            readonly information?: number;
            /** Number of warning annotations made during file processing */
            readonly warning?: number;
            /** Number of error annotations made during file processing */
            readonly error?: number;
        };
    };
}

/**
 * Metadata for IFC's.
 */
export interface BimChangeIfcMetadata extends BimChangeBaseMetadata {
    readonly serverRelativeUrl: string;
    /**
     * IFC property.
     */
    readonly ifc: IfcMetadata;
}

/**
 * Metadata shared by both 2D and 3D DWG's
 */
export interface DwgMetadataBase extends TwinfinityParserMetadata {
    /**
     * DWG version the DWG was last saved in.
     */
    readonly version: DwgVersion;

    /**
     * Version of the parser that parsed this DWG file. Useful when
     * we load DWG files which was parsed with old parsers to keep backwards
     * compatibility.
     * May be undefined if file was parsed before this property was introduced.
     */
    readonly parserVersion?: VersionInfo;

    /**
     * Relative URLs to the xrefs
     */
    readonly xrefs: {
        /**
         * Relative URL for the xref. To construct the absolute URLs of the xrefs
         * use `relativeUrl` together with parent {@link BimChangeBase.url} (excluding the file name)
         */
        [relativeUrl: string]: {
            /**
             * Name of the xref. Often same as file name (but can be different).
             */
            readonly name: string;
            /**
             * Whether xref is a overlay or not
             */
            readonly overlay: boolean;

            /**
             * Whether xref should be initially loaded or not.
             */
            readonly loaded: boolean;
        };
    };
}

/**
 * Metadata for a 3D DWG.
 */
export interface DwgMetadata3D extends DwgMetadataBase {
    readonly type: '3d';
}

/**
 * Metadata for a 2D DWG
 */
export interface DwgMetadata2D extends DwgMetadataBase {
    /**
     * Signifies that it is a 2D DWG.
     */
    readonly type: '2d';

    /**
     * Width of DWG
     */
    readonly width: number;
    /**
     * Height of DWG
     */
    readonly height: number;

    /**
     * DWG offset
     */
    readonly offset: {
        /**
         * Top
         */
        readonly top: number;
        /**
         * Left
         */
        readonly left: number;
    };

    /**
     * URL for the geometry file to be rendered
     */
    readonly url: {
        readonly svg: string;
    };

    /**
     * DWG margin
     */
    readonly margin: {
        /**
         * Top and bottom margin
         */
        readonly topBottom: number;
        /**
         * Left and right margin
         */
        readonly leftRight: number;
    };
}

/**
 * Metadata for both 2D and 3D DWG files.
 */
export interface DwgMetadata {
    /**
     * Metadata for DWG. Note that it is a discriminant union so check
     * the `dwg.type` property to see whether it is a 2D or 3D DWG.
     */
    readonly dwg: DwgMetadata2D | DwgMetadata3D;

    /**
     * URL of the DWG relative to the server
     */
    readonly serverRelativeUrl: string;
}

/** Represents a container. For example a project */
export interface BimContainer extends BimChangeBase, AvailableLayerDefinitions {
    /** Indicates change is a container */
    readonly type: BimChangeType.Container;
    /** Container language */
    readonly lcid: Lcid;
    /** Time in UTC */
    readonly modifiedUtc: Date;

    /** Whether or not there is any geometry in the container. If `true`, parsed IFC files exist that are accessible to the user. */
    readonly hasGeometry: boolean;

    /** Server relative URL of container
     * @deprecated Use {@link url} instead.
     */
    readonly serverRelativeUrl: string;

    readonly url: string;

    /** Type of container, for example "Project" */
    readonly containerType: string;
    /** Navigation attributes in format [[title, value], ..., [title, value]] */
    readonly navigationAttributes?: [string, string][];

    /** Optional container metadata properties. May or may not be set. */
    readonly metadataProperties?: {
        readonly [propertyName: string]: {
            /** Display name of property.  */
            readonly displayName?: string;
            /** property value */
            readonly value?: string;
        };
    };

    /** Language. Corresponds to {@link lcid}. Example: en-US */
    readonly language: string;
    /** Id of {@link BimContainer} that represents the main project. */
    readonly mainProject?: string;

    /** Site number */
    readonly siteNumber?: string;
}
/**
 * Alias for backwards compatibility
 * @deprecated
 */
export type Container = BimContainer;

/** Represents the IFC discipline for the change */
export interface BimChangeDiscipline {
    /** Discipline. Example A, K, V etc */
    readonly discipline?: Discipline;
}

/**
 * Represents a folder
 */
export interface BimFolder extends BimChangeBase, AvailableLayerDefinitions, ContentUrl {
    readonly type: BimChangeType.Folder;
    readonly metadata: {
        readonly serverRelativeUrl: string;
    } & BimChangeBase['metadata'];

    /** File count statistics for the folder. */
    readonly folderFileCount: {
        /** How many files there are in the folder itself. */
        readonly count: number;
        /** How many files there are in the folder itself and all descendant folders (all the way down to the last folder). */
        readonly recursiveCount: number;
    };
}

/**
 * Represents a generic file that is not defined by other change types such as
 * {@link BimChangeIfc} or {@link BimChangeDwg}.
 */
export interface BimChangeBlob extends BimChangeBase, BimChangeDiscipline, AvailableLayerDefinitions, ContentUrl {
    readonly type: BimChangeType.Blob;
    /**
     * Blob id of file (checksum of file contents).
     */
    readonly blobId: string;

    /**
     * Metadata for the file.
     */
    readonly metadata: {
        /** server relative path of file */
        readonly serverRelativeUrl: string;
    } & BimChangeBase['metadata'];
    // TODO Add mimetype?
}

/**
 * Represents a change for a IFC file
 */
export interface BimChangeIfc
    extends BimChangeBase,
        HttpIfcResource,
        Required<BimChangeDiscipline>,
        AvailableLayerDefinitions,
        ContentUrl {
    /** Indicates that we have a change with ifc */
    readonly type: BimChangeType.Ifc;
    /** Id of blob that contains the geometry */
    readonly blobId: string;
    /** Number of IFC products available in this change */
    readonly productCount: number;

    /** Floors (IfcBuildingStorey) available in IFC file */
    readonly floors: IfcFloor[];

    /** Set of IfcClasses available in the IFC file */
    readonly classes: BimIfcClass[];

    /** IFC change metadata. */
    readonly metadata: BimChangeIfcMetadata & BimChangeBase['metadata'];
}

/**
 * Represents a DWG.
 */
export interface BimChangeDwg extends BimChangeBase, BimChangeDiscipline, AvailableLayerDefinitions, ContentUrl {
    /** Indicates that we have a DWG change. */
    readonly type: BimChangeType.Dwg;

    /** Id of blob that contains the DWG. */
    readonly blobId: string;

    /** DWG metadata. */
    readonly metadata: DwgMetadata & BimChangeBase['metadata'];
}

/**
 * Information about a user
 */
export interface BimUserInfo {
    /** User identifier */
    readonly id: string;
    /** Combination of {@link firstName} and {@link lastName}. */
    readonly name: string;
    /** First name of user */
    readonly firstName: string;
    /** Last name of user */
    readonly lastName: string;

    /** URL to privacy policy for user. */
    readonly privacyPolicyUrl: string;

    /** Email address for user. */
    readonly email: string;
}

/**
 * Information about a container and the currently logged in user.
 */
export interface BimContainerInfo {
    /**
     * Id of container.
     */
    readonly id: string;

    /**
     * Title of container.
     */
    readonly title: string;

    /**
     * Language of container. Different containers can have different languages.
     */
    readonly language: string;

    /**
     * Kept for backwards compatibility. Same as {@link title} and {@link language}.
     */
    readonly info: {
        readonly title: string;
        readonly language: string;
    };

    /**
     * Information regarding the current user.
     */
    readonly user: BimUserInfo;
}

/**
 * Discriminant union type (look at `type` property) representing a Twinfinity change.
 * A change can be a file (IFC, DWG etc), layer or folder. Further types will be added in the future.
 * Common for all changes are that they have a unique id and a version.
 */
export type BimChange = BimChangeBlob | BimChangeIfc | BimChangeLayer | BimChangeDwg | BimFolder;

/**
 * Discriminant union type (look at `type` property) representing a Twinfinity change.
 * for those change types ({@link BimChange}) where layers can be attached.
 * Use {@link LayerCompatibleChange.availableLayerDefinitions} in order to determine
 * if it is possible to attach a layer to a change of type {@link LayerCompatibleChange}.
 */
export type LayerCompatibleChange = Exclude<BimChange, BimChangeLayer>;

/** Represents Twinfinity URLs to .idx, .geom and .prop files. */
export interface HttpIfcResourceUrls {
    /** URL to .idx file. */
    readonly idx?: URL;
    /** URL to .geom file. */
    readonly geom?: URL;
    /** URL to .prop file. */
    readonly prop?: URL;
}

/** Describes an IFC resource (.ifc file). */
export interface HttpIfcResource {
    /** Urls of .idx, .geom and .prop files available for the .ifc file. */
    readonly resourceUrl: HttpIfcResourceUrls;
}

/** Information required to create a layer, see {@link BimCoreApi.layers} and {@link LayerApi}. */
export interface BimChangeLayerData {
    /** Name of layer */
    readonly name: string;
    /** Format of layer. It should represent the type of your layer. For example, if a layer
     * holds sensors, a good format would be 'sensors'.
     */
    readonly format: string;

    /** Optional metadata about the layer. Do not put large amounts of data here.
     * Use {@link data} instead.
     */
    readonly metadata?: Lookup<any>;

    /** Optional layer data. This can be anything; binary, json, files etc.
     *  Large amounts of data should be put here. For example, if sensors are stored then information
     *  on each sensor should probably be put in this field. Information on the number of sensors is preferably
     *  stored in {@link metadata}.
     */
    readonly data?: BlobData;
}

/** Represents generic data type. Intended use is to handle data
 *  which can be in a file, blob, arraybuffer or js object, js primitive form etc.
 *  For example, to pass data into a method that shall store the data
 *  but does not care about how the data is represented. This is useful for storing
 *  binary data, primitive types and js objects.
 */
export type BlobData = File | Blob | ArrayBuffer | unknown;

/**
 * Type guard to check if specified change is a {@link BimChangeIfc}.
 * @param change Change to check
 */
export function isIfc(change: BimChange): change is BimChangeIfc {
    return change.type === BimChangeType.Ifc;
}

/**
 * Type guard to check if specified change is a {@link BimChangeDwg}.
 * @param change Change to check
 */
export function isDwg(change: BimChange): change is BimChangeDwg {
    return change.type === BimChangeType.Dwg;
}

/**
 * Type guard to check if specified change is a {@link BimChangeBlob}.
 * @param change Change to check
 */
export function isBlob(change: BimChange): change is BimChangeBlob {
    return change.type === BimChangeType.Blob;
}

/**
 * Type guard to check if specified change is a {@link BimFolder}.
 * @param change Change to check
 */
export function isFolder(change: BimChange): change is BimFolder {
    return change.type === BimChangeType.Folder;
}

/**
 * Type guard to check if specified change is a {@link BimChangeLayer}.
 * @param change Change to check
 */
export function IsLayer(change: BimChange): change is BimChangeLayer {
    return change.type === BimChangeType.Layer;
}
