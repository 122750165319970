import { Selector } from './Selector';
import { SpaceLabel } from './SpaceLabel';
import { TFButton } from './TFButton';
import {
    BimApi,
    BimTwinfinityApiClient,
    BimIfcObject,
    BimChangeIfc,
    BimContainer,
    CoordinateTracker,
    BimIfcClass,
    BimIfcSpace,
    PickOptionType,
    PredefinedCanvasPosition,
    PredefinedBimChangeMetadataQuery,
    BimChangeStatus,
    BimChangeDwg,
    StopWatch,
    MarkupSheet2D,
    BimChangeBlob,
    MarkupEntity,
    isFailure,
    PickResultType,
    IconHandler,
    PickResult,
    PointerInfoWithTimings,
    PredefinedPointerButtonId,
    getTexture,
    ChangeRecorder,
    Icon,
    PickResultEmpty,
    IfcMaterialHighlightIndex,
    PostProcessEffects,
    telemetry,
    yieldThreadFor,
    BimIfcLoader,
    TwinfinityViewer,
    Geometry3dHandle
} from '@twinfinity/core';
// import '@twinfinity/core/dist/Debug';

import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { Material } from '@babylonjs/core/Materials/material';
import { Axis, Color3, Color4 } from '@babylonjs/core/Maths/math';
import { TextObjectLayerEditor } from './TextObjectLayerEditor';
import { DeepImmutable, DeepImmutableObject } from '@babylonjs/core/types';
import { PointerEventTypes } from '@babylonjs/core/Events/pointerEvents';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import '@babylonjs/core/Loading/loadingScreen'; // Needed for side effect
import '@babylonjs/loaders/glTF'; // needed to load glb/gltf files
import { SceneLoader } from '@babylonjs/core/Loading';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { LaserTool } from './LaserTool';
import { PolyLineTool } from './PolyLineTool';
import { AreaTool } from './AreaTool';
import { DwgTool } from './DwgTool';
import { Scalar } from '@babylonjs/core/Maths/math.scalar';
import { MarkupTool } from './MarkupTool';
import { VertexNormalsRenderer } from './VertexNormalRenderer';
import { KeyboardEventTypes, KeyboardInfo } from '@babylonjs/core/Events/keyboardEvents';
import EasyMDE from 'easymde';
import { ClippingPlaneTool } from './ClippingPlaneTool';
import { Texture } from '@babylonjs/core/Materials/Textures/texture';
import { TFProgress } from './TFProgress';
import { Observable } from '@babylonjs/core/Misc/observable';

// import { Scalar } from '@babylonjs/core/Maths/math.scalar';

// Reference web components so they are not removed by the tree shaker (bundler)
TFProgress.initialize();
TFButton.initialize();

class App {
    private readonly _textObjectLayerEditor: TextObjectLayerEditor;
    private readonly _spaceLabelRootNode: TransformNode;
    private readonly _spaceLabelCoordinateTracker: CoordinateTracker<SpaceLabel>;
    private readonly _ifcClassSelector: Selector<BimIfcClass>;
    private readonly _ifcChangeSelector: Selector<BimChangeIfc>;
    private readonly _pdfSelector: Selector<BimChangeBlob>;
    private readonly _dwgChangeSelector: Selector<BimChangeDwg>;
    private readonly _ifcFloorSelector: Selector<string>;
    private readonly _markupSheetSelector: Selector<MarkupSheet2D>;
    private readonly _markupEntitySelector: Selector<MarkupEntity>;
    private readonly _containerChangeSelector: Selector<BimContainer>;
    private readonly _btnCreateSheet: HTMLButtonElement;
    private readonly _btnSaveSheets: HTMLButtonElement;
    private readonly _btnDupeSheet: HTMLButtonElement;
    private readonly _btnDeleteSheet: HTMLButtonElement;
    private readonly _btnResetView: HTMLButtonElement;
    private readonly _btnDeleteEntity: HTMLButtonElement;
    private readonly _btnRefreshSheets: HTMLButtonElement;
    private readonly _btnGetVisiblesInSight: HTMLButtonElement;
    private readonly _cbOrthoToggle: HTMLInputElement;
    private readonly _cbTopdownToggle: HTMLInputElement;
    private readonly _txtCurrentlyHiglightedIfcObject: HTMLParagraphElement;
    private readonly _spaceTransparentMaterial: StandardMaterial;
    private readonly _divFps: HTMLElement;
    private _isMilktruckLoaded = false;
    private readonly _cbLaserTool: HTMLInputElement;
    private readonly _cbClippingPlaneTool: HTMLInputElement;
    private readonly _cbPolylineTool: HTMLInputElement;
    private readonly _cbAreaTool: HTMLInputElement;
    private readonly _cbMarkupTool: HTMLInputElement;
    private readonly _heightNumberAreaTool: HTMLInputElement;
    private readonly _cbTwinfinityDwg: HTMLInputElement;
    private readonly _cbIcons: HTMLInputElement;
    private readonly _cbBackfaceCulling: HTMLInputElement;
    private readonly _cbHsv: HTMLInputElement;
    private readonly _laserTool: LaserTool;
    private readonly _clippingPlaneTool: ClippingPlaneTool;
    private readonly _polyLineTool: PolyLineTool;
    private readonly _areaTool: AreaTool;
    private readonly _dwgTool: DwgTool;
    private readonly _markupTool: MarkupTool;
    private readonly _markdownEditor = new EasyMDE();
    private _iconHandler?: IconHandler;

    private _dwgChanges: BimChangeDwg[] = [];
    private _cbSavePickTexture: HTMLInputElement;
    private readonly _currentSelection: { pickResult: PickResult; changeRecorder: ChangeRecorder } = {
        pickResult: PickResultEmpty.instance,
        changeRecorder: new ChangeRecorder()
    };

    private _iconHandlerTexture?: Texture;
    private _progress: TFProgress;
    private _keyBoardObserver?: Observable<KeyboardInfo>;

    public constructor(private readonly _api: BimApi) {
        this._textObjectLayerEditor = new TextObjectLayerEditor(_api);

        _api.grid.isEnabled = true;

        this._laserTool = new LaserTool(this._api);
        this._clippingPlaneTool = new ClippingPlaneTool(this._api);
        this._polyLineTool = new PolyLineTool('polyline', this._api);
        this._areaTool = new AreaTool('area', this._api);
        this._dwgTool = new DwgTool(this._api);
        this._markupTool = new MarkupTool(this._api, this._dwgTool.dwgApi);
        this._divFps = document.getElementById('fps')!;

        this._progress = document.getElementById('progress') as TFProgress;
        this._btnResetView = document.getElementById('btnResetView') as HTMLButtonElement;
        this._btnCreateSheet = document.getElementById('createSheetBtn') as HTMLButtonElement;
        this._btnSaveSheets = document.getElementById('saveSheetsBtn') as HTMLButtonElement;
        this._btnDeleteSheet = document.getElementById('deleteSheetBtn') as HTMLButtonElement;
        this._btnDupeSheet = document.getElementById('duplicateSheetBtn') as HTMLButtonElement;
        this._btnDeleteEntity = document.getElementById('deleteEntityBtn') as HTMLButtonElement;
        this._btnRefreshSheets = document.getElementById('refreshSheetsBtn') as HTMLButtonElement;
        this._cbOrthoToggle = document.getElementById('cbOrthoToggle') as HTMLInputElement;
        this._cbTopdownToggle = document.getElementById('cbTopdownToggle') as HTMLInputElement;
        this._cbLaserTool = document.getElementById('cbLaserTool') as HTMLInputElement;
        this._cbClippingPlaneTool = document.getElementById('cbClippingPlaneTool') as HTMLInputElement;
        this._cbPolylineTool = document.getElementById('cbPolyLineTool') as HTMLInputElement;
        this._cbAreaTool = document.getElementById('cbAreaTool') as HTMLInputElement;
        this._cbMarkupTool = document.getElementById('cbMarkupTool') as HTMLInputElement;
        this._cbIcons = document.getElementById('cbIcons') as HTMLInputElement;
        this._cbBackfaceCulling = document.getElementById('cbBackfaceCulling') as HTMLInputElement;
        this._cbHsv = document.getElementById('cbHsv') as HTMLInputElement;

        this._heightNumberAreaTool = document.getElementById('heightNumberAreaTool') as HTMLInputElement;
        this._cbTwinfinityDwg = document.getElementById('cbTwinfinityDwg') as HTMLInputElement;
        this._cbSavePickTexture = document.getElementById('cbSavePickTexture') as HTMLInputElement;
        this._btnGetVisiblesInSight = document.getElementById('btnGetVisiblesInSight') as HTMLButtonElement;

        this._ifcClassSelector = new Selector<BimIfcClass>('ifcclass-selector', {
            caption: 'Classes',
            idAndTextSelector: (c) => {
                return { id: c.id, text: `${c.id} (${c.ifcObjectCount})` };
            },
            isMultiple: true
        });

        this._ifcChangeSelector = new Selector<BimChangeIfc>('ifcchange-selector', {
            caption: 'Files',
            idAndTextSelector: (c) => {
                return { id: c.id, text: `${c.name} v.${c.version} (${c.discipline.short})` };
            },
            isMultiple: true
        });

        this._pdfSelector = new Selector<BimChangeBlob>('pdf-selector', {
            caption: 'PDFs',
            idAndTextSelector: (c) => {
                return { id: c.id, text: `${c.name} v.${c.version}` };
            },
            isMultiple: false
        });

        this._dwgChangeSelector = new Selector<BimChangeDwg>('dwgchange-selector', {
            caption: '2D DWGS',
            idAndTextSelector: (c) => {
                return { id: c.id, text: `${c.name} v.${c.version}` };
            },
            isMultiple: true
        });

        this._markupSheetSelector = new Selector<MarkupSheet2D>('markupSheet-selector', {
            caption: 'Markup Sheets',
            idAndTextSelector: (c) => {
                return { id: c.id, text: `${c.id}` };
            },
            isMultiple: false
        });

        this._markupEntitySelector = new Selector<MarkupEntity>('markupentity-selector', {
            caption: 'Markup Entities',
            idAndTextSelector: (c) => {
                return { id: c.id, text: `${c.id}` };
            },
            isMultiple: true
        });

        this._ifcFloorSelector = new Selector<string>('ifcfloor-selector', {
            caption: 'Floors',
            idAndTextSelector: (c) => {
                return { id: c.toLowerCase(), text: c.toLowerCase() };
            },
            isMultiple: true
        });

        this._containerChangeSelector = new Selector<BimContainer>('project-selector', {
            caption: 'Project/Archive',
            idAndTextSelector: (c) => {
                const title = (c.hasGeometry ? 'G => ' : '') + c.name;
                return { id: c.id, text: title };
            }
        });
        this._txtCurrentlyHiglightedIfcObject = document.getElementById(
            'txtCurrentlyHiglightedIfcObject'
        ) as HTMLParagraphElement;

        this._ifcClassSelector.onChange.add((selection) => this.onIfcClassChanged());

        this._ifcChangeSelector.onChange.add((selection) => this.onIfcFileChanged(selection));
        this._pdfSelector.onChange.add((selection) => this.onPdfFileChanged(selection));
        this._dwgChangeSelector.onChange.add((selection) => this.onDwgFileChanged(selection));
        this._markupSheetSelector.onChange.add((selection) => this.onMarkupSheetChanged(selection));
        this._markupEntitySelector.onChange.add((selection) => this.onMarkupEntityChanged(selection));
        this._ifcFloorSelector.onChange.add((selection) => this.onIfcFloorChanged(selection));
        this._btnResetView.addEventListener('click', (ev) => this.onResetView(ev));
        this._btnGetVisiblesInSight.addEventListener('click', (ev) => this.onGetVisiblesInSight(ev));
        this._btnCreateSheet.addEventListener('click', (ev) => this.createSheet(ev));
        this._btnSaveSheets.addEventListener('click', (ev) => this.saveSheets(ev));
        this._btnDeleteSheet.addEventListener('click', (ev) => this.deleteSheet(ev));
        this._btnDupeSheet.addEventListener('click', (ev) => this.duplicateSheet(ev));
        this._btnDeleteEntity.addEventListener('click', (ev) => this.deleteSelectedEntites(ev));
        this._btnRefreshSheets.addEventListener('click', (ev) => this.refreshSheets(ev));
        this._cbOrthoToggle.addEventListener('input', () => this.onToggleOrthographicCamera());
        this._cbTopdownToggle.addEventListener('input', () => this.onToggleTopdownCamera());
        this._cbLaserTool.addEventListener('input', () => this.onToggleLaserTool());
        this._cbClippingPlaneTool.addEventListener('input', () => this.onToggleClippingPlaneTool());
        this._cbPolylineTool.addEventListener('input', () => this.onTogglePolylineTool());
        this._cbAreaTool.addEventListener('input', () => this.onToggleAreaTool());
        this._cbMarkupTool.addEventListener('input', () => this.onToggleMarkupTool());
        this._heightNumberAreaTool.addEventListener('input', () => this.onHeightNumberAreaToolUpdate());
        this._cbTwinfinityDwg.addEventListener('input', () => this.onToggleTwinfinityDwg());
        this._cbIcons.addEventListener('input', () => this.onToggleIcons());
        this._cbBackfaceCulling.checked = this._api.viewer.performance.isBackfaceCullingEnabled;
        this._cbBackfaceCulling.addEventListener(
            'input',
            () => (this._api.viewer.performance.isBackfaceCullingEnabled = this._cbBackfaceCulling.checked)
        );

        this._cbHsv.addEventListener('input', () => {
            this._api.viewer.visualSettings.ifc.color.isEnabled = this._cbHsv.checked;

            const white = [255, 255, 255];
            for (const o of this._api.products()) {
                if (o.class.is('walls')) {
                    if (this._cbHsv.checked) {
                        o.setColor(white);
                    } else {
                        o.defaultColor();
                    }
                }
            }
        });

        this._containerChangeSelector.onChange.add((selectedContainers) => this.onContainerChanged(selectedContainers));

        // Important. We reuse the same material to cut down on the number of render calls.
        this._spaceTransparentMaterial = new StandardMaterial('spaceTransparent', this._api.viewer.scene);
        this._spaceTransparentMaterial.sideOrientation = Material.ClockWiseSideOrientation;
        this._spaceTransparentMaterial.specularColor = Color3.Black();
        this._spaceTransparentMaterial.diffuseColor = Color3.Green();
        this._spaceTransparentMaterial.alpha = 0.2;
        this._spaceTransparentMaterial.zOffset = -20;

        // Enable for best performance. However if frozen it cannot be changed from babylon inspector
        // which is something we do a lot in the sample app while debugging.
        // this._spaceTransparentMaterial.freeze();
        // All space labels are placed below a specific babylonjs scene graph node.
        this._spaceLabelRootNode = new TransformNode('spaceLabelRoot', this._api.viewer.scene);
        this._spaceLabelCoordinateTracker = _api.createCoordinateTracker<SpaceLabel>();
        this._spaceLabelCoordinateTracker.onUpdateObservable.add((tC) => {
            tC.trackedCoordinate.updateScreenPosition(tC);
        });

        this.initializeKeyboardShortcuts();
        this.initializeMouseClick();

        this.addFpsCounter();
    }

    private addFpsCounter(): void {
        // Primitive fps counter. Notice that it only updates
        // HTML once a second and only if fps differs from previous value.
        // Done in order to cut down on DOM rerendering.
        const engine = this._api.viewer.engine;
        let prevFps = 0;
        const sw = new StopWatch(true);

        engine.onEndFrameObservable.add(() => {
            if (sw.elapsed > 1000) {
                const fps = Math.round(engine.getFps());
                if (!Scalar.WithinEpsilon(fps, prevFps, 1)) {
                    prevFps = fps;
                    this._divFps.innerHTML = `${fps.toFixed()} fps`;
                }
                sw.resetAndStart();
            }
        });
    }

    public async Start(): Promise<void> {
        // Shows how to get a list of all containers and build a simple container selector
        const containers = await this._api.backend.getContainers();
        // Really ugly sort to get containers with geometry in front of all other
        // projects.
        containers.sort((c0, c1) => {
            const c0Key = c0.hasGeometry ? 'A:' : 'B:' + c0.name;
            const c1Key = c1.hasGeometry ? 'A:' : 'B:' + c1.name;
            return c0Key.localeCompare(c1Key);
        });

        this._containerChangeSelector.build(containers);

        const url = new URL(window.location.href);
        const containerName = url.searchParams.get('container')?.toLocaleLowerCase();
        let preselectedContainer = containers.slice(0, 1)[0];
        if (containerName) {
            preselectedContainer =
                containers.filter((c) => c.name.toLocaleLowerCase() === containerName)[0] ?? preselectedContainer;
        }
        await this._containerChangeSelector.setSelection([preselectedContainer]);
        this._markdownEditor.value('*TEST*');
    }

    private initializeKeyboardShortcuts(): void {
        const scene = this._api.viewer.scene;
        const CONTROL = 'Control';
        scene.onKeyboardObservable.add((kbInfo, eS) => {
            switch (kbInfo.type) {
                case KeyboardEventTypes.KEYDOWN:
                    if (kbInfo.event.key === 'Delete') {
                        if (this._currentSelection.pickResult.type === PickResultType.IfcProductMesh) {
                            this._currentSelection.pickResult.ifcProductMesh.ifcProduct.visible(false);
                        } else if (this._currentSelection.pickResult.type === PickResultType.Icon) {
                            //this._currentSelection.pickResult.object.visible = false;
                            this._currentSelection.pickResult.object.detach();
                        } else if (this._currentSelection.pickResult.type === PickResultType.Mesh) {
                            this._currentSelection.pickResult.mesh.setEnabled(false);
                        }
                    } else if (kbInfo.event.key === 'n') {
                        if (this._currentSelection.pickResult.type === PickResultType.Mesh) {
                            VertexNormalsRenderer.create({ mesh: this._currentSelection.pickResult.mesh });
                        } else if (this._currentSelection.pickResult.type === PickResultType.IfcProductMesh) {
                            VertexNormalsRenderer.create({
                                mesh: this._currentSelection.pickResult.mesh,
                                ifcProduct: this._currentSelection.pickResult.ifcProductMesh.ifcProduct
                            });
                        }
                    } else if (kbInfo.event.key === CONTROL) {
                        if (this._clippingPlaneTool.isEnabled) {
                            this._clippingPlaneTool.setLockDown(true);
                        }
                    } else if (kbInfo.event.key === 'g') {
                        if (this._currentSelection.pickResult.type === PickResultType.IfcProductMesh) {
                            this._currentSelection.pickResult.ifcProductMesh.ifcProduct.productMeshes.forEach((p) => {
                                p.ghostOutline = true;
                            });
                        }
                    } else if (kbInfo.event.code === 'NumpadAdd') {
                        this._dwgTool.incrementPage();
                    } else if (kbInfo.event.code === 'NumpadSubtract') {
                        this._dwgTool.decrementPage();
                    }
                    break;
                case KeyboardEventTypes.KEYUP:
                    if (kbInfo.event.key === CONTROL) {
                        if (this._clippingPlaneTool.isEnabled) {
                            this._clippingPlaneTool.setLockDown(false);
                        }
                    }
                    break;
            }
        });
    }

    private initializeMouseClick(): void {
        // Shows how to set up a custom click operation bypassing api.click completely
        // to gain more control.

        const pointerDragThresholdTimeInMs = 200;

        this._api.onPointerObservable.add((eventData) => {
            // console.log(this.pointerName.get(eventData.type) ?? 'unknown');

            const pointerButtonInfo = eventData.twinfinity.button(PredefinedPointerButtonId.Main);
            if (this._clippingPlaneTool.isEnabled) {
                this.onClippingPlaneToolHandleInput(eventData);
            } else if (this._laserTool.isEnabled) {
                this.onLaserToolHandleInput(eventData);
            } else if (this._polyLineTool.isEnabled) {
                this.onPolyLineToolHandleInput(eventData);
            } else if (this._areaTool.isEnabled) {
                this.onAreaToolHandleInput(eventData);
            } else if (this._markupTool.isEnabled) {
                this.onMarkupToolHandleInput(eventData);
            } else if (pointerButtonInfo.event === 'up' && pointerButtonInfo.duration < pointerDragThresholdTimeInMs) {
                // Ok we may have clicked something. Try to find out what it was.
                const cR = new ChangeRecorder();
                cR.set((this._api.selectables as any).debugOptions, 'saveTexture', this._cbSavePickTexture.checked);

                this.onModelClick(eventData.twinfinity.pick(true));
                cR.restoreAll();
            }
        });
    }

    private onAreaToolHandleInput(eventData: DeepImmutable<PointerInfoWithTimings>): void {
        if (eventData.type === PointerEventTypes.POINTERTAP) {
            this._areaTool.attemptAddPolyAreaAtCurrentPointerToPosition();
            // Continious checks of functions.
            // console.log(this._areaTool.getArea());
            // console.log(this._areaTool.getInteriorPoint());
        }
        // } else if (eventData.type === PointerEventTypes.POINTERMOVE && this._polyLineTool.size > 1) {
        //     this._areaTool.moveEndPointWithMouseCursor();
        // } else if (eventData.type === PointerEventTypes.POINTERDOUBLETAP) {
        //     console.log(this._areaTool.getArea());
        //     console.log(this._areaTool.getInteriorPoint());
        //     this._areaTool.isDone = true;
        // }
    }

    private onMarkupToolHandleInput(eventData: DeepImmutable<PointerInfoWithTimings>): void {
        if (eventData.type === PointerEventTypes.POINTERTAP) {
            const pickInfo = this._api.selectables.pick({
                type: PickOptionType.Canvas,
                position: PredefinedCanvasPosition.Mouse,
                isGeometryIntersectionEnabled: true
            });

            if (pickInfo.hitInfo.length > 0 && this._markupSheetSelector.selection.size === 1) {
                const hitInfo = pickInfo.hitInfo;
                const sheet = [...this._markupSheetSelector.selection.values()][0];
                // const markupArea = this._markupTool.createMarkupArea(
                //     pickObject.product.gid,
                //     hitInfo[0].position,
                //     this._markupSheetSelector.selection.values().next().value
                // );

                // this._markupTool.createMarkupArea(hitInfo[0].position, sheet);
                // this._markupTool.createMarkupLine(hitInfo[0].position, sheet);
                this._markupTool.createMarkupArrow(hitInfo[0].position, sheet);
                // this._markupTool.createMarkupCircle(hitInfo[0].position, sheet);

                // this._markupTool.createMarkupText(
                //     this._markdownEditor.value(),
                //     hitInfo[0].position,
                //     hitInfo[0].normal,
                //     sheet,
                //     this._api.viewer.scene.getTransformNodeById('drawing-parent')!
                // );
                this._markupEntitySelector.build([...sheet]);
            }
        }
    }

    private onPolyLineToolHandleInput(eventData: DeepImmutable<PointerInfoWithTimings>): void {
        if (eventData.type === PointerEventTypes.POINTERTAP) {
            this._polyLineTool.attemptAddPointAtCurrentPointerPosition();
        } else if (eventData.type === PointerEventTypes.POINTERMOVE && this._polyLineTool.size > 1) {
            this._polyLineTool.moveEndPointWithMouseCursor();
        } else if (eventData.type === PointerEventTypes.POINTERDOUBLETAP) {
            this._polyLineTool.isDone = true;
        }
    }

    private onLaserToolHandleInput(eventData: DeepImmutable<PointerInfoWithTimings>): void {
        if (eventData.type === PointerEventTypes.POINTERDOWN && eventData.event.button === 0) {
            this._laserTool.attemptAddAtCurrentPointerPosition();
        }
    }

    private onClippingPlaneToolHandleInput(eventData: DeepImmutable<PointerInfoWithTimings>): void {
        if (eventData.type === PointerEventTypes.POINTERWHEEL) {
            eventData.event.preventDefault();
            const wheelEvent = eventData.event as WheelEvent;
            if (wheelEvent) {
                const scrollage = wheelEvent.deltaY;
                this._clippingPlaneTool.scrollPreviewClippingPlane(scrollage);
            } else {
                throw new Error('Not a wheel event?');
            }
        }
    }

    private onModelClick(pickResult: PickResult): void {
        // Clear any current selection
        this._currentSelection.changeRecorder.restoreAll();

        // If we selected same object again then we are satisfied (any previous selection
        // has already been cleared)
        if (this._currentSelection.pickResult.equals(pickResult)) {
            this._currentSelection.pickResult = PickResultEmpty.instance;
            return;
        }
        this._currentSelection.pickResult = pickResult;

        if (this._currentSelection.pickResult.type === PickResultType.IfcProductMesh) {
            const ifcProduct = this._currentSelection.pickResult.ifcProductMesh.ifcProduct;
            ifcProduct.highlight(IfcMaterialHighlightIndex.One);

            this._currentSelection.changeRecorder.onRestore(ifcProduct, (ifcProduct) => {
                ifcProduct.highlight(IfcMaterialHighlightIndex.None);
            });
        } else if (this._currentSelection.pickResult.type === PickResultType.Icon) {
            const originalColor = this._currentSelection.pickResult.object.color.clone();
            this._currentSelection.pickResult.object.color.copyFromFloats(0, 0.8, 0, originalColor.a);
            this._currentSelection.changeRecorder.onRestore(this._currentSelection.pickResult.object, (icon) => {
                icon.color.copyFrom(originalColor);
            });
        } else if (this._currentSelection.pickResult.type === PickResultType.Mesh) {
            this._currentSelection.changeRecorder.set(this._currentSelection.pickResult.mesh, 'showBoundingBox', true);
        }
    }

    private onIfcClassChanged(): void {
        this._api.foreach((o) => o.visible(this.isIfcObjectVisible(o)));
    }

    private async onIfcFileChanged(selection: DeepImmutableObject<Set<BimChangeIfc>>): Promise<void> {
        this._api.foreach((o) => o.visible(this.isIfcObjectVisible(o)));

        // load layer and populate
        if (selection.size === 0) {
            // Clear layer object editor
            await this._textObjectLayerEditor.loadLayerFor(undefined);
        } else {
            // Loading the layer will automatically create the objects which populate
            // the UI on their own.
            const ifc = selection.values().next().value;
            await this._textObjectLayerEditor.loadLayerFor(ifc);
        }
    }

    private async onDwgFileChanged(selection: DeepImmutableObject<Set<BimChangeDwg>>): Promise<void> {}
    private async onPdfFileChanged(selection: DeepImmutableObject<Set<BimChangeBlob>>): Promise<void> {}

    private onMarkupSheetChanged(selection: DeepImmutableObject<Set<MarkupSheet2D>>): void {
        for (const sheet of this._markupTool.sheets ?? []) {
            if (selection.has(sheet)) {
                //enable
                sheet.isEnabled = true;
                this._markupEntitySelector.build(
                    [...sheet].sort((c0, c1) => {
                        return c0?.id?.localeCompare(c1?.id) ?? 0;
                    })
                );

                //enable meshes

                for (const entity of sheet) {
                    entity.isEnabled = true;
                }
            } else {
                for (const entity of sheet) {
                    entity.isEnabled = false;
                }
                //disable
                sheet.isEnabled = false;
            }
        }
    }

    private onMarkupEntityChanged(selection: DeepImmutableObject<Set<MarkupEntity>>): void {}

    private initializeSpaceLabels(spaces: BimIfcSpace[]): void {
        const cameraForwardVector = Vector3.Zero();
        for (const space of spaces) {
            const sL = new SpaceLabel(
                this._api.viewer.engine,
                this._spaceLabelRootNode,
                this._spaceTransparentMaterial,
                (s) => {
                    const camera = this._api.viewer.camera.activeCamera;
                    // Note it is too slow to do this for every label (but fine for this sample).
                    // should be done once per frame instead
                    camera.getDirectionToRef(Axis.Z, cameraForwardVector);
                    const isVisibleForCamera = Math.abs(Vector3.Dot(Axis.Z, cameraForwardVector)) < 0.4;
                    if (!isVisibleForCamera) {
                        return false;
                    }
                    // space label is visible for all spaces that have no floors
                    // OR if exactly one floor is visible and the space belongs to that floor.
                    return (
                        s.enclosingFloor === undefined ||
                        (this._ifcFloorSelector.selection.size === 1 &&
                            this._ifcFloorSelector.selection.has(s.enclosingFloor.name.toLowerCase()))
                    );
                },
                (s) => this.isIfcObjectVisible(s),
                space
            );
            this._spaceLabelCoordinateTracker.track(sL, sL.id);
        }
    }

    private onIfcFloorChanged(selection: DeepImmutableObject<Set<string>>): void {
        // onIfcFloorChanged only occurs when selection has actually changed hence it is safe
        // to remove all space labels directly (clicking same item repeatadly in  selector will
        // not trigger this event so we now for sure that space labels must change).

        this.removePreviousSpaceLabels();
        if (selection.size === 1) {
            // Ensure that we have space labels for all spaces on the floor (and for those)
            // spaces that has no floor
            const spaces = this._api.ifc.spaces.filter(
                (s) => s.enclosingFloor === undefined || selection.has(s.enclosingFloor.name.toLowerCase())
            );
            this.initializeSpaceLabels(spaces);
        }

        this._api.foreach((o) => o.visible(this.isIfcObjectVisible(o)));
    }

    private onResetView(ev: MouseEvent): void {
        ev.preventDefault();
        this._api.camera.defaultView();
    }

    private onGetVisiblesInSight(ev: MouseEvent): any {
        ev.preventDefault();

        (this._api.selectables as any).debugOptions.saveTexture = true;
        const engine = this._api.viewer.engine;
        this._api.selectables.getVisiblesInSight({
            textureSize: {
                width: engine.getRenderWidth(),
                height: engine.getRenderHeight()
            },
            isDistanceCullingEnabled: true
        });
        (this._api.selectables as any).debugOptions.saveTexture = false;
    }

    private createSheet(ev: MouseEvent): void {
        if (this._markupTool === undefined) {
            return;
        }
        const name = prompt('Sheet name', 'Untitled sheet');
        if (name) {
            this._markupTool.createNewMarkupSheet(name, this._markupSheetSelector);
        }
    }

    private async saveSheets(ev: MouseEvent): Promise<void> {
        if (this._markupTool === undefined) {
            return;
        }
        const result = await this._markupTool.saveMarkupSheets();
        console.log(result);
        console.log(this._markupTool.sheets?._layer.conflictCount);
        console.log(this._markupTool.sheets?._layer.conflicts);
    }

    private async deleteSheet(ev: MouseEvent): Promise<void> {
        if (this._markupTool === undefined) {
            return;
        }
        const sheet = [...this._markupSheetSelector.selection.values()][0];
        console.log(sheet);
        await this._markupTool.deleteMarkupSheet(sheet, this._markupSheetSelector);
    }

    private async duplicateSheet(ev: MouseEvent): Promise<void> {
        if (this._markupTool === undefined) {
            return;
        }

        const name = prompt('Sheet name', 'Untitled sheet');
        const sheet = [...this._markupSheetSelector.selection.values()][0];
        if (name && sheet) {
            this._markupTool.duplicateMarkupSheet(sheet, name, this._markupSheetSelector);
        }
    }

    private async refreshSheets(ev: MouseEvent): Promise<void> {
        const drawing = [...this._dwgChangeSelector.selection.values()][0];

        if (!this._markupTool || !drawing) {
            return;
        }

        //If there is only a conflict on a sheet here and it is not due to a conflict in the properties
        //it is probably because the sheet was deleted on one client and an entity was added to it on another.
        const result = await this._markupTool.refresh();
        if (!isFailure(result)) {
            console.log(result);
            console.log(this._markupTool.sheets?._layer.conflictCount);
            console.log(this._markupTool.sheets?._layer.conflicts);
        }

        const sheets = this._markupTool.sheets ? [...this._markupTool.sheets] : [];

        this._markupSheetSelector.build(sheets.sort((c0, c1) => c0.id.localeCompare(c1.id)));
        if (sheets.length > 0) {
            this._markupSheetSelector.setSelection([sheets[0]]);
        }
    }

    private async deleteSelectedEntites(ev: MouseEvent): Promise<void> {
        const entities = [...this._markupEntitySelector.selection.values()];
        for (const entity of entities) {
            this._markupTool.deleteEntity(entity);
        }

        const selectedSheet: MarkupSheet2D = this._markupSheetSelector.selection.values().next().value;
        const remainingEntitesInSheet = [...selectedSheet];
        this._markupEntitySelector.build(remainingEntitesInSheet);
    }

    private onToggleOrthographicCamera(): void {
        if (this._cbOrthoToggle.checked) {
            this._api.camera.setProjection('orthographic');
        } else {
            this._api.camera.setProjection('perspective');
        }
    }

    private onToggleTopdownCamera(): void {
        this._api.viewer.camera.topDownMode = this._cbTopdownToggle.checked;
        const boundingInfo = this._api.ifc.regionBoundingInfo;

        if (this._cbTopdownToggle.checked) {
            this._api.viewer.camera.zoomToExtent(boundingInfo, 'top');
        }
        const o = this._api.viewer.camera.options;
        o.isRotationEnabled = !this._cbTopdownToggle.checked;
    }

    private onToggleLaserTool(): void {
        this._laserTool.isEnabled = this._cbLaserTool.checked;
    }

    private onToggleClippingPlaneTool(): void {
        this._clippingPlaneTool.isEnabled = this._cbClippingPlaneTool.checked;
    }

    private onTogglePolylineTool(): void {
        this._polyLineTool.isEnabled = this._cbPolylineTool.checked;
    }

    private onToggleAreaTool(): void {
        this._areaTool.isEnabled = this._cbAreaTool.checked;
    }

    private async onToggleMarkupTool(): Promise<void> {
        const dwg = [...this._dwgChangeSelector.selection.values()][0];
        const pdf = [...this._pdfSelector.selection.values()][0];
        if (
            (dwg === undefined && pdf === undefined) ||
            (!this._cbTwinfinityDwg.checked && this._cbMarkupTool.checked)
        ) {
            window.alert('Please select and open a DWG or PDF');
            this._cbMarkupTool.checked = false;
            return;
        }

        this._markupTool.isEnabled = this._cbMarkupTool.checked;
        if (this._markupTool.isEnabled) {
            const sheets: MarkupSheet2D[] = [];
            const tmp = await this._markupTool.loadMarkupSheets(pdf ?? dwg);
            if (tmp) {
                sheets.push(...tmp);
            }

            this._markupSheetSelector.build(sheets.sort((c0, c1) => c0.id.localeCompare(c1.id)));
            if (sheets.length > 0) {
                this._markupSheetSelector.setSelection([sheets[0]]);
            }
        } else if (!this._markupTool.isEnabled) {
            this._markupTool.unloadMarkupSheets();
            this._markupSheetSelector.clear();
            this._markupEntitySelector.clear();
        }
    }

    private onHeightNumberAreaToolUpdate(): void {
        this._areaTool.height = parseFloat(this._heightNumberAreaTool.value);
    }
    private async onToggleTwinfinityDwg(): Promise<void> {
        const isEnabled = this._cbTwinfinityDwg.checked;
        if (!isEnabled) {
            this._dwgTool.clear();
        } else {
            const dwgs = Array.from(this._dwgChangeSelector.selection.values());
            const pdfs = Array.from(this._pdfSelector.selection.values());

            if (dwgs && dwgs.length > 0) {
                await this._dwgTool.load(dwgs);
            } else if (pdfs && pdfs.length > 0) {
                await this._dwgTool.loadPdfs(pdfs[0]);
            }
        }
    }

    private async onToggleIcons(): Promise<void> {
        const addIcons = this._cbIcons.checked;
        if (!addIcons) {
            this._iconHandler?.clear();
            return;
        }

        this._iconHandlerTexture =
            this._iconHandlerTexture ??
            (await getTexture(
                this._api.viewer.scene,
                new URL('./assets/icon-atlas.png', location.origin + location.pathname)
            ));

        this._iconHandler =
            this._iconHandler ??
            new IconHandler(this._api, {
                iconAtlasTexture: this._iconHandlerTexture,
                totalNumberOfIconsAndStyles: 100,
                numberOfIconsInAStyle: 1,
                hasAlpha: true,
                /*alphaSortIntervalInMs: 2000 ,*/
                iconMaxSize: 32,
                occlusionCullingIntervalMs: 1000
            });
        this._iconHandler.renderGroup = 1;

        const iconClassMap = new Map<BimIfcClass, number>();
        this._api.ifc.classes.forEach((c) => iconClassMap.set(c, Math.floor(Math.random() * 96)));
        iconClassMap.set(BimIfcClass.getOrAdd('IfcWindow'), 2);
        iconClassMap.set(BimIfcClass.getOrAdd('IfcDoor'), 65);
        iconClassMap.set(BimIfcClass.getOrAdd('IfcWall'), 82);
        iconClassMap.set(BimIfcClass.getOrAdd('IfcWallStandarCase'), 82);
        iconClassMap.set(BimIfcClass.getOrAdd('IfcRoof'), 49);
        iconClassMap.set(BimIfcClass.getOrAdd('IfcColumn'), 42);
        iconClassMap.set(BimIfcClass.getOrAdd('IfcSpace'), 64);
        iconClassMap.set(BimIfcClass.getOrAdd('IfcStair'), 71);
        this._api.foreach((o) => {
            this._iconHandler!.attach(
                new Icon(
                    o.gid,
                    iconClassMap.get(o.class)!,
                    0,
                    1, //Math.random() * 2,
                    o.boundingInfo().boundingSphere.center,
                    new Color4(
                        Math.random() * 0.6 + 0.4,
                        Math.random() * 0.6 + 0.4,
                        Math.random() * 0.6 + 0.4,
                        Math.random()
                    ),
                    true,
                    true
                )
            );
        });
    }

    private async onContainerChanged(selectedContainers: DeepImmutable<Set<BimContainer>>): Promise<void> {
        // Disable icon handlers
        this._cbIcons.checked = false;

        this.removePreviousSpaceLabels();
        this.removeMilktruck();

        // Take the currently selected container and load IFC files in it.
        const sC: BimContainer = selectedContainers.values().next().value;

        this._api.setBackgroundColor(Color3.Black());
        this._progress.clear();

        await this._api.setContainer(sC, 'skip-ifc-load');
        const { ifc } = this._api;

        const prgFetchFiles = this._progress.appendText('Fetch ifc, dwg and pdf...');
        const [ifcFiles, dwgChanges, pdfs] = await Promise.all([
            this._api.backend.getIfcChanges(sC),
            this.getProcessed2dDwgs(sC),
            this.getProcessedPdfs(sC)
        ]);
        prgFetchFiles.text = 'Fetch ifc, dwg and pdf. Done!';

        // Get all processed 2d dwgs from the selected container, this will be used to popilate the UI selector
        this._dwgChanges = dwgChanges;

        this._dwgChangeSelector.build(this._dwgChanges.sort((c0, c1) => c0.name.localeCompare(c1.name)));
        this._pdfSelector.build(pdfs.sort((c0, c1) => c0.name.localeCompare(c1.name)));
        const filesToLoadInitially = ifcFiles.filter(
            (ifc) => ifc.discipline.short === 'A' || ifc.discipline.short === 'K' || /^A/i.test(ifc.name)
        );
        const filesToLoadNext = ifcFiles.filter((ifc) => !filesToLoadInitially.includes(ifc));

        await this.appendIfcFilesToViewer({
            ifcFiles: filesToLoadInitially,
            ifc,
            viewer: this._api.viewer,
            positionCameraToFitBounds: true,
            // Exclude flow elements as they are often very detailed and we want a fast initial load
            // This is app/scenario specific and should not be seen as a general rule.
            geometryPredicate: (o) => o.class.type !== 'flow'
        });

        // If we have more files or if there are existing 'flow' elements not yet visulized
        // then display a button to load them
        if (filesToLoadNext.length > 0 || Array.from(ifc.products()).some((p) => !p.isOnGpu)) {
            const btn = new TFButton({ text: `Load & display remaining IFC products.` });
            btn.style.float = 'right';
            const btnLoadTheRest = this._progress.appendChild(btn);
            btnLoadTheRest.onClickObservable.addOnce(async () => {
                const geometryHandles = await this.appendIfcFilesToViewer({
                    ifcFiles: filesToLoadNext,
                    ifc,
                    viewer: this._api.viewer,
                    geometryPredicate: (o) => true
                });

                // Now provide option to unload the data again.
                const btnUnload = new TFButton({ text: `Unload & hide remaining IFC products.` });
                btnUnload.style.float = 'right';
                this._progress.appendChild(btnUnload);
                btnUnload.onClickObservable.addOnce(() => {
                    btnUnload.remove();
                    geometryHandles.forEach((gH) => this._api.viewer.removeMesh(gH));
                });
            });
        }

        // Load a milk truck
        await this.ensureMilktruckLoaded();

        // Get all dwgs of different status'es and list them in the console.
        // This is just here to show that it is possible to get the DWG's
        for (const status of [BimChangeStatus.Processed, BimChangeStatus.NotSupported, BimChangeStatus.Failed]) {
            const resp = await this._api.backend.getChanges(sC, PredefinedBimChangeMetadataQuery.dwg(status));
            if (resp.ok) {
                const dwgs = await resp.value;
                if (dwgs?.length > 0) {
                    console.log(`### DWG ${status}`, dwgs);
                }
            }
        }

        // Sample to list all processed docx files. You can get other types of files in the same way
        // jpg, png, html, js whatever you have stored in the backend.
        const resp = await this._api.backend.getChanges(
            sC,
            PredefinedBimChangeMetadataQuery.blob('docx', BimChangeStatus.Processed)
        );
        if (resp.ok) {
            const docxs = await resp.value;
            if (docxs?.length > 0) {
                console.log(`### DOCX ${status}`, docxs);
            }
        }
    }

    private async appendIfcFilesToViewer({
        ifcFiles,
        ifc,
        viewer,
        positionCameraToFitBounds,
        geometryPredicate
    }: {
        ifcFiles: BimChangeIfc[];
        ifc: BimIfcLoader;
        viewer: TwinfinityViewer;
        positionCameraToFitBounds?: boolean;
        geometryPredicate: (o: BimIfcObject) => unknown;
    }): Promise<Geometry3dHandle[]> {
        const sw = new StopWatch(true);
        const evtAddIfc = telemetry.startTrackEvent('Adding ifc files');
        const prgloadIfcHiearchy = this._progress.appendText('Load ifc hiearchy...');
        const prgLoadPropertySets = this._progress.appendText(`Load ifc property sets...`);

        // Load only A and K files first. We do this to show that its possibly to lazy load other files
        // later on. Different apps will have different requirements.
        await ifc.add(ifcFiles);
        viewer.addOrUpdateEnvironment({
            boundingInfo: ifc.regionBoundingInfo,
            positionCameraToFitBounds: positionCameraToFitBounds ?? false
        });

        this.hideSpacesIfOtherIfcProductsExist(ifc);
        // Start loadin property sets in the background
        const propertySetPromise = ifc.loadPropertySets();
        prgloadIfcHiearchy.text = 'Load ifc hiearchy. Done!';
        evtAddIfc.stop();

        // Populate some UI controls with data from loaded IFC files.
        this._ifcChangeSelector.build(ifc.ifcHttpResources.sort((c0, c1) => c0.name.localeCompare(c1.name)));
        this._ifcClassSelector.build(ifc.classes.sort((c0, c1) => c0.id.localeCompare(c1.id)));

        const sortOptions: Intl.CollatorOptions = { numeric: true, sensitivity: 'base' };
        const uniqueFloorNames = [...new Set<string>(ifc.floors.map((f) => f.name.toLowerCase()))].sort((c0, c1) =>
            c0.localeCompare(c1, undefined, sortOptions)
        );
        this._ifcFloorSelector.build(uniqueFloorNames);

        const evtLoadGeometry = telemetry.startTrackEvent('loading geometry.');
        const prgFetchIfcGeom = this._progress.appendText('Fetch ifc geometry...');
        // In this sample we initially skip loading flow which often has a lot of data (toilets etc).
        // We do this to as it will probably give a good initial FPS (good user experience). However
        // different apps will have different requirements.
        const geometryBuilder = await ifc.geometryBuilder(geometryPredicate);
        prgFetchIfcGeom.text = 'Fetch ifc geometry. Done!';
        let prevProgress = 0;

        const prgBuildIfcGeom = this._progress.appendText('Build ifc geometry...');

        const geometries = await geometryBuilder.build(
            ({ geometry, progress, totalIndiceCount, processedIndiceCount }) => {
                viewer.addOrReplaceMesh(geometry);
                if (prevProgress !== progress) {
                    prgBuildIfcGeom.text = `Build ifc geometry  ${progress}%.`;
                    prevProgress = progress;
                }
            }
        );
        prgBuildIfcGeom.text = `Build ifc geometry 100%.`;
        evtLoadGeometry.stop();

        await propertySetPromise; // Await property sets to be loaded
        prgLoadPropertySets.text = `Load ifc property sets. Done!`;
        this._progress.appendText(`Done in ${(sw.totalElapsed / 1000.0).toFixed(2)} sec`);
        telemetry.trackTrace({ message: `Building loaded in ${sw.totalElapsed} ms`, severityLevel: 1 });

        await yieldThreadFor(1000);
        this._progress.clear();
        return geometries;
    }

    private hideSpacesIfOtherIfcProductsExist(ifc: BimIfcLoader): void {
        const onlySpacesHasGeometry = ifc.spaces.length === ifc.ifcProductsWithGeometryCount;
        if (!onlySpacesHasGeometry) {
            for (const space of this._api.ifc.spaces) {
                space.visible(false);
            }
        }
    }

    private async ensureMilktruckLoaded(): Promise<void> {
        if (!this._isMilktruckLoaded) {
            this._isMilktruckLoaded = true;
            SceneLoader.ShowLoadingScreen = false;
            // Loads a hiearchy below __root__/Yup2Zup/
            // this is coded into the glb file. It is possible to use other methods on SceneLoader to
            // get only parts of the glb or load beneath a different node in the scene if that is required.
            await SceneLoader.AppendAsync('./assets/cesiummilktruck.glb', '', this._api.viewer.scene);
            const root = this._api.viewer.scene.getMeshById('__root__');
            const attached: Mesh[] = [];
            for (const childMesh of root?.getChildMeshes() ?? []) {
                if (childMesh instanceof Mesh) {
                    // Make childMesh pickable.
                    childMesh.isPickable = true;
                    this._api.selectables.attach(childMesh);
                    attached.push(childMesh);
                }
            }
        }
    }

    private removeMilktruck(): void {
        const root = this._api.viewer.scene.getMeshById('__root__');
        root?.dispose(); // Ensures that all children are disposed and that this._api.selectables.detach is called for each child
        this._isMilktruckLoaded = false;
    }

    private removePreviousSpaceLabels(): void {
        for (const [, sL] of this._spaceLabelCoordinateTracker.entries()) {
            sL.dispose();
        }

        this._spaceLabelCoordinateTracker.clear();
    }

    private isIfcObjectVisible(o: BimIfcObject): boolean {
        let isVisible = this._ifcClassSelector.selection.size === 0 || this._ifcClassSelector.selection.has(o.class);
        isVisible &&= this._ifcChangeSelector.selection.size === 0 || this._ifcChangeSelector.selection.has(o.ifc);
        isVisible &&=
            this._ifcFloorSelector.selection.size === 0 ||
            (!!o.enclosingFloor && this._ifcFloorSelector.selection.has(o.enclosingFloor.name.toLowerCase()));
        return isVisible;
    }

    private async getProcessed2dDwgs(container: BimContainer): Promise<BimChangeDwg[]> {
        const resp = await this._api.backend.getChanges(
            container,
            PredefinedBimChangeMetadataQuery.dwg(BimChangeStatus.Processed)
        );

        if (resp.ok) {
            const dwgs = await resp.value;
            return dwgs.filter((dwg) => dwg.metadata.dwg.type === '2d');
        }
        return [];
    }

    private async getProcessedPdfs(container: BimContainer): Promise<BimChangeBlob[]> {
        const respPDF = await this._api.backend.getChanges(
            container,
            PredefinedBimChangeMetadataQuery.pdf(BimChangeStatus.Processed)
        );

        if (respPDF.ok) {
            const pdfs = await respPDF.value;
            console.log(pdfs);
            return pdfs;
        }
        return [];
    }
}

let app: App;
window.addEventListener('DOMContentLoaded', async () => {
    const client = new BimTwinfinityApiClient(new URL('https://bim.bit.projektstruktur.se'));
    const api = await BimApi.create('viewer', client, {
        isPSAuthenticationRedirectEnabled: true,
        applicationName: 'samples/viewer'
    });
    api.viewer.isSkyboxEnabled = false;
    api.viewer.grid.isEnabled = true;

    const canvas = api.viewer.engine.getInputElement()!;

    // Allow DOM nodes to be dragged over the canvas. Required for Area tool. Othwerwise
    // it will not be possible to drag the HTML nodes of the polygon.
    canvas.addEventListener('ondragover', (ev) => ev.preventDefault());

    api.camera.attachBehavior(PostProcessEffects.createLineShading());
    api.camera.attachBehavior(PostProcessEffects.createSSAOPipeline()); //16, 0.113, 16, 0.0513, 1.0));

    app = new App(api);
    await app.Start();
});
