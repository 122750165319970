import { Matrix } from '../loader/babylonjs-import';
import { Vertex3 } from '../math';
import { CoordinateTracker, TrackCoordinate3D } from './CoordinateTracker';
import { DynamicPolygonPoint } from './DynamicPolygonPoint';

/**
 * Represents result of {@link validatePoints} function.
 */
export enum DynamicPolygonPointValidationResult {
    /** Result is unknown */
    Unknown = 0,
    /** Result Ok */
    Ok = 1,
    /** Result is a Complex Polygon */
    ComplexPolygon = 2,
    /** Result is given when one point lies outside of the plane */
    PointsDoesNotLieInPlane = 3,
    /** Result if the set of points is insufficient */
    NotEnoughPoints = 4
}

export interface DynamicPolygonPointParent<T extends TrackCoordinate3D> {
    /** @hidden */
    _head?: T;

    /** @hidden */
    readonly _dynamicPolygonPointTracker: CoordinateTracker<T>;

    /** @hidden */
    _virtualPointFactory(): T;

    /** @hidden */
    apply(): void;

    isEnabled: boolean;

    /** @hidden */
    worldMatrix(): Matrix;

    /** @hidden */
    _validatePoints(points: Vertex3[]): DynamicPolygonPointValidationResult;

    /** @hidden */
    _minPoints: number;

    /** @hidden */
    _dragPoint?: DynamicPolygonPoint;
}
