import { ThinEngine } from '@babylonjs/core/Engines/thinEngine';
import { Extensions } from './Extensions';
import { ISize } from './loader/babylonjs-import';

/**
 * Twinfinity extension methods for BabylonJS `ThinEngine`.
 */
export interface TwinfinityThinEngineExtensions {
    /**
     * Writes current engine render size to the specified parameter
     * and returns it.
     * Same as calling `ThinEngine.getRenderWidth()` and `ThinEngine.getRenderHeight`.
     * @param dst render size written here.
     * @returns `dst` parameter.
     */
    getRenderSizeToRef(dst: ISize): ISize;
}

declare module '@babylonjs/core/Engines/thinEngine' {
    export interface ThinEngine {
        /**
         * Twinfinity extension methods for BabylonJS `ThinEngine`.
         */
        readonly twinfinity: TwinfinityThinEngineExtensions;
    }
}

class ThinEngineExtensions implements TwinfinityThinEngineExtensions {
    constructor(readonly _thinEngine: ThinEngine) {}
    getRenderSizeToRef(dst: ISize): ISize {
        dst.width = this._thinEngine.getRenderWidth();
        dst.height = this._thinEngine.getRenderHeight();
        return dst;
    }
}

Object.defineProperty(ThinEngine.prototype, Extensions.TWINFINITY_PROPERTY, {
    enumerable: false,
    configurable: true,
    get: function (this: ThinEngine): TwinfinityThinEngineExtensions {
        const ret = new ThinEngineExtensions(this);
        Object.defineProperty(this, Extensions.TWINFINITY_PROPERTY, {
            value: ret
        });
        return ret;
    }
});
