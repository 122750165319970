import {
    BimApi,
    MeasureHandler,
    PickOptionType,
    PredefinedCanvasPosition,
    TrackCoordinate2DState
} from '@twinfinity/core';

/**
 * Simple wrapper around the {@link MeasureHandler} for handling laser tool
 * measurements with a custom label visualization.
 */
export class LaserTool {
    private readonly _measureHandler: MeasureHandler;
    private _nextLaserId = 0;
    public constructor(private readonly _api: BimApi) {
        this._measureHandler = new MeasureHandler(_api);
    }

    /**
     * `true` If laser tool is activated
     */
    public get isEnabled(): boolean {
        return this._measureHandler.isLaserPreviewActive;
    }

    /**
     * Is set to `true` to activate laser tool. Is set to `false` to deactivate
     * and remove all current lasers.
     */
    public set isEnabled(v: boolean) {
        if (v) {
            this._measureHandler.activateLaserPreview();
        } else {
            this._measureHandler.clear();
        }
    }

    /**
     * Attempts to add a laser at the current pointer position.
     * @returns Id of added laser or undefined if none could be added.
     */
    public attemptAddAtCurrentPointerPosition(): number | undefined {
        const laserOrigin = this._api.selectables.pick({
            type: PickOptionType.Canvas,
            position: PredefinedCanvasPosition.Mouse,
            isGeometryIntersectionEnabled: true // Here we do need exact click position
        }).hitInfo[0];

        if (laserOrigin) {
            const laserId = this._nextLaserId++;
            const lblDomId = `lblLaser${laserId}`;
            this._measureHandler.createWallLaser(laserId, laserOrigin, 0.75, (screenTrackCoordinate, eventData) => {
                // try to get dom element from body by id
                // if no label (first time for this id), create it
                const state = screenTrackCoordinate.state;
                if (state === TrackCoordinate2DState.Added) {
                    // create dom element set some styles to make it appear on canvas
                    const label = document.createElement('div');
                    label.id = lblDomId;
                    label.style.backgroundColor = 'white';
                    label.style.padding = '3px';
                    label.style.position = 'absolute';
                    label.style.cursor = 'pointer';
                    label.innerText = Math.round(eventData!.length * 1000) / 1000 + 'm';

                    // add click event to label, remove it on click
                    label.addEventListener('click', () => {
                        this._measureHandler.removeWallLaser(laserId); // Will trigger another callback call
                    });

                    //update label position
                    label!.style.left = screenTrackCoordinate.position.x + 'px';
                    label!.style.top = screenTrackCoordinate.position.y + 'px';
                    label!.style.display = screenTrackCoordinate.visible ? 'block' : 'none';

                    // add label to dom
                    document.body.appendChild(label);
                } else if (state === TrackCoordinate2DState.Deleted) {
                    const label = document.getElementById(lblDomId);
                    label?.remove();
                } else if (state === TrackCoordinate2DState.Updated) {
                    //update label position
                    const label = document.getElementById(lblDomId);
                    if (label) {
                        label.style.left = screenTrackCoordinate.position.x + 'px';
                        label.style.top = screenTrackCoordinate.position.y + 'px';
                        label.style.display = screenTrackCoordinate.visible ? 'block' : 'none';
                    }
                }
            });
            return laserId;
        }
    }
}
