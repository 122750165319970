import { Effect, PostProcess, Camera } from '../loader/babylonjs-import';
import { ShortFrustumDepthRenderer } from '../loader/ShortFrustumDepthRenderer';
import { BaseLineShading, TEXTURE_SAMPLER } from './BaseLineShading';
import { NEAR_FAR } from './TwinfinityPostProcess';

/**
 * LineShading is a post-process effect that adds an outline to objects in the viewer.
 */

const SHADER_NAME_PREFIX = 'outline';
const DEPTH_SAMPLER = 'depthSampler';

export class LineShading extends BaseLineShading {
    private _shortFrustumDepthRenderer: ShortFrustumDepthRenderer;

    constructor(lineThickness: number) {
        super();
        this.lineThickness = lineThickness;
    }

    protected initialize(camera: Camera): PostProcess {
        const engine = camera.getEngine();

        const samplers = [DEPTH_SAMPLER];
        const defines: string[] = [];

        samplers.push(TEXTURE_SAMPLER);

        if (this._postProcess === undefined) {
            Effect.ShadersStore[SHADER_NAME_PREFIX + 'FragmentShader'] = LineShading._shaderSource;
            const newPostProcess = new PostProcess(
                'Depth based outline post-process',
                SHADER_NAME_PREFIX,
                ['thickness', NEAR_FAR],
                samplers,
                1 /* ratio */,
                null,
                undefined,
                engine,
                false,
                defines.join('\n')
            );

            const viewer = camera.getScene().twinfinity.viewer;
            if (viewer) {
                const shortFrustumProjectionMatrixState = camera.twinfinity.shortFrustum;
                this._shortFrustumDepthRenderer = viewer.enableDepthRenderer(camera.mode !== Camera.PERSPECTIVE_CAMERA);
                newPostProcess.onApplyObservable.add((effect) => {
                    this.updateLineThicknessUniform(effect, this.lineThickness);
                    effect.setFloat2(
                        NEAR_FAR,
                        shortFrustumProjectionMatrixState.depthRenderCameraMinZ,
                        shortFrustumProjectionMatrixState.depthRenderCameraMaxZ
                    );
                    effect.setTexture(DEPTH_SAMPLER, this._shortFrustumDepthRenderer.depthRenderer.getDepthMap());
                });
            } else {
                throw new Error('No viewer created?');
            }
            return newPostProcess;
        } else return this._postProcess;
    }

    private static readonly _shaderSource = `
    #ifdef GL_ES
        precision highp float;
    #endif

    varying vec2 vUV;

    // Samplers
    uniform sampler2D textureSampler;
    uniform sampler2D depthSampler;

    uniform float thickness;
    uniform vec2 ${NEAR_FAR};

    float getOutlineByDepth(ivec2 currentTexel)
    {
        const int offset = 1;
        float depthUp    = texelFetchOffset(depthSampler, currentTexel, 0, ivec2(0,  offset)).x;
        float depthDown  = texelFetchOffset(depthSampler, currentTexel, 0, ivec2(0, -offset)).x;
        float depthLeft  = texelFetchOffset(depthSampler, currentTexel, 0, ivec2(-offset, 0)).x;
        float depthRight = texelFetchOffset(depthSampler, currentTexel, 0, ivec2(offset, 0)).x;
        float depthMid   = texelFetchOffset(depthSampler, currentTexel, 0, ivec2(0, 0)).x;
        float delta      = ((depthMid)*4.0) - ((depthUp) + (depthDown) + (depthRight) + (depthLeft)); 
        
        return step(0.01 * depthMid, abs(delta)) * -thickness;
    }

    void main(void) 
    {
        ivec2 texel = ivec2(gl_FragCoord.xy);

        vec4 depthColor = texture2D(depthSampler, vUV);
        // vec4 depth = vec4(depthColor.x, depthColor.x, depthColor.x, 1.0); // DEBUG: Show Depth

        // 10.0 is how fast the outlines fade with distance.
        const float fadeDistance = 10.0;
        float fadeDepth = depthColor.x * fadeDistance;
        fadeDepth = clamp(fadeDepth, 0.0, 1.0);
        fadeDepth = 1.0 - fadeDepth;

        // Fade out the line strength the closer we get to the short distance view frustum
        float distanceFade = 1.0 - smoothstep(0.1, 0.9, depthColor.r);
        
        float outline = getOutlineByDepth(texel);
        
        
        vec4 baseColor = texture2D(textureSampler, vUV);
        baseColor.rgb *= (outline * fadeDepth * distanceFade) + 1.0;
        gl_FragColor = baseColor; // DEBUG: Show Depth
    }
    `;

    detach(camera: Camera): boolean {
        const detached = super.detach(camera);

        if (detached) {
            this._shortFrustumDepthRenderer.disable();
        }

        return detached;
    }
}
