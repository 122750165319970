/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { telemetry } from '../Telemetry';
import { BimIfcIndex } from './BimIfcIndex';
import { MemoryBlock } from './MemoryBlock';
import { BimProductMeshDescriptor, BimVertexData } from './bim-format-types';
import { BimVertexDataReader } from './bim-vertex-data-reader';
import { StopWatch, yieldThreadFor } from './stopwatch';

/**
 * Represents a memory block for storing geometry data. That is vertices, normals and indices.
 */
export class GeometryMemoryBlock {
    private _vertexDatacache = new Map<string, { refCount: number; data: BimVertexData }>();
    private _byteSize = 0;
    private _importId = -1;

    /**
     * Gets the size of the memory block in bytes.
     * @returns The size of the memory block in bytes.
     */
    public get byteSize(): number {
        return this._byteSize;
    }

    /**
     * Imports packed vertex data into the geometry memory block.
     *
     * @param reader - The {@link BimVertexDataReader} used to read the vertex data.
     * @param meshDescriptors - The mesh descriptors containing information about the vertex data in {@link BimVertexDataReader}.
     * Mesh descriptors that are already imported are ignored (so we never store duplicated data).
     * @returns A Promise that resolves when the vertex data is imported.
     */
    public async importPackedVertexData(
        reader: BimVertexDataReader,
        meshDescriptors: BimIfcIndex['meshDescriptors']
    ): Promise<void> {
        // To avoid fragmenting the heap too much (and for performance reasons) we allocate
        // large memory blocks for vertices, normals and indices. To size them we have to first
        // look at all meshdescriptors and calculate the size required. Then we allocated
        // the blocks and finally we then allocate smaller "slices" (one per mesh descriptor)
        // ,from the memory blocks, and hand them out as BimVertexData instances.

        const event = telemetry.startTrackEvent('Loaded geometry data into buffer.');

        this._importId++;
        const vertexMemoryBlock = new MemoryBlock(`vertices_${this._importId}`, Float32Array);
        const packedNormalsMemoryBlock = new MemoryBlock(`packedNormals_${this._importId}`, Uint16Array);
        const uint8IndicesMemoryBlock = new MemoryBlock(`indicesUint8_${this._importId}`, Uint8Array);
        const uint16IndicesMemoryBlock = new MemoryBlock(`indicesUint16_${this._importId}`, Uint16Array);
        const uint32IndicesMemoryBlock = new MemoryBlock(`indicesUint32_${this._importId}`, Uint32Array);
        const deduplicated = { count: 0, byte: 0, primitives: 0, indices: 0 };
        try {
            const deadline = new StopWatch(true);

            const meshDescriptorsToAllocate: BimProductMeshDescriptor[] = [];

            // Calculate the number of primitives and indices required so we can size the memory blocks
            // Ignore already imported mesh descriptors
            for (const meshDescriptor of meshDescriptors) {
                if (meshDescriptor === undefined) continue;

                // Check if we already have this mesh descriptor. If so dont import it again.
                const existing = this._vertexDatacache.get(meshDescriptor.h);
                if (existing) {
                    this.updateDeduplicationStats(deduplicated, existing.data);
                    existing.refCount++;
                    continue;
                }

                meshDescriptorsToAllocate.push(meshDescriptor);

                vertexMemoryBlock.length += meshDescriptor.p * 3; // Expand for vertices
                packedNormalsMemoryBlock.length += meshDescriptor.p; // Expand for normals
                indiceBlock(meshDescriptor).length += meshDescriptor.i; // Expand for indices

                if (deadline.elapsed > 150) {
                    await yieldThreadFor(4);
                    deadline.resetAndStart();
                }
            }

            if (meshDescriptorsToAllocate.length === 0) return;

            // We have meshdescriptors to import. Memory blocks are sized correctly
            // so now we can allocate geometry data from the memory blocks and copy
            // the packed vertex data into them.
            for (const meshDescriptor of meshDescriptorsToAllocate) {
                this._vertexDatacache.set(meshDescriptor.h, {
                    refCount: 1,
                    data: reader.deflateToRef(meshDescriptor, {
                        positions: vertexMemoryBlock.subarray(meshDescriptor.p * 3),
                        packedNormals: packedNormalsMemoryBlock.subarray(meshDescriptor.p),
                        indices: indiceBlock(meshDescriptor).subarray(meshDescriptor.i)
                    })
                });

                if (deadline.elapsed > 150) {
                    await yieldThreadFor(4);
                    deadline.resetAndStart();
                }
            }

            this._byteSize +=
                vertexMemoryBlock.byteLength +
                packedNormalsMemoryBlock.byteLength +
                uint8IndicesMemoryBlock.byteLength +
                uint16IndicesMemoryBlock.byteLength +
                uint32IndicesMemoryBlock.byteLength;
        } finally {
            event.stop({ importId: this._importId, deduplicated });
        }

        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        function indiceBlock(md: BimProductMeshDescriptor) {
            if (md.p < 256) return uint8IndicesMemoryBlock;
            if (md.p < 65536) return uint16IndicesMemoryBlock;
            return uint32IndicesMemoryBlock;
        }
    }

    /**
     * Checks if the given {@link BimProductMeshDescriptor} exists in the geometry memory block.
     * @param meshDescriptor - The {@link BimProductMeshDescriptor} to check.
     * @returns `true` if the {@link BimProductMeshDescriptor} exists, `false` otherwise.
     */
    public has(meshDescriptor: BimProductMeshDescriptor): boolean {
        return this._vertexDatacache.has(meshDescriptor.h);
    }

    /**
     * Retrieves the vertex data for a given BimProductMeshDescriptor.
     *
     * @param meshDescriptor - The BimProductMeshDescriptor object.
     * @returns The BimVertexData associated with the given mesh descriptor. `undefined` if the mesh descriptor is not found.
     */
    public getVertexData(meshDescriptor: BimProductMeshDescriptor): BimVertexData | undefined {
        return this._vertexDatacache.get(meshDescriptor.h)?.data;
    }

    /**
     * Resets the GeometryMemoryBlock freeing all the used memory.
     * It is safe to call {@link importPackedVertexData} after calling this method.
     */
    public reset(): void {
        this._vertexDatacache.clear();
    }

    private updateDeduplicationStats(
        deduplicated: { count: number; byte: number; primitives: number; indices: number },
        data: BimVertexData
    ): void {
        deduplicated.count++;
        deduplicated.byte +=
            data.indices.length * data.indices.BYTES_PER_ELEMENT +
            data.positions.length * data.positions.BYTES_PER_ELEMENT +
            data.packedNormals.length * data.packedNormals.BYTES_PER_ELEMENT;
        deduplicated.primitives += data.positions.length;
        deduplicated.indices += data.indices.length;
    }
}
