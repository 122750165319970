/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { BimIfcIndex } from './BimIfcIndex';
import { BimObjectAttribute } from './BimObjectAttribute';
import { BimIndexableIfcObject, BimTypeObject } from './BimTypeObject';

export class BimTypeObjectReader {
    public read(index: BimIfcIndex, objectAttributes: BimObjectAttribute[]): BimTypeObject[] {
        const typeObjects: BimTypeObject[] = [];
        for (const raw of index.types ?? []) {
            const attributes: BimIndexableIfcObject = {};
            for (const attributeIndex of raw.a ?? []) {
                const rawAttribute = objectAttributes[attributeIndex];
                attributes[rawAttribute.name] = rawAttribute.value;
            }

            const typeObject = new BimTypeObject(
                raw.id,
                raw.gid,
                index.typeClasses[raw.c ?? 0],
                raw.t == null ? undefined : index.texts[raw.t],
                raw.n == null ? undefined : index.texts[raw.n],
                raw.d == null ? undefined : index.texts[raw.d],
                attributes
            );

            typeObjects.push(typeObject);
        }

        return typeObjects;
    }
}
