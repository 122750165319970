/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { telemetry } from '../Telemetry';
import { DeepImmutable } from './babylonjs-import';
import { Discipline } from './discipline';

const predefinedIfcClassType = Object.freeze({
    beam: 'beam',
    other: 'other',
    columns: 'columns',
    covering: 'covering',
    walls: 'walls',
    doors: 'doors',
    flow: 'flow',
    furniture: 'furniture',
    reinforcement: 'reinforcement',
    roof: 'roof',
    floor: 'floor',
    space: 'space',
    stairs: 'stairs',
    windows: 'windows',
    annotations: 'annotations'
});

/**
 * All possible types an IFC class can be assigned.
 * The type is used to group IFC classes into categories.
 */
export type IfcClassType = keyof typeof predefinedIfcClassType;

/**
 * Add new ifc classes here when required. Do not prefix with Ifc
 * we do that automatically in the code.
 * Class ids shall be mapped to their corresponding type (category).
 */
const tmpPredefinedIfcClassIdWithoutPrefix = Object.freeze({
    Actuator: 'other',
    AirTerminal: 'other',
    AirTerminalBox: 'other',
    AirToAirHeatRecovery: 'other',
    Alarm: 'other',
    Alignment: 'other',
    AlignmentCant: 'other',
    AlignmentHorizontal: 'other',
    AlignmentSegment: 'other',
    AlignmentVertical: 'other',
    Annotation: 'annotations',
    AudioVisualAppliance: 'other',
    Beam: 'beam',
    BeamStandardCase: 'beam',
    Bearing: 'other',
    Boiler: 'other',
    Borehole: 'other',
    Bridge: 'other',
    BridgePart: 'other',
    Building: 'other',
    BuildingElementPart: 'other',
    BuildingElementProxy: 'other',
    BuildingStorey: 'other',
    BuiltElement: 'other',
    Burner: 'other',
    CableCarrierFitting: 'other',
    CableCarrierSegment: 'other',
    CableFitting: 'other',
    CableSegment: 'other',
    CaissonFoundation: 'other',
    ChamferEdgeFeature: 'other',
    Chiller: 'other',
    Chimney: 'other',
    CivilElement: 'other',
    Coil: 'other',
    Column: 'columns',
    ColumnStandardCase: 'columns',
    CommunicationsAppliance: 'other',
    Compressor: 'other',
    Condenser: 'other',
    Controller: 'other',
    ConveyorSegment: 'other',
    CooledBeam: 'other',
    CoolingTower: 'other',
    Course: 'other',
    Covering: 'covering',
    CurtainWall: 'walls',
    Damper: 'other',
    DeepFoundation: 'other',
    DiscreteAccessory: 'other',
    DistributionBoard: 'other',
    DistributionChamberElement: 'other',
    DistributionControlElement: 'other',
    DistributionElement: 'other',
    DistributionFlowElement: 'other',
    DistributionPort: 'other',
    Door: 'doors',
    DoorStandardCase: 'doors',
    DuctFitting: 'other',
    DuctSegment: 'other',
    DuctSilencer: 'other',
    EarthworksCut: 'other',
    EarthworksElement: 'other',
    EarthworksFill: 'other',
    ElectricalElement: 'other',
    ElectricAppliance: 'other',
    ElectricDistributionBoard: 'other',
    ElectricDistributionPoint: 'other',
    ElectricFlowStorageDevice: 'other',
    ElectricFlowTreatmentDevice: 'other',
    ElectricGenerator: 'other',
    ElectricMotor: 'other',
    ElectricTimeControl: 'other',
    ElementAssembly: 'other',
    EnergyConversionDevice: 'other',
    Engine: 'other',
    EquipmentElement: 'other',
    EvaporativeCooler: 'other',
    Evaporator: 'other',
    ExternalSpatialElement: 'other',
    Facility: 'other',
    FacilityPartCommon: 'other',
    Fan: 'other',
    Fastener: 'other',
    Filter: 'other',
    FireSuppressionTerminal: 'other',
    FlowController: 'flow',
    FlowFitting: 'flow',
    FlowInstrument: 'flow',
    FlowMeter: 'flow',
    FlowMovingDevice: 'flow',
    FlowSegment: 'flow',
    FlowStorageDevice: 'flow',
    FlowTerminal: 'flow',
    FlowTreatmentDevice: 'flow',
    Footing: 'other',
    FurnishingElement: 'furniture',
    Furniture: 'furniture',
    GeographicElement: 'other',
    Geomodel: 'other',
    Geoslice: 'other',
    GeotechnicalStratum: 'other',
    Grid: 'other',
    HeatExchanger: 'other',
    Humidifier: 'other',
    ImpactProtectionDevice: 'other',
    Interceptor: 'other',
    JunctionBox: 'other',
    Kerb: 'other',
    Lamp: 'other',
    LightFixture: 'other',
    LinearElement: 'other',
    LinearPositioningElement: 'other',
    LiquidTerminal: 'other',
    MarineFacility: 'other',
    MarinePart: 'other',
    MechanicalFastener: 'other',
    MedicalDevice: 'other',
    Member: 'other',
    MemberStandardCase: 'other',
    MobileTelecommunicationsAppliance: 'other',
    MooringDevice: 'other',
    MotorConnection: 'other',
    NavigationElement: 'other',
    OpeningElement: 'walls',
    OpeningStandardCase: 'walls',
    Outlet: 'other',
    Pavement: 'other',
    Pile: 'other',
    PipeFitting: 'other',
    PipeSegment: 'other',
    Plate: 'other',
    PlateStandardCase: 'other',
    Project: 'other',
    ProjectionElement: 'other',
    ProtectiveDevice: 'other',
    ProtectiveDeviceTrippingUnit: 'other',
    Proxy: 'other',
    Pump: 'other',
    Rail: 'other',
    Railing: 'other',
    Railway: 'other',
    RailwayPart: 'other',
    Ramp: 'other',
    RampFlight: 'other',
    Referent: 'other',
    ReinforcedSoil: 'reinforcement',
    ReinforcingBar: 'reinforcement',
    ReinforcingMesh: 'reinforcement',
    Road: 'other',
    RoadPart: 'other',
    Roof: 'roof',
    RoundedEdgeFeature: 'other',
    SanitaryTerminal: 'other',
    Sensor: 'other',
    ShadingDevice: 'other',
    Sign: 'other',
    Signal: 'other',
    Site: 'other',
    Slab: 'floor',
    SlabElementedCase: 'floor',
    SlabStandardCase: 'floor',
    SolarDevice: 'other',
    Space: 'space',
    SpaceHeater: 'other',
    SpatialZone: 'other',
    StackTerminal: 'other',
    Stair: 'stairs',
    StairFlight: 'stairs',
    StructuralCurveAction: 'other',
    StructuralCurveConnection: 'other',
    StructuralCurveMember: 'other',
    StructuralCurveMemberVarying: 'other',
    StructuralCurveReaction: 'other',
    StructuralLinearAction: 'other',
    StructuralLinearActionVarying: 'other',
    StructuralPlanarAction: 'other',
    StructuralPlanarActionVarying: 'other',
    StructuralPointAction: 'other',
    StructuralPointConnection: 'other',
    StructuralPointReaction: 'other',
    StructuralSurfaceAction: 'other',
    StructuralSurfaceConnection: 'other',
    StructuralSurfaceMember: 'other',
    StructuralSurfaceMemberVarying: 'other',
    StructuralSurfaceReaction: 'other',
    SurfaceFeature: 'other',
    SwitchingDevice: 'other',
    SystemFurnitureElement: 'other',
    Tank: 'other',
    Tendon: 'other',
    TendonAnchor: 'other',
    TendonConduit: 'other',
    TrackElement: 'other',
    Transformer: 'other',
    TransportElement: 'other',
    TubeBundle: 'other',
    UnitaryControlElement: 'other',
    UnitaryEquipment: 'other',
    Unknown: 'other',
    Valve: 'other',
    Vehicle: 'other',
    VibrationDamper: 'other',
    VibrationIsolator: 'other',
    VirtualElement: 'other',
    VoidingFeature: 'other',
    Wall: 'walls',
    WallElementedCase: 'walls',
    WallStandardCase: 'walls',
    WasteTerminal: 'other',
    Window: 'windows',
    WindowStandardCase: 'windows'
});

const IfcClassIdPrefix = 'Ifc';

const isIfcClassIdRegex = /^ifc[^\s]+$/i;

/**
 * The known predefined IFC class IDs. Can be used with {@link BimIfcClass.is} to determine if a class is a predefined IFC class.
 * @example
 * ```typescript
 * function checkIfWindow(o: BimIfcObject): boolean {
 *   return o.class.is('IfcWindow');
 * }
 * ```
 */
export type PredefinedIfcClassId = `${typeof IfcClassIdPrefix}${Capitalize<
    keyof typeof tmpPredefinedIfcClassIdWithoutPrefix
>}`;

/**
 * Represents a predefined IFC class ID without the 'Ifc' prefix.
 */
export type PredefinedIfcClassIdWithoutPrefix = `${Uncapitalize<keyof typeof tmpPredefinedIfcClassIdWithoutPrefix>}`;

const predefinedIfcClassIdWithoutPrefix = tmpPredefinedIfcClassIdWithoutPrefix as Record<
    keyof typeof tmpPredefinedIfcClassIdWithoutPrefix,
    IfcClassType
>;

// Build a lookup table for predefined ifc class ids. We want to be able to map a id of any casing to the correctly cased version
// (and its type)
const predefinedIfcIdAndTypeLookup = new Map<string, { id: PredefinedIfcClassId; type: IfcClassType }>();
for (const [idWithoutPrefix, type] of Object.entries(predefinedIfcClassIdWithoutPrefix)) {
    const idWithPrefix = IfcClassIdPrefix + idWithoutPrefix;
    predefinedIfcIdAndTypeLookup.set(idWithPrefix.toLowerCase(), {
        id: idWithPrefix as PredefinedIfcClassId,
        type
    });
}

function getNormalizedIdAndType(id: string): [string, IfcClassType] {
    const normalizedId = id.toLowerCase();
    const normalized = predefinedIfcIdAndTypeLookup.get(normalizedId);
    if (normalized) {
        return [normalized.id, normalized.type];
    }

    // If we are dealing with a non predefined id then we just return the normalized id and 'other' type
    telemetry.trackTrace({ message: `Unknown ifc class id: ${id}`, severityLevel: SeverityLevel.Warning });
    const idWithoutPrefix = normalizedId.slice(3);
    return [
        IfcClassIdPrefix + idWithoutPrefix[0].toUpperCase() + idWithoutPrefix.slice(1),
        predefinedIfcClassType.other
    ];
}

/**
 * Represents IFC class (IfcWindow, IfcWall etc)
 * There is one instance of this class for each unique IFC class. Therefore reference equality can be used to compare instances.
 * @example
 * ```typescript`
 *  BimIfcClass.getOrAdd("IfcWindow") === BimIfcClass.predefined.window; // true
 *  BimIfcClass.ifcBuildingStorey === BimIfcClass.getOrAdd("IfcBuildingStorey"); // true
 * ``
 */
/**
 * Represents an IFC class in the BIM system.
 */
export class BimIfcClass {
    private static _cache: Map<string, BimIfcClass> = new Map<string, BimIfcClass>();

    /**
     * The number of {@link BimIfcObject} instances using this class.
     */
    public readonly ifcObjectCount: number = 0;

    /**
     * The number of instances where {@link BimIfcObject.hasGeometry} is true and using this class.
     */
    public readonly ifcObjectsWithGeometryCount: number = 0;

    /**
     * The set of disciplines with ifcproducts that have references to this class.
     */
    public readonly referencedDisciplinesSet: DeepImmutable<Set<Discipline>> = new Set<Discipline>();

    /**
     * Gets an array of all disciplines with ifcproducts that have references to this class.
     */
    public get referencedDisciplines(): DeepImmutable<Discipline>[] {
        return [...this.referencedDisciplinesSet.values()];
    }

    /**
     * Adds a referenced discipline to the set of referenced disciplines.
     *
     * @param discipline - The discipline to add.
     */
    public addReferencedClass(discipline: Discipline): void {
        this.referencedDisciplinesSet.add(discipline);
    }

    /**
     * Represents all known IFC classes. These classes can be used with the {@link is} operator to check if an object belongs to a specific IFC class.
     * They can also be used to create user interface lists or perform other operations related to IFC classes.
     * However, to determine the actual classes in use for the currently loaded IFC files, refer to {@link BImCoreApi.ifc.classes}.
     *
     * @example
     * ```typescript
     * function checkIfBuildingStorey(o: BimIfcObject): boolean {
     *   return o.class.is(BimIfcClass.predefined.buildingStorey);
     * }
     * ```
     */
    public static readonly predefined = (() => {
        return Object.freeze(
            Object.keys(predefinedIfcClassIdWithoutPrefix).reduce((predefined, ifcClassIdWithoutPrefixString) => {
                const propName =
                    ifcClassIdWithoutPrefixString[0].toLowerCase() + ifcClassIdWithoutPrefixString.slice(1);
                predefined[propName as PredefinedIfcClassIdWithoutPrefix] = BimIfcClass.getOrAdd(
                    IfcClassIdPrefix + ifcClassIdWithoutPrefixString
                );
                return predefined;
            }, {} as Record<PredefinedIfcClassIdWithoutPrefix, BimIfcClass>)
        );
    })();

    /**
     * Convinience property referencing the IfcBuildingStorey class. Can be used with {@link is} to determine if a class is a building storey.
     */
    public static readonly ifcBuildingStorey = BimIfcClass.predefined.buildingStorey;

    /**
     * Convinience property referencing the IfcSpace class. Can be used with {@link is} to determine if a class is a space.
     */
    public static readonly ifcSpace = BimIfcClass.predefined.space;

    /**
     * Convinience property referencing the IfcProject class. Can be used with {@link is} to determine if a class is a project.
     */
    public static readonly ifcProject = BimIfcClass.predefined.project;

    /**
     * Convinience property referencing the IfcSite class. Can be used with {@link is} to determine if a class is a site.
     */
    public static readonly ifcSite = BimIfcClass.predefined.site;

    /**
     * Convinience property referencing the IfcBuilding class. Can be used with {@link is} to determine if a class is a building.
     */
    public static readonly ifcBuilding = BimIfcClass.predefined.building;

    /**
     * Convinience property referencing the IfcCovering class. Can be used with {@link is} to determine if a class is a covering.
     */
    public static readonly ifcCovering = BimIfcClass.predefined.covering;

    /**
     * Constructor. It is recommended to avoid creating instances of this class directly. Instead, use the {@link getOrAdd} method.
     * @param id Identifier. While it might seem intuitive to use string equals operation against id, it is advised to use the {@link is} method for comparison.
     * It's also possible to do reference equality checks between instances of {@link BimIfcClass} directly.
     * This is because there only ever exists one {@link BimIfcClass} instance for each unique IFC class.
     * ```typescript
     * const window = BimIfcClass.getOrAdd('ifcwiNdoW'); // The id is case insensitive in getOrAdd()
     * for(const p of api.ifc.products()) {
     *      o.visible(o.class === window || o.class === BimIfcClass.predefined.wall)
     * }
     * ```
     * @param type This parameter represents the type of IFC class.
     */
    private constructor(public readonly id: string, public readonly type: IfcClassType) {}

    /**
     * Checks if the current instance is of the specified class ({@link BimIfcClass}, {@link PredefinedIfcClassId}) or type ({@link IfcClassType}).
     * @param c - The class or type to ch
     * öeck against.
     * @returns `true` if the current instance is of the specified class or type, `false` otherwise.
     * @remarks
     * If you wish to peform the check with a string and it might not be one of the {@link PredefinedIfcClassId}. Then you can always
     * fall back to comparing against {@link id} directly. However remember that such a comparison is case sensitive.
     * @example
     * ```typescript
     * // Fastest
     * function checkIfBuildingStorey(o: BimIfcObject): boolean {
     *   return o.class.is(BimIfcClass.predefined.buildingStorey);
     * }
     * // Fast. Check if type/category is 'walls' (IfcClassType)
     * function checkIfWallsType(o: BimIfcObject): boolean {
     *   return o.class.is('walls');
     * }
     *
     * // Slowest but still fast enough for many use cases
     *  function checkIfBuildingStorey(o: BimIfcObject): boolean {
     *   return o.class.is('IfcBuildingStorey'); // Compare using PredefinedIfcClassId
     * }
     * ```
     */
    public is(c: BimIfcClass | IfcClassType | PredefinedIfcClassId): boolean {
        return c === this || (typeof c === 'string' && (this.type === c || this.id === c));
    }

    /**
     * Checks if the given string looks like valid ID for an IFC class (format is IfcXxx).
     * @param id The string to check.
     * @returns `true` if the string is a valid IFC class ID, `false` otherwise.
     */
    public static isValidId(id: string): boolean {
        return isIfcClassIdRegex.test(id);
    }

    /**
     * Retrieves an instance of {@link BimIfcClass} based on the provided `id`. Lookup is case insensitive.
     * Given the same id then the same instance is always returned. The instance is cached and reused up until
     * {@link reset} after that new instances are created, cached and reused, until the next call to {@link reset } etc.
     * If the instance does not exist in the cache, a new instance is created and added to the cache.
     * @param id - The id of the {@link BimIfcClass} to retrieve or add to the cache. The id is case insensitive.
     * @returns The {@link BimIfcClass} instance associated with the provided `id`.
     * @throws Error if the provided `id` is not a valid ifc class id on the format (IfcXxx).
     */
    public static getOrAdd(id: string): BimIfcClass {
        return this.parse(id);
    }

    /**
     * Parse a id string and retrieves the corresponding {@link BimIfcClass} instance. Parsing is case insensitive.
     * Given the same id then the same instance is always returned. The instance is cached and reused up until
     * {@link reset} after that new instances are created, cached and reused, until the next call to {@link reset } etc.
     * If the instance does not exist in the cache, a new instance is created and added to the cache.
     * @param id - The id of the {@link BimIfcClass} to retrieve or add to the cache. The id is case insensitive.
     * @returns The {@link BimIfcClass} instance associated with the provided `id`.
     * @throws Error if the provided `id` is not a valid ifc class id on the format (IfcXxx).
     */
    public static parse(id: string): BimIfcClass {
        if (!this.isValidId(id)) throw new Error(`id ${id} is not a valid ifc class id on format (IfcXxx)`);

        let ret = BimIfcClass._cache.get(id);
        if (ret) return ret;

        const [normalizedId, type] = getNormalizedIdAndType(id);
        ret = BimIfcClass._cache.get(normalizedId);
        if (!ret) {
            ret = new BimIfcClass(normalizedId, type);
        }

        BimIfcClass._cache.set(id, ret);

        return ret;
    }

    /**
     * Resets to the original state.
     * {@link referencedDisciplinesSet} is cleared. {@link ifcObjectCount} and {@link ifcObjectsWithGeometryCount}
     * are set to 0.
     */
    public static reset(): void {
        for (const d of BimIfcClass._cache.values()) {
            d.referencedDisciplinesSet.clear();
            (d.ifcObjectCount as number) = 0;
            (d.ifcObjectsWithGeometryCount as number) = 0;
        }
    }
}
