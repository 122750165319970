/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { BimIfcIndex } from './BimIfcIndex';
import { BimObjectAttribute } from './BimObjectAttribute';

export class BimObjectAttributeReader {
    public read(index: BimIfcIndex): BimObjectAttribute[] {
        const attributes = [];

        for (const raw of index.objectAttributes ?? []) {
            attributes.push(new BimObjectAttribute(index.texts[raw.n], raw.v));
        }

        return attributes;
    }
}
