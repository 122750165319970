import { Discipline } from '../discipline';
import { BimIfcClass } from '../bim-ifc-class';
import {
    TwinfinityInfo,
    BimContainer,
    BimContainerInfo,
    BimChangeIfc,
    BimChangeType,
    isIfc,
    isDwg,
    isBlob,
    BimChangeBlob,
    BimChange,
    BimChangeDwg,
    TwinfinityParserMetadata,
    IsLayer,
    IfcMetadata
} from '../bim-api-client';
import { Permissions } from '../Permission';
import { BimLayerApiClient } from './BimLayerApiClient';
import { Http, HttpResponseType, TypedResponse } from '../../http';
import { BimMessageApiClient } from './BimMessageApiClient';
import { BimUploadApiClient } from './BimUploadApiClient';
import { TwinfinityApiClient } from './TwinfinityApiClient';
import { LayerApiClient } from './LayerApiClient';
import { MessageApiClient } from './MessageApiClient';
import { UploadApiClient } from './UploadApiClient';
import { Writeable, asWriteable } from '../../Types';
import { PredefinedBimChangeMetadataQuery } from '../PredefinedBimChangeMetadataQuery';
import { OwnerSystem } from '../OwnerSystem';
import { SettingsApiClient } from './SettingsApiClient';
import { BimSettingsApiClient } from './BimSettingsApiClient';
import { LayerDefinitions, LayerDefinitionsDTO } from '../LayerDefinitions';
import { IfcSiteInformationImplementation } from '../IfcSiteInformation';

function assignCalculatedBimIfcChangeProperties(ifcChange: Writeable<BimChangeIfc>): void {
    const ifc = ifcChange.metadata.ifc;

    ifcChange.resourceUrl = {
        ...(ifc?.url?.idx && { idx: new URL(ifc.url.idx) }),
        ...(ifc?.url?.geom && { geom: new URL(ifc.url.geom) }),
        ...(ifc?.url?.prop && { prop: new URL(ifc.url.prop) })
    };
    ifcChange.floors = ifc?.floors ?? [];
    ifcChange.classes = (ifc?.classes ?? []).map((c: string) => BimIfcClass.getOrAdd(c));
    ifcChange.productCount = ifc?.statistics?.productCount ?? -1;
}

function assignDiscipline(
    change: Writeable<BimChangeBlob> | Writeable<BimChangeDwg> | Writeable<BimChangeIfc>,
    defaultDisciplineShort?: string
): void {
    // Server sends us discipline as string (short id). We convert it to
    // a strongly typed Discipline class instance.
    const disciplineShort = (change.discipline as unknown as string) ?? defaultDisciplineShort;
    if (disciplineShort) {
        change.discipline = Discipline.getOrAdd(disciplineShort);
    }
}

function assignParseError(parserMetadata?: Writeable<TwinfinityParserMetadata>): void {
    if (parserMetadata) {
        // server will not send hasParseError false but if it does not exist we set it to false
        parserMetadata.hasParseError = parserMetadata.hasParseError ?? false;
    }
}

function assignIfcSiteInformation(ifcChange: Writeable<BimChangeIfc>): void {
    const ifc = ifcChange.metadata.ifc as Writeable<IfcMetadata>;

    ifc.sites = (ifc?.sites ?? []).map((site) => new IfcSiteInformationImplementation(site));
}

/**
 * Enhances a {@link BimChange} dto with calculated properties.
 * When a {@link BimChange} dto is received from the backend, it may not contain all the properties that we need
 * so we calculate them here.
 *
 * @param dto - The {@link BimChange} object to enhance.
 * @hidden
 * @internal
 * @returns The enhanced {@link BimChange} object.
 */
export function enhanceChangeDtoWithCalculatedProperties<T extends BimChange>(dto: T): T {
    if (!IsLayer(dto)) {
        // change.availableLayerDefinitions may actually be undefined  when transferred over the wire
        // but we do not want to expose that on the type because we always replace it with a LayerDefinitions collection
        // (that may be empty).
        LayerDefinitions.copyToRef(
            { json: dto.availableLayerDefinitions as unknown as LayerDefinitionsDTO | undefined },
            dto
        );
    }
    asWriteable(dto).path = new URL(dto.apiUrl.replace(/\/_ps\/api\/$/, '')).pathname;
    Permissions.copyToRef({ json: dto.permissionsString }, dto);
    if (isIfc(dto)) {
        assignDiscipline(dto, Discipline.NotAvailableShortId);
        assignParseError(dto.metadata?.ifc);
        assignCalculatedBimIfcChangeProperties(dto);
        assignIfcSiteInformation(dto);
    } else if (isDwg(dto)) {
        assignDiscipline(dto);
        assignParseError(dto.metadata?.dwg);
    } else if (isBlob(dto)) {
        assignDiscipline(dto);
    }
    return dto;
}

/**
 * Enhances a {@link BimContainer} object with calculated properties.
 * When a {@link BimContainer} dto is received from the backend, it may not contain all the properties that we need
 * so we calculate them here.
 * @param dto - The {@link BimContainer} object to enhance.
 * @hidden
 * @internal
 * @returns The enhanced {@link BimContainer} object.
 */
export function enhancedContainerDtoWithCalculatedProperties(dto: Writeable<BimContainer>): BimContainer {
    dto.path = new URL(dto.url).pathname;
    Permissions.copyToRef({ json: dto.permissionsString }, dto);
    // container.availableLayerDefinitions may actually be undefined when transferred over the wire
    // but we do not want to expose that on the type because we always replace it with a LayerDefinitions collection
    // (that may be empty).
    LayerDefinitions.copyToRef(
        { json: dto.availableLayerDefinitions as unknown as LayerDefinitionsDTO | undefined },
        dto
    );
    return dto;
}

/**
 * Loads container and IFC data from the Twinfinity backend.
 */
export class BimTwinfinityApiClient implements TwinfinityApiClient {
    public readonly baseUrl: URL;
    private readonly _pnRootWebUrl: URL;
    private readonly _archiveRootWebUrl: URL;

    /**
     * Access to layer operations.
     */
    public readonly layers: LayerApiClient;

    /**
     * Access to message operations.
     */
    public readonly messages: MessageApiClient;

    /**
     * Access to upload operations.
     */
    public readonly upload: UploadApiClient;

    /**
     * Access to settings operations.
     */
    public readonly settings: SettingsApiClient;

    /**
     * Constructor
     * @param baseUrl Url to twinfinity. Example: https://bim.demo.projektstruktur.se or https://bim.demo.projektstruktur.se/sites/portal/projects/projectA
     */
    public constructor(baseUrl: URL) {
        const schemeAndServer = `${baseUrl.protocol}//${baseUrl.host}`;
        this.baseUrl = new URL(schemeAndServer);
        this._pnRootWebUrl = new URL(`${baseUrl.protocol}//${baseUrl.host}/sites/portal/`);
        this._archiveRootWebUrl = new URL(`${baseUrl.protocol}//${baseUrl.host}/sites/archive/`);
        this.layers = new BimLayerApiClient(this);
        this.messages = new BimMessageApiClient(this._pnRootWebUrl);
        this.upload = new BimUploadApiClient(this);
        this.settings = new BimSettingsApiClient(this._pnRootWebUrl);
    }

    /** Hostname of {@link baseUrl}. */
    public get id(): string {
        return this.baseUrl.hostname;
    }

    /** @inheritDoc */
    public canDelete({ permissions, ownerSystem, type }: BimChange): boolean {
        return permissions.hasAll('delete') && OwnerSystem.isDeletable(ownerSystem) && type === BimChangeType.Blob;
    }

    /** @inheritDoc */
    public async deleteChanges(changesToDelete: BimChange[]): Promise<TypedResponse<number>[]> {
        if (changesToDelete.some((change) => !OwnerSystem.isDeletable(change)))
            throw new Error('Only changes originally created in Twinfinity can be deleted.');

        if (changesToDelete.some((change) => change.type !== BimChangeType.Blob))
            throw new Error('Only BimChangeBlobs can be deleted.');

        if (changesToDelete.some((change) => !change.permissions.hasAny('delete')))
            throw new Error('Missing delete permission one one or more changes.');

        const changesGroupedByContainer = this.toLookup(changesToDelete, (change) => change.containerId);

        const results = Array.from(changesGroupedByContainer, async ([, changes]) => {
            const baseUrl = this.getLongestCommonUrlPath(changes.map((change) => change.apiUrl));

            const entitiesToDelete = changes.map((change) => {
                return { id: change.id, etag: change.etag };
            });

            const result = await Http.delete<number>(`${baseUrl}/_ps/api/file`, HttpResponseType.json, {
                body: JSON.stringify(entitiesToDelete),
                headers: { 'Content-Type': 'application/json' }
            });

            return result;
        });

        return Promise.all(results);
    }

    private toLookup<TKey, TValue>(values: TValue[], getKey: (value: TValue) => TKey): Map<TKey, TValue[]> {
        const map = new Map<TKey, TValue[]>();

        values.forEach((value) => {
            map.getOrAdd(getKey(value), (key) => []).push(value);
        });

        return map;
    }

    private getLongestCommonUrlPath(urls: string[]): string {
        const segmentedUrls = urls
            .map((url) =>
                url
                    .match('(.*)/_ps/api')![1]
                    .split('/')
                    .filter((str) => !!str)
            )
            .sort((urlSegments) => urlSegments.length);

        const shortestUrl = segmentedUrls.pop()!;

        const longestCommonUrl = [];

        for (let segmentIndex = 0; segmentIndex < shortestUrl.length; segmentIndex++) {
            const currentUrlSegment = shortestUrl[segmentIndex];

            if (segmentedUrls.every((url) => url[segmentIndex] === currentUrlSegment))
                longestCommonUrl.push(currentUrlSegment);
            else break;
        }

        return longestCommonUrl.join('/');
    }

    /** @inheritDoc */
    public async getInfo(): Promise<TwinfinityInfo> {
        const pnInfoPromise = this.getOrUndefined<TwinfinityInfo>(
            `${this._pnRootWebUrl.href}_ps/api/system`,
            HttpResponseType.json
        );
        const archiveInfoPromise = this.getOrUndefined<TwinfinityInfo>(
            `${this._archiveRootWebUrl.href}_ps/api/system`,
            HttpResponseType.json
        );

        const [pnInfo, archiveInfo] = await Promise.all([pnInfoPromise, archiveInfoPromise]);
        const ret = pnInfo ?? archiveInfo;
        if (!ret) throw new Error('');
        return ret;
    }

    /** @inheritDoc */
    public async getContainerInfo(containerOrUrl?: BimContainer | URL): Promise<TypedResponse<BimContainerInfo>> {
        if (containerOrUrl) {
            if ('url' in containerOrUrl) {
                containerOrUrl = new URL(containerOrUrl.url);
            }
            return await Http.get<BimContainerInfo>(
                `${containerOrUrl.href.replace(/\/+$/, '')}/_ps/api/project/info`,
                HttpResponseType.json
            );
        }

        // Either no url or container was specified
        // Therefore we have to fetch containerInfo for both sites/portal and sites/archive in order to
        // find out what the default container info is (portal wins over archive).
        const responses = await Promise.all([
            this.getContainerInfo(this._pnRootWebUrl),
            this.getContainerInfo(this._archiveRootWebUrl)
        ]);
        // Return first ok response. If no such response then return first non ok response.
        return responses.filter((r) => r.ok)[0] ?? responses[0];
    }

    /** @inheritDoc */
    public async getContainers(id?: string): Promise<BimContainer[]> {
        const containerPromises = Promise.all([
            Http.get<BimContainer[]>(`${this._pnRootWebUrl.href}_ps/api/project/containers`, HttpResponseType.json),
            Http.get<BimContainer[]>(`${this._archiveRootWebUrl.href}_ps/api/project/containers`, HttpResponseType.json)
        ]);

        const result = await containerPromises;
        const containers: BimContainer[] = [];
        for (const r of result) {
            // TODO Log errors and stop querying backed pnRootWebUrl or archiveRootWebUrl if it does not
            // have that product
            if (r.status === 200) {
                const tmpContainers = await r.value;
                for (const container of tmpContainers.map(enhancedContainerDtoWithCalculatedProperties)) {
                    containers.push(container);
                }
            }
        }

        return containers;
    }

    /** @inheritDoc */
    public async getChanges<T extends BimChange = BimChange>(
        parentOrUrl: BimContainer | URL | BimChange,
        options: { id: string } | { query: string } | { query: 'all' }
    ): Promise<TypedResponse<T[]>> {
        let getUrl = ('url' in parentOrUrl ? new URL(parentOrUrl.url) : parentOrUrl).toString();
        getUrl = getUrl.replace(/\/+$/g, ''); // Trim ending /'es
        getUrl = getUrl.replace(/(\/+_ps\/+api)$/g, '') + '/_ps/api/file';
        if ('id' in options) {
            getUrl += `/${options.id}`;
        } else {
            if (options.query !== 'all') {
                getUrl += `?$filter=${options.query}`;
            }
        }

        const changesResponse = await this.get<BimChange[]>(getUrl, HttpResponseType.json);
        if (changesResponse.status === 200) {
            (await changesResponse.value).forEach(enhanceChangeDtoWithCalculatedProperties);
        }
        return changesResponse as TypedResponse<T[]>;
    }

    /** @inheritDoc */
    public async getIfcChanges(parentOrUrl: BimContainer | URL | BimChange): Promise<BimChangeIfc[]> {
        const ifcChangesResponse = await this.getChanges(parentOrUrl, PredefinedBimChangeMetadataQuery.ifc());
        if (ifcChangesResponse.status !== 200) {
            throw new Error(`Failed getting IFC changes for ${parentOrUrl}. Status: ${ifcChangesResponse.status}`);
        }
        const ifcChanges = await ifcChangesResponse.value;
        return ifcChanges.filter((c): c is BimChangeIfc => c.type === BimChangeType.Ifc);
    }

    /** @inheritDoc */
    public async get<T>(
        absoluteUrl: string | URL,
        converter: (r: Response) => TypedResponse<T>,
        init?: RequestInit
    ): Promise<TypedResponse<T>> {
        return Http.get(absoluteUrl, converter, init);
    }

    private async getOrUndefined<T>(
        absoluteUrl: string | URL,
        converter: (r: Response) => TypedResponse<T>,
        init?: RequestInit
    ): Promise<T | undefined> {
        const resp = await this.get<T>(absoluteUrl, converter, init);
        if (resp.status !== 200) return undefined;
        return await resp.value;
    }
}
