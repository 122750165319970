/**
 * Represents either production or development build type
 */
export type BuildType = 'Dev' | 'Prod';

/**
 * Contains property metadata for the Twinfinity build
 */
export interface BuildInfo {
    /** Major version number */
    readonly major: number;
    /** Minor version number */
    readonly minor: number;
    /** Patch version number */
    readonly patch: number;
    /** Unique build id */
    readonly id: number;
    /** The commit checksum */
    readonly commit: string;
    /** String that uniquely identifies the current version */
    readonly version: string;
    /** The build type */
    readonly buildType: BuildType;
}

const gBi: BuildInfo = {
    major: 111111,
    minor: 222222,
    patch: 333333,
    id: 444444,
    commit: 'unknownCommit',
    version: '',
    buildType: 'Prod'
};

(gBi.version as string) = `${gBi.major}.${gBi.minor}.${gBi.patch}.${gBi.id}-[${gBi.commit}]`;
if (gBi.commit === 'unknownCommit' && gBi.id === 444444) {
    (gBi.buildType as BuildType) = 'Dev';
}

export const buildInfo = Object.freeze(gBi);
