import { Vertex3 } from '../math';
import { RgbaDTO } from '../loader/bim-format-types';
import { MergableObjectEventArgs } from '../MergableSet';
import { MarkupEntityBase } from './MarkupLayer/MarkupEntityBase';

/** Markup event type */
export enum MarkupEventTypes {
    Added,
    Update,
    Delete
}

/** Type which represents an event on a 2d MarkupLayer. */
export type MarkupLayerEvent2D = MergableObjectEventArgs<MarkupEntityBase> & {
    eventType: MarkupEventTypes.Added | MarkupEventTypes.Delete | MarkupEventTypes.Update;
};

/** Data transfer object for markup entities, this represents the entity data that will be saved to the layer. */
export interface EntityBaseDto {
    id: string;
    properties: { [key: string]: number | string | boolean };
}

/** Data transfer object for markup sheets, this represents the sheet data that will be saved to the layer. */
export interface SheetDto extends EntityBaseDto {
    type: MarkupEntityTypes.Sheet;
    entities: { [id: string]: MarkupEntityDto };
}
/** Represents the format of the markup layer. */
export interface MarkupDto {
    version: { major: number; minor: number; patch: number };
    sheets: { [id: string]: SheetDto };
}

/** Data transfer object for markup areas, this represents the area data that will be saved to the layer. */
export interface AreaDto extends EntityBaseDto {
    type: MarkupEntityTypes.Area;
    color: RgbaDTO;
    points: Vertex3[];
}

/** Data transfer object for markup lines, this represents the line data that will be saved to the layer. */
export interface LineDto extends EntityBaseDto {
    type: MarkupEntityTypes.Line;
    thickness: number;
    color: RgbaDTO;
    linePoints: Vertex3[];
}

/** Data transfer object for markup arrows, this represents the arrow data that will be saved to the layer. */
export interface ArrowDto extends EntityBaseDto {
    type: MarkupEntityTypes.Arrow;
    color: RgbaDTO;
    arrowPoint: Vertex3;
    bluntPoint: Vertex3;
    lineThickness: number;
    arrowHeight: number;
    arrowWidth: number;
}

/** Data transfer object for markup texts, this represents the text data that will be saved to the layer. */
export interface TextDto extends EntityBaseDto {
    type: MarkupEntityTypes.Text;
    text: string;
    position: Vertex3;
    normal: Vertex3;
    scale: Vertex3 | number;
    rotation: number;
    backgroundColor: RgbaDTO;
}

/** Data transfer object for markup circles, this represents the circle data that will be saved to the layer. */
export interface CircleDto extends EntityBaseDto {
    type: MarkupEntityTypes.Circle;
    color: RgbaDTO;
    circlePoint: Vertex3;
    radiusPoint: Vertex3;
    lineThickness: number;
}

export enum MarkupEntityTypes {
    Undefined = 0,
    Sheet = 1,
    Area = 2,
    Text = 3,
    Line = 4,
    Arrow = 5,
    Circle = 6
}

export interface MarkupStyle<STYLE> {
    style: STYLE;
}

export type MarkupEntityDto = AreaDto | LineDto | ArrowDto | TextDto | CircleDto;
export type MarkupProperty = number | boolean | string;
