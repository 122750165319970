/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { Lookup } from './lookup';
import { KeyboardEventTypes, Scene } from './babylonjs-import';

export class Keyboard {
    public readonly pressedKeys: Lookup<boolean> = {};

    public constructor(scene: Scene) {
        scene.onKeyboardObservable.add((kbInfo) => {
            switch (kbInfo.type) {
                case KeyboardEventTypes.KEYDOWN:
                    this.pressedKeys[kbInfo.event.key] = true;
                    break;
                case KeyboardEventTypes.KEYUP:
                    delete this.pressedKeys[kbInfo.event.key];
                    break;
            }
        });
    }
}
