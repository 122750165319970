/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */

import { BimProductMeshDescriptor, BimVertexData, MutableShallow, SimpleBoundingBox } from './bim-format-types';
import { BimProduct } from './BimProduct';
import { BimProductMesh } from './BimProductMesh';
import { BimIfcClass } from './bim-ifc-class';
import { IBimIfcLoaderElement } from './bim-ifc-loader-element';
import { BimIfcMesh } from './bim-ifc-mesh';
import { BimIfcStyle } from './bim-ifc-style';
import { BimPropertySets } from './bim-property-sets';
import { Discipline } from './discipline';
import { setMax, setMin } from '../math';
import { RgbaComponent } from './rgba';
import { Writeable } from '../Types';
import { Materials } from './materials';
import { Matrix, DeepImmutable, BoundingInfo, Vector3 } from './babylonjs-import';
import { BimChangeIfc } from './bim-api-client';
import { VertexDataMerge, Geometry3d } from './vertex-data-merge';

import { GeometryBuffer } from './GeometryBuffer';
import { IfcMaterialHighlightIndex } from './CustomBabylonMaterials/IfcMaterial';
import { BimTypeObject } from './BimTypeObject';

const emptyObject = {};
const noMeshes: BimProductMesh[] = [];

const tmpMin = Vector3.Zero();
const tmpMax = Vector3.Zero();

export interface BimIfcObjectRecursionOptions {
    stopRecursion: boolean;
    abort: boolean;
}

export interface BimIfcObjectForEachAction {
    (o: BimIfcObject, recursionOptions: BimIfcObjectRecursionOptions): void;
}

export interface BimIfcObjectForEachActionAsync {
    (o: BimIfcObject, recursionOptions: BimIfcObjectRecursionOptions): Promise<void>;
}

export interface BimIfcObjectForEachPredicate {
    (o: BimIfcObject, recursionOptions: BimIfcObjectRecursionOptions): unknown;
}

export function DefaultBimIfcObjectForEachPredicate(
    o: BimIfcObject,
    recursionOptions: BimIfcObjectRecursionOptions
): unknown {
    return true;
}

/**
 * This class contains the structure of an IFC Object
 */
export class BimIfcObject {
    private _children: BimIfcObject[] | undefined;

    public readonly rawProduct: BimProduct;
    /**
     * Returns true if object is visible
     */
    public readonly visible: (visible?: boolean, meshes?: BimProductMesh[]) => boolean;

    /**
     * Contains all property sets for this product
     * Readonly
     */
    public readonly properties: DeepImmutable<BimPropertySets> = new BimPropertySets();
    /**
     * Contains all quantifiers for this product
     * Readonly
     */
    public readonly quantifiers: DeepImmutable<BimPropertySets> = new BimPropertySets();

    protected constructor(
        public readonly entityLabelInIfc: string,
        public readonly ifcLoaderElement: IBimIfcLoaderElement,
        rawProduct: MutableShallow<BimProduct>,
        public readonly parents: BimIfcObject[],
        public readonly enclosingFloor?: BimIfcBuildingStorey
    ) {
        rawProduct.c = rawProduct.c ?? 0;
        rawProduct.cl = rawProduct.cl ?? emptyObject;
        rawProduct.d = rawProduct.d ?? ifcLoaderElement.index.texts.length - 1;
        rawProduct.m = rawProduct.m ?? noMeshes;
        rawProduct.n = rawProduct.n ?? ifcLoaderElement.index.texts.length - 1;
        rawProduct.pd = rawProduct.pd ?? emptyObject;
        this.rawProduct = rawProduct;

        let hasGeometry = this.rawProduct.m.length > 0;
        if (hasGeometry && this.ifcLoaderElement.loadOptions?.ifcObjectHasGeometryPredicate) {
            hasGeometry = this.ifcLoaderElement.loadOptions.ifcObjectHasGeometryPredicate(this);
        }

        if (!hasGeometry) {
            this.visible = BimIfcObject.neverVisible;
            rawProduct.m = noMeshes; // Trim away mesh objects we cannot show anyway.
        } else {
            this.visible = this.possiblyVisible;
        }
    }

    /** Creates a geometry consisting of all specified IFC objects (or specific meshes.).
     * @param items All objects that will be combined. It is possible to specify multiple {@link BimProductMesh}
     * to be used on each IFC object. If none are specified, then all meshes in the IFC objects are used.
     * When {@link BimIfcObject.isOnGpu} === `false`, the geometry will automatically be loaded and included in the final
     * {@link Geometry3d}.
     * @returns A {@link Geometry3d} instance that represents all geometry. Note that the geometry is in IFC space and not BabylonJS worldspace.
     */
    public static async createGeometryFromAsync(
        items: (BimIfcObject | { ifcObject: BimIfcObject; meshes: BimProductMesh[] })[]
    ): Promise<Geometry3d> {
        const { usedLoaderElements, vdm } = BimIfcObject.initializeCreateGeometryFrom(items, true);

        const vertexDataPromises: Promise<boolean>[] = [];
        for (const lE of usedLoaderElements) {
            lE.transformsRepository.clear();
            vertexDataPromises.push(lE.ensureVertexDataLoaded());
        }
        await Promise.all(vertexDataPromises);
        return vdm.merge();
    }

    /** Creates a geometry consisting of all specified IFC objects (or specific meshes.).
     * @param items All objects that will be combined. It is possible to optionally specify exactly which {@link BimProductMesh} that are
     * to be used on each IFC object. If none is specified, then all meshes in the IFC objects are used.
     * Geom. Items where {@link BimIfcObject.isOnGpu} === `false` will not be included in the final
     * {@link Geometry3d}.
     * @returns A {@link Geometry3d} instance that represents all geometry. Note that the geometry is in IFC space and not BabylonJS worldspace.
     */
    public static createGeometryFrom(
        items: (BimIfcObject | { ifcObject: BimIfcObject; meshes: BimProductMesh[] })[]
    ): Geometry3d {
        const { usedLoaderElements, vdm } = BimIfcObject.initializeCreateGeometryFrom(items, false);
        for (const lE of usedLoaderElements) {
            lE.transformsRepository.clear();
        }
        return vdm.merge();
    }

    public static create(ifcLoaderElement: IBimIfcLoaderElement): BimIfcObject {
        const p = ifcLoaderElement.index.project;
        return new BimIfcObject('project', ifcLoaderElement, p, []);
    }

    /**
     * Calculates the min and max value in the coordinate system.
     * @param min Minimum bounding vector3
     * @param max Maximum bounding vector3
     * @param predicate ?
     * @param clearTransformCache ?
     */
    public aabb(
        min: Vector3,
        max: Vector3,
        predicate = DefaultBimIfcObjectForEachPredicate,
        clearTransformCache = true
    ): void {
        let predicateResult: unknown = false;
        this.foreach((o, recursionOptions) => {
            predicateResult = predicate(o, recursionOptions);
            if (predicateResult && o.hasGeometry) {
                for (const m of o.productMeshes) {
                    m.aabb(min, max, clearTransformCache);
                }
            }
            return predicateResult;
        });

        if (clearTransformCache) {
            this.ifcLoaderElement.transformsRepository.clear();
        }
    }

    public isOnGpu(meshes?: BimProductMesh[]): boolean {
        const _meshes = meshes ?? this.rawProduct.m;
        const len = _meshes.length;
        let onGpu = len > 0;
        for (let i = 0; i < len && onGpu; ++i) {
            onGpu = _meshes[i].isOnGpu;
        }

        return onGpu;
    }

    public get ifc(): BimChangeIfc {
        return this.ifcLoaderElement.ifc;
    }

    public get gid(): string {
        return this.rawProduct.gid;
    }

    /**
     * @deprecated Use {@link boundingInfo} or {@link aabb}.
     */
    public get bbox(): SimpleBoundingBox {
        setMax(tmpMin);
        setMin(tmpMax);
        this.aabb(tmpMin, tmpMax);
        return [tmpMin.x, tmpMin.y, tmpMin.z, tmpMax.x, tmpMax.y, tmpMax.z];
    }

    public boundingInfo(
        bI?: BoundingInfo,
        predicate = DefaultBimIfcObjectForEachPredicate,
        clearTransformCache = true
    ): BoundingInfo {
        if (bI === undefined) {
            setMax(tmpMin);
            setMin(tmpMax);
            this.aabb(tmpMin, tmpMax, predicate, clearTransformCache);
            return new BoundingInfo(tmpMin, tmpMax);
        }
        tmpMin.copyFrom(bI.minimum);
        tmpMax.copyFrom(bI.maximum);
        this.aabb(tmpMin, tmpMax, predicate, clearTransformCache);
        bI.reConstruct(tmpMin, tmpMax);
        return bI;
    }

    public get childCount(): number {
        if (this._children) {
            return this._children.length;
        }
        if (this.rawProduct.cl) {
            return Object.keys(this.rawProduct.cl).length;
        }
        return 0;
    }

    public get hasGeometry(): boolean {
        return this.rawProduct.m?.length > 0;
    }

    public get productMeshes(): BimProductMesh[] {
        return this.rawProduct.m;
    }

    public meshDescriptor(pM: BimProductMesh): BimProductMeshDescriptor {
        return pM.descriptor;
    }

    /**
     * Returns the discipline class for this IFC-product
     */
    public get discipline(): Discipline {
        return this.ifcLoaderElement.discipline;
    }

    public style(m: BimProductMesh): BimIfcStyle {
        return m.style;
    }

    public vertexData(m: BimProductMesh): BimVertexData {
        return m.vertexData;
    }

    public transform(m: BimProductMesh, clearTransformCache = true): Matrix {
        return m.transform(clearTransformCache);
    }

    public mesh(m: BimProductMesh, clearTransformCache = true): BimIfcMesh {
        return m.mesh(clearTransformCache);
    }

    /**
     * Returns the class object for IFC-product
     */
    public get class(): BimIfcClass {
        return BimIfcClass.getOrAdd(this.ifcLoaderElement.index.classes[this.rawProduct.c]);
    }

    /**
     * Returns name of IFC-product
     */
    public get name(): string {
        return this.ifcLoaderElement.index.texts[this.rawProduct.n];
    }

    public get description(): string {
        return this.ifcLoaderElement.index.texts[this.rawProduct.d];
    }

    public get type(): BimTypeObject | undefined {
        if (this.rawProduct.t == null) {
            return undefined;
        }

        return this.ifcLoaderElement.typeObjectRepository.get(this.rawProduct.t);
    }

    public defaultColor(meshes?: BimProductMesh[]): boolean {
        let wasChanged = false;
        const _meshes = meshes ?? this.rawProduct.m;
        const len = _meshes.length;
        for (let i = 0; i < len; ++i) {
            wasChanged = _meshes[i].defaultColor() || wasChanged;
        }

        return wasChanged;
    }

    public setColors(src: Iterable<[BimProductMesh, ArrayLike<number>]>): boolean {
        let wasChanged = false;
        for (const [m, c] of src) {
            wasChanged = m.setColor(c) || wasChanged;
        }
        return wasChanged;
    }

    public setColor(color: ArrayLike<number>, meshes?: BimProductMesh[]): boolean {
        let wasChanged = false;
        const _meshes = meshes ?? this.rawProduct.m;
        const len = _meshes.length;
        for (let i = 0; i < len; ++i) {
            wasChanged = _meshes[i].setColor(color) || wasChanged;
        }
        return wasChanged;
    }

    /**
     * Highlights the object
     * @param meshes The meshes to highlight
     * @param highlightIndex The highlight color index to use, setting it to zero means the mesh will not be highlighted
     * @returns a boolean indicating if the object was changed from the highlighting
     */
    public highlight(
        highlightIndex: IfcMaterialHighlightIndex = IfcMaterialHighlightIndex.One,
        meshes?: BimProductMesh[]
    ): boolean {
        let wasChanged = false;
        meshes = meshes ?? this.rawProduct.m;
        const len = meshes.length;
        for (let i = 0; i < len; ++i) {
            const prev = meshes[i].highlight;
            meshes[i].highlight = highlightIndex;
            wasChanged ||= prev !== highlightIndex;
        }
        return wasChanged;
    }

    /**
     * Makes the object give an ghosty outline when either SSAO or line outlines are enabled. It does this by making the object invisible
     * but also flag it to still write to the depth buffer used by the lines and SSAO
     * @param enabled If true the object will be hidden but will also be set to still write to the depth buffer
     * @param meshes The specific meshes of the object to apply this method on
     * @returns A bool indicating if any of the objects meshes was changed as a result of this method call
     */
    public ghostOutline(enabled: boolean, meshes?: BimProductMesh[]): boolean {
        let anyChanged = false;

        const _meshes = meshes ?? this.rawProduct.m;
        for (let i = 0; i < _meshes.length; i++) {
            const mesh = _meshes[i];
            const prev = mesh.ghostOutline;
            mesh.ghostOutline = enabled;
            anyChanged ||= prev !== enabled;
        }

        return anyChanged;
    }

    /**
     * Aggregates a bbox from current objects meshes.
     * This does not include related children.
     * This is alpha. Expect changes. Will probably be replaced by
     * one of the other bounding info functions.
     * @deprecated Use {@link boundingInfo} or {@link aabb}.
     * @alpha
     */
    public computeObjectBboxInWorldSpace(): SimpleBoundingBox {
        const bound = [Infinity, Infinity, Infinity, -Infinity, -Infinity, -Infinity] as SimpleBoundingBox;
        const temp = new Vector3();
        for (const m of this.rawProduct.m) {
            const thisMatrix: Matrix = this.ifcLoaderElement.transformsRepository.getMatrix(m);
            const bbox = this.meshDescriptor(m).bbox;
            Vector3.TransformCoordinatesToRef(temp.set(bbox[0], bbox[1], bbox[2]), thisMatrix, temp);
            if (temp.x < bound[0]) {
                bound[0] = temp.x;
            }
            if (temp.y < bound[1]) {
                bound[1] = temp.y;
            }
            if (temp.z < bound[2]) {
                bound[2] = temp.z;
            }
            Vector3.TransformCoordinatesToRef(temp.set(bbox[3], bbox[4], bbox[5]), thisMatrix, temp);
            if (temp.x > bound[3]) {
                bound[3] = temp.x;
            }
            if (temp.y > bound[4]) {
                bound[4] = temp.y;
            }
            if (temp.z > bound[5]) {
                bound[5] = temp.z;
            }
        }
        return bound;
    }

    // TODO Probaby remove and use operations on BoundingInfo instead.
    private simpleBoundingIntersection(containingBound: SimpleBoundingBox, objectBound: SimpleBoundingBox): boolean {
        // test bounding sphere
        const objectMin = [objectBound[0], objectBound[1], objectBound[2]];
        const objectMax = [objectBound[3], objectBound[4], objectBound[5]];
        const objectX = objectMax[0] - objectMin[0];
        const objectY = objectMax[1] - objectMin[1];
        const objectZ = objectMax[2] - objectMin[2];
        const objectRadius = Math.max(objectX, objectY, objectZ) * 0.5;
        const objectCenter = [objectX / 2 + objectMin[0], objectY / 2 + objectMin[1], objectZ / 2 + objectMin[2]];

        const containingMin = [containingBound[0], containingBound[1], containingBound[2]];
        const containingMax = [containingBound[3], containingBound[4], containingBound[5]];
        const containingX = containingMax[0] - containingMin[0];
        const containingY = containingMax[1] - containingMin[1];
        const containingZ = containingMax[2] - containingMin[2];
        const containingRadius = Math.max(containingX, containingY, containingZ) * 0.5;
        const containingCenter = [
            containingX / 2 + containingMin[0],
            containingY / 2 + containingMin[1],
            containingZ / 2 + containingMin[2]
        ];
        const a = containingCenter[0] - objectCenter[0];
        const b = containingCenter[1] - objectCenter[1];
        const c = containingCenter[2] - objectCenter[2];
        const distance = Math.sqrt(a * a + b * b + c * c);

        if (distance - containingRadius - objectRadius < 0) {
            return true;
        }

        // test bounding box
        const corners = [
            objectMin,
            [objectBound[3], objectBound[1], objectBound[2]],
            [objectBound[0], objectBound[1], objectBound[5]],
            [objectBound[3], objectBound[1], objectBound[5]],
            [objectBound[0], objectBound[4], objectBound[2]],
            [objectBound[3], objectBound[4], objectBound[2]],
            [objectBound[0], objectBound[4], objectBound[5]],
            objectMax
        ];
        for (const corner of corners) {
            if (corner[0] > containingBound[0] && corner[0] < containingBound[3]) {
                if (corner[1] > containingBound[1] && corner[1] < containingBound[4]) {
                    if (corner[2] > containingBound[2] && corner[2] < containingBound[5]) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    /**
     * Checks intersections of an object (containing object) against an array of other objects.
     * @param objects Array of objects to test intersection against.
     * @param includeEnclosedObjects Default is true, it includes geometries completely geometrically on the inside, in the returned result.
     * @param includeIntersectingObjects Default is true, it includes geometries that intersects on border surface, in the returned result.
     * If both enclosedObjects and intersectingObjects are false, the function will make a quick rough test based on bbox and return all objects intersecting and enclosing on bounding box level.
     */
    public geometricallyIntersects(
        objects: BimIfcObject[],
        includeEnclosedObjects = true,
        includeIntersectingObjects = true
    ): BimIfcObject[] {
        if (!this.hasGeometry) {
            return [];
        }

        const firstSelectionFastRoughTest: BimIfcObject[] = []; // Will contain all objects that intersects with this BoundingInfo
        const cachedBounds = [];
        const containingBound = this.computeObjectBboxInWorldSpace();
        // this function tests all bounding boxes
        for (const object of objects) {
            if (!object.hasGeometry) {
                continue;
            }
            const objectBound = object.computeObjectBboxInWorldSpace();
            if (this.simpleBoundingIntersection(containingBound, objectBound)) {
                firstSelectionFastRoughTest.push(object);
                cachedBounds.push(objectBound);
            }
        }

        const objectsCompletelyInsideGeometry: BimIfcObject[] = []; // will contain objects bounding box are completely inside this geometry
        const secondTestNotCompletelyInside: BimIfcObject[] = []; // will contain the rest
        const boundIndex = [];
        // This loop test which objects are completely inside the bounding box.
        for (let i = 0; i < firstSelectionFastRoughTest.length; i++) {
            const object: BimIfcObject = firstSelectionFastRoughTest[i];
            let isInside = false;
            for (const m of this.rawProduct.m) {
                const containingMesh = this.mesh(m);
                isInside = containingMesh.geometricallyContainsBoundingBox(cachedBounds[i]);
                if (isInside) {
                    objectsCompletelyInsideGeometry.push(object);
                    break;
                }
            }
            if (!isInside) {
                secondTestNotCompletelyInside.push(object);
                boundIndex.push(cachedBounds[i]);
            }
        }

        // this loop checks if object bbox intersects with containing mesh
        const objectsBboxIntersects: BimIfcObject[] = [];
        for (let i = 0; i < secondTestNotCompletelyInside.length; i++) {
            const object: BimIfcObject = secondTestNotCompletelyInside[i];
            for (const m of this.rawProduct.m) {
                const containingMesh = this.mesh(m);
                const intersecting = containingMesh.geometricallyIntersectingBoundingBox(boundIndex[i]);
                if (intersecting) {
                    objectsBboxIntersects.push(object);
                    break;
                }
            }
        }

        // if not interested in enclosed or intersecting on hi resolution test, just return the first bbox test result
        if (!includeEnclosedObjects && !includeIntersectingObjects) {
            for (const obj of objectsCompletelyInsideGeometry) {
                objectsBboxIntersects.push(obj);
            }
            return objectsBboxIntersects;
        }

        const objectsGeometricallyIntersects: BimIfcObject[] = []; // will contain only objects that intersects with containing geometry surface.
        const objectsBboxNotInsideAndNotGeometriclyIntersecting: BimIfcObject[] = []; // will contain the rest. Can be completely on inside geometry, but bbox intersects, and not geometry.
        // This loop tests witch object got vertices on both inside and outside of containing geometry.
        for (let i = 0; i < objectsBboxIntersects.length; i++) {
            const object = objectsBboxIntersects[i];
            let onTheBorder = false;
            for (const m of object.rawProduct.m) {
                const objectMesh = object.mesh(m);
                for (const m of this.rawProduct.m) {
                    const containingMesh = this.mesh(m);
                    onTheBorder = containingMesh.geometricallyIntersects(objectMesh);
                    if (onTheBorder) {
                        objectsGeometricallyIntersects.push(object);
                        break;
                    }
                }
                if (onTheBorder) {
                    break;
                }
            }
            if (!onTheBorder) {
                objectsBboxNotInsideAndNotGeometriclyIntersecting.push(object);
            }
        }

        // This loop test witch object is completely inside containing geometry.
        if (includeEnclosedObjects) {
            for (const object of objectsBboxNotInsideAndNotGeometriclyIntersecting) {
                let allInside = true;
                for (const m of object.rawProduct.m) {
                    const objectMesh = object.mesh(m);
                    let isInside = false;
                    for (const m of this.rawProduct.m) {
                        const containingMesh = this.mesh(m);
                        isInside = containingMesh.geometricallyContains(objectMesh);
                        if (isInside) {
                            break;
                        }
                    }
                    if (!isInside) {
                        allInside = false;
                        break;
                    }
                }
                if (allInside) {
                    objectsCompletelyInsideGeometry.push(object);
                }
            }
        }

        const objectsToReturn: BimIfcObject[] = [];
        if (includeIntersectingObjects) {
            for (const obj of objectsGeometricallyIntersects) {
                objectsToReturn.push(obj);
            }
        }
        if (includeEnclosedObjects) {
            for (const obj of objectsCompletelyInsideGeometry) {
                objectsToReturn.push(obj);
            }
        }
        return objectsToReturn;
    }

    /**
     * @deprecated No longer does anything.
     */
    public applyColor(): void {}

    // Copy color of all meshes in object to dst
    public copyColorsTo(dst: Pick<Map<BimProductMesh, Writeable<ArrayLike<number>>>, 'get' | 'set'>): void {
        for (const m of this.productMeshes) {
            const rgba = dst.get(m) ?? [0, 0, 0, 0];
            m.copyColorTo(rgba);
            dst.set(m, rgba);
        }
    }

    public copyColorTo(m: BimProductMesh, dst: Writeable<ArrayLike<number>>, dstOffset = 0): void {
        return m.copyColorTo(dst, dstOffset);
    }

    public getColorComponent(m: BimProductMesh, colorComponent: RgbaComponent): number {
        return m.getColorComponent(colorComponent);
    }

    /** Get the closest parent in the IFC hiearchy. Equivalent of
     * @example
     * ```typescript
     * const parent = bimIfcObject.parents[bimIfcObjects.parents.length -1];
     * ```
     */
    public get parent(): BimIfcObject | undefined {
        return this.parents.length > 0 ? this.parents[this.parents.length - 1] : undefined;
    }

    /** Remove the IFC object and its children from the IFC hiearchy. */
    public remove(): void {
        if (this.parent) {
            const cLen = this.parent._children?.length;
            if (cLen) {
                for (let i = 0; i < cLen; ++i) {
                    if (this.parent._children![i] === this) {
                        this.parent._children?.splice(i, 1);
                        break;
                    }
                }
            }

            this.parents.length = 0;

            // TODO If we have removed the node then we can also delete a lot of data
            // from the .idx file. Such as no longer used transforms, meshes, meshdescriptors etc
        }
    }

    /** All children IFC objects. */
    public get children(): BimIfcObject[] {
        if (this._children) {
            return this._children!;
        }

        const parents = [...this.parents, this];
        this._children = [];
        const enclosingFloor = this instanceof BimIfcBuildingStorey ? this : this.enclosingFloor;
        for (const childId in this.rawProduct.cl) {
            this._children.push(
                this.createBimIfcObject(
                    childId,
                    this.ifcLoaderElement,
                    this.rawProduct.cl[childId],
                    parents,
                    enclosingFloor
                )
            );
        }

        // Save some memory since we now have the info in a BimIfcProduct instance
        // anyway. Faster to set as undefined than doing delete and delete can also interfere
        // with compiler optimizations.
        this.rawProduct.cl = undefined;
        return this._children;
    }

    public foreach(action: BimIfcObjectForEachAction): void {
        const recursionOptions: BimIfcObjectRecursionOptions = {
            stopRecursion: false,
            abort: false
        };
        for (const product of this.entries()) {
            action(product, recursionOptions);
            if (recursionOptions.abort) {
                break;
            } else if (recursionOptions.stopRecursion) {
                recursionOptions.stopRecursion = false;
                continue; // Do not visit any more child nodes below this node
            }
        }
    }

    /**
     * Gets a iterator for all Ifc products currently loaded.
     */
    public *entries(): IterableIterator<BimIfcObject> {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let next: BimIfcObject = this;
        const stack = [next];

        while (stack.length > 0) {
            next = stack.pop()!;
            // reference children here to ensure that when next is returned then next._children array
            // is already built. Would prefer to use the ... operator
            // but that can cause the call stack size to exceed the limit because it
            // pushes every element of the array onto the call stack.
            for (const child of next.children) {
                stack.push(child);
            }
            yield next;
        }
    }

    public get project(): BimIfcObject {
        return this.parents[0];
    }

    public get site(): BimIfcObject | undefined {
        return this.parents[1];
    }

    public get building(): BimIfcObject | undefined {
        return this.parents[2];
    }

    private static neverVisible(): boolean {
        return false;
    }

    private get materials(): Materials {
        return this.ifcLoaderElement.materials;
    }

    private static initializeCreateGeometryFrom(
        items: (BimIfcObject | { ifcObject: BimIfcObject; meshes: BimProductMesh[] })[],
        allowNotOnGpu: boolean
    ): {
        readonly usedLoaderElements: Set<IBimIfcLoaderElement>;
        readonly vdm: VertexDataMerge;
    } {
        const vdm = new VertexDataMerge('dummy', new GeometryBuffer(), {
            includeNormals: true,
            disableTransparencyAndMergeIdChecks: true
        });

        const usedLoaderElements = new Set<IBimIfcLoaderElement>();
        for (const i of items) {
            if ('ifcObject' in i && (allowNotOnGpu || i.ifcObject.isOnGpu())) {
                usedLoaderElements.add(i.ifcObject.ifcLoaderElement);
                const meshes = i.meshes?.length > 0 ? i.meshes : i.ifcObject.rawProduct.m;
                for (const m of meshes) {
                    vdm.add(m);
                }
            } else if (i instanceof BimIfcObject && (allowNotOnGpu || i.isOnGpu())) {
                usedLoaderElements.add(i.ifcLoaderElement);
                for (const m of i.rawProduct.m) {
                    vdm.add(m);
                }
            }
        }
        return { usedLoaderElements, vdm };
    }

    private possiblyVisible(visible?: boolean, meshes?: BimProductMesh[]): boolean {
        if (visible === undefined) {
            return this.rawProduct.m.some((m) => m.visible);
        }
        meshes = meshes ?? this.rawProduct.m;
        const len = meshes.length;
        let isVisible = false;
        for (let i = 0; i < len; ++i) {
            isVisible = visible || isVisible;
            meshes[i].visible = visible;
        }
        return isVisible;
    }

    private createBimIfcObject(
        entityLabelInIfc: string,
        ifcLoaderElement: IBimIfcLoaderElement,
        rawProduct: BimProduct,
        parents: BimIfcObject[],
        enclosingFloor?: BimIfcBuildingStorey
    ): BimIfcObject {
        const ifcClass = this.ifcLoaderElement.index.classes[rawProduct.c ?? 0];

        if (ifcClass === 'IfcSpace') {
            return new BimIfcSpace(entityLabelInIfc, ifcLoaderElement, rawProduct, parents, enclosingFloor);
        } else if (ifcClass === 'IfcBuildingStorey') {
            return new BimIfcBuildingStorey(entityLabelInIfc, ifcLoaderElement, rawProduct, parents, enclosingFloor);
        } else if (ifcClass === 'IfcDoor') {
            return new BimIfcDoor(entityLabelInIfc, ifcLoaderElement, rawProduct, parents, enclosingFloor);
        } else {
            return new BimIfcObject(entityLabelInIfc, ifcLoaderElement, rawProduct, parents, enclosingFloor);
        }
    }
}

export class BimIfcSpace extends BimIfcObject {
    public constructor(
        entityLabelInIfc: string,
        ifcLoaderElement: IBimIfcLoaderElement,
        rawProduct: BimProduct,
        parents: BimIfcObject[],
        enclosingFloor?: BimIfcBuildingStorey
    ) {
        super(entityLabelInIfc, ifcLoaderElement, rawProduct, parents, enclosingFloor);
    }

    public get calculatedArea(): number {
        return this.rawProduct.pd['calculatedArea'] as number;
    }

    public get elevationWithFlooring(): number {
        return this.rawProduct.pd['elevationWithFlooring'] as number;
    }

    public get spaceType(): string | undefined {
        return this.rawProduct.pd['spacetype'] as string | undefined;
    }

    public get longname(): string | undefined {
        return this.rawProduct.pd['longname'] as string | undefined;
    }
}

export class BimIfcBuildingStorey extends BimIfcObject {
    public readonly ifcObjectsWithGeometryCount: number = 0;

    public constructor(
        entityLabelInIfc: string,
        ifcLoaderElement: IBimIfcLoaderElement,
        rawProduct: BimProduct,
        parents: BimIfcObject[],
        enclosingFloor?: BimIfcBuildingStorey
    ) {
        super(entityLabelInIfc, ifcLoaderElement, rawProduct, parents, enclosingFloor);
    }

    public get elevation(): number {
        return this.rawProduct.pd['elevation'] as number;
    }

    public get totalHeight(): number {
        return this.rawProduct.pd['totalHeight'] as number;
    }

    public static is(o: BimIfcObject): o is BimIfcBuildingStorey {
        return o.class === BimIfcClass.ifcBuildingStorey;
    }
}

export class BimIfcDoor extends BimIfcObject {
    public constructor(
        entityLabelInIfc: string,
        ifcLoaderElement: IBimIfcLoaderElement,
        rawProduct: BimProduct,
        parents: BimIfcObject[],
        enclosingFloor?: BimIfcBuildingStorey
    ) {
        super(entityLabelInIfc, ifcLoaderElement, rawProduct, parents, enclosingFloor);
    }
    public get operationType(): string | undefined {
        return this.rawProduct.pd['operationType'] as string | undefined;
    }
}
