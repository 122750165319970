/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */
import { BimCoreApi } from '@twinfinity/core';
import '@twinfinity/core';

import { Texture } from '@babylonjs/core/Materials/Textures/texture';
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Axis, Space } from '@babylonjs/core/Maths/math.axis';
import { Plane } from '@babylonjs/core/Maths/math.plane';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { DynamicTexture } from '@babylonjs/core/Materials/Textures/dynamicTexture';
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';

export class BimDrawingPlane {
    mesh: Mesh;
    mathematicalPlane: Plane;
    texture: DynamicTexture;
    private _material: StandardMaterial;
    public readonly boundingInfo = new BoundingInfo(
        this._parent.position.add(this._parent.scaling.scale(-1)),
        this._parent.position.add(this._parent.scaling)
    );

    /**
     * @hidden Not for external use.
     */
    public constructor(
        private readonly _api: BimCoreApi,
        private readonly _parent: TransformNode,
        private readonly _textureSize: number
    ) {
        // this.texture = new Texture(null, this._api.viewer.scene, false, true, Texture.TRILINEAR_SAMPLINGMODE);
        this.texture = new DynamicTexture(
            'dynsvgtex',
            { width: this._textureSize, height: this._textureSize },
            _api.viewer.scene,
            true
        );
        this.texture.name = 'dwgZoomTexture';

        const xmin = 0;
        const zmin = 0;
        const xmax = 1;
        const zmax = 1;
        const precision = {
            w: 8,
            h: 8
        };
        const subdivisions = {
            h: 1,
            w: 1
        };

        // Use a tiled ground of size (0,0) - (1,1) to get uv coordinates
        // of (0,0) - (1,1).
        this.mesh = MeshBuilder.CreateTiledGround(
            'Tiled Ground',
            {
                xmin: xmin,
                zmin: zmin,
                xmax: xmax,
                zmax: zmax,
                subdivisions: subdivisions,
                precision: precision
            },
            this._api.viewer.scene
        );
        this.mesh.id = 'backgroundPlane';
        this.mesh.name = 'dwgZoomPlane';
        /*translate and rotate mesh then bake it into the verticies.
        this is done to center the plane and make sure its correctly rotated when viewing DWGs*/
        this.mesh.position.x -= 0.5;
        this.mesh.position.z -= 0.5;
        this.mesh.bakeCurrentTransformIntoVertices();
        this.mesh.rotate(Axis.Y, Math.PI, Space.WORLD);
        this.mesh.bakeCurrentTransformIntoVertices();

        this.mesh.parent = this._parent;

        this._material = new StandardMaterial('dwgZoomMaterial', this._api.viewer.scene);
        this._material.diffuseTexture = this.texture;
        this._material.disableLighting = true;

        this._material.diffuseTexture.wrapU = Texture.CLAMP_ADDRESSMODE;
        this._material.diffuseTexture.wrapV = Texture.CLAMP_ADDRESSMODE;
        this._material.specularColor = new Color3(0, 0, 0);
        this._material.backFaceCulling = true;
        this._material.emissiveColor = Color3.White();

        this.mesh.material = this._material;

        this.mathematicalPlane = Plane.FromPositionAndNormal(Vector3.Zero(), Axis.Y);

        this._api.selectables.attach(this.mesh);
    }

    /**
     * Rescales the plane based on points in world space.
     * Note that the plane should always be a square.
     * @param centerInWorld The point to place the rescaled plane.
     * @param size The new x and z scale of the plane.
     */
    public rescaleToPoints(frustumCornerPointsOnZoomPlane: Vector3[]): void {
        //TODO: Check if frustum height is greater than width, in which case this should be frustum height instead.
        const frustumWidthOnPlane = Math.abs(frustumCornerPointsOnZoomPlane[0].x - frustumCornerPointsOnZoomPlane[1].x);
        this.mesh.scaling.x = frustumWidthOnPlane / this.parent.scaling.x;
        this.mesh.scaling.z = frustumWidthOnPlane / this.parent.scaling.z;
        this.mesh.position.x = frustumCornerPointsOnZoomPlane[0].x / this.parent.scaling.x - this.mesh.scaling.x / 2;
        this.mesh.position.z = frustumCornerPointsOnZoomPlane[0].z / this.parent.scaling.z + this.mesh.scaling.z / 2;
    }

    /**
     * @hidden Not for external use.
     */
    public dispose(): void {
        this._material.dispose();
        this.texture.dispose();
        this.mesh.dispose();
    }

    /**
     * @hidden Not for external use.
     */
    public get parent(): TransformNode {
        return this._parent;
    }
}
