/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */
import { AxesViewer } from './AxesViewer';
import { Camera, Vector3, Scene, TransformNode, IDisposable, Vector2, Observer } from '../loader/babylonjs-import';

export class CoordinateAxesRenderer implements IDisposable {
    private _root?: TransformNode;
    private readonly _tempVector = Vector3.Zero();
    private _axesViewer?: AxesViewer;
    private readonly _orthoFrustumSize = Vector2.Zero();

    public scale = 0.01;
    private _onBeforeRenderObserver?: Observer<Scene>;

    public constructor(
        private readonly _scene: Scene,
        private readonly _getCoordinateAxesWorldPosition: () => Vector3 | undefined
    ) {
        this.isEnabled = false;
    }

    public get isEnabled(): boolean {
        return this._root?.isEnabled() ?? false;
    }

    public set isEnabled(v: boolean) {
        if (v && this._root === undefined) {
            this._root = new TransformNode('coordinateAxesRenderer', this._scene);
            this._axesViewer = new AxesViewer(this._root, 'coordinateAxes', 0.01, 2);
            this._onBeforeRenderObserver = this._scene.onBeforeRenderObservable.add(() => this.onUpdateAxesPosition())!;
        }
        this._root?.setEnabled(v);
    }

    public onUpdateAxesPosition(): void {
        if (this.isEnabled && this._root) {
            const worldPosition = this._getCoordinateAxesWorldPosition();
            const activeCamera = this._scene.activeCamera;
            if (worldPosition && activeCamera) {
                this._root.position.copyFrom(worldPosition);
                const cameraPosition = activeCamera.globalPosition;
                this._root.position.subtractToRef(cameraPosition, this._tempVector);
                if (activeCamera.mode === Camera.PERSPECTIVE_CAMERA) {
                    const dist = this._tempVector.length();
                    // ensure that object has same size for viewer regardless of distance from camera
                    this._root.scaling.set(dist, dist, dist);
                } else {
                    // Use constant scale in Orthographic view since
                    // we don't have perspective there.
                    // At abs(right - left) == 1 we want it to be say 10 cm. Ie we multiply frustom size with 0.1
                    activeCamera.twinfinity.getOrthoFrustumSizeToRef(this._orthoFrustumSize);
                    const scale = (this._orthoFrustumSize.x * 0.5) / activeCamera.getEngine().getHardwareScalingLevel();
                    this._root.scaling.set(scale, scale, scale);
                }

                // Account for handedness, similar to Matrix.decompose
                // if (effectiveMesh._getWorldMatrixDeterminant() < 0) {
                //     this._rootMesh.scaling.y *= -1;
                // }
            }
        }
    }

    public dispose(): void {
        if (this._onBeforeRenderObserver !== undefined) {
            this._root?.dispose(); // dispose both self and children
            this._root = undefined;
            this._axesViewer = undefined;

            this._scene.onBeforeRenderObservable.remove(this._onBeforeRenderObserver);
            this._onBeforeRenderObserver = undefined;
        }
    }
}
