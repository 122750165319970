import { DrawingApi } from '@twinfinity/experimental';
import { BimApi, BimChangeBlob, BimChangeDwg, ChangeRecorder, Projection } from '@twinfinity/core';

export class DwgTool {
    private _dwgApi: DrawingApi;
    private _pdfApi: DrawingApi<BimChangeBlob>;
    private _changeRecorder = new ChangeRecorder();
    constructor(private readonly _api: BimApi) {
        this._dwgApi = new DrawingApi(this._api, 4096);
        this._pdfApi = new DrawingApi(this._api, 4096);
    }

    public get dwgApi(): DrawingApi {
        return this._dwgApi;
    }

    public async load(dwgs: BimChangeDwg[]): Promise<void> {
        await this._dwgApi.load(dwgs);
        this.onEnable(this._dwgApi);
    }

    public async loadPdfs(pdf: BimChangeBlob): Promise<void> {
        await this._pdfApi.load(pdf);
        this.onEnable(this._pdfApi);
    }

    public async incrementPage(): Promise<void> {
        for (const pdf of this._pdfApi) {
            const currentPage = await pdf.page();
            await pdf.page(currentPage + 1);
        }
    }

    public async decrementPage(): Promise<void> {
        for (const pdf of this._pdfApi) {
            const currentPage = await pdf.page();
            await pdf.page(currentPage - 1);
        }
    }

    private async onEnable(api: DrawingApi<BimChangeDwg | BimChangeBlob>): Promise<void> {
        this._changeRecorder.set(this._api.grid, 'isEnabled', false);
        this._changeRecorder.set(this._api, 'axes', true);
        this._changeRecorder.set(this._api.viewer.camera, 'projection', Projection.Orthographic);
        // DwgApi cannot handle rotating camera yet.
        this._changeRecorder.set(this._api.viewer.camera.options, 'isRotationEnabled', false);

        const isIfcRootNodeEnabled = this._api.viewer.ifcRootNode.isEnabled();
        this._changeRecorder.onRestore(this._api.viewer.ifcRootNode, (n) => n.setEnabled(isIfcRootNodeEnabled));
        this._api.viewer.ifcRootNode.setEnabled(false);

        // Ensure camera is positioned so it looks at the DWG from the top.
        await api.fitDrawingsToCamera(this._api.viewer.camera);

        this._changeRecorder.onRestore(this._api.camera, (c) => c.defaultView());
        const topdownToggle = document.getElementById('cbTopdownToggle') as HTMLInputElement;
        this._changeRecorder.set(topdownToggle, 'disabled', true);

        const orthoToggle = document.getElementById('cbOrthoToggle') as HTMLInputElement;
        this._changeRecorder.set(orthoToggle, 'disabled', true);
    }

    public clear(): void {
        this._dwgApi.clear();
        this._pdfApi.clear();
        this._changeRecorder.restoreAll();
    }

    public setGrayscale(grayscale: boolean): void {
        this._dwgApi.foreach((dwg) => {
            dwg.setGrayscale(grayscale);
        });
    }

    public invertBackgroundColor(invert: boolean): void {
        this._dwgApi.foreach((dwg) => {
            dwg.invertBackgroundColor(invert);
        });
    }

    // public setPage(page: number): void {
    //     this._pdfApi.foreach((pdf) => {
    //         pdf.setPage(page);
    //     });
    // }

    public forceRenderAllDwgs(): void {
        this._dwgApi.foreach(async (dwg) => {
            await dwg.forceUpdate();
        });
    }
}
