import { AbstractMesh, IShaderMaterialOptions, Scene, Vector2 } from '../babylonjs-import';
import { DITHER_SAMPLER, DITHER_SIZE, DITHERING, RESOLUTION } from '../ShaderSnippets';
import { CullingMaterial, UniformsAndDefinesAndSamplers } from './CullingMaterial';
import { DitherTexture } from './DitherTexture';

export abstract class DitheringMaterial extends CullingMaterial {
    protected _ditherSize: number;
    protected _isDitherModeEnabled = true;

    abstract getFragmentSource(): string;
    abstract getVertexSource(): string;

    constructor(
        name: string,
        scene: Scene,
        options: Partial<IShaderMaterialOptions>,
        public readonly ditherTexture: DitherTexture,
        writeColorBlending: boolean,
        excludeIgnoreDepthWriteCuttingPlanes: boolean
    ) {
        super(name, scene, options, writeColorBlending, excludeIgnoreDepthWriteCuttingPlanes);

        this._ditherSize = ditherTexture.getTexture(scene).getSize().width;

        this.ditherTexture = ditherTexture;

        this.pictureSettings.ifc.transparency.onPropertyChanged.add((change) => {
            if (change.property === 'ditheringOptions') {
                // Updates the dither texture to calculate the new frames, no need to mark this material as dirty since
                this.ditherTexture.currentMode = this.pictureSettings.ifc.transparency.ditheringOptions.mode;
                this.ditherTexture.animatedNoiseTextures =
                    this.pictureSettings.ifc.transparency.ditheringOptions.animatedFrames;
            }
        });
    }

    getUniformsAndDefinesAndSamplers(): UniformsAndDefinesAndSamplers {
        const defines: string[] = [];
        const uniforms = [];
        const samplers = [];

        const uniformsAndDefines = super.getUniformsAndDefinesAndSamplers();

        if (this._isDitherModeEnabled) {
            defines.push(DITHERING);
            uniforms.push(...[RESOLUTION, DITHER_SIZE]);
            samplers.push(DITHER_SAMPLER);
        }

        return {
            uniforms: [...uniforms, ...uniformsAndDefines.uniforms],
            defines: [...defines, ...uniformsAndDefines.defines],
            samplers: [...samplers, ...uniformsAndDefines.samplers]
        };
    }

    override bindFunction(mesh: AbstractMesh): void {
        super.bindFunction(mesh);

        const effect = this.getEffect();
        effect.setTexture(DITHER_SAMPLER, this.ditherTexture.getTexture(mesh.getScene()));

        if (this._isDitherModeEnabled) {
            const scene = this.getScene();

            effect.setVector2(
                DITHER_SIZE,
                new Vector2(
                    scene.getEngine().getRenderWidth() / this._ditherSize,
                    scene.getEngine().getRenderHeight() / this._ditherSize
                )
            );

            this.getEffect().setVector2(
                RESOLUTION,
                new Vector2(scene.getEngine().getRenderWidth(), scene.getEngine().getRenderHeight())
            );
        }
    }
}
