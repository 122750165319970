import { SettingsApiClient } from './SettingsApiClient';
import { BimUserSettingsApiClient } from './BimUserSettingsApiClient';
import { UserSettingApCLient } from './UserSettingApCLient';

export class BimSettingsApiClient implements SettingsApiClient {
    public user: UserSettingApCLient;

    public constructor(baseUrl: URL) {
        this.user = new BimUserSettingsApiClient(baseUrl);
    }
}
