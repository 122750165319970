import { Color3 } from '@babylonjs/core/Maths/math.color';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { BimIfcObject } from '@twinfinity/core';
import { FloatArray, VertexBuffer } from '@babylonjs/core';

export class VertexNormalsRenderer {
    public constructor(private readonly Mesh: Mesh) {}

    public static create(o: { mesh: Mesh } | { mesh: Mesh; ifcProduct: BimIfcObject }): VertexNormalsRenderer {
        let center = o.mesh.position;
        let normals: FloatArray;
        let positions: FloatArray;
        let meshId = `${o.mesh.id}_vertexNormals`;

        if ('ifcProduct' in o) {
            const geom = BimIfcObject.createGeometryFrom([o.ifcProduct]);
            positions = geom.positions;
            normals = geom.copyNormalsTo(new Float32Array(geom.positions.length));
            center = geom.center;
            meshId = `${o.mesh.id}_${o.ifcProduct.entityLabelInIfc}_vertexNormals`;
        } else {
            positions = o.mesh.getVerticesData(VertexBuffer.PositionKind)!;
            normals = o.mesh.getVerticesData(VertexBuffer.NormalKind)!;
        }

        const lines: [Vector3, Vector3][] = [];

        for (let i = 0; i < normals!.length; i += 3) {
            const v1 = Vector3.FromArray(positions, i).addInPlace(center);
            const v2 = Vector3.FromArray(normals, i).scaleInPlace(0.07).addInPlace(v1);
            lines.push([v1, v2]);
        }

        const normalLines = MeshBuilder.CreateLineSystem(meshId, { lines }, o.mesh.getScene());
        if (o.mesh.parent !== undefined) {
            normalLines.parent = o.mesh.parent;
        }
        normalLines.color = Color3.Red();
        normalLines.isPickable = false;
        return new VertexNormalsRenderer(normalLines);
    }

    public dispose(): void {
        this.Mesh.dispose();
    }
}
