import {
    BimApi,
    PickOptionType,
    PredefinedCanvasPosition,
    PolyLine,
    TrackCoordinate3D,
    TrackCoordinate2D,
    copyVertex3ToRef,
    TrackCoordinate2DState,
    Vertex3
} from '@twinfinity/core';

/**
 * Custom class for point in the PolyLine tool. This is not at all
 * required. You can use any object that implements TrackCoordinate3D (which in turn)
 * extends Vertex3. Ie you can use a simple Vector3 if that is what you need.
 */
class MeasurePointWithLabel implements TrackCoordinate3D {
    public x = 0;
    public y = 0;
    public z = 0;
    private _label?: HTMLDivElement;
    public constructor(public readonly parent: PolyLine<MeasurePointWithLabel>, pos: Vertex3) {
        copyVertex3ToRef(pos, this);
        parent.points.push(this);
    }

    public updateHTMLElement(o: TrackCoordinate2D<MeasurePointWithLabel>): void {
        const state = o.state;

        if (this._label === undefined) {
            this._label = document.createElement('div');
            this._label.classList.add('polyLinePoint');
        }

        // Position label so it is not where mouse pointer is (because then it steals focus)
        const clientRect = this._label.getBoundingClientRect();
        this._label.style.left = `${Math.floor(o.position.x - clientRect.width) - 2}px`;

        this._label.style.top = `${Math.floor(o.position.y - clientRect.height) - 2}px`;
        this._label.style.display = o.visible ? 'block' : 'none';

        let innerHtml = `
            <div>
                <span>p: ${this.x.toFixed(1)}, ${this.y.toFixed(1)}, ${this.z.toFixed(1)}</span>
            </div>`;

        const isFirstPoint = this.parent.points[0] === this;
        if (!isFirstPoint) {
            // calculate distance from the first point
            const distance = this.parent.distance({ end: this });
            innerHtml += `
                <div>
                    <span>d:</span>
                    <span>${distance.toFixed(1)} m</span>
                </div>`;
        }

        this._label.innerHTML = innerHtml;

        if (state === TrackCoordinate2DState.Added) {
            this._label = document.body.appendChild(this._label);
            this._label.addEventListener('click', (o) => {
                // Remove the point from the polyline.
                const indexOfPoint = this.parent.points.findIndex((p) => p === this);
                this.parent.points.splice(indexOfPoint, 1);
                this.parent.apply();
                o.preventDefault();
            });
        } else if (state === TrackCoordinate2DState.Deleted) {
            this._label.remove();
        } else if (state === TrackCoordinate2DState.Updated) {
        }
    }
}

/**
 * A simple sample that shows how to use {@link PolyLine} to make a
 * tool that measures the distance between 2 or more points.
 */
export class PolyLineTool {
    private _polyLine: PolyLine<MeasurePointWithLabel>;
    private _isEnabled = false;

    /**
     * If `false` then it is possible to add new points and move the last point.
     */
    public isDone = false;

    public constructor(name: string, private readonly _api: BimApi) {
        this._polyLine = new PolyLine(name, _api);
        this._polyLine.color.set(0, 1, 0);
        this._polyLine.onPointTrackableScreen.add((eventData, eventState) => {
            const measurePointWithLabel = eventData.trackedCoordinate;
            measurePointWithLabel.updateHTMLElement(eventData);
        });
    }

    /**
     * Number of points in the polyline
     */
    public get size(): number {
        return this._polyLine.points.length;
    }

    /**
     * `true` if laser tool is activated
     */
    public get isEnabled(): boolean {
        return this._isEnabled;
    }

    /**
     * Set to `true` to activate laser tool. Set to `false` to deactivate
     * and and remove all current lasers.
     */
    public set isEnabled(v: boolean) {
        this._isEnabled = v;
        if (!v) {
            this._polyLine.clear();
            this.isDone = false;
        }
    }

    /**
     * Attempts to add a point to the polyline at the current pointer position.
     */
    public attemptAddPointAtCurrentPointerPosition(): boolean {
        if (!this.isEnabled || this.isDone) {
            return false;
        }
        const pointOrigin = this._api.selectables.pick({
            type: PickOptionType.Canvas,
            position: PredefinedCanvasPosition.Mouse,
            isGeometryIntersectionEnabled: true // Here we need exact click position
        }).hitInfo[0];

        if (pointOrigin) {
            new MeasurePointWithLabel(this._polyLine, pointOrigin.position);
            if (this._polyLine.points.length === 1) {
                // Need at least two points to make a line (and end point shall follow mouse cursor).
                new MeasurePointWithLabel(this._polyLine, pointOrigin.position);
            }
            this._polyLine.apply(); // Triggers calls to onPolyLinePointChange
        }
        return !!pointOrigin;
    }

    /**
     * Moves the last point so it is located beneath the mouse cursor. If there is no object
     * beneath the mouse cursor it will snap back to the second last point in the polyline.
     */
    public moveEndPointWithMouseCursor(): void {
        if (this.isDone || !this.isEnabled || this._polyLine.points.length < 2) {
            return;
        }
        const pointerOrigin = this._api.selectables.pick({
            type: PickOptionType.Canvas,
            position: PredefinedCanvasPosition.Mouse,
            isGeometryIntersectionEnabled: true // Here we need exact click position
        }).hitInfo[0];

        const lastLineEnd = this._polyLine.points[this._polyLine.points.length - 1];

        if (pointerOrigin) {
            // we got a hit on a object in 3d so move the end of the line there.
            copyVertex3ToRef(pointerOrigin.position, lastLineEnd);
        } else {
            // we got no hit on object in 3D so move end line pos back to the starting point of
            // the last line this will give the illusion that the line disappears and it will reappear
            // once the line pointer is over a  objekt again.
            const lastLineStart = this._polyLine.points[this._polyLine.points.length - 2];
            copyVertex3ToRef(lastLineStart, lastLineEnd);
        }
        // Geometry is updated and triggers calls to onPolyLinePointChange
        this._polyLine.apply();
    }
}
