/**
 * Represents status of a Change.
 */

export enum BimChangeStatus {
    /**
     * Unknown
     */
    Unknown = 'Unknown',
    /**
     * Change is currently being processed. Data is being extracted.
     */
    Processing = 'Processing',

    /**
     * Change has been processed and relevant data has been extracted.
     * This symbolizes a successfully stored change in the system (for example
     * a successfully stored IFC file)
     */
    Processed = 'Processed',

    /**
     * Change has been processed but for some reason a failure occurred.
     * This indicates that changes are stored in the system but some kind of failure
     * occurred.
     * For example, relevant information could not be extracted from a IFC file so it is
     * not possible to visualize it in 3D.
     */
    Failed = 'Failed',

    /**
     * This type of change is not supported yet. (For example 3D DWG)-
     */
    NotSupported = 'NotSupported'
}
