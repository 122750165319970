/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import { BimIfcIndex } from './BimIfcIndex';
import { BimObjectAttribute } from './BimObjectAttribute';
import { BimTypeObject } from './BimTypeObject';
import { BimTypeObjectReader } from './BimTypeObjectReader';

export class BimTypeObjectRepository {
    readonly types: BimTypeObject[];

    public constructor(private readonly _index: BimIfcIndex, objectAttributes: BimObjectAttribute[]) {
        this.types = new BimTypeObjectReader().read(_index, objectAttributes);
    }

    public has(id: number): boolean {
        return id >= 0 && id < this.types.length;
    }

    public get(id: number): BimTypeObject {
        return this.types[id];
    }
}
