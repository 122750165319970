import { Effect } from './babylonjs-import';
import { UniformsAndDefinesAndSamplers } from './CustomBabylonMaterials/CullingMaterial';
import { HSV_VALUES, USE_HSV_MAPPING } from './ShaderSnippets';

/**
 * Used to modify the fragments output by materials {@link IfcMaterial} and {@link TransparentIfcMaterial}.
 * If enabled {@link isEnabled} then the following occurs.
 * Each RGB fragment (alpha is untouched) is converted to HSV and multiplied by the corresponding value in {@link multiplier}.
 * The resulting HSV value is then converted back to RGB and output as a fragment, with the original alpha intact.
 * One use case is to set {@link multiplier.s} and {@link multiplier.v} to 1.2 and 1.5 to make colors pop. White becomes whiter, black
 * becomes blacker, red becomes redder etc.
 */
export class IfcFragmentHsvColoringSettings {
    public readonly multiplier = { h: 1, s: 1.2, v: 1.5 };

    /**
     * Represents the HSV parameters for a loader.
     */
    public constructor(
        /**
         * Indicates whether the HSV parameters are enabled.
         */
        public isEnabled: boolean,

        /**
         * The HSV multiplier values.
         */
        multiplier?: Partial<IfcFragmentHsvColoringSettings['multiplier']>
    ) {
        this.multiplier = { ...this.multiplier, ...(multiplier ?? {}) };
    }

    /**
     * Inserts uniforms and defines into the provided `UniformsAndDefinesAndSamplers` object.
     * If the `isEnabled` flag is true, it adds the HSV values and the USE_HSV_MAPPING define.
     * For internal use only.
     * @internal
     * @hidden
     * @param uniformsAndDefines - The object to insert uniforms and defines into.
     */
    public _insertUniformsAndDefines(uniformsAndDefines: UniformsAndDefinesAndSamplers): void {
        if (this.isEnabled) {
            uniformsAndDefines.uniforms.push(HSV_VALUES);
            uniformsAndDefines.defines.push(USE_HSV_MAPPING);
        }
    }

    /**
     * Assigns effect values for the HSV effect. For internal use only.
     * @hidden
     * @internal
     * @param effect - The effect to assign values to.
     */
    public _setEffectValues(effect: Effect): void {
        effect.setFloat3(HSV_VALUES, this.multiplier.h, this.multiplier.s, this.multiplier.v);
    }
}
