import { Camera, Matrix } from '../loader/babylonjs-import';

export interface ProjectionMatrixOptionsBase {
    minZ?: number;
    maxZ?: number;
}

export interface ProjectionMatrixPerspectiveOptions extends ProjectionMatrixOptionsBase {
    fov?: number;
}

export interface ProjectionMatrixOrthographicOptions extends ProjectionMatrixOptionsBase {
    ortho?: { left?: number; right?: number; bottom?: number; top?: number };
}

export type ProjectionMatrixOptions = ProjectionMatrixPerspectiveOptions | ProjectionMatrixOrthographicOptions;

export class ProjectionMatrix {
    public static getToRef(camera: Camera, options: ProjectionMatrixOptions, dstMatrix: Matrix): Matrix {
        if ('fov' in options && camera.mode !== Camera.PERSPECTIVE_CAMERA) {
            throw new Error('fov must be used with a non perspective camera.');
        }

        if ('ortho' in options && camera.mode !== Camera.ORTHOGRAPHIC_CAMERA) {
            throw new Error('ortho must be used with a orthographic camera.');
        }

        const scene = camera.getScene();
        const engine = camera.getEngine();
        if (camera.mode === Camera.PERSPECTIVE_CAMERA) {
            let getProjectionMatrix = Matrix.PerspectiveFovLHToRef;
            if (scene.useRightHandedSystem) {
                getProjectionMatrix = Matrix.PerspectiveFovRHToRef;
            }
            const reverseDepth = engine.useReverseDepthBuffer;
            const perspectiveOptions = options as ProjectionMatrixPerspectiveOptions;
            let minZ = perspectiveOptions.minZ ?? camera.minZ;
            if (minZ <= 0) {
                minZ = 0.1;
            }
            const znear = reverseDepth ? perspectiveOptions.maxZ ?? camera.maxZ : minZ;
            const zfar = reverseDepth ? minZ : perspectiveOptions.maxZ ?? camera.maxZ;
            getProjectionMatrix(
                perspectiveOptions.fov ?? camera.fov,
                engine.getAspectRatio(camera),
                znear,
                zfar,
                dstMatrix,
                camera.fovMode === Camera.FOVMODE_VERTICAL_FIXED,
                engine.isNDCHalfZRange,
                camera.projectionPlaneTilt,
                engine.useReverseDepthBuffer
            );
        } else if (camera.mode === Camera.ORTHOGRAPHIC_CAMERA) {
            let getProjectionMatrix = Matrix.OrthoOffCenterLHToRef;
            if (scene.useRightHandedSystem) {
                getProjectionMatrix = Matrix.OrthoOffCenterRHToRef;
            }

            const halfWidth = engine.getRenderWidth() / 2.0;
            const halfHeight = engine.getRenderHeight() / 2.0;
            const ortographicOptions = options as ProjectionMatrixOrthographicOptions;
            getProjectionMatrix(
                ortographicOptions.ortho?.left ?? camera.orthoLeft ?? -halfWidth,
                ortographicOptions.ortho?.right ?? camera.orthoRight ?? halfWidth,
                ortographicOptions.ortho?.bottom ?? camera.orthoBottom ?? -halfHeight,
                ortographicOptions.ortho?.top ?? camera.orthoTop ?? halfHeight,
                ortographicOptions.minZ ?? camera.minZ,
                ortographicOptions.maxZ ?? camera.maxZ,
                dstMatrix,
                engine.isNDCHalfZRange
            );
        } else {
            throw new Error('Not a supported camera mode.');
        }

        return dstMatrix;
    }
}
