import { MarkupEntity, MarkupEntityConstructorArgs } from './MarkupEntity';
import { MarkupSheet2D } from './MarkupSheet2D';
import { Vertex3 } from '../../math';
import { Color3, Color4 } from '../../loader/babylonjs-import';
import { MarkupProperty, MarkupStyle } from '../MarkupEntityTypes';
import { equalsWithEpsilonVertex3 } from '../../math/index';
import { MergableObjectEventArgs, MergableObjectEventSource } from '../../MergableSet';

/**
 * Style for the markup area.
 */
export interface MarkupAreaStyle {
    /** Background color for the markup area */
    backgroundColor: Color4;
}

/**
 * Class representing a markup area in the markup API.
 */
export class MarkupArea extends MarkupEntity implements MarkupStyle<MarkupAreaStyle> {
    style = { backgroundColor: new Color4(...Color3.Teal().asArray(), 1.0) };
    points: Vertex3[];

    /**
     * Do not use. Use {@link MarkupSheet2D.add} instead.
     * @hidden
     */
    public constructor({
        id,
        sheet,
        properties,
        points,
        style
    }: MarkupEntityConstructorArgs & { points: Vertex3[]; style: MarkupAreaStyle }) {
        super(id, sheet, properties);
        this.points = points;
        this.style = style;
    }

    /**
     * Copy the area to another markup sheet.
     * @param sheet Markup sheet to copy the area to.
     * @returns The new markup area entity.
     */
    copyTo(sheet: MarkupSheet2D): MarkupArea {
        const deepCopyPoints: Vertex3[] = [];

        for (let i = 0; i < this.points.length; i++) {
            const originalVertex = this.points[i];

            deepCopyPoints.push({ x: originalVertex.x, y: originalVertex.y, z: originalVertex.z });
        }
        return sheet.add(
            MarkupArea,
            {
                points: deepCopyPoints,
                style: { backgroundColor: this.style.backgroundColor.clone() }
            },
            new Map<string, MarkupProperty>(this.properties)
        );
    }

    onUpdate(o: MergableObjectEventArgs<MarkupArea>): void {
        if (o.eventSource === MergableObjectEventSource.Remote && !o.conflictReason && o.remote) {
            this.copyFrom(o.remote);
        }

        super.onUpdate(o);
    }

    copyFrom(area: MarkupArea): void {
        this.points = this.points = area.points.map((p) => {
            return { ...p };
        });

        this.properties.clear();
        for (const [key, value] of area.properties) {
            this.properties.set(key, value);
        }
    }

    clone(): MarkupArea {
        const deepCopyPoints: Vertex3[] = [];

        for (let i = 0; i < this.points.length; i++) {
            const originalVertex = this.points[i];

            deepCopyPoints.push({ x: originalVertex.x, y: originalVertex.y, z: originalVertex.z });
        }
        const area = new MarkupArea({
            id: this.id,
            points: deepCopyPoints,
            sheet: this.sheet,
            properties: new Map<string, MarkupProperty>(this.properties),
            style: { backgroundColor: this.style.backgroundColor.clone() }
        });
        return area;
    }

    isEqual(o: MarkupArea): boolean {
        return (
            o.id === this.id &&
            o.sheet.isEqual(this.sheet) &&
            o.properties.size === this.properties.size &&
            [...this.properties].every(([key, value]) => value === o.properties.get(key)) &&
            this.points.length === o.points.length &&
            [...this.points].every((point, idx) => equalsWithEpsilonVertex3(point, o.points[idx]))
        );
    }
}
