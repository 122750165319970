import { BimCoreApi, boundingClientRectCache, BimChangeBlob, BimChangeDwg } from '@twinfinity/core';
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo';
import { BimDrawing } from './BimDrawing';
import { Nullable } from '@babylonjs/core/types';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { BimDrawingPdf } from './BimDrawingPdf';
import { BimDrawingDwg } from './BimDrawingDwg';

export interface BimDrawingObjectRecursionOptions {
    stopRecursion: boolean;
    abort: boolean;
}

export type DrawingType<Change> = Change extends BimChangeDwg
    ? BimDrawingDwg
    : Change extends BimChangeBlob
    ? BimDrawingPdf
    : never;

export interface BimDrawingObjectForEachAction<T> {
    (o: DrawingType<T>, recursionOptions: BimDrawingObjectRecursionOptions): void;
}

//TODO: Create root transform node for all DWGs similar to the root transform node for IFCs
/**
 * Represents a drawing that has been loaded using the DrawingApi class.
 */
export class BimDrawingObject {
    /**
     * Constructor. For internal use only
     * @internal
     * @hidden
     * @param _api BimApi
     * @param _drawing {@link BimSvg} or {@link BimPdf} instance
     * @param drawingChange  {@link BimChangeDwg} or {@link BimChangeBlob} that the drawing source is loaded from
     * @param _onDisposed Callback which is invoked when `this.dispose` is called-
     */
    public constructor(
        protected readonly _api: BimCoreApi,
        protected readonly _drawing: BimDrawing,
        public readonly drawingChange: BimChangeDwg | BimChangeBlob,
        protected readonly _onDisposed: (o: BimDrawingObject) => void
    ) {}
    //TODO: add enclosing floor so we can use data to place dwgs in world space in the future.
    // public foreach(action: BimDwgObjectForEachAction): void {} //To be implemented

    /**
     * `true` if the drawing is visible, otherwise `false`.
     */
    public get visible(): boolean {
        return this._drawing.parent.plane.mesh.isEnabled();
    }
    //TODO register/unregister oncameramove observable
    public set visible(v: boolean) {
        this._drawing.parent.plane.mesh.setEnabled(v);
    }

    /**
     * Bounding info for the drawing plane
     */
    public get boundingInfo(): BoundingInfo {
        return this._drawing.parent.plane.boundingInfo;
    }

    /**
     * Dispose the drawing (it is unloaded and will not longer be visualized.)
     */
    public dispose(): void {
        this._drawing.parent.dispose();
        this._onDisposed(this);
    }

    /**
     * Force the drawing to zoom based on the camera position and render.
     */
    public async forceUpdate(): Promise<void> {
        await this._drawing.parent.zoom(
            this._api.viewer.camera.activeCamera,
            boundingClientRectCache.getOrAdd(this._api.viewer.canvas)
        );
    }

    public get parentTransformNode(): Nullable<TransformNode> {
        return this._drawing.parent.plane.parent;
    }
}
