import { Observable } from '@babylonjs/core/Misc/observable';

const textAttributeName = 'text';

/**
 * Represents a custom button element.
 */
export class TFButton extends HTMLElement {
    /**
     * Observable that notifies when the button is clicked.
     */
    public readonly onClickObservable = new Observable<MouseEvent>();

    /**
     * Creates a new instance of the TFButton class.
     * @param o - Optional configuration object.
     */
    constructor(o?: { text?: string }) {
        super();
        if (o?.text != null) {
            this.text = o.text;
        }
        this.attachShadow({ mode: 'open' });
        this.style.pointerEvents = 'auto';
        this.render();
    }

    public static initialize(): void {}

    /**
     * Gets the text of the button.
     */
    public get text(): string | null {
        return this.getAttribute(textAttributeName);
    }

    /**
     * Sets the text of the button.
     * @param val - The text to set.
     */
    public set text(val: string | null | undefined) {
        if (val == null) {
            this.removeAttribute(textAttributeName);
        } else {
            if (val !== this.text) {
                this.setAttribute(textAttributeName, val);
            }
        }
    }

    /**
     * Called when the element is connected to the DOM.
     */
    connectedCallback(): void {
        this.addEventListener('click', this.handleClick);
    }

    /**
     * Called when the element is disconnected from the DOM.
     */
    disconnectedCallback(): void {
        this.onClickObservable.clear();
        this.removeEventListener('click', this.handleClick);
    }

    /**
     * Returns the list of attributes to observe for changes.
     */
    static get observedAttributes(): [string] {
        return [textAttributeName];
    }

    /**
     * Called when an observed attribute has changed.
     * @param name - The name of the attribute that changed.
     * @param oldValue - The previous value of the attribute.
     * @param newValue - The new value of the attribute.
     */
    attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
        if (name === textAttributeName && oldValue !== newValue) {
            this.render();
        }
    }

    /**
     * Handles the click event of the button.
     * @param ev - The click event.
     */
    private handleClick(ev: MouseEvent): void {
        this.onClickObservable.notifyObservers(ev);
    }

    /**
     * Renders the button element.
     */
    private render(): void {
        if (this.shadowRoot) {
            const text = this.getAttribute(textAttributeName) || 'Click me';
            this.shadowRoot.innerHTML = `          
                <button type="button">${text}</button>
            `;
        }
    }
}

customElements.define('tf-button', TFButton);
