/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */
import { Extensions } from '../Extensions';
import { Scene } from './babylonjs-import';
import { TwinfinityViewer } from './twinfinity-viewer';

/**
 * Twinfinity extension for {@link Scene} objects
 */
export interface TwinfinitySceneExtensions {
    /**
     * If it is the main {@link Scene} (created by {@link TwinfinityViewer}) then this property
     * will point to said {@link TwinfinityViewer}.
     */
    viewer?: TwinfinityViewer;
}

declare module '@babylonjs/core/scene' {
    export interface Scene {
        readonly twinfinity: TwinfinitySceneExtensions;
    }
}

Object.defineProperty(Scene.prototype, Extensions.TWINFINITY_PROPERTY, {
    enumerable: false,
    configurable: true,
    get: function (this: Scene): TwinfinitySceneExtensions {
        const ret = { viewer: undefined };
        Object.defineProperty(this, Extensions.TWINFINITY_PROPERTY, {
            value: ret
        });
        return ret;
    }
});
