/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */
import { TypedResponse, HttpResponseType, HttpStatusCode, Http } from '../../http';
import { DeepImmutable } from '../babylonjs-import';
import { MailMessage, MailMessageId, MessageApiClient } from './MessageApiClient';

/** See {@link LayerApi} for documentation.  */
export class BimMessageApiClient implements MessageApiClient {
    private static readonly _httpResponseType = HttpResponseType.status([HttpStatusCode.Ok]);

    public constructor(readonly _baseApiUrl: URL) {}

    public async sendMailMessage(mailMessage: DeepImmutable<MailMessage>): Promise<TypedResponse<string>> {
        return await Http.post<MailMessageId>(
            `${this._baseApiUrl.toString()}_ps/api/mail/submit`,
            BimMessageApiClient._httpResponseType.json,
            {
                body: JSON.stringify(mailMessage),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );
    }
}
