/** @internal NOTE: Internal APIs. Subject to change. Use of these APIs in production applications is not supported. */ /** */

import '@twinfinity/core';
import { SvgContent } from './SvgContent';
import { ISize } from '@babylonjs/core/Maths/math.size';
import { Vertex2 } from '@twinfinity/core';
import { BimDrawing } from './BimDrawing';

export class BimSvg extends BimDrawing {
    readonly svgSource: string;
    readonly svgContent: SvgContent;
    readonly offset: Vertex2;
    readonly size: ISize;

    public constructor(svg: string, x: number, y: number, width: number, height: number) {
        super();
        this.offset = { x: x, y: y };
        this.size = { width: width, height: height };

        svg = svg.replace('preserveAspectRatio="xMinYMin slice"', '');
        svg = svg.replace('preserveAspectRatio="none"', '');
        this.svgSource = svg;
        const match = /<svg[^>]+>/i.exec(this.svgSource);
        /**
         * it should be noted that this will only work if the svgSource only contains 1 <svg> tag.
         */
        if (match !== null) {
            this.svgContent = {
                start: this.svgSource.slice(0, match.index),
                header: match[0],
                content: this.svgSource.slice(match.index + match[0].length).replace('</svg>', ''),
                addedSvgs: [],
                closingHeader: '</svg>'
            };
        }
    }
}
