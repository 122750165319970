// const spaceLabels = document.getElementById('spaceLabels') as HTMLDivElement;

export class ObjectPool<T> {
    private readonly _pool: T[] = [];
    public constructor(private readonly _objectFactory: () => T) {}

    public get(): T {
        return this._pool.pop() ?? this._objectFactory();
    }

    public release(o: T): void {
        this._pool.push(o);
    }
}
