export class ProgressLine {
    public constructor(private readonly _parentElement: HTMLParagraphElement) {}
    public get text(): string {
        return this._parentElement.textContent!;
    }

    public set text(v: string) {
        this._parentElement.textContent = v;
    }
}

export class TFProgress extends HTMLElement {
    public constructor() {
        super();
    }

    public static initialize(): void {}

    public appendText(text: string): ProgressLine {
        const p = document.createElement('p');
        p.textContent = text;
        this.appendChild(p);
        return new ProgressLine(p);
    }

    public clear(): void {
        this.innerHTML = '';
    }
}

customElements.define('tf-progress', TFProgress);
